import { createSelector } from '@ngrx/store';
import { State } from '../reducers';

export const selectApplicationState = (state: State) => state.application;
// next deadline date
export const getDeadline = createSelector(
    selectApplicationState,
    application => application.deadline
);
// days remaining til deadline date
export const getRemaining = createSelector(
    selectApplicationState,
    application => application.remainingDays
);
// can request enrollment
export const getEnroll = createSelector(
    selectApplicationState,
    application => application.canEnroll
);
// can request booking
export const getCanBooking = createSelector(
    selectApplicationState,
    application => application.canBook
);
// has requested booking acceptance
export const hasReqBooking = createSelector(
    selectApplicationState,
    application => application.hasReqBook
);
// who is this users coach or advisor
export const getAmoHelper = createSelector(
    selectApplicationState,
    application => application.amoHelper
);
// who is this users coach or advisor
export const getDeadlineMessage = createSelector(
    selectApplicationState,
    application => application.deadlineMessage
);
// get is application late
export const getIsLate = createSelector(
    selectApplicationState,
    application => application.isLate
);
// get is application late fee
export const getLateFee = createSelector(
    selectApplicationState,
    application => application.lateFee
);
// get application has coupon
export const getHasCoupon = createSelector(
    selectApplicationState,
    application => application.hasCoupon
);
// get application has coupon
export const getNeedMalpractice = createSelector(
    selectApplicationState,
    application => application.needMalpractice
);
// get user has at least 1 reserved active application
export const getHasReserved = createSelector(
    selectApplicationState,
    application => application.hasReserved
);
// application checkout price
export const getLastMinute = createSelector(
    selectApplicationState,
    application => application.lastMinute
);
// application checkout price
export const getCurrentApplication = createSelector(
    selectApplicationState,
    application => application.application
);
//
export const getCurrentApplicationId = createSelector(
    selectApplicationState,
    application => application.application_id
);
//
export const getCurrentApplicationState = createSelector(
    selectApplicationState,
    application => application.application_state
);
//
export const getIsSigningClinicalAgreement = createSelector(
    selectApplicationState,
    application => application.is_signing_clinical_agreement
);
//
export const getClinicalDocumentList = createSelector(
    selectApplicationState,
    application => application.clinical_document_list
);
//
export const getProgramDeadlineDays = createSelector(
    selectApplicationState,
    application => application.program_deadlinedays
);
