<div class="sidebar-title-container">
  <ion-label class="sidebar-title">My documents</ion-label>
</div>
<div class="no-color-container">
  <div class="text-container">
    <ion-item button [routerLink]="['/documents/personal']" [routerDirection]="'forward'" (click)="dismiss()">
      <ion-text color="text">
        <p class="link-anchor"> Personal Documents
        </p>
      </ion-text>
    </ion-item>
    <ion-item button [routerLink]="['/documents/receipts']" [routerDirection]="'forward'" (click)="dismiss()">
      <ion-text color="text">
        <p class="link-anchor"> Application Receipts and Letters
        </p>
      </ion-text>
    </ion-item>
    <!-- <ion-button color="text" class="btn-link-text" type="button" fill="clear" [routerLink]="['/documents']"
      [routerDirection]="'forward'">
      Receipts and Letters
    </ion-button> -->
  </div>
</div>