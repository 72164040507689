import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl} from '@angular/platform-browser';

@Pipe({
    name: 'safe'
  })
export class SafePipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}
  transform(value: string, type: string): SafeHtml | SafeScript | SafeStyle | SafeUrl | SafeResourceUrl  {
    switch (type) {
      // eslint-disable-next-line @typescript-eslint/indent
			// eslint-disable-next-line @typescript-eslint/indent
      case 'html': return this._sanitizer.bypassSecurityTrustHtml(value);
      // eslint-disable-next-line @typescript-eslint/indent
			// eslint-disable-next-line @typescript-eslint/indent
      case 'style': return this._sanitizer.bypassSecurityTrustStyle(value);
      // eslint-disable-next-line @typescript-eslint/indent
			// eslint-disable-next-line @typescript-eslint/indent
			case 'script': return this._sanitizer.bypassSecurityTrustScript(value);
      // eslint-disable-next-line @typescript-eslint/indent
			// eslint-disable-next-line @typescript-eslint/indent
      case 'url': return this._sanitizer.bypassSecurityTrustUrl(value);
      // eslint-disable-next-line @typescript-eslint/indent
			// eslint-disable-next-line @typescript-eslint/indent
      case 'resourceUrl': return this._sanitizer.bypassSecurityTrustResourceUrl(value);
      // eslint-disable-next-line @typescript-eslint/indent
			// eslint-disable-next-line @typescript-eslint/indent
      default: throw new Error(`Invalid safe type specified: ${type}`);
      // eslint-disable-next-line @typescript-eslint/indent
			// eslint-disable-next-line @typescript-eslint/indent
		}
  }
}
