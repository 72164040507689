import { Injectable } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { isNil } from '../../../shared/utils';

@Injectable({
    providedIn: 'root',
})
export class WarningService {
    constructor(
        private alertController: AlertController,
        private navCtrl: NavController
    ) {}

    /**
     * @description creates a simple pop up warning
     * @param message a string with the warning message to be shown
     * @param subhead a string with a sub header, optional param
     * @returns a promise that can either be used or ignored
     */
    create = async (message: string, subhead?: string) => {
        // create the warning
        const myWarning = await this.alertController.create({
            header: `Attention`,
            subHeader: isNil(subhead) ? '' : `${subhead}`,
            message: `${message}`,
            buttons: ['Got it.'],
            cssClass: 'user-warning',
        });
        // present the warning
        await myWarning.present();
        // return a promise when warning is dismissed
        return myWarning.onDidDismiss();
    };

    emailWarning = async (message: string) => {
        // create the warning
        const myWarning = await this.alertController.create({
            header: `Attention`,
            subHeader: ``,
            cssClass: 'user-warning',
            message: `${message}`,
            buttons: [
                {
                    text: 'Login',
                    handler: () => {
                        this.navCtrl.navigateRoot(['/login']);
                    },
                },
                {
                    text: 'Got it',
                    handler: () => {
                        this.alertController.dismiss();
                    },
                },
            ],
        });
        // present the warning
        await myWarning.present();
        // return a promise when warning is dismissed
        return myWarning.onDidDismiss();
    };

    migrationWarning = async (message: string): Promise<boolean> => {
        // create the warning
        let resolveFunction: (confirm: boolean) => void;
        let promise = new Promise<boolean>(resolve => {
            resolveFunction = resolve;
        });
        const myWarning = await this.alertController.create({
            header: `Reset Password`,
            subHeader: ``,
            cssClass: 'user-warning',
            message: `We have a new password policy. Please reset your password.`,
            buttons: [
                {
                    text: 'Cancel',
                    handler: () => resolveFunction(false),
                },
                {
                    text: 'Reset',
                    handler: () => resolveFunction(true),
                },
            ],
        });
        // present the warning
        await myWarning.present();
        // return a promise when warning is dismissed
        return promise;
    };

    addedToItinerary = async (message: string): Promise<boolean> => {
        // create the warning
        let resolveFunction: (confirm: boolean) => void;
        let promise = new Promise<boolean>(resolve => {
            resolveFunction = resolve;
        });

        const myWarning = await this.alertController.create({
            header: `Itinerary Updated`,
            subHeader: ``,
            cssClass: 'user-warning',
            message: message,
            buttons: [
                {
                    text: 'Continue Browsing',
                    handler: () => resolveFunction(true),
                },
                {
                    text: 'Finalize Itinerary',
                    handler: () => resolveFunction(false),
                },
            ],
        });
        // present the warning
        await myWarning.present();
        // return a promise when warning is dismissed
        return promise;
    };

    /**
     * can be used for any yes or no question.
     *
     * @param {*} contentObject
     * @returns {Promise<boolean>}
     */
    confirmationPrompt = async (contentObject: any): Promise<boolean> => {
        // create the warning
        let resolveFunction: (confirm: boolean) => void;
        let promise = new Promise<boolean>(resolve => {
            resolveFunction = resolve;
        });

        const myWarning = await this.alertController.create({
            header: contentObject.header,
            subHeader: contentObject.subHeader,
            cssClass: contentObject.cssClass,
            message: contentObject.message,
            buttons: [
                {
                    text: contentObject.dismissText,
                    handler: () => resolveFunction(true),
                },
                {
                    text: contentObject.confirmationText,
                    handler: () => resolveFunction(false),
                },
            ],
        });
        // present the warning
        await myWarning.present();
        // return a promise when warning is dismissed
        return promise;
    };

    completeProfileWarning = async (message: string): Promise<boolean> => {
        // create the warning
        let resolveFunction: (confirm: boolean) => void;
        let promise = new Promise<boolean>(resolve => {
            resolveFunction = resolve;
        });

        const myWarning = await this.alertController.create({
            header: `Complete Profile`,
            subHeader: ``,
            cssClass: 'user-warning',
            message: message,
            buttons: [
                {
                    text: 'Complete Profile',
                    handler: () => resolveFunction(true),
                },
            ],
        });
        // present the warning
        await myWarning.present();
        // return a promise when warning is dismissed
        return promise;
    };
}
