import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { NavController } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { catchError, map, flatMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SearchGuard implements CanActivate {
    constructor(
        private navCtrl: NavController,
        private cookie: CookieService,
        private location: Location,
        private router: Router
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return of(this.cookie.check('amoactkn')).pipe(
            flatMap((e: boolean) => {
                const badProfile = this.cookie.check('_bp_');
                if (e && badProfile) {
                    const url = this.router
                        .createUrlTree([`/my-profile-block`])
                        .toString();
                    this.location.replaceState(url);
                    this.navCtrl.navigateRoot(['/my-profile-block']);
                    return of(false);
                } else {
                    return of(true);
                }
            }),
            catchError((error: any) => {
                console.log(error);
                return of(true);
            })
        );
    }
}
