<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>
      <ion-img class="amoLogo" src="assets/icons/logo_orange.png"></ion-img>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div>
    <ion-card class="not-found">
      <ion-card-header>
        <ion-card-subtitle class="text-teal">
          <br />
          We're sorry...<br />
          The page you are looking for has not been found.
        </ion-card-subtitle>
      </ion-card-header>
      <ion-card-content>
        <ion-img src="assets/svg/not-found.svg"></ion-img>
      </ion-card-content>
      <ion-card-content>
        <ion-button fill="clear" expand="block" type="button" color="secondary" [routerLink]="['/dashboard']"
          [routerDirection]="'root'">Go back to the dashboard</ion-button>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>