import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoadingService } from '@app/core/services/loading/loading.service';
import { ApplicationService } from '@app/core/services/application/application.service';
//import { Warning } from "./../services/warning.service";
import { WarningService } from '@app/core/services/warning/warning.service';

@Component({
    selector: 'app-document-viewer-modal',
    templateUrl: './document-viewer-modal.component.html',
    styleUrls: ['./document-viewer-modal.component.scss'],
})
export class DocumentViewerModalComponent implements OnInit {
    constructor(
        private modalCtrl: ModalController,
        private loading: LoadingService,
        private applicationService: ApplicationService,
        private warning: WarningService
    ) {}

    @Input() url: any;
    @Input() userid: any;
    @Input() clear: boolean = true;

    documentFormat = 'url';

    showDocument = false; // shows document on page
    currentDocument = ''; // name of document shown in iframe
    isMultiDoc = false;
    selectedIndex = 0;

    ngOnInit() {
        //console.log("received docs init", this.url);
        if (Array.isArray(this.url) == false) {
            this.documentOpener(this.url);
            this.isMultiDoc = false;
        } else {
            this.isMultiDoc = true;
            this.showDocument = false;
            //console.log(this.url);
            this.selectedIndex = 0;
            this.viewImmunization(this.url[0].Key || this.url[0].key || null);
            this.url.forEach((element) => {
                if (element.Key) {
                    element.Key = decodeURI(element.Key);
                }
                if (element.key) {
                    element.key = decodeURI(element.key);
                }
            });
            //console.log("received docs", this.url);
        }
    }

    close() {
        this.modalCtrl.dismiss();
    }

    documentOpener(url) {
        if (url) {
            if (url.includes('.doc?') || url.includes('.docx?')) {
                this.documentFormat = 'office';
            }
            this.currentDocument = url;
            this.showDocument = true;
        }
    }

    //input: folder path
    //output: https doc path
    viewImmunization(documentKey: any) {
        this.showDocument = false;
        // if we have a document name from aws
        if (documentKey) {
            this.applicationService.userViewdocument(documentKey, this.userid).subscribe((res: any) => {
                if (res.url) {
                    // present a loading screen
                    this.loading.presentLazyLoadingCtrl('Loading', 1500);
                    // console.log(res.url);
                    this.documentOpener(res.url);
                } else {
                    // if there was an error with url and aws can't get the image
                    this.warning.create(
                        `There  was an error loading the document. Contact us at <a href="mailto:rotations@amopportunities.org">rotations@amopportunities.org</a>`
                    );
                }
            });
        } else {
            this.warning.create(
                `There  was an error loading the document. Contact us at <a href="mailto:rotations@amopportunities.org">rotations@amopportunities.org</a>`
            );
        }
    }

    // clearImmunizationDocuments(file: any, index: number) {
    //   let originalfilename = file;
    //   this.warning
    //     //.confirmation("Are you sure you want to clear this document?")
    //     .confirmationPrompt({
    //       header: 'Clear Immunization',
    //       cssClass: 'user-warning',
    //       message: 'Are you sure you want to clear this document?',
    //       dismissText: 'Cancel',
    //       confirmationText: 'Clear Now.',
    //     })
    //     .then((confirm) => {
    //       if (confirm) {
    //         if (originalfilename && this.userid) {
    //           // this.applicationService
    //           //   .applicationArchiveDocument({
    //           //     userid: this.userid,
    //           //     originalfilename,
    //           //     itemno: -1
    //           //   })
    //           //   .subscribe(
    //           //     (res) => {
    //           //       this.url.splice(index, 1);
    //           //     },
    //           //     (error) => console.log(error)
    //           //   );
    //         }
    //       }
    //     });
    // }
}
