<ion-header>
    <ion-item class="modal-header-color">
        <ion-label>
            <ion-text>
                <h2 class="modal-title ion-text-center ion-text-wrap">
                    {{ program?.name }}
                </h2>
            </ion-text>
        </ion-label>
        <ion-button slot="end" class="close" type="button" fill="clear" (click)="dismiss()">
            <ion-icon slot="icon-only" color="danger" name="close" size="medium"></ion-icon>
        </ion-button>
    </ion-item>
</ion-header>

<ion-content class="background-white">
    <div *ngIf="program">
        <app-program-summary [program]="program" [user]="user"></app-program-summary>
    </div>
</ion-content>
