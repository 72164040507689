import { Component, OnInit } from '@angular/core';
import { ToolService } from '@app/core/services/tool/tool.service';
import { ModalService } from '@core/services/modal/modal.service';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';


@Component({
  selector: 'app-virtual-sidebar',
  templateUrl: './virtual-sidebar.component.html',
  styleUrls: ['./virtual-sidebar.component.scss'],
})
export class SidebarVirtualComponent implements OnInit {

  faCircle = faCircle;

  textcontent: any;
  data = {
    title: '',
    header: '',
    topMessage: '',
    bottomMessage: '',
    icon: '',
    color: '',
    class: 'sidebar-modal',
  };

  constructor(
    private modalService: ModalService,
    private toolService: ToolService
  ) { }

  ngOnInit() {
    this.toolService.content.subscribe((textcontent: any) => this.textcontent = textcontent.shared.virtualSidebar);
  }

  openInformationModal = () => {
    this.data.topMessage = this.textcontent.componentContent.modalContentTop;
    this.data.bottomMessage = this.textcontent.componentContent.modalContentBottom;
    this.modalService.basicInfoAlert(this.data).then((response: any) => {
      // console.log(response);

    });

  }
}
