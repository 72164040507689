import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PipesComponentsModule } from '@app/shared/pipes/pipes.modules';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { EligibilityDocumentsComponent } from './components/document-eligibility /eligibility-documents.component';
import { GeneralDocumentsComponent } from './components/document-general/general-documents.component';
import { ResourcesComponent } from './components/document-resources/resources.component';
import { UploadComponent } from './components/document-upload/document-upload.component';
import { SharedComponentsModule } from '@app/shared/components/shared.module';
import { DocumentAdditionalDocComponent } from './components/document-additional-doc/document-additional-doc.component';
import { DocumentApplicationListComponent } from './components/document-application-list/document-application-list.component';
import { ApplicationInformationTooltipComponent } from './components/application-information-tooltip/application-information-tooltip.component';
import { DocumentStatusChipComponent } from './components/document-status-chip/document-status-chip.component';

@NgModule({
    declarations: [
        EligibilityDocumentsComponent,
        UploadComponent,
        GeneralDocumentsComponent,
        ResourcesComponent,
        DocumentAdditionalDocComponent,
        DocumentApplicationListComponent,
        ApplicationInformationTooltipComponent,
        DocumentStatusChipComponent,
    ],
    imports: [IonicModule, CommonModule, RouterModule, FontAwesomeModule, PipesComponentsModule, SharedComponentsModule],
    exports: [
        EligibilityDocumentsComponent,
        UploadComponent,
        GeneralDocumentsComponent,
        ResourcesComponent,
        DocumentAdditionalDocComponent,
        DocumentApplicationListComponent,
    ],
})
export class DocumentComponentsModule {}
