import { Action, createAction, props } from '@ngrx/store';

export const setIsAdmin = createAction(
    '[User] Set Is Admin',
    props<{ isAdmin: boolean }>()
);
//
export const setValidProfile = createAction(
    '[User] Set Has Valid Profile',
    props<{ isValidProfile: boolean }>()
);
//
export const setUserEmail = createAction(
    '[User] Set User Email',
    props<{ userEmail: string }>()
);
//
export const setUserName = createAction(
    '[User] Set User Name',
    props<{ userName: string }>()
);
//
export const setIsManager = createAction(
    '[User] Set Is Manager',
    props<{ isManager: boolean }>()
);

export const setBlockContent = createAction(
    '[User] Set Has Content Blocked',
    props<{ isBlockContent: boolean }>()
);

export const programRedirect = createAction(
    '[User] Set Has Program Redirect',
    props<{ programRedirect: any }>()
);

export const setHideProgramPrices = createAction(
    '[User] Set Has Program Prices Hidden',
    props<{ isHideProgramPrices: boolean }>()
);
//
export const setHideNavigation = createAction(
    '[User] Set Has Hide Navigation',
    props<{ hide_navigation: boolean }>()
);
//
export const setUserId = createAction(
    '[User] Set User ID',
    props<{ user_id: string }>()
);
// does user department exist
export const setDepartmentExists = createAction(
    '[User] Set Department Exists ID',
    props<{ departmentExists: boolean }>()
);
// does user department id
export const setDepartmentId = createAction(
    '[User] Set User Department ID',
    props<{ department_id: string }>()
);
// does user have unclaimed invite record
export const setUnclaimedInvite = createAction(
    '[User] Set User Unclaimed Invite',
    props<{ unclaimedInviteCode: boolean }>()
);

export const setProfileImage = createAction(
    '[User] Set User Profile Image',
    props<{ profileImage: string }>()
);
