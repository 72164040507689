<ion-header>
    <ion-item class="modal-header-color">
        <ion-label>
            <ion-text>
                <h2 class="ion-text-center ion-text-wrap modal-title">
                    {{ (toolService.content | async)?.shared?.otherSchool?.modalTitle }}
                </h2>
            </ion-text>
        </ion-label>
        <ion-button slot="end" class="close" type="button" fill="clear" (click)="dismiss()">
            <ion-icon slot="icon-only" color="danger" name="close" size="medium"></ion-icon>
        </ion-button>
    </ion-item>
</ion-header>
<ion-grid class="modal-content">
    <ion-row>
        <ion-col size="12">
            <ion-text color="text" class="ion-text-center">
                <p>{{ (toolService.content | async)?.shared?.otherSchool?.directionText }}</p>
            </ion-text>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col size="12">
            <form [formGroup]="otherSchool_form" (ngSubmit)="submitForm()">
                <!-- <ion-card class="card-no-shadow">
        <ion-card-content> -->
                <ion-list>
                    <!-- <ion-item class="form-elements" lines="none">
            <ion-label position="stacked" class="form-title" >Your email:</ion-label>
            <ion-input class="basic-inputs" formControlName="email" type="text" value="{{form.value.email}}"></ion-input>
          </ion-item> -->

                    <ion-item button lines="none" class="form-elements" detail="false">
                        <ion-label color="text" position="stacked" class="ion-text-wrap form-title">School country: </ion-label>
                        <ion-select class="basic-select" formControlName="schoolCountry">
                            <ion-select-option *ngFor="let country of toolService.countries | async" value="{{ country }}">
                                {{ country }}
                            </ion-select-option>
                        </ion-select>
                    </ion-item>
                    <ion-item class="form-elements" lines="none">
                        <ion-label position="stacked" class="form-title">School name:</ion-label>
                        <ion-input class="basic-inputs" type="text" formControlName="schoolName"></ion-input>
                    </ion-item>
                </ion-list>
                <div style="width: 100%; text-align: center">
                    <ion-button type="button" (click)="submitForm()" [disabled]="this.otherSchool_form.invalid" class="half-button">Submit </ion-button>
                </div>
            </form>
        </ion-col>
    </ion-row>
</ion-grid>
<!-- <ion-footer>
  <ion-toolbar>

  </ion-toolbar>
</ion-footer> -->
