import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges } from '@angular/core';
import { ToolService } from '@app/core/services/tool/tool.service';
import { faFile } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { QueryService } from '@app/core/graphql/query/query.service';
@Component({
    selector: 'app-program-details-card',
    templateUrl: './program-details-description.component.html',
    styleUrls: ['./program-details-description.component.scss'],
    animations: [
        trigger('slideInOut', [
            state(
                'in',
                style({
                    'max-height': '100em',
                    opacity: '1',
                    visibility: 'visible',
                    overflow: 'auto',
                })
            ),
            state(
                'out',
                style({
                    'max-height': '0px',
                    opacity: '0',
                    visibility: 'hidden',
                })
            ),
            transition('in => out', [
                group([
                    animate(
                        '200ms ease-in-out',
                        style({
                            opacity: '0',
                        })
                    ),
                    animate(
                        '400ms ease-in-out',
                        style({
                            'max-height': '0px',
                        })
                    ),
                    animate(
                        '500ms ease-in-out',
                        style({
                            visibility: 'hidden',
                        })
                    ),
                ]),
            ]),
            transition('out => in', [
                group([
                    animate(
                        '1ms ease-in-out',
                        style({
                            visibility: 'visible',
                        })
                    ),
                    animate(
                        '400ms ease-in-out',
                        style({
                            'max-height': '100em',
                            overflow: 'auto',
                        })
                    ),
                    animate(
                        '600ms ease-in-out',
                        style({
                            opacity: '1',
                        })
                    ),
                ]),
            ]),
        ]),
        trigger('flipApp', [
            state(
                'true',
                style({
                    transform: 'rotate(90deg)',
                })
            ),
            transition('notFlipped => flipped', animate('1000ms ease')),
            transition('flipped => notFlipped', animate('1000ms ease')),
        ]),
    ],
})
export class ProgramDetailsDescriptionComponent implements OnChanges {
    @Input() program: any;
    animationProgramHighlights = 'in';
    animationProgramAbout = 'in';
    animationProgramAdditional = 'in';
    animationProgramLanguages = 'in';
    animationProgramPatient = 'in';
    specialty = 'in';
    animationProgramExposure = 'in';
    animationProgramSpecialty = 'in';
    animationProgramAffiliations = 'in';
    animationHospitalAffiliations = 'in';
    animationProgramPreceptor = 'in';
    animationProgramAdditionalReq = 'in';
    animationExpectations = 'in';
    animationBestSuitedFor = 'in';
    animationEvaluation = 'in';

    animationHospitalArrow = 'true';
    animationExpectationsArrow = 'true';
    animationAcademicArrow = 'true';
    animationAdditionalReqArrow = 'true';

    animationExposureArrow = 'false';
    animationPatientLoadArrow = 'false';
    animationLanguagesArrow = 'false';
    animationEvaluatedArrow = 'false';
    animationBestSuitedArrow = 'false';

    animationAdditionalInfoArrow = 'false';

    languages: string;
    specialtyInfo: string;
    exposuretype: string;
    patientload: string;
    affiliations: string;
    hospitalAffiliation: string;
    preceptorInfo: string;
    bestSuitedFor: string;
    expectations: string;
    evaluation: string;
    additionaltext: string;
    // Parent
    showProgramHighlight = false;
    showProgramAbout = false;
    showProgramAdditional = false;
    // Children
    showAffiliations = false;
    showHospitalAffiliations = false;
    showPatientLoad = false;
    showPreceptorInfo = false;
    showSpecialtyInfo = false;
    showExposureInfo = false;
    showLanguages = false;
    showAdditionalInfo = false;
    showBestSuited = false;
    showPreceptor = false;
    showExpectations = false;
    showAdditionalRequirements = false;
    showEvaluation = false;

    aboutRotation = false;
    programHighlights = false;
    content: any;
    additionalContent: any;

    virtualNPPATypes: string[] = ['Virtual Nurse Practitioner', 'Virtual Physician Assistant', 'Virtual Osteopathic'];

    // conditional to show "Virtual" tag
    showVirtualTag: boolean = true;

    checkStructureContent = false;
    //  Icons
    faChevronRight = faChevronRight;

    additionalReqContent: any;
    userSubmissionPreDocs = [];
    userSubmissionPostDocs = [];
    faFile = faFile;

    constructor(public toolService: ToolService, private queryService: QueryService) {}

    ngOnChanges() {
        try {
            if (this.program !== undefined && this.program !== null) {
                if (Object.keys(this.program).length > 0 && this.program.constructor === Object && Array.isArray(this.program.additionalrequirements)) {
                    this.additionalReqContent = this.program.additionalrequirements.filter((reqDocs) => {
                        return !reqDocs.id;
                    });
                }

                this.content = this.program.content;
                // Child slideouts
                if (this.program.affiliatedsites && this.program.affiliatedsites.length > 0 && this.program.affiliatedsites[0].name) {
                    this.showAffiliations = true;
                    this.affiliations = this.program.affiliatedsites;
                }
                if (this.content.options.showaffiliations === true) {
                    this.showHospitalAffiliations = true;
                    this.hospitalAffiliation = this.content.text.affiliations;
                }
                if (this.content.text.showgeneral) {
                    this.showSpecialtyInfo = true;
                    this.specialtyInfo = this.content.text.general;
                }
                if (this.content.text.additionalinfo) {
                    this.showAdditionalInfo = true;
                    this.additionaltext = this.content.text.additionalinfo;
                }
                if (this.content.text.preceptorinfo) {
                    this.showPreceptor = true;
                    this.preceptorInfo = this.content.text.preceptorinfo;
                }
                if (this.content.options.showspokenlanguages) {
                    this.showLanguages = true;
                    this.languages = this.content.text.spokenlanguages;
                }
                if (this.content.options.showbestsuitedfor) {
                    this.showBestSuited = true;
                    this.bestSuitedFor = this.content.text.bestsuitedfor;
                }
                if (this.content.options.showexposuretype) {
                    this.showExposureInfo = true;
                    this.exposuretype = this.content.text.exposuretype;
                }
                if (this.content.options.showpatientload) {
                    this.showPatientLoad = true;
                    this.patientload = this.content.text.patientload;
                }
                if (this.content.options.showexpectations) {
                    this.showExpectations = true;
                    this.expectations = this.content.text.expectations;
                }
                if (this.content.options.showevaluationmethod) {
                    this.showEvaluation = true;
                    this.evaluation = this.content.text.evaluationmethod;
                }
                // Parent slideouts
                if (this.showPatientLoad || this.showExposureInfo || this.showLanguages || this.showSpecialtyInfo) {
                    this.aboutRotation = true;
                }
                if (this.showPreceptor || this.showAffiliations) {
                    this.programHighlights = true;
                }

                //
                // Query for application requirement descriptions.
                // Updated to improve speed of page load
                // Will need to adjust the graphql function for requirementsDescriptions to reduce sql queries
                this.queryService.applicationRequirementsDescriptions(this.program.id).subscribe(
                    (response: any) => {
                        //
                        this.program = { ...this.program, requirementsDescriptions: response.data.program.requirementsDescriptions };
                        //
                        if (this.program.requirementsDescriptions) {
                            if (
                                this.program.requirementsDescriptions.postReservationDescriptions.length > 0 ||
                                this.program.requirementsDescriptions.preApprovalDescriptions.length > 0
                            ) {
                                this.showAdditionalRequirements = true;
                            }
                        }
                    },
                    (err) => {
                        console.log(err);
                    }
                );

                if (this.program.type && this.program.type.split(',').some((type) => this.virtualNPPATypes.includes(type))) {
                    this.showVirtualTag = false;
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    flipper = (collapseItem: string) => {
        switch (collapseItem) {
            case 'specialty':
                {
                    this.animationProgramSpecialty = this.animationProgramSpecialty === 'out' ? 'in' : 'out';
                    this.animationAcademicArrow = this.animationAcademicArrow === 'true' ? 'false' : 'true';
                }
                break;
            case 'exposure':
                {
                    this.animationProgramExposure = this.animationProgramExposure === 'out' ? 'in' : 'out';
                    this.animationExposureArrow = this.animationExposureArrow === 'true' ? 'false' : 'true';
                }
                break;
            case 'patient':
                {
                    this.animationProgramPatient = this.animationProgramPatient === 'out' ? 'in' : 'out';
                    this.animationPatientLoadArrow = this.animationPatientLoadArrow === 'true' ? 'false' : 'true';
                }
                break;
            case 'programLanguages':
                {
                    this.animationProgramLanguages = this.animationProgramLanguages === 'out' ? 'in' : 'out';
                    this.animationLanguagesArrow = this.animationLanguagesArrow === 'true' ? 'false' : 'true';
                }
                break;
            case 'affiliations':
                {
                    this.animationProgramAffiliations = this.animationProgramAffiliations === 'in' ? 'out' : 'in';
                    this.animationAcademicArrow = this.animationAcademicArrow === 'true' ? 'false' : 'true';
                }
                break;
            case 'preceptor':
                {
                    this.animationProgramPreceptor = this.animationProgramPreceptor === 'out' ? 'in' : 'out';
                }
                break;
            case 'programAdditionalReq':
                {
                    this.animationProgramAdditionalReq = this.animationProgramAdditionalReq === 'in' ? 'out' : 'in';
                    this.animationAdditionalReqArrow = this.animationAdditionalReqArrow === 'true' ? 'false' : 'true';
                }
                break;
            case 'hospitalAffiliations':
                {
                    this.animationHospitalAffiliations = this.animationHospitalAffiliations === 'in' ? 'out' : 'in';
                    this.animationHospitalArrow = this.animationHospitalArrow === 'true' ? 'false' : 'true';
                }
                break;
            case 'expectations':
                {
                    this.animationExpectations = this.animationExpectations === 'in' ? 'out' : 'in';
                    this.animationExpectationsArrow = this.animationExpectationsArrow === 'true' ? 'false' : 'true';
                }
                break;
            case 'bestsuited':
                {
                    this.animationBestSuitedFor = this.animationBestSuitedFor === 'out' ? 'in' : 'out';
                    this.animationBestSuitedArrow = this.animationBestSuitedArrow === 'true' ? 'false' : 'true';
                }
                break;
            case 'evaluation':
                {
                    this.animationEvaluation = this.animationEvaluation === 'out' ? 'in' : 'out';
                    this.animationEvaluatedArrow = this.animationEvaluatedArrow === 'true' ? 'false' : 'true';
                }
                break;
            case 'additionalInfo': {
                this.animationProgramAdditional = this.animationProgramAdditional === 'in' ? 'out' : 'in';
                this.animationAdditionalInfoArrow = this.animationAdditionalInfoArrow === 'true' ? 'false' : 'true';
            }
        }
    };
}
