import { Action, createAction, props } from '@ngrx/store';

export const setSearchFilters = createAction(
    '[Search] Set Filters',
    props<{ filters: any }>()
);

export const setSearchContext = createAction(
    '[Search] Set Context',
    props<{ context: string }>()
);

export const setSearchLoading = createAction(
    '[Search] Set Loader',
    props<{ loading: boolean }>()
);

export const setShowSuggested = createAction(
    '[Search] Set Show Suggested',
    props<{ suggested: boolean }>()
);

export const setShowPriceDrop = createAction(
    '[Search] Set Show Price Drop',
    props<{ pricedrop: boolean }>()
);

export const setSuggestedText = createAction(
    '[Search] Set Suggested Button Text',
    props<{ text: string }>()
);

export const setShowProgramBundle = createAction(
    '[Search] Set Show Program Bundle',
    props<{ showBundles: boolean }>()
);
export const setProgramMapList = createAction(
    '[Search] Set Program Map List',
    props<{ programMapList: Array<any> }>()
);

export const setProgramBundleMapDisabled = createAction(
    '[Search] Set Program Map Disabled',
    props<{ programBundleMapDisabled: boolean }>()
);
