import { Action, createReducer, on } from '@ngrx/store';

import { UserActions } from '../actions';

export const userFeatureKey = 'user';

export interface UserState {
    isAdmin: boolean; // is this user an admin
    isValidProfile: boolean; // is this profile valid
    isManager: boolean;
    isBlockContent: boolean; // if content gets block via department
    programRedirect: any; // carries program url if logged in
    isHideProgramPrices: boolean; // management permission that hides program prices
    userName: string; // current users name
    userEmail: string; // current users email
    hide_navigation: boolean; //
    user_id: string; //
    departmentExists: boolean; // is a departmentid exist as user property
    department_id: string; // department id attached to this user
    unclaimedInviteCode: boolean; // user has unclaimed invite record attributed to them
    profileImage: string;
}

export const initialState: UserState = {
    isAdmin: false,
    isValidProfile: true,
    isManager: false,
    isBlockContent: false,
    isHideProgramPrices: false,
    programRedirect: '',
    userName: '',
    userEmail: '',
    hide_navigation: false,
    user_id: '',
    departmentExists: false,
    department_id: '',
    unclaimedInviteCode: false,
    profileImage: ''
};

const UserReducer = createReducer(
    initialState,
    on(UserActions.setIsAdmin, (state, args) => ({
        ...state,
        isAdmin: args.isAdmin,
    })),
    on(UserActions.setValidProfile, (state, args) => ({
        ...state,
        isValidProfile: args.isValidProfile,
    })),
    on(UserActions.setUserEmail, (state, args) => ({
        ...state,
        userEmail: args.userEmail,
    })),
    on(UserActions.setUserName, (state, args) => ({
        ...state,
        userName: args.userName,
    })),
    on(UserActions.setIsManager, (state, args) => ({
        ...state,
        isManager: args.isManager,
    })),
    on(UserActions.setBlockContent, (state, args) => ({
        ...state,
        isBlockContent: args.isBlockContent,
    })),
    on(UserActions.programRedirect, (state, args) => ({
        ...state,
        programRedirect: args.programRedirect,
    })),
    on(UserActions.setHideProgramPrices, (state, args) => ({
        ...state,
        isHideProgramPrices: args.isHideProgramPrices,
    })),
    on(UserActions.setHideProgramPrices, (state, args) => ({
        ...state,
        isHideProgramPrices: args.isHideProgramPrices,
    })),
    on(UserActions.setHideNavigation, (state, args) => ({
        ...state,
        hide_navigation: args.hide_navigation,
    })),
    on(UserActions.setUserId, (state, args) => ({
        ...state,
        user_id: args.user_id,
    })),
    on(UserActions.setDepartmentExists, (state, args) => ({
        ...state,
        departmentExists: args.departmentExists,
    })),
    on(UserActions.setDepartmentId, (state, args) => ({
        ...state,
        department_id: args.department_id,
    })),
    on(UserActions.setUnclaimedInvite, (state, args) => ({
        ...state,
        unclaimedInviteCode: args.unclaimedInviteCode,
    })),
    on(UserActions.setProfileImage, (state, args) => ({
        ...state,
        profileImage: args.profileImage,
    }))
);
export function reducer(state: UserState | undefined, action: Action) {
    return UserReducer(state, action);
}
