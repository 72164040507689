<div class="sidebar-title-container">
  <ion-label class="sidebar-title">{{textcontent?.componentTitle}}</ion-label>
</div>
<div class="no-color-container">
  <ion-label class="sidebar-title">{{textcontent?.componentContent?.subTitle}}</ion-label>
  <ion-text color="text">
    <p class="sidebar-text">{{textcontent?.componentContent?.body}}</p>
    <p class="sidebar-text">{{textcontent?.componentContent?.attendance}}</p>
  </ion-text>
  <div button (click)="openInformationModal()">
    <div color="color">
      <fa-icon [icon]="faCircle"></fa-icon>&nbsp;<a
        class="link-anchor">{{textcontent?.componentContent?.readMore}}</a><br />
    </div>
  </div>
</div>