import { Action, createAction, props } from '@ngrx/store';

export const setIsAuthenticated = createAction(
    '[Auth] Set Is Authenticated',
    props<{ is_authenticated: boolean }>()
);

export const setLogout = createAction(
    '[Auth] Set Is Not Authenticated',
    props<{ is_authenticated: boolean }>()
);

export const setDisplayTopNav = createAction(
    '[Auth] Set Show Topbar Navigation',
    props<{ show_topnav: boolean }>()
);

export const setDisplayFooterNav = createAction(
    '[Auth] Set Show Footer Navigation',
    props<{ show_bottomnav: boolean }>()
);
