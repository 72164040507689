import { Component, Input, OnInit } from '@angular/core';
import { MutationService } from '@app/core/graphql/mutation/mutation.service';
import { QueryService } from '@app/core/graphql/query/query.service';
import { ApplicationService } from '@app/core/services/application/application.service';
import { ToolService } from '@app/core/services/tool/tool.service';
import { faHome } from '@fortawesome/pro-solid-svg-icons';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-housing-suggestions',
    templateUrl: './application-housing-suggestions.component.html',
    styleUrls: ['./application-housing-suggestions.component.scss'],
})
export class ApplicationHousingSuggestionsComponent implements OnInit {
    constructor(
        public toolService: ToolService,
        private modalController: ModalController,
        private applicationService: ApplicationService
    ) {}

    @Input() applicationid: string;
    updated = false;
    acceptedDisclosure: boolean;

    icons: any = {
        home: faHome,
    };

    ngOnInit() {
        this.acceptedDisclosure = false;
    }

    acceptDisclosure = (): void => {
        this.acceptedDisclosure = true;
    };

    dismiss() {
        this.modalController.dismiss({
            update: this.updated,
        });
    }

    // suggest housing airbnb
    suggestAirBnb() {
        this.applicationService
            .suggestHousing(this.applicationid)
            .subscribe((airbnb: any) => {
                window.open(airbnb['url'], '_blank');
            });
    }

    // suggest housing ahn
    suggestAhn() {
        const url =
            'https://usa.homestaynetwork.com/guests/new?affiliate=amopportunities';
        window.open(url, '_blank');
    }

    // suggest housing sara
    suggestSara() {
        const url =
            'https://www.sarahomestay.com/school/20463-amopportunities/reservation';
        window.open(url, '_blank');
    }

    suggestFourStay() {
        const url = 'https://amopportunitieshousing.4stay.com/';
        window.open(url, '_blank');
    }
}
