export const regexTools = {
    wordReg: /[a-zA-Z]/g,
    numberReg: /[0-9]/g,
    upperCaseRegex: /[A-Z]/g,
    lowerCaseRegex: /[a-z]/g,
    numberCaseRegex: /[0-9]/g,
    specialCaseRegex: /([\^$*.\[\]{}\(\)?\-'"!@#+^%&=/,><\’:;|_~`])/g,
    passwordSpecialCase: /([\^$*.\[\]{}\(\)?\-'"!@#^%&=/,><\’:;|_~`])/g,
    nonEnglishRegex: /[^\u0000-\u007F]+/g,
    numbersAndHyphenParenthesisRegex: /[0-9-()]/g,
};
