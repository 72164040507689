import { createSelector, select } from '@ngrx/store';
import { State } from '../reducers';

export const selectUserState = (state: State) => state.user;

// is this user admin
export const isAdmin = createSelector(selectUserState, user => user.isAdmin);

// is this user profile valid
export const isValidProfile = createSelector(
    selectUserState,
    user => user.isValidProfile
);
// is this user manager
export const isManager = createSelector(
    selectUserState,
    user => user.isManager
);

// block content for the user based on department
export const blockContent = createSelector(
    selectUserState,
    user => user.isBlockContent
);

// redirect user to program page
export const programRedirect = createSelector(
    selectUserState,
    user => user.programRedirect
);

// block content for the user based on department
export const hideProgramPrices = createSelector(
    selectUserState,
    user => user.isHideProgramPrices
);
//
export const getUserEmail = createSelector(
    selectUserState,
    user => user.userEmail
);
//
export const getUserName = createSelector(
    selectUserState,
    user => user.userName
);
//
export const getHideNavigation = createSelector(
    selectUserState,
    user => user.hide_navigation
);
//
export const getUserID = createSelector(selectUserState, user => user.user_id);
//
export const getDepartmentExists = createSelector(
    selectUserState,
    user => user.departmentExists
);
//
export const getDepartmentId = createSelector(
    selectUserState,
    user => user.department_id
);
// get user unclaimed invite records exist
export const getUnclaimedInviteExists = createSelector(
    selectUserState,
    user => user.unclaimedInviteCode
);
//
export const getProfileImage = createSelector(
    selectUserState,
    user => user.profileImage
);

