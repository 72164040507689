import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dayZone',
})
export class DayZonePipe implements PipeTransform {
    transform(time: any, ...args: any): any {
        if (time == '12:00 PM') {
            return 'Noon';
        } else if (time == '12:00 AM') {
            return 'Midnight';
        } else {
            return time;
        }
    }
}
