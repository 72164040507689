<div class="parent">
  <img class="amoLogo" src="assets/icons/logo_orange.png" />
  <div class="title bold-text">
    {{(toolService.content | async)?.shared?.actionModal?.componentTitle}}
    <!-- {{content?.componentTitle}} -->
  </div>
  <span [ngSwitch]="type">
    <div class="message ion-text-wrap" *ngSwitchCase="'stripe'">
      {{(toolService.content | async)?.shared?.actionModal?.componentContent}}
    </div>
    <div class="message ion-text-wrap" *ngSwitchCase="'passwordReset'">
      {{(toolService.content | async)?.shared?.actionModal?.successpassword}}
    </div>
    <div class="message ion-text-wrap" *ngSwitchDefault>
        {{(toolService.content | async)?.shared?.actionModal?.componentContent}}
    </div>
  </span>

  <div>
    <fa-icon [icon]="faCheckCircle" class="icon-size"></fa-icon>
  </div>
  <div class="confirm-button">
    <ion-button color="primary" (click)="close()" class="half-button">
      {{ (toolService.content | async)?.shared?.actionModal?.componentButton }}
    </ion-button>
  </div>
</div>