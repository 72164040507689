import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
// import { ItineraryListComponent } from '@app/shared/components/itinerary-list/itinerary-list.component';
import { SharedComponentsModule } from '@app/shared/components/shared.module';
import { SharedSidebarComponentsModule } from '@app/shared/components/sidebar.module';
import { PipesComponentsModule } from '@app/shared/pipes/pipes.modules';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { QuicklinkModule } from 'ngx-quicklink';
import { ApplicationProgramComponentsModule } from '../application/application.program.module';
// Component imports
import { SearchProgramMobileComponent } from './components/program-search-filters-mobile/search-program.component';
import { SearchFiltersComponent } from './components/program-search-filters/search-filters.component';
import { ProgramSearchMapComponent } from './components/program-search-map/program-search-map.component';
import { SearchTempComponent } from './components/program-search-public-block/program-search-public-block.component';
import { ProgramSearchQuickActionsComponent } from './components/program-search-quick-actions/program-search-quick-actions.component';
import { ProgramApplyComponent } from './components/program-apply/program-apply.component';
import { MobileSearchListComponent } from './components/program-mobile-search-list/mobile-search-list.component';
import { ProgramNotFoundComponent } from './components/program-not-found/program-not-found.component';
import { ProgramReviewCardComponent } from './components/program-review-card/program-review-card.component';
import { ProgramSearchEndResultsComponent } from './components/program-search-end-results/program-search-end-results.component';
import { ProgramSearchFiltersListComponent } from './components/program-search-filters-list/program-search-filters-list.component';
import { ItiHelpComponent } from './components/iti-help/iti-help.component';
import { ProgramBundleParentComponent } from './components/program-bundle-parent/program-bundle-parent.component';
import { ProgramBundleCardsComponent } from './components/program-bundle-cards/program-bundle-cards.component';
import { ProgramTimeZonesComponent } from './components/program-time-zones/program-time-zones.component';
import { SwiperModule } from 'swiper/angular';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
    declarations: [
        SearchTempComponent,
        SearchProgramMobileComponent,
        ProgramApplyComponent,
        ProgramNotFoundComponent,
        ProgramReviewCardComponent,
        ProgramSearchMapComponent,
        SearchFiltersComponent,
        ProgramSearchQuickActionsComponent,
        ProgramSearchFiltersListComponent,
        ProgramSearchEndResultsComponent,
        MobileSearchListComponent,
        ItiHelpComponent,
        ProgramBundleParentComponent,
        ProgramBundleCardsComponent,
        ProgramTimeZonesComponent,
    ],
    imports: [
        QuicklinkModule,
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesComponentsModule,
        MatTooltipModule,
        ReactiveFormsModule,
        RouterModule,
        SwiperModule,
        FontAwesomeModule,
        ApplicationProgramComponentsModule,
        SharedSidebarComponentsModule,
        SharedComponentsModule,
        GoogleMapsModule,
    ],
    exports: [
        QuicklinkModule,
        SearchTempComponent,
        SearchProgramMobileComponent,
        ProgramApplyComponent,
        ProgramNotFoundComponent,
        ProgramReviewCardComponent,
        ProgramSearchMapComponent,
        SearchFiltersComponent,
        ProgramSearchQuickActionsComponent,
        ProgramSearchFiltersListComponent,
        ProgramSearchEndResultsComponent,
        MobileSearchListComponent,
        ItiHelpComponent,
        ProgramBundleParentComponent,
        ProgramBundleCardsComponent,
        ProgramTimeZonesComponent,
    ],
})
export class ProgramComponentsModule {}
