import { createAction, props } from '@ngrx/store';

export const setPaymentMethod = createAction(
    '[Payment] Set Payment Method',
    props<{ paymentMethod: string }>()
);

export const setPaymentApp = createAction(
    '[Payment] Application most payable',
    props<{ payableApplication: any }>()
);

export const setPaymentType = createAction(
    '[Payment] Set Payment Type',
    props<{ paymentType: string }>()
);

export const setCheckoutPrice = createAction(
    '[Payment] Set Checkout Price',
    props<{ checkoutPrice: number }>()
);

export const setProcessingFee = createAction(
    '[Payment] Set Credit / Wire Fee',
    props<{ processingFee: number }>()
);

export const setViewPaymentInfo = createAction(
    '[Payment] Set View Payment Info',
    props<{ viewPaymentInfo: boolean }>()
);

export const setPaymentListUpdate = createAction(
    '[Payment] Set Payment Balance',
    props<{ paymentUpdate: string[] }>()
);

export const setCouponCode = createAction(
    '[Payment] Set Coupon Code',
    props<{ couponCode: string }>()
);

export const setCouponDiscount = createAction(
    '[Payment] Set Coupon Discount',
    props<{ couponDiscount: number }>()
);

export const setPendingWire = createAction(
    '[Payment] Set Pending Wire Transfer',
    props<{ pendingWire: boolean }>()
);

export const setForceFullPayment = createAction(
    '[Payment] Set Force Full Payment',
    props<{ forceFullPayment: boolean }>()
);

export const setCheckoutCoupons = createAction(
    '[Payment] Set Checkout Coupons',
    props<{ checkoutCoupons: string[] }>()
);

export const setStripeCustomerID = createAction(
    '[Payment] Set Customer ID',
    props<{ customerID: string }>()
);

// coupons relevant to this payment
export const setPaymentCoupons = createAction(
    '[Payment] Set Payment Coupons',
    props<{ paymentCoupons: Array<{}> }>()
);
// coupons relevant to this payment
export const setPaymentRedirect = createAction(
    '[Payment] Set Payment Redirect',
    props<{ redirect: boolean }>()
);
// primary payment list
export const setPaymentList = createAction(
    '[Payment] Set Payment List',
    props<{ paymentList: Array<{}> }>()
);
// payment select list
export const setPaymentSelectList = createAction(
    '[Payment] Set Payment Select List',
    props<{ paymentSelectList: Array<{}> }>()
);
// payment cart
export const setPaymentCart = createAction(
    '[Payment] Set Payment Cart',
    props<{ paymentCart: Array<{}> }>()
);
// payment cart
export const setPaymentIntentId = createAction(
    '[Payment] Set Payment Intent ID',
    props<{ paymentIntentId: string }>()
);
