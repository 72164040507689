import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthActions } from '@core/ngrx/actions';
import { State } from '@core/ngrx/reducers';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

const helper = new JwtHelperService();

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    isLoginSubject: any = new BehaviorSubject<boolean>(this.hasToken());

    constructor(
        private http: HttpClient,
        private cookie: CookieService,
        private apollo: Apollo,
        private store: Store<State>,
        private navController: NavController,
        private router: Router,
        private location: Location
    ) {}

    private hasToken(): boolean {
        return this.cookie.check('amoactkn');
    }

    cognitoActivate(code: string, email: string, pwd: string) {
        return this.http.post(`${environment.API_GATEWAY_COGNITO_AUTH}/auth/confirm-signup`, { code, email, pwd });
    }

    cognitoResendCode(email: string) {
        return this.http.post(`${environment.API_GATEWAY_COGNITO_AUTH}/auth/confirm-signup`, { action: 'resend', email });
    }

    resendForgotPasswordVerificationCode(email: string) {
        return this.http.post(`${environment.API_GATEWAY_COGNITO_AUTH}/auth/forgot-password`, { email: email });
    }

    private CognitoLogin(data: object) {
        data['clientId'] = environment.COGNTIO_APP_CLIENT_ID;
        return this.http.post(`${environment.API_GATEWAY_COGNITO_AUTH}/auth/login?ngsw-bypass=true`, data);
    }

    amoCognitoLogin(data: object) {
        return this.CognitoLogin(data);
    }

    private cognitoLogout(data: any): Observable<any> {
        return this.http.post(`${environment.API_GATEWAY_COGNITO_AUTH}/auth/logout?r=${this.cookie.get('amoactkn')}`, data);
    }

    /** when a user hits logout */
    logout = (): void => {
        this.cognitoLogout({ out: true }).subscribe(
            (response: any) => {
                // clear states and set to initial
                const client = this.apollo.getClient();
                client.cache.reset();
                client.clearStore();
                this.store.dispatch(AuthActions.setLogout({ is_authenticated: false }));
                // clear cookies
                // this.cookie.delete('amoactkn', '/', 'amopportunities.org');
                this.cookie.delete('XSRF-TOKEN', '/', 'amopportunities.org');
                this.cookie.delete('amosess.sid', '/', 'amopportunities.org');
                this.cookie.delete('role', '/', 'amopportunities.org');
                this.cookie.delete('mgmt_token', '/', 'amopportunities.org');
                this.cookie.delete('mfa-session', '/', 'amopportunities.org');
                this.cookie.delete('_bp_', '/', 'amopportunities.org');
                this.cookie.delete('_seg_', '/', 'amopportunities.org');
                this.cookie.deleteAll('/', 'amopportunities.org');
                // send back to login page
                this.redirectLogin();
            },
            (err: any) => {
                // if lambda errors still call client logout functions
                const client = this.apollo.getClient();
                client.cache.reset();
                client.clearStore();
                this.store.dispatch(AuthActions.setLogout({ is_authenticated: false }));
                // clear cookies
                // this.cookie.delete('amoactkn', '/', 'amopportunities.org');
                this.cookie.delete('XSRF-TOKEN', '/', 'amopportunities.org');
                this.cookie.delete('amosess.sid', '/', 'amopportunities.org');
                this.cookie.delete('role', '/', 'amopportunities.org');
                this.cookie.delete('mgmt_token', '/', 'amopportunities.org');
                this.cookie.delete('mfa-session', '/', 'amopportunities.org');
                this.cookie.delete('_bp_', '/', 'amopportunities.org');
                this.cookie.delete('_seg_', '/', 'amopportunities.org');
                this.cookie.deleteAll('/', 'amopportunities.org');
                // send back to login page
                this.redirectLogin();
            }
        );
    };

    private redirectLogin() {
        setTimeout(() => {
            this.cookie.deleteAll('/', 'amopportunities.org');
            const url = this.router.createUrlTree([`/login`]).toString();
            this.location.replaceState(url);
            location.reload();
        }, 1100);
    }

    //
    saveAgreement = (token: string, pdf: string): Observable<any> => {
        const decodedToken = helper.decodeToken(token);
        const data = { userId: decodedToken._id, base64: pdf };
        return this.http.put(`${environment.API}/users/processGeneralAgreement`, data);
    };

    // updates the user value of termsAgreed when being prompted with the login policy update component
    updateAgreePrompt = (id: string) =>
        this.http.put(`${environment.API}/users/${id}/useragreed`, {
            userId: id,
        });

    /**
     *
     * @param data email and mfa code
     */
    cognitoMFA(data: any) {
        return this.http.post(`${environment.API_GATEWAY_COGNITO_AUTH}/auth/verify-mfa-code`, data);
    }

    // check to see if manager is already in user pool and resend onboarding email.
    confirmActivation(body: object): Observable<any> {
        return this.http.post(`${environment.API}/managements/confirmactivation`, body);
    }
}
