import { createSelector } from '@ngrx/store';
import { State } from '../reducers';

export const selectAuthState = (state: State) => state.auth;

// is this user authenticated
export const isAuthenticated = createSelector(
    selectAuthState,
    auth => auth.is_authenticated
);
// is this user seeing hot links
export const isHotLinks = createSelector(
    selectAuthState,
    auth => auth.show_hotlinks
);
