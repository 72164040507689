import { Action, createAction, props } from '@ngrx/store';

export const setApplicationDeadline = createAction(
    '[Application] Set Deadline',
    (deadline: any) => ({ deadline })
);

export const setRemainingDays = createAction(
    '[Application] Deadline Day Difference',
    (remainingDays: number) => ({ remainingDays })
);

export const setDeadlineMessage = createAction(
    '[Application] Deadline Message',
    (deadlineMessage: string) => ({ deadlineMessage })
);

export const setCanBook = createAction(
    '[Application] Booking Restrictions',
    props<{ canBook: boolean }>()
);

export const setRequestedBooking = createAction(
    '[Application] Has Requested Booking Acceptance',
    props<{ hasReqBook: boolean }>()
);

export const setCanEnroll = createAction(
    '[Application] Request Enrollment',
    props<{ canEnroll: boolean }>()
);

export const setCoachOrAdvisor = createAction(
    '[Application] Set AMO Helper',
    props<{ amoHelper: string }>()
);

export const setIsLate = createAction(
    '[Application] Set Is Late',
    props<{ isLate: boolean }>()
);

export const setLateFee = createAction(
    '[Application] Set Late Fee',
    props<{ lateFee: number }>()
);

export const setHasCoupon = createAction(
    '[Application] Set Has Coupon',
    props<{ hasCoupon: boolean }>()
);

export const setNeedMalpractice = createAction(
    '[Application] Set Requires Malpractice',
    props<{ needMalpractice: boolean }>()
);

export const setHasReserved = createAction(
    '[Application] Set Has One Reserved Application',
    props<{ hasReserved: boolean }>()
);

export const setLastMinute = createAction(
    '[Application] Set Last Minute Application ',
    props<{ lastMinute: boolean }>()
);

export const setCurrentApplicationId = createAction(
    '[Application] Set Current Application ID ',
    props<{ application_id: string }>()
);

export const setCurrentApplication = createAction(
    '[Application] Set Current Application',
    props<{ application: any }>()
);

export const setCurrentApplicationState = createAction(
    '[Application] Set Current Application',
    props<{ application_state: string }>()
);
//
export const setIsSigningClinicalAgreement = createAction(
    '[Application] Set Is Signing Clinical Agreement',
    props<{ is_signing_clinical_agreement: boolean }>()
);
//
export const setClinicalDocumentList = createAction(
    '[Application] Set Clinical Document List',
    props<{ clinical_document_list: any }>()
);
//
export const setProgramDeadlineDays = createAction(
    '[Application] Set Program Deadline Days',
    props<{ program_deadlinedays: number }>()
);
