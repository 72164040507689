<ion-header>
    <ion-item class="modal-header-color">
        <ion-label>
            <ion-text>
                <div class="modal-title ion-text-center ion-text-wrap">
                    {{ data?.title }}
                </div>
            </ion-text>
        </ion-label>
        <ion-button slot="end" class="close" type="button" fill="clear" (click)="dismiss()">
            <ion-icon slot="icon-only" color="danger" name="close" size="medium"></ion-icon>
        </ion-button>
    </ion-item>
</ion-header>
<div class="ion-text-center">
    <img class="modal-logo" src="assets/icons/logo_orange_full.png" />
</div>
<div class="confirmation-text-box">
    <div class="ion-text-center ion-text-wrap">
        {{ data?.message }}
    </div>
    <div style="margin-top: 1rem">
        <div class="ion-text-center ion-text-wrap bold orange">
            {{ (toolService.content | async)?.shared?.actionConfirmation?.cardContent }}
        </div>
    </div>
</div>
<ion-footer>
    <ion-toolbar class="center-block">
        <ion-button type="button" class="half-button" (click)="continue()" color="{{ data.buttonColor }}">{{ data?.buttonText }} </ion-button>
    </ion-toolbar>
</ion-footer>
