<ion-content>
    <div class="parent">
        <div class="ion-text-right">
            <ion-button
                type="button"
                fill="clear"
                color="dark"
                (click)="cancelArgeeToDepartment()"
                gt
            >
                <ion-icon slot="end" size="large" name="close"></ion-icon>
            </ion-button>
        </div>

        <!-- exclamation icon at top -->
        <div class="ion-text-center university-icon-box">
            <!-- <ion-icon name="alert" color="secondary" size="large"></ion-icon> -->
            <fa-icon
                class="university-title-icon"
                [icon]="faExclamationCircle"
            ></fa-icon>
        </div>

        <div class="ion-text-center ion-text-wrap university-title-text">
            Your university is partnered with AMO!
        </div>

        <div class="ion-text-center ion-text-wrap university-subtitle-text">
            Would you like to connect your account with your <br />
            university?
        </div>

        <div class="ion-text-center ion-text-wrap university-allow-text">
            This will allow your school to verify your rotations more <br />
            easily and provide access to any programs specifically <br />
            developed for your school.
        </div>

        <div class="ion-text-center ion-text-wrap university-note-text">
            *Please note that the AMO partnered organization on <br />
            this page will be able to view certain account <br />
            information to monitor clinical experience delivery.
        </div>

        <div class="university-button-box">
            <div>
                <ion-button
                    type="button"
                    size="large"
                    fill="outline"
                    color="secondary"
                    class="university-button"
                    (click)="cancelArgeeToDepartment()"
                    >Maybe Later</ion-button
                >
            </div>
            <div>
                <ion-button
                    class="university-button"
                    type="button"
                    size="large"
                    color="secondary"
                    (click)="confirmAgreeToDepartment()"
                    >Yes</ion-button
                >
            </div>
        </div>
        <!-- <ion-note>You will be redirected in 3 seconds.</ion-note> -->
    </div>
</ion-content>
