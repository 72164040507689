import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SEOService {
    constructor(private meta: Meta) {}

    programMetaTags(page: any) {
        let descriptionText = '';
        if (page.description !== null || undefined) {
            descriptionText = page.description.replace(/<[^>]*>/g, ' ');
        } else {
            descriptionText =
                'AMOpportunities arranges U.S. clinical experiences for non-U.S. medical students and graduates. Our innovative services allow trainees to confidently search, apply, and attend the experience they need. Finding the right clinical Experience never has been easier.';
        }

        if (page.image.length === 0) {
            page.image.push({
                Key: 'https://www.amopportunities.org/img/meta-image.jpg',
            });
        }

        // eslint-disable-next-line max-len
        this.meta.updateTag({
            property: 'og:title',
            content: `AMOpportunities - Clinical Experience ${page.specialty} ${page.name} ${page.type} in ${page.city}, ${page.state}`,
        });
        this.meta.updateTag({
            property: 'og:image',
            content: `https://www.amopportunities.org/${page.image[0].Key}`,
        });
        this.meta.updateTag({
            property: 'og:description',
            content: descriptionText,
        });
        this.meta.updateTag({ name: 'description', content: descriptionText });
        this.meta.updateTag({
            name: 'image',
            content: `https://www.amopportunities.org/${page.image[0].Key}`,
        });
        this.meta.updateTag({
            name: 'title',
            content: `AMOpportunities - Clinical Experience ${page.specialty} ${page.name} ${page.type} in ${page.city}, ${page.state}`,
        });

        this.meta.addTags([
            {
                property: 'twitter:card',
                content: `https://www.amopportunities.org/${page.image[0].Key}`,
            },
            {
                property: 'twitter:url',
                content: `https://amopportunities.org/program/${page.id}`,
            },
            {
                property: 'twitter:title',
                content: `AMOpportunities - Clinical Experience ${page.specialty} ${page.name} ${page.type} in ${page.city}, ${page.state}`,
            },
            { property: 'twitter:description', content: descriptionText },
            { name: 'robots', content: 'INDEX, FOLLOW' },
        ]);
        return of({ updated: 'true' });
    }

    leaveProgramMetaTags() {
        this.meta.updateTag({ name: 'robots', content: 'NOINDEX, NOFOLLOW' });
        this.meta.updateTag({
            property: 'og:title',
            content:
                'AMOpportunities - The #1 U.S. Clinical Experience Provider',
        });
        this.meta.updateTag({
            property: 'og:image',
            content: 'https://www.amopportunities.org/img/meta-image.jpg',
        });
        this.meta.updateTag({
            property: 'og:description',
            content:
                'AMOpportunities arranges U.S. clinical experiences for non-U.S. medical students and graduates. Our innovative services allow trainees to confidently search, apply, and attend the experience they need. Finding the right clinical Experience never has been easier.',
        });
        this.meta.updateTag({
            name: 'description',
            content:
                'AMOpportunities arranges U.S. clinical experiences for non-U.S. medical students and graduates. Our innovative services allow trainees to confidently search, apply, and attend the experience they need. Finding the right clinical Experience never has been easier.',
        });
        this.meta.updateTag({
            name: 'image',
            content: 'https://www.amopportunities.org/img/meta-image.jpg',
        });
        this.meta.updateTag({
            name: 'title',
            content:
                'AMOpportunities - The #1 U.S. Clinical Experience Provider',
        });

        this.meta.removeTag('property = twitter:card');
        this.meta.removeTag('property = twitter:url');
        this.meta.removeTag('property = twitter:title');
        this.meta.removeTag('property = twitter:description');
    }
}
