import { Action, createReducer, on } from '@ngrx/store';

import { ApplicationActions } from '../actions';

export const applicationFeatureKey = 'application';

export interface ApplicationState {
    deadline: any; // the date of an applications next deadline
    remainingDays: number; // days remaining til applications next deadline
    canBook: boolean; // can book === has this user uploaded their cv and school records
    canEnroll: boolean; // can enroll === has this user completed all the steps required for this application,
    hasReqBook: boolean; // user can request their Pending-Booked application be reviewed for reserved
    amoHelper: string; // the amo coach or advisor who is a users main point of contact
    deadlineMessage: string; // deadline message
    isLate: boolean; // is an application late
    lateFee: number; // if application is late what is the price change
    hasCoupon: boolean; // does this application have a valid coupon during this current payment cycle
    needMalpractice: boolean; // if this application is hands on it needs malpractice
    hasReserved: boolean; // user has at least one reserved application
    lastMinute: boolean; // application is tagged as last minute,
    application: any; //
    application_id: string; //
    application_state: string; //
    is_signing_clinical_agreement: boolean; //
    clinical_document_list: any;
    program_deadlinedays: number; // application program record deadlinedays column value
}

export const initialState: ApplicationState = {
    deadline: new Date().toISOString(),
    remainingDays: 999,
    canBook: false,
    canEnroll: false,
    hasReqBook: false,
    amoHelper: 'Sam',
    deadlineMessage: 'Upcoming.',
    isLate: false,
    lateFee: 0,
    hasCoupon: false,
    needMalpractice: false,
    hasReserved: false,
    lastMinute: false,
    application: {},
    application_id: '',
    application_state: 'Loading',
    is_signing_clinical_agreement: false,
    clinical_document_list: [],
    program_deadlinedays: 0
};

const ApplicationReducer = createReducer(
    initialState,
    on(ApplicationActions.setRemainingDays, (state, args) => ({
        ...state,
        remainingDays: args.remainingDays,
    })),
    on(ApplicationActions.setApplicationDeadline, (state, args) => ({
        ...state,
        deadline: args.deadline,
    })),
    on(ApplicationActions.setCanEnroll, (state, args) => ({
        ...state,
        canEnroll: args.canEnroll,
    })),
    on(ApplicationActions.setCanBook, (state, args) => ({
        ...state,
        canBook: args.canBook,
    })),
    on(ApplicationActions.setRequestedBooking, (state, args) => ({
        ...state,
        hasReqBook: args.hasReqBook,
    })),
    on(ApplicationActions.setCoachOrAdvisor, (state, args) => ({
        ...state,
        amoHelper: args.amoHelper,
    })),
    on(ApplicationActions.setDeadlineMessage, (state, args) => ({
        ...state,
        deadlineMessage: args.deadlineMessage,
    })),
    on(ApplicationActions.setIsLate, (state, args) => ({
        ...state,
        isLate: args.isLate,
    })),
    on(ApplicationActions.setLateFee, (state, args) => ({
        ...state,
        lateFee: args.lateFee,
    })),
    on(ApplicationActions.setHasCoupon, (state, args) => ({
        ...state,
        hasCoupon: args.hasCoupon,
    })),
    on(ApplicationActions.setNeedMalpractice, (state, args) => ({
        ...state,
        needMalpractice: args.needMalpractice,
    })),
    on(ApplicationActions.setHasReserved, (state, args) => ({
        ...state,
        hasReserved: args.hasReserved,
    })),
    on(ApplicationActions.setLastMinute, (state, args) => ({
        ...state,
        lastMinute: args.lastMinute,
    })),
    on(ApplicationActions.setCurrentApplication, (state, args) => ({
        ...state,
        application: args.application,
    })),
    on(ApplicationActions.setCurrentApplicationId, (state, args) => ({
        ...state,
        application_id: args.application_id,
    })),
    on(ApplicationActions.setCurrentApplicationState, (state, args) => ({
        ...state,
        application_state: args.application_state,
    })),
    on(ApplicationActions.setIsSigningClinicalAgreement, (state, args) => ({
        ...state,
        is_signing_clinical_agreement: args.is_signing_clinical_agreement,
    })),
    on(ApplicationActions.setClinicalDocumentList, (state, args) => ({
        ...state,
        clinical_document_list: args.clinical_document_list,
    })),
    on(ApplicationActions.setProgramDeadlineDays, (state, args) => ({
        ...state,
        program_deadlinedays: args.program_deadlinedays,
    }))
);

export function reducer(state: ApplicationState | undefined, action: Action) {
    return ApplicationReducer(state, action);
}
