<!-- <ion-content> -->
<ion-header>
    <ion-item class="modal-header-color">
        <ion-label>
            <ion-text>
                <h2 class="ion-text-wrap ion-text-center modal-title">
                    <fa-icon [icon]="icons.home"></fa-icon>
                    {{ (toolService.content | async)?.application?.components?.housingsuggestions?.modalTitle }}
                </h2>
            </ion-text>
        </ion-label>
        <ion-button slot="end" class="close" type="button" fill="clear" (click)="dismiss()">
            <ion-icon slot="icon-only" color="danger" name="close" size="medium"></ion-icon>
        </ion-button>
    </ion-item>
</ion-header>
<ion-grid class="modal-content">
    <ion-row>
        <ion-col>
            <ion-item *ngIf="!acceptedDisclosure">
                <ion-text class="ion-text-center">
                    <h3>{{ (toolService.content | async)?.application?.components?.housingsuggestions?.contentTitle }}</h3>
                </ion-text>
            </ion-item>
            <ion-item *ngIf="!acceptedDisclosure">
                <ion-text>
                    <p>{{ (toolService.content | async)?.application?.components?.housingsuggestions?.contentBody }}</p>
                </ion-text>
            </ion-item>

            <!-- <ion-item> -->
            <div *ngIf="acceptedDisclosure">
                <!-- <ion-col size="12">
          <img class='logo' src="/img/logo/4stay.png" />
        </ion-col>
        <ion-col size="12">
          <ion-row class="ion-justify-content-center ion-align-items-end">
            <ion-text class="ion-text-center ion-text-wrap">
              {{(toolService.content | async)?.application?.components?.housingsuggestions?.fourStayTitle}}
            </ion-text>
          </ion-row>
        </ion-col>
        <ion-col size="12">
          <ion-row class="ion-justify-content-center ion-align-items-end">
            <ion-button expand="full" color="fourStay" block (click)="suggestFourStay()">
              {{(toolService.content | async)?.application?.components?.housingsuggestions?.fourStayButton}}
            </ion-button>
          </ion-row>
        </ion-col>
        <div class="borderTop"></div> -->

                <!-- <ion-col size="12">
                    <img class="logo" src="/img/sarahomestay.png" />
                </ion-col>
                <ion-col size="12">
                    <ion-row class="ion-justify-content-center ion-align-items-end">
                        <ion-text class="ion-text-center ion-text-wrap">
                            {{ (toolService.content | async)?.application?.components?.housingsuggestions?.sarahsHomestayTitle }}
                        </ion-text>
                    </ion-row>
                </ion-col>
                <ion-col size="12">
                    <ion-row class="ion-justify-content-center ion-align-items-end">
                        <ion-button
                            expand="full"
                            color="sarahomestay"
                            block
                            href="{{ (toolService.content | async)?.application?.components?.housingsuggestions?.sarahsHomestayUrl }}"
                            target="_blank"
                        >
                            {{ (toolService.content | async)?.application?.components?.housingsuggestions?.sarahsHomestayButton }}
                        </ion-button>
                    </ion-row>
                </ion-col> -->
                <ion-col size="12">
                    <img class="logo student-home-stay-logo" src="/img/SRS_H1_Color@3x copy-3.png" />
                </ion-col>
                <ion-col size="12">
                    <ion-row class="ion-justify-content-center ion-align-items-end">
                        <ion-text class="ion-text-center ion-text-wrap">
                            {{ (toolService.content | async)?.application?.components?.housingsuggestions?.studentRoomStayTitle }}
                        </ion-text>
                    </ion-row>
                </ion-col>
                <ion-col size="12">
                    <ion-row class="ion-justify-content-center ion-align-items-end">
                        <ion-button
                            expand="full"
                            color="sarahomestay"
                            block
                            href="{{ (toolService.content | async)?.application?.components?.housingsuggestions?.studentRoomStayUrl }}"
                            target="_blank"
                        >
                            {{ (toolService.content | async)?.application?.components?.housingsuggestions?.studentRoomStayButton }}
                        </ion-button>
                    </ion-row>
                </ion-col>
                <div class="borderTop"></div>
                <!-- <ion-col size="12">
                    <img class="logo" src="/img/AHN_LOGO_VERT.png" />
                </ion-col>
                <ion-col size="12">
                    <ion-row class="ion-justify-content-center ion-align-items-end">
                        <ion-text class="ion-text-center ion-text-wrap">
                            {{ (toolService.content | async)?.application?.components?.housingsuggestions?.homeStayTitle }}
                        </ion-text>
                    </ion-row>
                </ion-col>
                <ion-col size="12">
                    <ion-row class="ion-justify-content-center ion-align-items-end">
                        <ion-button expand="full" color="ahn" block (click)="suggestAhn()">
                            {{ (toolService.content | async)?.application?.components?.housingsuggestions?.homeStayButton }}
                        </ion-button>
                    </ion-row>
                </ion-col>
                <div class="borderTop"></div> -->
                <ion-col size="12">
                    <img src="img/airbnb_vertical.png" class="logo" />
                </ion-col>
                <ion-col size="12">
                    <ion-row class="ion-justify-content-center ion-align-items-end">
                        <ion-text>
                            {{ (toolService.content | async)?.application?.components?.housingsuggestions?.airBnbTitle }}
                        </ion-text>
                    </ion-row>
                </ion-col>
                <ion-col size="12">
                    <ion-row class="ion-justify-content-center ion-align-items-end">
                        <ion-button expand="full" color="airbnb" block (click)="suggestAirBnb()">
                            {{ (toolService.content | async)?.application?.components?.housingsuggestions?.airBnbButton }}</ion-button
                        >
                    </ion-row>
                </ion-col>
            </div>
        </ion-col>
    </ion-row>
</ion-grid>
<ion-footer *ngIf="!acceptedDisclosure">
    <ion-toolbar>
        <ion-button expand="full" *ngIf="!acceptedDisclosure" (click)="acceptDisclosure()" block>I Accept</ion-button>
    </ion-toolbar>
</ion-footer>
