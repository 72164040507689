<ion-header>
    <ion-toolbar>
        <ion-button slot="start" class="close" type="button" fill="clear" (click)="dismiss()">
            <ion-icon slot="icon-only" color="dark" name="close" size="medium"></ion-icon>
        </ion-button>
        <ion-title class="ion-text-center">Filters</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-grid>
        <ion-row>
            <ion-col>
                <ion-list *ngIf="content">
                    <ion-list-header> Select program features </ion-list-header>
                    <ion-item class="item-select" lines="none">
                        <ion-label position="stacked">Select Medical Specialty</ion-label>
                        <ion-select
                            [interfaceOptions]="customAlertOptions"
                            [(ngModel)]="selectedSpecialtyValue"
                            interface="alert"
                            (ionChange)="setSpecialty($event)"
                            multiple="true"
                        >
                            <ion-select-option *ngFor="let specialty of content?.content?.specialty; let i = index; trackBy: trackByIndex" [value]="specialty">
                                {{ specialty?.value }}
                            </ion-select-option>
                        </ion-select>
                        <ion-button (click)="clearRow('specialty')" class="mobile-clear-button" fill="clear" color="secondary" slot="end"> Clear </ion-button>
                    </ion-item>
                    <ion-item lines="none">
                        <ion-label position="stacked">Select Rotation Type</ion-label>
                        <ion-select
                            [interfaceOptions]="customAlertOptions"
                            [(ngModel)]="selectedTypeValue"
                            interface="alert"
                            (ionChange)="setType($event)"
                            multiple="true"
                        >
                            <ion-select-option *ngFor="let type of content?.content?.types; let i = index; trackBy: trackByIndex" [value]="type"
                                >{{ type?.value }}
                            </ion-select-option>
                        </ion-select>
                        <ion-button (click)="clearRow('type')" class="mobile-clear-button" fill="clear" color="secondary" slot="end"> Clear </ion-button>
                    </ion-item>
                    <ion-item lines="none">
                        <ion-label position="stacked">Select Months</ion-label>
                        <ion-select
                            [interfaceOptions]="customAlertOptions"
                            [(ngModel)]="selectedDateValue"
                            interface="alert"
                            (ionChange)="setDate($event)"
                            multiple="true"
                        >
                            <ion-select-option *ngFor="let date of content?.content?.dates; let i = index; trackBy: trackByIndex" [value]="date"
                                >{{ date?.display }}
                            </ion-select-option>
                        </ion-select>
                        <ion-button (click)="clearRow('dates')" class="mobile-clear-button" fill="clear" color="secondary" slot="end"> Clear </ion-button>
                    </ion-item>
                    <ion-item lines="none">
                        <ion-label position="stacked">Select Tags</ion-label>
                        <ion-select
                            [interfaceOptions]="customAlertOptions"
                            [(ngModel)]="selectedTagValue"
                            interface="alert"
                            (ionChange)="setTag($event)"
                            multiple="true"
                        >
                            <ion-select-option *ngFor="let tag of content?.content?.tags; let i = index; trackBy: trackByIndex" [value]="tag"
                                >{{ tag?.value }}
                            </ion-select-option>
                        </ion-select>
                        <ion-button (click)="clearRow('tag')" class="mobile-clear-button" fill="clear" color="secondary" slot="end"> Clear </ion-button>
                    </ion-item>
                    <ion-item lines="none">
                        <ion-label position="stacked">Select States</ion-label>
                        <ion-select
                            [interfaceOptions]="customAlertOptions"
                            [(ngModel)]="selectedStateValue"
                            interface="alert"
                            (ionChange)="setState($event)"
                            multiple="true"
                        >
                            <ion-select-option *ngFor="let state of content?.content?.states; let i = index; trackBy: trackByIndex" [value]="state"
                                >{{ state?.value }}
                            </ion-select-option>
                        </ion-select>
                        <ion-button (click)="clearRow('states')" class="mobile-clear-button" fill="clear" color="secondary" slot="end"> Clear </ion-button>
                    </ion-item>
                    <ion-item lines="none">
                        <ion-label position="stacked">Select Cities</ion-label>
                        <ion-select
                            [interfaceOptions]="customAlertOptions"
                            [(ngModel)]="selectedCityValue"
                            interface="alert"
                            (ionChange)="setCity($event)"
                            multiple="true"
                        >
                            <ion-select-option *ngFor="let city of content?.content?.cities; let i = index; trackBy: trackByIndex" [value]="city"
                                >{{ city?.value }}
                            </ion-select-option>
                        </ion-select>
                        <ion-button (click)="clearRow('cities')" class="mobile-clear-button" fill="clear" color="secondary" slot="end"> Clear </ion-button>
                    </ion-item>
                </ion-list>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<ion-footer>
    <ion-row>
        <ion-col class="ion-text-center">
            <ion-button expand="full" class="ion-text-wrap" style="max-width: 100%" color="secondary" (click)="reset()"> Clear All </ion-button>
        </ion-col>
        <ion-col class="ion-text-center">
            <ion-button expand="full" class="ion-text-wrap" style="max-width: 100%" color="primary" (click)="runSearch()"> Search </ion-button>
        </ion-col>
    </ion-row>
</ion-footer>
