import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToolService } from '@app/core/services/tool/tool.service';
import { NavController } from '@ionic/angular';
import { Angulartics2GoogleTagManager } from 'angulartics2';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
    constructor(
        private navController: NavController,
        private title: Title,
        public toolService: ToolService,
        private router: Router,
        private angulartics2GoogleTagManager: Angulartics2GoogleTagManager
    ) {}

    ngOnInit() {
        this.title.setTitle(`AMOpportunities - Page Not Found`);

        this.angulartics2GoogleTagManager.eventTrack('Page Not Found', {
            category: 'Error',
            label: window.location.href,
        });
    }
}
