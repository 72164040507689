<ion-app>
    <ion-header no-border>
        <ion-toolbar *ngIf="cookiesDisabled" class="text-center warning-banner-bar">
            <ion-item lines="none" color="warning-tint">
                <ion-label> Please enable cookies to access the AMO platform. </ion-label>
            </ion-item>
        </ion-toolbar>
        <ion-toolbar *ngIf="!cookiesDisabled" [ngClass]="($validProfile | async) === false ? 'hide' : 'tabs-container'">
            <ion-buttons slot="start">
                <a [routerLink]="['/dashboard/']">
                    <ion-img class="amoLogo" src="assets/icons/logo_orange.png"></ion-img>
                </a>
            </ion-buttons>
            <ion-buttons slot="end">
                <!-- <ion-icon *ngIf="isAdmin$ | async" name="globe" size="large" matTooltip="AMO Admin" color="primary"> </ion-icon> -->
                <div *ngIf="isLoggedIn | async" class="header-button-row">
                    <a *ngIf="isAdmin$ | async" class="nav-anchor" href="https://admin.amopportunities.org/dashboard" target="_blank">
                        <fa-icon [icon]="faUserCircle"></fa-icon>
                        Admin App
                    </a>
                    <button
                        class="global-nav-bar nav-bar-header-btn"
                        mat-button
                        [routerLink]="['/dashboard']"
                        [queryParams]="{ applications: 'latest' }"
                        (contextmenu)="open($event, 'dashboard?applications=latest')"
                    >
                        <fa-icon [icon]="faProHome"></fa-icon>
                        Dashboard
                    </button>

                    <button
                        class="global-nav-bar nav-bar-header-btn"
                        mat-button
                        [routerLink]="['/search/']"
                        [queryParams]="{
                            offset: '0',
                            limit: '25',
                            order: order
                        }"
                        (contextmenu)="open($event, 'search')"
                    >
                        <fa-icon [icon]="faProSearch"></fa-icon>
                        Search
                    </button>
                    <button
                        class="global-nav-bar nav-bar-header-btn"
                        mat-button
                        [routerLink]="['/search/']"
                        [queryParams]="{ suggested: 'true' }"
                        (contextmenu)="open($event, 'search?suggested=true')"
                    >
                        <fa-icon [icon]="faHospital"></fa-icon>
                        Suggested
                    </button>
                    <button class="global-nav-bar nav-bar-header-btn" mat-button [routerLink]="['/favorites/']" (contextmenu)="open($event, 'favorites')">
                        <fa-icon [icon]="faHeart"></fa-icon>
                        Favorites
                    </button>

                    <button
                        *ngIf="(itineraryList$ | async).length > 0 && enableItineraries"
                        class="global-nav-bar nav-bar-header-btn"
                        mat-button
                        [routerLink]="['/itinerary-create']"
                        [queryParams]="{ applications: 'latest' }"
                        matTooltip="View Your Itinerary"
                    >
                        <fa-icon [icon]="faLayerGroup"></fa-icon>
                        <ion-text> Itinerary</ion-text>
                        <ion-chip color="primary">
                            <ion-label color="white">{{ (itineraryList$ | async).length }}</ion-label>
                        </ion-chip>
                    </button>

                    <button class="global-nav-bar nav-bar-header-btn" mat-button [routerLink]="['/documents/']" matTooltip="Your Application Documents">
                        <fa-icon [icon]="faFileAlt"></fa-icon>
                        Documents
                    </button>

                    <button class="global-nav-bar nav-bar-header-btn" mat-button [routerLink]="['/payment/']" (contextmenu)="open($event, 'payment')">
                        <fa-icon [icon]="faShoppingCart"></fa-icon>
                        Payment
                    </button>

                    <!-- <button
                    class="global-nav-bar nav-bar-header-btn"
                    mat-button
                    [routerLink]="['/profile/']"
                    (contextmenu)="open($event, 'profile')"
                >
                    <fa-icon [icon]="faUser"></fa-icon>
                    Profile
                </button> -->

                    <!-- <button
                    class="global-nav-bar nav-bar-header-btn"
                    mat-button
                    [routerLink]="['/services/']"
                    matTooltip="User Services"
                    *ngIf="!(censorContent$ | async)"
                    (contextmenu)="open($event, 'services')"
                >
                    <fa-icon [icon]="faHandHoldingBox"></fa-icon>
                    Services
                </button> -->
                    <button class="global-nav-bar nav-bar-header-btn" mat-button [matMenuTriggerFor]="menuHelp" *ngIf="(censorContent$ | async) === false">
                        <fa-icon [icon]="faQuestionCircle"></fa-icon>
                        Help
                    </button>
                    <mat-menu #menuHelp="matMenu">
                        <a class="help-links" target="_blank" href="{{ (toolService.content | async)?.basic?.url?.faq }}" mat-menu-item>
                            <fa-icon [icon]="faAlignLeft"></fa-icon>FAQ
                        </a>
                        <a class="help-links" target="_blank" href="{{ (toolService.content | async)?.basic?.url?.contact }}" mat-menu-item>
                            <ion-icon class="nav-icon" name="call"></ion-icon>
                            Contact Us
                        </a>
                        <!-- <button
                        class="global-nav-bar"
                        mat-button
                        [routerLink]="['/chat']"
                        (contextmenu)="open($event, 'chat')"
                        [routerDirection]="'root'"
                    >
                        <fa-icon [icon]="faCommentAlt"></fa-icon>
                        Chat
                    </button> -->
                    </mat-menu>

                    <button class="global-nav-bar nav-bar-header-btn user-menu" id="menuDropdown" mat-button [matMenuTriggerFor]="menuLogout">
                        <div class="wrapper-menu">
                            <fa-icon [icon]="faBars"></fa-icon>
                            <!-- <span *ngIf="userImage !=''">
                            <img src="{{userImage}}" />
                        </span>
                        <span *ngIf="userImage ==''">{{ userIconName }}</span> -->
                            <span *ngIf="userImage !== '' && userImage !== null; else nameblock">
                                <img src="{{ userImage }}" alt="{{ userIconName }}" />
                            </span>
                            <ng-template #nameblock>
                                <span>{{ userIconName }}</span>
                            </ng-template>
                        </div>
                    </button>
                    <mat-menu #menuLogout="matMenu" xPosition="before" class="logout-menu">
                        <div class="menu-logout-items">
                            <ion-list lines="none">
                                <!-- <ion-item *ngIf="!(censorContent$ | async)"> -->
                                <ion-item>
                                    <ion-label class="title">
                                        {{ userName$ | async }}
                                    </ion-label>
                                </ion-item>

                                <div class="line"></div>

                                <ion-item button [routerLink]="['/profile/']" [routerDirection]="'forward'" *ngIf="(censorContent$ | async) === false">
                                    <ion-label class="bold">Profile Details</ion-label>
                                    <span class="ion-text-end"> More<fa-icon [icon]="faChevronRight"></fa-icon> </span>
                                </ion-item>

                                <ion-item
                                    button
                                    [routerLink]="['/profile/personal-information/']"
                                    [routerDirection]="'forward'"
                                    *ngIf="(censorContent$ | async) === false"
                                >
                                    <fa-icon slot="start" [icon]="profileMenuIcons.faSolidUser"></fa-icon>
                                    <ion-label>Personal Information</ion-label>
                                </ion-item>
                                <ion-item
                                    *ngIf="(censorContent$ | async) === false"
                                    button
                                    [routerLink]="['/profile/clinical-preferences/']"
                                    [routerDirection]="'forward'"
                                >
                                    <fa-icon slot="start" [icon]="profileMenuIcons.faBriefcaseMedical"></fa-icon>
                                    <ion-label>Clinical Preferences</ion-label>
                                </ion-item>

                                <div class="line"></div>

                                <ion-item button [routerLink]="['/profile/']" [routerDirection]="'forward'" *ngIf="(censorContent$ | async) === false">
                                    <ion-label class="bold">Account Settings</ion-label>
                                    <span class="ion-text-end"> More<fa-icon slot="start" [icon]="faChevronRight"></fa-icon> </span>
                                </ion-item>

                                <ion-item
                                    button
                                    [routerLink]="['/profile/account-settings/']"
                                    [routerDirection]="'forward'"
                                    *ngIf="(censorContent$ | async) === false"
                                >
                                    <fa-icon slot="start" [icon]="profileMenuIcons.faLockAlt"></fa-icon>
                                    <ion-label>Account Information</ion-label>
                                </ion-item>
                                <ion-item
                                    button
                                    [routerLink]="['/profile/account-subscriptions/']"
                                    [routerDirection]="'forward'"
                                    *ngIf="(censorContent$ | async) === false"
                                >
                                    <fa-icon slot="start" [icon]="profileMenuIcons.faPaperPlane"></fa-icon>
                                    <ion-label>Subscriptions</ion-label>
                                </ion-item>
                                <!-- <ion-item button class="disabled">
                                <fa-icon
                                    slot="start"
                                    [icon]="profileMenuIcons.faUsers"
                                ></fa-icon>
                                <ion-label>Invite Friends</ion-label>
                            </ion-item>
                            <ion-item button class="disabled">
                                <fa-icon
                                    slot="start"
                                    [icon]="profileMenuIcons.faPaperPlane"
                                ></fa-icon>
                                <ion-label>Join an Institution</ion-label>
                            </ion-item> -->
                                <ion-item
                                    button
                                    [routerLink]="['/profile/account-terms/']"
                                    [routerDirection]="'forward'"
                                    *ngIf="(censorContent$ | async) === false"
                                >
                                    <fa-icon slot="start" [icon]="profileMenuIcons.faNewspaper"></fa-icon>
                                    <ion-label>Terms and Conditions</ion-label>
                                </ion-item>

                                <ion-item
                                    button
                                    [routerLink]="['/services/']"
                                    *ngIf="(censorContent$ | async) === false"
                                    (contextmenu)="open($event, 'services')"
                                >
                                    <ion-label class="bold">Services</ion-label>
                                </ion-item>

                                <ion-item button id="amoIdLogout" (click)="logout()">
                                    <ion-label class="bold">Log out</ion-label>
                                </ion-item>
                            </ion-list>
                        </div>
                    </mat-menu>

                    <!-- <button
                    id="amoIdLogout"
                    class="global-nav-bar nav-bar-header-btn"
                    mat-button
                    (click)="logout()"
                >
                    <fa-icon [icon]="faSignOutAlt"></fa-icon>
                    Log out
                </button> -->
                </div>
                <div *ngIf="(isLoggedIn | async) === false" class="header-button-row">
                    <button mat-button class="global-nav-bar" [routerLink]="['/signup']" [routerDirection]="'root'">Create Account</button>
                    <button class="global-nav-bar" mat-button [routerLink]="['/login']" [routerDirection]="'root'">
                        Log in
                        <ion-icon name="log-in-outline" size="small"></ion-icon>
                    </button>
                    <button class="global-nav-bar" mat-button [routerLink]="['/search']" [routerDirection]="'root'">
                        Search
                        <ion-icon name="search" size="small"></ion-icon>
                    </button>
                </div>
                <!-- Template for right click menu -->
                <ng-template #navMenu let-selectedTab>
                    <section>
                        <ion-item class="context-menu" button (click)="openNewTab(selectedTab)">
                            <ion-label>Open in new tab</ion-label>
                        </ion-item>
                    </section>
                </ng-template>
            </ion-buttons>
        </ion-toolbar>
        <ion-toolbar *ngIf="(route.queryParams | async).mgmttype === 'permalink'" class="permalink-navbar ion-text-center">
            <ion-button (click)="closeTab()">
                <ion-icon slot="start" name="close"></ion-icon>
                Close Program Preview
            </ion-button>
        </ion-toolbar>
        <ion-progress-bar *ngIf="loadingService.isLoading | async" type="indeterminate" reversed="true"> </ion-progress-bar>
    </ion-header>

    <ion-menu side="start" swipe-gesture="false" contentId="my-content">
        <ion-header>
            <ion-toolbar slot="start">
                <ion-title>Menu </ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <!-- <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages"
          [ngClass]="!($validProfile | async) ? 'hide' : 'tabs-container'">
          <ion-item [routerDirection]="'root'" [routerLink]="[p.url]">
            <ion-icon slot="start" [name]="p.icon"></ion-icon>
            <ion-label>
              {{p.title}}
            </ion-label>
          </ion-item>
        </ion-menu-toggle> -->

            <ion-list *ngIf="(isLoggedIn | async) === false">
                <ion-menu-toggle auto-hide="false">
                    <ion-item [routerDirection]="'root'" [routerLink]="['/login']">
                        <ion-label> Log In </ion-label>
                    </ion-item>
                    <ion-item [routerDirection]="'root'" [routerLink]="['/signup']">
                        <ion-label> Create Account </ion-label>
                    </ion-item>
                </ion-menu-toggle>
            </ion-list>

            <ion-list *ngIf="isLoggedIn | async">
                <ion-menu-toggle auto-hide="false">
                    <ion-item *ngIf="isAdmin$ | async" href="https://admin.amopportunities.org/dashboard" target="_blank">
                        <fa-icon slot="start" [icon]="faUserCircle"></fa-icon>
                        <ion-label>Admin App</ion-label>
                    </ion-item>
                    <ion-item [routerDirection]="'root'" [routerLink]="['/search']">
                        <fa-icon slot="start" [icon]="faSearch"></fa-icon>
                        <ion-label> Search </ion-label>
                    </ion-item>
                    <ion-item [routerLink]="['/search/']" [queryParams]="{ suggested: 'true' }" matTooltip="Suggested Programs Just for You.">
                        <fa-icon slot="start" [icon]="faClinicMedical"></fa-icon>
                        <ion-label> Suggested </ion-label>
                    </ion-item>
                    <ion-item [routerLink]="['/favorites/']" matTooltip="Suggested Programs Just for You.">
                        <ion-icon slot="start" name="heart"></ion-icon>
                        <ion-label> Favorites </ion-label>
                    </ion-item>
                    <ion-item [routerDirection]="'root'" [routerLink]="['/dashboard']" [queryParams]="{ applications: 'latest' }">
                        <fa-icon slot="start" [icon]="faHome"></fa-icon>

                        <ion-label> Dashboard </ion-label>
                    </ion-item>

                    <ion-item *ngIf="(itineraryList$ | async).length && enableItineraries" [routerLink]="['/itinerary-create']">
                        <ion-icon slot="start" name="list"></ion-icon>

                        <ion-label> Itinerary </ion-label>
                        <ion-chip color="primary">
                            <ion-label color="white">{{ (itineraryList$ | async).length }}</ion-label>
                        </ion-chip>
                    </ion-item>

                    <ion-item [routerDirection]="'root'" [routerLink]="['/documents/']">
                        <ion-icon slot="start" name="document"></ion-icon>
                        <ion-label> Documents </ion-label>
                    </ion-item>

                    <ion-item [routerDirection]="'root'" [routerLink]="['/payment/']">
                        <fa-icon slot="start" [icon]="faShoppingCart"></fa-icon>
                        <ion-label> Payment </ion-label>
                    </ion-item>

                    <ion-item [routerDirection]="'root'" [routerLink]="['/profile']">
                        <fa-icon slot="start" [icon]="faIdCard"></fa-icon>
                        <ion-label> My Profile </ion-label>
                    </ion-item>

                    <ion-item [routerDirection]="'root'" [routerLink]="['/services']" *ngIf="(censorContent$ | async) === false">
                        <fa-icon slot="start" [icon]="faHandHoldingHeart"></fa-icon>
                        <ion-label> Services </ion-label>
                    </ion-item>
                    <!-- <ion-item
                        [routerDirection]="'root'"
                        [routerLink]="['/chat']"
                        *ngIf="!(censorContent$ | async)"
                    >
                        <fa-icon slot="start" [icon]="faCommentAlt"></fa-icon>
                        <ion-label>
                            Customer Chat
                        </ion-label>
                    </ion-item> -->
                    <ion-item target="_blank" href="https://app.amopportunities.org/contact" *ngIf="(censorContent$ | async) === false">
                        <ion-icon slot="start" name="at"></ion-icon>
                        <ion-label> Contact Us </ion-label>
                    </ion-item>
                    <ion-item target="_blank" href="https://support.amopportunities.org" *ngIf="(censorContent$ | async) === false">
                        <fa-icon slot="start" [icon]="faQuestionCircle"></fa-icon>
                        <ion-label> FAQ </ion-label>
                    </ion-item>
                    <ion-item (click)="logout()">
                        <ion-icon slot="start" name="log-out"></ion-icon>
                        <ion-label> Log out </ion-label>
                    </ion-item>
                </ion-menu-toggle>
            </ion-list>
        </ion-content>
    </ion-menu>

    <ion-grid *ngIf="cookiesDisabled">
        <ion-row class="ion-justify-content-center">
            <ion-col size="12" size-xl="10" size-lg="8" size-md="8" size-sm="12" size-xs="12">
                <div>
                    <ion-card class="program-missing-card">
                        <ion-card-header class="ion-text-center">
                            <ion-img class="amoLogo" src="assets/icons/logo_orange.png"></ion-img>

                            <ion-card-title> </ion-card-title>
                            <ion-card-title> Enable Cookies </ion-card-title>
                            <ion-card-subtitle> Please enable cookies to access the AMO platform. </ion-card-subtitle>
                        </ion-card-header>

                        <ion-card-content>
                            <ion-button
                                class="ion-text-wrap call-to-action-btn"
                                type="button"
                                expand="block"
                                fill="clear"
                                size="large"
                                href="mailto: {{ (toolService.content | async)?.basic?.email?.main }}"
                                >Contact Support
                            </ion-button>
                            <ion-button
                                class="ion-text-wrap call-to-action-btn"
                                type="button"
                                expand="block"
                                fill="clear"
                                size="large"
                                href="https://support.amopportunities.org/knowledge"
                                >Read our FAQ
                            </ion-button>
                            <ion-button
                                class="ion-text-wrap call-to-action-btn"
                                type="button"
                                expand="block"
                                fill="clear"
                                size="large"
                                href="https://www.amopportunities.org"
                                >Home
                            </ion-button>
                        </ion-card-content>
                        <ion-card-content class="ion-text-center">
                            <ion-text class="ion-text-center">
                                Call Us:
                                {{ (toolService.content | async)?.basic.phone.main }}
                            </ion-text>
                        </ion-card-content>
                    </ion-card>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>

    <div *ngIf="showUpdateBanner" class="text-center bannerUpdateShow">
        <div class="icon">
            <fa-icon [icon]="faExclamationCircle"></fa-icon>
        </div>
        <div class="banner-msg">
            <ion-label>Website Update Detected.</ion-label>
            <ion-label>A new version of the website is available, click &apos;Update Now&apos; for best experience.</ion-label>
        </div>
        <div>
            <ion-button id="letsupdate" mode="md" size="small" fill="outline" color="light" type="button" (click)="getwebsiteUpdates()">Update Now </ion-button>
        </div>
    </div>
    <ion-router-outlet *ngIf="!cookiesDisabled" id="my-content"></ion-router-outlet>
</ion-app>
<!-- <ion-app *ngIf="isValidBrowser === false">

  <ion-content>
    <ion-grid>
      <ion-row class="ion-justify-content-center">
        <ion-col size="12" size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="4">

          <ion-card style='background-color: white;'>
            <ion-card-header class="ion-text-center">
                <ion-img class="amoLogo" src="assets/icons/logo_orange.png"></ion-img>
            </ion-card-header>

            <ion-card-content>

              
                <ion-row class="ion-justify-content-center">

                  <ion-col size="12" size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
                    <ion-text>
                      <h1 class="top-title text-teal ion-text-center">
                        Please update your web browser.
                      </h1>
                    </ion-text>


                    <ion-list>
                      <ion-item lines="none">

                      </ion-item>
                      <ion-item lines="none">
                        <ion-text class="ion-text-wrap">
                          <p>We do not support your current web browser. Please use the latest version of <i class="fab fa-chrome"></i> <a href="https://www.google.com/chrome/"
                            targe="_blank">Google Chrome</a>, <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a> or <a
                            href="https://www.microsoft.com/en-us/edge"
                            target="_blank">Microsoft Edge</a> for the best AMO experience.</p>
                        </ion-text>
                      </ion-item>
                      <br>
                      <ion-item lines="none">
                        <ion-text class="ion-text-wrap">
                          <p>Email us at <a href="mailto:support@amopportunities.org?subject=Please update your web browser.">support@amopportunities.org</a> or call us 
                            at <a href="tel:1-312-821-9020">+1 (312) 821-9020</a>
                            if you have any further issues.</p>
                        </ion-text>
                      </ion-item>
                    </ion-list>
                  </ion-col>
                </ion-row>
          
            </ion-card-content>
            
          </ion-card>

        </ion-col>
      
      </ion-row>
    
    </ion-grid>
  
  </ion-content>

</ion-app> -->
