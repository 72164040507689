<div class="row h-100">
    <div class="col m-2">
        <div class="text-end">
            <button class="bg-transparent me-2" (click)="close()">
                <fa-icon [icon]="faTimes"></fa-icon>
            </button>
        </div>
        <div class="modal-body m-5">
            <div class="" *ngIf="modalTab === 1">
                <ion-col class="text-center" size="12">
                    <div>
                        <fa-icon class="icon-size" slot="start" [icon]="faCheckCircle"></fa-icon>
                    </div>
                </ion-col>
                <ion-col size="12">
                    <ion-text class="text-center bold-text">
                        <h4 class="text-color">
                            Congratulations your application
                        </h4>
                        <h4 class="text-color">
                            was created!!
                        </h4>
                    </ion-text>
                    <ion-text class="bold-text">
                        <h6 class="text-color">
                            What to expect?
                        </h6>
                    </ion-text>
                    <ion-text>
                        Your application has been submitted for processing. AMO generates an information card based on
                        your profile if you have not submitted your CV/Resume.
                    </ion-text>
                </ion-col>

            </div>
            <div class="" *ngIf="modalTab === 2">
                <ion-text class="bold-text">
                    <h6 class="text-color me-1">
                        <fa-icon class="icon-upload" slot="start" [icon]="faUpload"></fa-icon>
                        <span class="ms-3">Start uploading your documents</span>
                    </h6>
                </ion-text>
                <div class="mt-3 mb-4">
                    To avoid delays in application processing, start uploading your documents as soon as possible. If
                    your application requires additional information, an AMO Advisor will contact you directly via
                    email.
                </div>
                <div class="text-center mt-4">
                    <img height="180px" width="300px"
                        src="https://d1icd6shlvmxi6.cloudfront.net/gsc/PW4QN2/f0/35/e8/f035e8edbcc74088912014dbf52b19d6/images/apply_now_program_details/u29.png?pageId=531f4b28-f1b4-40d5-962f-0b43ca8064ae" />

                </div>
            </div>
            <div class="" *ngIf="modalTab === 3">
                <ion-text class="bold-text">
                    <h6 class="text-color me-1">
                        <fa-icon class="icon-upload" slot="start" [icon]="faPlane"></fa-icon>
                        <span class="ms-3">Travel Identification</span>
                    </h6>
                </ion-text>
                <div class="mt-4 mb-4">
                    In-person experiences require a valid visa, US passport or US driver’s license. Virtual rotations do
                    not require a visa.
                </div>
                <div class="mt-3 mb-4">
                    For any questions about traveling or visa support, please contact your AMO Advisor.
                </div>
                <div class="mt-3 mb-4">
                    <h6>
                        <a target="_blank" href="{{
                            (toolService.content | async)?.basic?.url
                                ?.roundrobinlink
                        }}?email={{ USER_EMAIL }}" target="_blank">
                            <span class="contact-advisor"><u>Contact your Advisor</u></span>
                        </a>
                    </h6>
                </div>
            </div>
            <div class="mb-1" *ngIf="modalTab === 4">
                <ion-text class="bold-text">
                    <h6 class="text-color me-1">
                        <fa-icon class="icon-upload" slot="start" [icon]="faCalendarCheck"></fa-icon>
                        <span class="ms-3">After being accepted
                        </span>
                    </h6>
                </ion-text>
                <div class="mt-4 mb-4">
                    Upon acceptance you are eligible to reserve your visitor experience. After your reservation, you are
                    connected with your personal AMO Visitor Experience Coach to guide you along your journey.
                </div>
                <div class="mt-3 mb-4">
                    Your Coach is here to answer questions, ease concerns, and to help finalize the enrollment process.
                </div>
            </div>
        </div>
    </div>
</div>

<hr class="hr-body mt-1 mb-1">
<div class="d-flex justify-content-between ms-2 me-2 mt-1 ps-3 pe-3 m-2">
    <div>
        <ion-button fill="clear" color="secondary" *ngIf="modalTab > 1" (click)="modalPrevHandler()"><fa-icon
                class="m-1" [icon]="faAngleLeft"></fa-icon>Back</ion-button>
    </div>
    <div>
        <ion-button fill="outline" color="secondary" *ngIf="modalTab < 4" (click)="modalNextHandler()">Next</ion-button>
        <ion-button fill="outline" color="secondary" *ngIf="modalTab === 4" (click)="close()">Done</ion-button>
    </div>
</div>