<div>
  <ion-toolbar color="--ion-color-light-teal-2" class="ion-text-center">
    <ion-title class="ion-text-center">
      <img class="amoLogo-modal" src="assets/icons/logo_orange.png" />
    </ion-title>

  </ion-toolbar>
  <ion-card-content>
    <div class="top-message ion-text-left">
      {{topMessage}}
    </div>
    <br>
    <div class="ion-text-left bottom-message" [innerHTML]="bottomMessage | safe: 'html'">
      <!-- {{bottomMessage}} -->
    </div>
    <div class="modal-button-container ion-text-center">
      <div *ngIf="task === 'tempUser'">
        <ion-button id="LoginButton" color="secondary" class="small-button" (click)="toLogin()">
          Log in
        </ion-button>
        <ion-button id="signupButton" color="primary" class="small-button" (click)="toSignUp()">
          Signup
        </ion-button>
      </div>
    </div>
  </ion-card-content>
</div>