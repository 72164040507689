import { Injectable } from '@angular/core';
import { State } from '@app/core/ngrx/reducers';
import { paymentSelectors } from '@app/core/ngrx/selectors';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { forkJoin, Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class QueryService {
    // the current payment cart
    CURRENT_CART: any;

    constructor(private apollo: Apollo) {
        // payment service subscription to current cart
    }

    travelRequirementsQuery = gql`
        query travelRequirements {
            user {
                id
                passportexpiredate
                passportnumber
                passportissuedate
                passportissuecountry
            }
        }
    `;

    clinicalPreferencesQuery = gql`
        query clinicalPreferences {
            user {
                id
                preflocation
                prefmonth
                prefspecialty
            }
        }
    `;

    medicalEducationQuery = gql`
        query medicalEducation {
            user {
                id
                fieldofstudyid
                hasvisa
                status
                university
                usmle
                yearsattended
                universitycountry
                departmentid
            }
        }
    `;

    managementProfile = gql`
        query managementProfile {
            management {
                id
                email
                role
                rules
                checkdepartment {
                    id
                    amoservices
                }
            }
        }
    `;

    userProfile = gql`
        query userProfile {
            user {
                id
                acquisition
                altphone
                address
                birthday
                city
                country
                countryofbirth
                departmentid
                fieldofstudyid
                fieldofstudy
                gender
                hasvisa
                item1name
                item2name
                item3name
                item4name
                item5name
                item6name
                item7name
                item8name
                item9name
                item10name
                passportexpiredate
                passportnumber
                passportissuedate
                passportissuecountry
                phone
                preflocation
                prefmonth
                prefspecialty
                postalcode
                name
                role
                state
                status
                subscribed
                notifications
                suggestions
                optout
                statement
                university
                universitycountry
                usmle
                yearsattended
                profileimage
                email
            }
            countries
        }
    `;

    applicationPayment = gql`
        query Payment($id: ID, $cartStatus: JSON) {
            payableApplications(id: $id) {
                id
                activePriceDropCoupon
                city
                coupons
                couponCodes
                deposit
                name
                enddate
                pastDeadline
                firstPaymentPrice
                finalPaymentPrice
                fullPaymentPrice
                finalSubtotalPrice(cartStatus: $cartStatus)
                firstSubtotalPrice
                fullSubtotalPrice(cartStatus: $cartStatus)
                forceFullPayment
                madedeposit
                madepayment
                malpracticepaid
                paymentType
                pendingWiretransfer
                price
                pricechange
                isLate
                isChecked
                isCouponDisabled
                malpracticeChecked
                malpracticeAddonPrice
                paymentApplicationAddons
                partialPaymentDiscount
                previousPaymentList
                specialty
                state
                states
                startdate
                switchProtectionChecked
                switchProtectionAddonPrice
                type
                userid
                wiretransferLineItems
                voucherDiscount
                applicationChain
                program {
                    id
                    name
                    lastminute
                    image
                    preapprovalrequired
                }
            }
        }
    `;

    applicationChainDetails(id: string) {
        return this.apollo.query({
            query: gql`
                query userApplicationChains($id: ID!) {
                    user {
                        id
                        name
                        email
                        role
                        preflocation
                        fieldofstudyid
                        usmle
                        status
                        immunizations
                        applicationChains(id: $id) {
                            id
                            name
                            states
                            submittedrequirements
                        }
                    }
                    submittedChainedProgramDocuments(id: $id) {
                        documents
                    }
                    applicationRequirementList(id: $id) {
                        documents
                    }
                    applicationChainMalpracticeDocument(id: $id) {
                        documents
                    }
                    applicationChainHealthInsuranceDocument(id: $id) {
                        documents
                    }
                }
            `,
            variables: {
                id,
            },
            fetchPolicy: 'network-only',
        });
    }

    getApplicationChainHealthInsuranceDocuments(id: string) {
        return this.apollo.query({
            query: gql`
                query applicationChainHealthInsuranceDocument($id: ID!) {
                    applicationChainHealthInsuranceDocument(id: $id) {
                        documents
                    }
                }
            `,
            variables: {
                id,
            },
            fetchPolicy: 'network-only',
        });
    }

    applicationMalpracticeDocuments(id: string) {
        return this.apollo.query({
            query: gql`
                query applicationMalpracticeDocument($id: ID!) {
                    applicationMalpracticeDocument(id: $id) {
                        documents
                    }
                }
            `,
            variables: {
                id,
            },
            fetchPolicy: 'network-only',
        });
    }

    applicationHealthinsuranceDocuments(id: string) {
        return this.apollo.query({
            query: gql`
                query applicationHealthinsuranceDocument($id: ID!) {
                    applicationHealthinsuranceDocument(id: $id) {
                        documents
                    }
                }
            `,
            variables: {
                id,
            },
            fetchPolicy: 'network-only',
        });
    }

    applicationPaymentDetails(id: any): Observable<any> {
        return this.apollo.query({
            query: gql`
                query paymentPage($id: ID!) {
                    application(id: $id) {
                        id
                        name
                        programid
                        userid
                        price
                        specialty
                        state
                        city
                        startdate
                        enddate
                        type
                        deposit
                        madedeposit
                        madepayment
                        user {
                            id
                            name
                            email
                            stripecustomerid
                        }
                        program {
                            id
                            lastminute
                            image
                        }
                    }
                }
            `,
            variables: {
                id,
            },
            fetchPolicy: 'network-only',
        });
    }

    programSearch = (where: any): Observable<any> => {
        //
        let searchQuery;

        if (where.basesearch) {
            searchQuery = { ...where, params: { basesearch: where.basesearch } };
        } else {
            searchQuery = where;
        }

        return this.apollo.query({
            query: gql`
                query programSearch($searchQuery: JSONObject) {
                    user {
                        id
                        name
                        fieldofstudy
                        programs(where: $searchQuery)
                    }
                    programLocations
                    searchContent(where: $searchQuery)
                }
            `,
            variables: {
                searchQuery,
            },
            fetchPolicy: 'no-cache',
        });
    };

    /**
     *
     * @param where search query object using specific properties
     * @returns  progrma list
     * @description query without the added weight of search content
     */
    programSearchScrollActions = (where: any): Observable<any> => {
        //
        //
        let searchQuery;

        if (where.basesearch) {
            searchQuery = { ...where, params: { basesearch: where.basesearch } };
        } else {
            searchQuery = where;
        }
        //
        return this.apollo.query({
            query: gql`
                query programSearchScrollActions($searchQuery: JSONObject) {
                    user {
                        id
                        name
                        fieldofstudy
                        programs(where: $searchQuery)
                    }
                    searchContent(where: $searchQuery)
                }
            `,
            variables: {
                searchQuery,
            },
            fetchPolicy: 'no-cache',
        });
    };

    /**
     *
     * @param where search query object using specific properties
     * @returns  progrma list
     * @description query without the added weight of search content
     */
    programSearchScrollActionsManagement = (where: any): Observable<any> => {
        //
        //
        return this.apollo.query({
            query: gql`
                query programSearchScrollActions($where: JSONObject) {
                    management {
                        id
                        name
                        programs(where: $where)
                    }
                    searchContent(where: $where)
                }
            `,
            variables: {
                where,
            },
            fetchPolicy: 'network-only',
        });
    };

    publicProgramSearch = (where: any): Observable<any> => {
        return this.apollo.query({
            query: gql`
                query programSearch($where: JSONObject) {
                    publicProgramSearch {
                        id
                        createdAt
                        type
                        name
                        specialty
                        specialties
                        city
                        createdAt
                        state
                        permitted
                        price
                        livedate
                        image
                        price
                        featured
                        offered
                        programtags
                        geoloc
                        hostid
                        live
                        priceDropPrice
                    }
                    programLocations
                    searchContent(where: $where)
                }
            `,
            variables: {
                where,
            },
            fetchPolicy: 'cache-first',
        });
    };

    checkUserHash = (userid: any): Observable<any> => {
        return this.apollo.query({
            query: gql`
                query findUser($id: ID!) {
                    findUser(id: $id) {
                        hashid
                    }
                }
            `,
            fetchPolicy: 'network-only',
            variables: {
                id: userid,
            },
        });
    };

    payableApplication = (id?: string) => {
        return this.apollo.query({
            query: this.applicationPayment,
            variables: {
                id,
            },
            fetchPolicy: 'network-only',
        });
    };

    updateCartFinal = (id?: string, cartStatus?: any) => {
        return this.apollo.query({
            query: gql`
                query updateCartFinal($id: ID, $cartStatus: JSON) {
                    payableApplications(id: $id) {
                        id
                        finalPaymentPrice
                        finalPaymentPrice
                        finalSubtotalPrice(cartStatus: $cartStatus)
                        fullSubtotalPrice(cartStatus: $cartStatus)
                    }
                }
            `,
            variables: {
                id,
                cartStatus,
            },
            fetchPolicy: 'network-only',
        });
    };

    dashboard = (): Observable<any> => {
        return this.apollo.query({
            query: gql`
                query userDashboard {
                    user {
                        id
                        hasReservedApplication
                        name
                        email
                        role
                        preflocation
                        fieldofstudyid
                        usmle
                        status
                        hasvisa
                        activeApplications {
                            id
                            specialty
                            name
                            states
                            coach
                            city
                            madedeposit
                            madepayment
                            month
                            image
                            type
                            requiresPayment
                            startyear
                            state
                            startdate
                            signedcontract
                            enddate
                            switchprotectionpaid
                            program {
                                id
                                image
                                preapprovalrequired
                                requiredpredocumentlist
                                requiredpostdocumentlist
                            }
                        }
                        completeApplications {
                            id
                            specialty
                            name
                            states
                            coach
                            enddate
                            city
                            state
                            month
                            startyear
                            image
                            type
                            startdate
                            program {
                                id
                                image
                            }
                        }
                        applicationChainsDashboard
                    }
                }
            `,
            fetchPolicy: 'network-only',
        });
    };

    landingNew = (): Observable<any> => {
        return this.apollo.query({
            query: gql`
                query myUser {
                    user {
                        id
                        name
                        profileComplete
                        acquisition
                    }
                }
            `,
            fetchPolicy: 'network-only',
        });
    };

    landingManager = (): Observable<any> => {
        return this.apollo.query({
            query: gql`
                query myUser {
                    management {
                        id
                        name
                    }
                }
            `,
            fetchPolicy: 'network-only',
        });
    };

    landing = (): Observable<any> => {
        return this.apollo.query({
            query: gql`
                query myUser {
                    user {
                        id
                        name
                        profileComplete
                        termsagreed
                        hasActiveApplication
                    }
                }
            `,
            fetchPolicy: 'network-only',
        });
    };

    applicationDetails = (applicationId: any): Observable<any> => {
        return this.apollo.query({
            query: gql`
                query applicationDetails($id: ID!) {
                    user {
                        id
                        acquisition
                        altphone
                        address
                        birthday
                        city
                        email
                        country
                        countryofbirth
                        fieldofstudyid
                        gender
                        hasvisa
                        hasReservedApplication
                        immunizations
                        passportexpiredate
                        passportnumber
                        passportissuedate
                        passportissuecountry
                        phone
                        preflocation
                        prefmonth
                        prefspecialty
                        postalcode
                        name
                        state
                        status
                        statement
                        subscribed
                        notifications
                        suggestions
                        optout
                        university
                        usmle
                        yearsattended
                        item1name
                        item2name
                        item3name
                        item4name
                        item5name
                        item6name
                        item7name
                        item8name
                        item9name
                        item10name
                        visameeting
                        application(id: $id) {
                            id
                            userid
                            fullname
                            programid
                            name
                            canEnroll
                            coach
                            contracturl
                            contractapitoken
                            city
                            state
                            type
                            createdAt
                            accepteddate
                            reserveddate
                            specialty
                            signedcontract
                            pendingWiretransfer
                            programlocation
                            paidPreApproved
                            physician
                            startdate
                            enddate
                            enrollable
                            isLate
                            image
                            expires
                            month
                            startyear
                            price
                            pricechange
                            paymentDeadlineDate
                            deposit
                            states
                            madedeposit
                            madepayment
                            madefullpayment
                            pricechange
                            untilchange
                            accepteddate
                            housingaddress
                            housingtype
                            airbnblink
                            requiresinvitation
                            canrequestinvitation
                            visitorstatus
                            couponid
                            hashousing
                            hasrequestedbookingreview
                            hasrequestedinvitation
                            nolatefee
                            predepartureorientation
                            preenrollmentorientation
                            preacceptancereviewable
                            migrationdocumentdate
                            switchprotectionpaid
                            checklist
                            immunizationStatuses
                            submittedrequirements
                            program {
                                id
                                affiliatedsites
                                additionalrequirements
                                name
                                specialty
                                description
                                reportingtime
                                lastminute
                                programlocation
                                address
                                city
                                state
                                postalcode
                                price
                                physician
                                deposit
                                exposuretype
                                type
                                content
                                image
                                schedule
                                permitted
                                geoloc
                                programtags
                                preapprovalrequired
                                requiredpostdocumentlist
                                requiredpredocumentlist
                                requirementsDescriptions
                                host {
                                    name
                                }
                            }
                        }
                    }
                    requiredPostProgramDocuments(id: $id) {
                        documents
                    }
                    submittedProgramDocuments(id: $id) {
                        documents
                    }
                    applicationDocuments(id: $id) {
                        documents
                    }
                    singleApplicationRequirementList(id: $id) {
                        documents
                    }
                    submittedDocumentsRequirements(id: $id) {
                        documents
                    }
                    applicationHealthinsuranceDocument(id: $id) {
                        documents
                    }
                    applicationMalpracticeDocument(id: $id) {
                        documents
                    }
                }
            `,
            fetchPolicy: 'network-only',
            variables: {
                id: applicationId,
            },
        });
    };

    applicationChainApplicationUserDocuments = (applicationid: string[]): Observable<any> => {
        return forkJoin(
            applicationid.map((id) => {
                return this.apollo.query({
                    query: gql`
                        query applicationDocuments($id: ID!) {
                            applicationDocuments(id: $id) {
                                documents
                            }
                        }
                    `,
                    fetchPolicy: 'network-only',
                    variables: { id: id },
                });
            })
        );
    };

    applicationDocumentRequirementsList = (applicationid: string): Observable<any> => {
        return this.apollo.query({
            query: gql`
                query applicationRequirementSingle($id: ID!) {
                    singleApplicationRequirementList(id: $id) {
                        documents
                    }
                    submittedDocumentsRequirements(id: $id) {
                        documents
                    }
                    applicationMalpracticeDocument(id: $id) {
                        documents
                    }
                }
            `,
            fetchPolicy: 'network-only',
            variables: { id: applicationid },
        });
    };

    applicationChainRouting = (applicationId: string): Observable<any> => {
        return this.apollo.query({
            query: gql`
                query applicationChainRouting($id: ID!) {
                    user {
                        id
                        hasReservedApplication
                        name
                        email
                        role
                        preflocation
                        fieldofstudyid
                        usmle
                        status
                        application(id: $id) {
                            id
                            specialty
                            name
                            states
                            coach
                            city
                            madedeposit
                            madepayment
                            month
                            image
                            type
                            requiresPayment
                            startyear
                            state
                            startdate
                            signedcontract
                            userid
                            enddate
                            program {
                                id
                                preapprovalrequired
                                requiredpredocumentlist
                                requiredpostdocumentlist
                            }
                        }
                    }
                }
            `,
            fetchPolicy: 'network-only',
            variables: {
                id: applicationId,
            },
        });
    };

    findProgram = (programid: any): Observable<any> => {
        return this.apollo.query({
            query: gql`
                query findProgram($id: ID!) {
                    program(id: $id) {
                        id
                        live
                        name
                        programlocation
                        affiliatedsites
                        description
                        specialty
                        geoloc
                        programtags
                        permitted
                        deposit
                        price
                        priceDropPrice
                        image
                        offered
                        city
                        state
                        schedule
                        content
                        exposuretype
                        customavailability
                        type
                        additionalrequirements
                        isFavorite
                        pricedropenddate
                        hourlyprice
                        reviews {
                            name
                            programid
                            rating
                            content
                            type
                        }
                    }
                }
            `,
            fetchPolicy: 'network-only',
            variables: {
                id: programid,
            },
        });
    };

    /**
     *
     * @param programId: id to query application requirements descriptions
     * @returns object
     */
    applicationRequirementsDescriptions = (programId: string): Observable<any> => {
        return this.apollo.query({
            query: gql`
                query applicationRequirementsDescriptions($id: ID!) {
                    program(id: $id) {
                        id
                        requirementsDescriptions
                    }
                }
            `,
            fetchPolicy: 'network-only',
            variables: {
                id: programId,
            },
        });
    };

    /**
     * @description takes the application id and queries for latest updates related to documents
     * runs as netwok only
     *
     */
    documentCheck = (applicationId: string): Observable<any> => {
        return this.apollo.query({
            query: gql`
                query bookingEligible {
                    user {
                        id
                        hasvisa
                        item1name
                        item2name
                        item3name
                        item4name
                        item5name
                        item6name
                        item7name
                        item8name
                        item9name
                        item10name
                    }
                }
            `,
            fetchPolicy: 'network-only',
            variables: {
                id: applicationId,
            },
        });
    };

    suggestedPrograms = (): Observable<any> => {
        return this.apollo.query({
            query: gql`
                query suggestedPrograms {
                    user {
                        id
                        prefspecialty
                        preflocation
                    }
                }
            `,
            fetchPolicy: 'cache-first',
        });
    };

    profile = (): Observable<any> => {
        return this.apollo.query({
            query: this.userProfile,
            fetchPolicy: 'network-only',
        });
    }; // end profile

    settings = (): Observable<any> => {
        return this.apollo.query({
            query: gql`
                query userSettings {
                    user {
                        id
                        subscribed
                        notifications
                        suggestions
                        newsletter
                        applicationalerts
                        presentations
                        programalerts
                        offers
                        surveys
                        resources
                        optout
                        email
                        sms_optin
                        sms_optedin_date
                    }
                }
            `,
            fetchPolicy: 'network-only',
        });
    }; // end profile

    seatsRemaining = (): any => {
        return gql`
            query seatsRemaining($programId: ID!, $date: String!) {
                seatsRemaining(programId: $programId, date: $date)
            }
        `;
    };

    userlookup = (userid: any): Observable<any> => {
        return this.apollo.query({
            query: gql`
                query findUser($id: ID) {
                    findUser(id: $id) {
                        id
                        name
                        email
                        status
                    }
                }
            `,
            fetchPolicy: 'network-only',
            variables: {
                id: userid,
            },
        });
    };

    findFavorite = (): any => {
        return gql`
            query findFavorite($programId: ID!) {
                findFavorite(programId: $programId)
            }
        `;
    };

    managementProfileSearch = (): Observable<any> => {
        return this.apollo.query({
            query: this.managementProfile,
            fetchPolicy: 'network-only',
        });
    }; // end profile

    managementProgramSearch = (where: any): Observable<any> => {
        return this.apollo.query({
            query: gql`
                query programSearch($where: JSONObject) {
                    management {
                        id
                        name
                        programs(where: $where)
                    }
                    programLocations
                    searchContent(where: $where)
                }
            `,
            variables: {
                where,
            },
            fetchPolicy: 'network-only',
        });
    };

    ProgramBundleSearch = (where: any): Observable<any> => {
        return this.apollo.query({
            query: gql`
                query programBundleSearch($where: JSONObject) {
                    user {
                        id
                        name
                        programBundles
                    }
                    searchContent(where: $where)
                }
            `,
            variables: {
                where,
            },
            fetchPolicy: 'network-only',
        });
    };

    ManagersProgramBundleSearch = (where: any): Observable<any> => {
        return this.apollo.query({
            query: gql`
                query programBundleSearch($where: JSONObject) {
                    management {
                        id
                        name
                        programBundles
                    }
                    searchContent(where: $where)
                }
            `,
            variables: {
                where,
            },
            fetchPolicy: 'network-only',
        });
    };

    housing = (applicationId: any): any => {
        return this.apollo.query({
            query: gql`
                query findApplication($id: ID!) {
                    user {
                        id
                        application(id: $id) {
                            id
                            housingtype
                            housingaddress
                            airbnblink
                            hashousing
                        }
                    }
                }
            `,
            fetchPolicy: 'network-only',
            variables: {
                id: applicationId,
            },
        });
    };

    passport = (): any => {
        return this.apollo.query({
            query: gql`
                query findUserPassport {
                    user {
                        id
                        item2name
                        passportnumber
                        passportissuedate
                        passportexpiredate
                        passportissuecountry
                        visameeting
                    }
                    countries
                }
            `,
        });
    };

    department = (code: string): any => {
        if (code) {
            return this.apollo.query({
                query: gql`
                    query departmentInvite($code: String) {
                        departmentInvite(code: $code) {
                            account
                        }
                    }
                `,
                variables: {
                    code,
                },
            });
        } else {
            return of({ data: null });
        }
    };

    // inviteLetterEnabledChecker () {
    //     return this.apollo.query({
    //         query: gql`
    //         query inviteLetterEnabledChecker {
    //             user {
    //                 id
    //                 name
    //             }
    //         }
    //         `
    //     });
    // }

    enrollmentCheck = (applicationId: string): Observable<any> => {
        return this.apollo.query({
            query: gql`
                query enrollment($id: ID!) {
                    user {
                        id
                        acquisition
                        altphone
                        address
                        birthday
                        city
                        email
                        country
                        countryofbirth
                        fieldofstudyid
                        gender
                        hasvisa
                        hasReservedApplication
                        immunizations
                        passportexpiredate
                        passportnumber
                        passportissuedate
                        passportissuecountry
                        phone
                        preflocation
                        prefmonth
                        prefspecialty
                        postalcode
                        name
                        state
                        status
                        statement
                        subscribed
                        notifications
                        suggestions
                        optout
                        university
                        usmle
                        yearsattended
                        item1name
                        item2name
                        item3name
                        item4name
                        item5name
                        item6name
                        item7name
                        item8name
                        item9name
                        item10name
                        visameeting
                        application(id: $id) {
                            id
                            userid
                            fullname
                            programid
                            name
                            canEnroll
                            coach
                            contracturl
                            contractapitoken
                            city
                            state
                            type
                            createdAt
                            accepteddate
                            reserveddate
                            specialty
                            signedcontract
                            pendingWiretransfer
                            programlocation
                            paidPreApproved
                            physician
                            startdate
                            enddate
                            enrollable
                            isLate
                            image
                            expires
                            month
                            startyear
                            price
                            pricechange
                            paymentDeadlineDate
                            deposit
                            states
                            madedeposit
                            madepayment
                            madefullpayment
                            pricechange
                            untilchange
                            accepteddate
                            housingaddress
                            housingtype
                            airbnblink
                            requiresinvitation
                            canrequestinvitation
                            visitorstatus
                            couponid
                            hashousing
                            hasrequestedbookingreview
                            hasrequestedinvitation
                            nolatefee
                            predepartureorientation
                            preenrollmentorientation
                            preacceptancereviewable
                            migrationdocumentdate
                            switchprotectionpaid
                            checklist
                            immunizationStatuses
                            program {
                                id
                                affiliatedsites
                                additionalrequirements
                                name
                                specialty
                                description
                                reportingtime
                                lastminute
                                programlocation
                                address
                                city
                                state
                                postalcode
                                price
                                physician
                                deposit
                                exposuretype
                                type
                                content
                                image
                                schedule
                                permitted
                                geoloc
                                programtags
                                preapprovalrequired
                                requiredpostdocumentlist
                                requiredpredocumentlist
                                requirementsDescriptions
                                host {
                                    name
                                }
                            }
                        }
                    }
                    requiredPostProgramDocuments(id: $id) {
                        documents
                    }
                    submittedProgramDocuments(id: $id) {
                        documents
                    }
                    applicationDocuments(id: $id) {
                        documents
                    }
                    singleApplicationRequirementList(id: $id) {
                        documents
                    }
                    submittedDocumentsRequirements(id: $id) {
                        documents
                    }
                    applicationHealthinsuranceDocument(id: $id) {
                        documents
                    }
                    applicationMalpracticeDocument(id: $id) {
                        documents
                    }
                }
            `,
            fetchPolicy: 'network-only',
            variables: {
                id: applicationId,
            },
        });
    };

    applicationChainEnrollmentCheck = (applicationId: string): Observable<any> => {
        return this.apollo.query({
            query: gql`
                query chainedenrollment($id: ID!) {
                    user {
                        id
                        immunizations
                    }
                    canEnrollApplications(id: $id)
                    requiredChainedPostProgramDocuments(id: $id) {
                        documents
                    }
                    submittedChainedProgramDocuments(id: $id) {
                        documents
                    }
                }
            `,
            fetchPolicy: 'network-only',
            variables: {
                id: applicationId,
            },
        });
    };

    findImmunizationDocuments() {
        return this.apollo.query({
            query: gql`
                query findImmunizations {
                    user {
                        id
                        immunizations
                    }
                }
            `,
        });
    }

    userPrimaryDocuments() {
        return this.apollo.query({
            query: gql`
                query findImmunizations {
                    user {
                        id
                        item1name
                        item1
                        item2name
                        item2
                        item3name
                        item3
                        item4name
                        item4
                        item5name
                        item5
                        item6name
                        item6
                        item7name
                        item7
                        item8name
                        item8
                        item9name
                        item9
                        item10name
                        item10
                        immunizations
                    }
                }
            `,
        });
    }

    /**
     * starting this one over -steve
     */

    profileTravelRequirements() {
        return this.apollo.query({
            query: this.travelRequirementsQuery,
            fetchPolicy: 'network-only',
        });
    }

    profileClinicalPreferences() {
        return this.apollo.query({
            query: this.clinicalPreferencesQuery,
            fetchPolicy: 'network-only',
        });
    }

    profileMedicalEducation() {
        return this.apollo.query({
            query: this.medicalEducationQuery,
            fetchPolicy: 'network-only',
        });
    }
}
