import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MutationService } from '@app/core/graphql/mutation/mutation.service';
import { QueryService } from '@app/core/graphql/query/query.service';
import { ApplicationService } from '@app/core/services/application/application.service';
import { FormService } from '@app/core/services/form/form.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { ToolService } from '@app/core/services/tool/tool.service';
import { WarningService } from '@app/core/services/warning/warning.service';
import { faFileContract } from '@fortawesome/pro-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { flatMap } from 'rxjs/operators';

@Component({
    selector: 'app-passport',
    templateUrl: './application-passport.component.html',
    styleUrls: ['./application-passport.component.scss'],
})
export class ApplicationPassportComponent implements OnInit {
    @Input() applicationId: string;
    user: any;
    passportQuery = this.queryService.passport();
    update = false;
    passport_form: FormGroup;
    passportForm_subscription = this.formService.passportForm().subscribe((form: FormGroup) => (this.passport_form = form));
    content = this.toolService.content;
    icons: any = {
        file: faFileContract,
    };

    constructor(
        public toolService: ToolService,
        private queryService: QueryService,
        private modalController: ModalController,
        private mutationService: MutationService,
        private toastService: ToastService,
        private formService: FormService,
        private warning: WarningService
    ) {}

    ngOnInit() {
        this.queryService.passport().subscribe((response: any) => {
            if (response.data.user) {
                const user = response.data.user;
                this.user = response.data.user;
                this.passport_form.patchValue({
                    passportnumber: user.passportnumber,
                    passportissuecountry: user.passportissuecountry,
                    passportissuedate: user.passportissuedate,
                    passportexpiredate: user.passportexpiredate,
                    passportitemname: user.item2name,
                    visameetingdate: user.visameeting,
                });
            }
            if (response.data.user.item2name === null) {
                this.toastService.create({
                    message: 'You must upload a image of your passport before requesting a invitation letter',
                    duration: 10000,
                    position: 'middle',
                    buttons: [
                        {
                            text: 'Close',
                            role: 'cancel',
                            handler: () => {
                                console.log('Cancel clicked');
                            },
                        },
                    ],
                    closeButtonText: 'Done',
                    color: 'danger',
                });
            }
        });
    }

    submitForm = () => {
        // if passport form is invalid throw an error
        if (!this.passport_form.valid) {
            const result = this.formService.invalidFields(this.passport_form);
            const missingFields = result.map((field: any) => field.control).join(', ');
            this.warning.create(missingFields, 'Passport form is missing the following fields:');
        } else {
            const data = {
                passportnumber: this.passport_form.value.passportnumber,
                passportissuecountry: this.passport_form.value.passportissuecountry,
                passportissuedate: this.passport_form.value.passportissuedate,
                passportexpiredate: this.passport_form.value.passportexpiredate,
                visameeting: this.passport_form.value.visameetingdate,
            };

            this.mutationService.easyUpdateProfile(data, this.user.id).subscribe((response: any) => {
                if (response.data.easyUpdateProfile.success) {
                    this.update = true;
                    this.dismiss();
                } else {
                    throw new Error('Profile did not update');
                }
            });
        }
    };

    dismiss() {
        this.modalController.dismiss({
            update: this.update,
        });
    }
}

const formatControlName = (s: string) => {
    return s.toLowerCase() === 'passportitemname'
        ? 'Passport Upload'
        : s.toLowerCase() === 'passportnumber'
        ? 'Passport Number'
        : s.toLowerCase() === 'passportissuecountry'
        ? 'Passport Issue Country'
        : s.toLowerCase() === 'passportissuedate'
        ? 'Passport Issue Date'
        : s.toLowerCase() === 'passportexpiredate'
        ? 'Passport Expiration Date'
        : s.toLowerCase() === 'visameetingdate'
        ? 'Visa Meeting Date'
        : '';
};
