import { Component, Input, OnInit } from '@angular/core';
import { ToolService } from '@app/core/services/tool/tool.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-action-confirmation',
  templateUrl: './action-confirmation.component.html',
  styleUrls: ['./action-confirmation.component.scss'],
})
export class ActionConfirmationComponent implements OnInit {

  @Input() data?: any;

  constructor(
    private modal: ModalController,
    public toolService: ToolService
  ) { }

  ngOnInit() {
    if (!this.data.buttonColor) {
      this.data.buttonColor = 'primary';
    }
  }

  dismiss = () => {
    this.modal.dismiss({confirmed: false, params: {}});
  }

  continue = () => {
    this.modal.dismiss({confirmed: true, params: this.data});
  }

}
