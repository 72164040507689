import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
    exports: [
        CommonModule,
        MatButtonModule,
        MatCheckboxModule,
        MatStepperModule,
        MatDatepickerModule,
        MatMenuModule,
        MatIconModule,
        MatTooltipModule,
        MatInputModule,
        MatAutocompleteModule,
        MatMomentDateModule,
        MatSelectModule,
        MatExpansionModule,
    ],
    declarations: [],
})
export class AngularMaterialModule {}
