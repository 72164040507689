import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PipesComponentsModule } from '@app/shared/pipes/pipes.modules';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { QuicklinkModule } from 'ngx-quicklink';
import { ProgramComponentsModule } from '../program/program.module';
import { SharedComponentsModule } from '@app/shared/components/shared.module';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from '@app/shared/angular.material.module';

import { UserApplicationsListComponent } from './components/user-applications-list/user-applications-list.component';
import { MeetAmoComponent } from './components/user-about-amo/user-about-amo.component';
import { AmoGuidesComponent } from './components/user-rotation-guides/user-rotation-guides.component';
import { UserAccountSettingsModal2EmailComponent } from './components/user-account-settings-modal2-email/user-account-settings-modal2-email.component';
import { UserAccountSettingsModal2PasswordComponent } from './components/user-account-settings-modal2-password/user-account-settings-modal2-password.component';

import { FormsModule } from '@angular/forms';
import { UserAccountVerifyEmailModalComponent } from './components/user-account-verify-email-modal/user-account-verify-email-modal.component';

@NgModule({
    declarations: [
        MeetAmoComponent,
        AmoGuidesComponent,
        UserApplicationsListComponent,
        UserAccountSettingsModal2EmailComponent,
        UserAccountSettingsModal2PasswordComponent,
        UserAccountVerifyEmailModalComponent,
    ],
    imports: [
        QuicklinkModule,
        IonicModule,
        CommonModule,
        ProgramComponentsModule,
        PipesComponentsModule,
        SharedComponentsModule,
        FontAwesomeModule,
        RouterModule,
        AngularMaterialModule,
        FormsModule,
    ],
    exports: [QuicklinkModule, MeetAmoComponent, AmoGuidesComponent, UserApplicationsListComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserComponentsModule {}
