import { createSelector } from '@ngrx/store';
import { State } from '../reducers';

export const selectPaymentState = (state: State) => state.payment;
// is this user admin
export const getPaymentMethod = createSelector(
    selectPaymentState,
    payment => payment.paymentMethod
);
// is this user admin
export const getPayableApplication = createSelector(
    selectPaymentState,
    payment => payment.payableApplication
);
// application payment type
export const getPaymentType = createSelector(
    selectPaymentState,
    payment => payment.paymentType
);
// application checkout price
export const getCheckoutPrice = createSelector(
    selectPaymentState,
    payment => payment.checkoutPrice
);
// application checkout price
export const getProcessingFee = createSelector(
    selectPaymentState,
    payment => payment.processingFee
);
// application checkout price
export const getViewPaymentInfo = createSelector(
    selectPaymentState,
    payment => payment.viewPaymentInfo
);
// application checkout price
export const getPaymentListUpdate = createSelector(
    selectPaymentState,
    payment => payment.paymentUpdate
);
// application checkout price
export const getCouponCode = createSelector(
    selectPaymentState,
    payment => payment.couponCode
); // application checkout price
export const getCouponDiscount = createSelector(
    selectPaymentState,
    payment => payment.couponDiscount
);
// application has pending wire transfer
export const getPendingWire = createSelector(
    selectPaymentState,
    payment => payment.pendingWire
);
// application force full payment
export const getForceFullPayment = createSelector(
    selectPaymentState,
    payment => payment.forceFullPayment
);
// application force full payment
export const getCheckoutCoupons = createSelector(
    selectPaymentState,
    payment => payment.checkoutCoupons
);
// application force full payment
export const getCustomerID = createSelector(
    selectPaymentState,
    payment => payment.customerID
);
// current coupos affecting the currlent payment balance
export const getPaymentCoupons = createSelector(
    selectPaymentState,
    payment => payment.paymentCoupons
);
// get payment redirect
export const getPaymentRedirect = createSelector(
    selectPaymentState,
    payment => payment.redirect
);
// get payment redirect
export const getPaymentCart = createSelector(
    selectPaymentState,
    payment => payment.paymentCart
);
// get payment redirect
export const getPaymentList = createSelector(
    selectPaymentState,
    payment => payment.paymentList
);
// get payment redirect
export const getPaymentSelectList = createSelector(
    selectPaymentState,
    payment => payment.paymentSelectList
);
//
export const getPaymentIntentId = createSelector(
    selectPaymentState,
    payment => payment.paymentIntentId
);
