import { Injectable } from '@angular/core';
import { QueryService } from '@app/core/graphql/query/query.service';
import { AuthService } from '@app/core/services/auth/auth.service';
import { MutationService } from '@core/graphql/mutation/mutation.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CookieService } from 'ngx-cookie-service';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

@Injectable()
export class UserEffects {
    // hasValidProfile = createEffect(() => this.actions$.pipe(
    //     ofType('[User] Set Has Valid Profile'),
    //     mergeMap(() => this.authService.fetchValidProfile()
    //     .pipe(
    //         map(response =>
    //             ({type: '[User] Set Has Valid Profile Load', payload: response})),
    //             catchError(() => of({type: '[User] Set Has Valid Profile Failed'}))
    //             )
    //         )
    //     )
    // );

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private mutationService: MutationService,
        private queryService: QueryService,
        private cookie: CookieService
    ) {}
}
