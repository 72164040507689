export const isNil = (x: any): boolean => {
    try {
        if (x === null || x === undefined) {
            return true;
        } else if (x === 'null' || x === 'undefined') {
            return true;
        } else if (x === '' || (typeof x === 'object' && Object.keys(x).length === 0) || (Array.isArray(x) && x.length === 0)) {
            return true;
        } else if ((typeof x === 'object' && Object.keys(x).length === 0) || (typeof x === 'string' && x.trim().length === 0)) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        return true;
    }
};

export const validCV = (s: string) => {
    return isNil(s) ? false : s.indexOf('_Temporary') !== -1 ? false : true;
};
