import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationService } from '@app/core/services/application/application.service';
import { NavController, NavParams, PopoverController } from '@ionic/angular';

@Component({
    selector: 'app-cancel',
    templateUrl: './application-cancel.component.html',
    styleUrls: ['./application-cancel.component.scss'],
})
export class ApplicationCancelComponent implements OnInit {
    @Input() application: any = {};

    applicationId: string;

    constructor(
        private route: ActivatedRoute,
        private applicationService: ApplicationService,
        private navCtrl: NavController,
        private popCtrl: PopoverController,
        private navParams: NavParams
    ) {}

    ngOnInit() {
        this.applicationId = this.application.id;
    }
}
