import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { UniversityPickerComponent } from './university-picker/university-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [UniversityPickerComponent],
    imports: [CommonModule, IonicModule, RouterModule, FormsModule, ReactiveFormsModule, MatTooltipModule],
    exports: [UniversityPickerComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UniversityPickerComponentModule {}
