import { Action, createReducer, on } from '@ngrx/store';

import { AuthActions } from '../actions';

export const authFeatureKey = 'auth';

export interface AuthState {
    is_authenticated: boolean; // is this user able to provide us with a email and password we know
    show_hotlinks: boolean; // show mobile hot links footer
    show_topnav: boolean;
    show_bottomnav: boolean;
}

export const initialState: AuthState = {
    is_authenticated: false,
    show_hotlinks: false,
    show_topnav: true,
    show_bottomnav: false,
};

const AuthReducer = createReducer(
    initialState,
    on(AuthActions.setIsAuthenticated, (state, args) => ({
        ...state,
        is_authenticated: args.is_authenticated,
    })),
    on(AuthActions.setLogout, (state, args) => ({
        ...state,
        is_authenticated: false,
    })),
    on(AuthActions.setDisplayTopNav, (state, args) => ({
        ...state,
        show_topnav: args.show_topnav,
    })),
    on(AuthActions.setDisplayFooterNav, (state, args) => ({
        ...state,
        show_bottomnav: args.show_bottomnav,
    }))
);
export function reducer(state: AuthState | undefined, action: Action) {
    return AuthReducer(state, action);
}
