<ion-header>
    <ion-item class="modal-header-color">
        <ion-label>
            <ion-text>
                <h2 class="ion-text-center ion-text-wrap modal-title">
                    <fa-icon [icon]="icons.file"></fa-icon>
                    Request Invitation Letter
                </h2>
            </ion-text>
        </ion-label>
        <ion-button slot="end" class="close" type="button" fill="clear" (click)="dismiss()">
            <ion-icon slot="icon-only" color="danger" name="close" size="medium"></ion-icon>
        </ion-button>
    </ion-item>
</ion-header>
<ion-grid class="modal-content">
    <ion-col size="12">
        <form [formGroup]="passport_form" (ngSubmit)="submitForm()">
            <!-- <ion-card class="card-no-shadow">
        <ion-card-content> -->
            <ion-list>
                <ion-item class="form-elements" lines="none">
                    <ion-label position="stacked" class="form-title">* Passport Number</ion-label>
                    <ion-input class="basic-inputs" type="text" formControlName="passportnumber"></ion-input>
                </ion-item>

                <ion-item button lines="none" class="form-elements">
                    <ion-label color="text" position="stacked" class="ion-text-wrap form-title">* Passport Country</ion-label>
                    <ion-select class="basic-select" formControlName="passportissuecountry">
                        <ion-select-option *ngFor="let country of (content | async)?.countries" value="{{ country }}">
                            {{ country }}
                        </ion-select-option>
                    </ion-select>
                </ion-item>

                <ion-item class="form-elements" lines="none">
                    <ion-label position="stacked" class="form-title">Passport Issue Date</ion-label>
                    <div class="date-picker-container ion-text-center">
                        <input
                            class="datePicker date-input"
                            matInput
                            [matDatepicker]="pickerPassportIssue"
                            placeholder="MM/DD/YYYY"
                            formControlName="passportissuedate"
                        />
                        <mat-datepicker-toggle matSuffix [for]="pickerPassportIssue"></mat-datepicker-toggle>
                        <mat-datepicker #pickerPassportIssue></mat-datepicker>
                    </div>
                </ion-item>

                <ion-item class="form-elements" lines="none">
                    <ion-label position="stacked" class="form-title">Passport Expiration Date</ion-label>
                    <div class="date-picker-container ion-text-center">
                        <input
                            class="datePicker"
                            matInput
                            [matDatepicker]="pickerPassportExpired"
                            placeholder="MM/DD/YYYY"
                            formControlName="passportexpiredate"
                        />
                        <mat-datepicker-toggle matSuffix [for]="pickerPassportExpired"></mat-datepicker-toggle>
                        <mat-datepicker #pickerPassportExpired></mat-datepicker>
                    </div>
                </ion-item>

                <ion-item class="form-elements" lines="none">
                    <ion-label position="stacked" class="form-title">Visa Meeting Date</ion-label>
                    <div class="date-picker-container ion-text-center">
                        <input class="datePicker" matInput [matDatepicker]="pickerPassportVisa" placeholder="MM/DD/YYYY" formControlName="visameetingdate" />
                        <mat-datepicker-toggle matSuffix [for]="pickerPassportVisa"></mat-datepicker-toggle>
                        <mat-datepicker #pickerPassportVisa></mat-datepicker>
                    </div>
                </ion-item>

                <!-- <div>
              <ion-label>Visa Meeting Location</ion-label>
              <input matInput placeholder="Choose a location" formControlName="visameetinglocation">
            </div> -->
            </ion-list>

            <!-- </ion-card-content>
      </ion-card> -->
        </form>
    </ion-col>
</ion-grid>
<ion-footer>
    <ion-toolbar>
        <ion-button type="button" (click)="submitForm()" expand="block">Submit </ion-button>
    </ion-toolbar>
</ion-footer>
