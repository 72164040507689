import { Component, Input, OnInit } from '@angular/core';
import { ToolService } from '@app/core/services/tool/tool.service';
import { ModalController } from '@ionic/angular';
import momentTimeZone from 'moment-timezone';

@Component({
    selector: 'app-program-time-zones',
    templateUrl: './program-time-zones.component.html',
    styleUrls: ['./program-time-zones.component.scss'],
})
export class ProgramTimeZonesComponent implements OnInit {
    countryZones: any;
    sourceSelect: string;
    targetSelect: string;
    zoneClock = {
        source: '',
        target: '',
    };
    clocks: any = [
        { clock: '7:00 AM', equivalent: '', dayDiff: 0 },
        { clock: '8:00 AM', equivalent: '', dayDiff: 0 },
        { clock: '9:00 AM', equivalent: '', dayDiff: 0 },
        { clock: '10:00 AM', equivalent: '', dayDiff: 0 },
        { clock: '11:00 AM', equivalent: '', dayDiff: 0 },
        { clock: '12:00 PM', equivalent: '', dayDiff: 0 },

        { clock: '1:00 PM', equivalent: '', dayDiff: 0 },
        { clock: '2:00 PM', equivalent: '', dayDiff: 0 },
        { clock: '3:00 PM', equivalent: '', dayDiff: 0 },
        { clock: '4:00 PM', equivalent: '', dayDiff: 0 },
        { clock: '5:00 PM', equivalent: '', dayDiff: 0 },
        { clock: '6:00 PM', equivalent: '', dayDiff: 0 },
        { clock: '7:00 PM', equivalent: '', dayDiff: 0 },
        { clock: '8:00 PM', equivalent: '', dayDiff: 0 },
        { clock: '9:00 PM', equivalent: '', dayDiff: 0 },
        { clock: '10:00 PM', equivalent: '', dayDiff: 0 },
        { clock: '11:00 PM', equivalent: '', dayDiff: 0 },
        { clock: '12:00 AM', equivalent: '', dayDiff: 0 },

        { clock: '1:00 AM', equivalent: '', dayDiff: 0 },
        { clock: '2:00 AM', equivalent: '', dayDiff: 0 },
        { clock: '3:00 AM', equivalent: '', dayDiff: 0 },
        { clock: '4:00 AM', equivalent: '', dayDiff: 0 },
        { clock: '5:00 AM', equivalent: '', dayDiff: 0 },
        { clock: '6:00 AM', equivalent: '', dayDiff: 0 },
    ];

    // css options
    customDropDownList: any = {
        className: "application-dropdown-list"
    };
    constructor(
        public toolService: ToolService,
        private modalCtrl: ModalController
    ) {}

    ngOnInit() {
        this.countryZones = [
            {
                name: "International Date Line West (GMT-12:00)",
                key: "Etc/GMT+12"
            },
            {
                name: "Midway Island, Samoa (GMT-11:00)",
                key: "Pacific/Midway"
            },
            {
                name: "Hawaii (GMT-10:00)",
                key: "Pacific/Honolulu"
            },
            {
                name: "Alaska (GMT-09:00)",
                key: "US/Alaska"
            },
            {
                name: "Pacific Time (US & Canada) (GMT-08:00)",
                key: "America/Los_Angeles"
            },
            {
                name: "Tijuana, Baja California (GMT-08:00)",
                key: "America/Tijuana"
            },
            {
                name: "Arizona (GMT-07:00)",
                key: "US/Arizona"
            },
            {
                name: "Chihuahua, La Paz, Mazatlan (GMT-07:00)",
                key: "America/Chihuahua"
            },
            {
                name: "Mountain Time (US & Canada) (GMT-07:00)",
                key: "US/Mountain"
            },
            {
                name: "Central America (GMT-06:00)",
                key: "America/Managua"
            },
            {
                name: "Central Time (US & Canada) (GMT-06:00)",
                key: "US/Central"
            },
            {
                name: "Guadalajara, Mexico City, Monterrey (GMT-06:00)",
                key: "America/Mexico_City"
            },
            {
                name: "Saskatchewan (GMT-06:00)",
                key: "Canada/Saskatchewan"
            },
            {
                name: "Bogota, Lima, Quito, Rio Branco (GMT-05:00)",
                key: "America/Bogota"
            },
            {
                name: "Eastern Time (US & Canada) (GMT-05:00)",
                key: "US/Eastern"
            },
            {
                name: "Indiana (East) (GMT-05:00)",
                key: "US/East-Indiana"
            },
            {
                name: "Atlantic Time (Canada) (GMT-04:00)",
                key: "Canada/Atlantic"
            },
            {
                name: "Caracas, La Paz (GMT-04:00)",
                key: "America/Caracas"
            },
            {
                name: "Manaus (GMT-04:00)",
                key: "America/Manaus"
            },
            {
                name: "Santiago (GMT-04:00)",
                key: "America/Santiago"
            },
            {
                name: "Newfoundland (GMT-03:30)",
                key: "Canada/Newfoundland"
            },
            {
                name: "Brasilia (GMT-03:00)",
                key: "America/Sao_Paulo"
            },
            {
                name: "Buenos Aires, Georgetown (GMT-03:00)",
                key: "America/Argentina/Buenos_Aires"
            },
            {
                name: "Greenland (GMT-03:00)",
                key: "America/Godthab"
            },
            {
                name: "Montevideo (GMT-03:00)",
                key: "America/Montevideo"
            },
            {
                name: "Mid-Atlantic (GMT-02:00)",
                key: "America/Noronha"
            },
            {
                name: "Cape Verde Is. (GMT-01:00)",
                key: "Atlantic/Cape_Verde"
            },
            {
                name: "Azores (GMT-01:00)",
                key: "Atlantic/Azores"
            },
            {
                name: "Casablanca, Monrovia, Reykjavik (GMT+00:00)",
                key: "Africa/Casablanca"
            },
            {
                name: "Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London (GMT+00:00)",
                key: "Etc/Greenwich"
            },
            {
                name: "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (GMT+01:00)",
                key: "Europe/Amsterdam"
            },
            {
                name: "Belgrade, Bratislava, Budapest, Ljubljana, Prague (GMT+01:00)",
                key: "Europe/Belgrade"
            },
            {
                name: "British Summer Time (GMT+01:00)",
                key: "Europe/London"
            },
            {
                name: "Brussels, Copenhagen, Madrid, Paris (GMT+01:00)",
                key: "Europe/Brussels"
            },
            {
                name: "Sarajevo, Skopje, Warsaw, Zagreb (GMT+01:00)",
                key: "Europe/Sarajevo"
            },
            {
                name: "West Central Africa (GMT+01:00)",
                key: "Africa/Lagos"
            },
            {
                name: "Amman (GMT+02:00)",
                key: "Asia/Amman"
            },
            {
                name: "Athens, Bucharest, Istanbul (GMT+02:00)",
                key: "Europe/Athens"
            },
            {
                name: "Beirut (GMT+02:00)",
                key: "Asia/Beirut"
            },
            {
                name: "Cairo (GMT+02:00)",
                key: "Africa/Cairo"
            },
            {
                name: "Harare, Pretoria (GMT+02:00)",
                key: "Africa/Harare"
            },
            {
                name: "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (GMT+02:00)",
                key: "Europe/Helsinki"
            },
            {
                name: "Jerusalem (GMT+02:00)",
                key: "Asia/Jerusalem"
            },
            {
                name: "Minsk (GMT+02:00)",
                key: "Europe/Minsk"
            },
            {
                name: "Windhoek (GMT+02:00)",
                key: "Africa/Windhoek"
            },
            {
                name: "Kuwait, Riyadh, Baghdad (GMT+03:00)",
                key: "Asia/Kuwait"
            },
            {
                name: "Moscow, St. Petersburg, Volgograd (GMT+03:00)",
                key: "Europe/Moscow"
            },
            {
                name: "Nairobi (GMT+03:00)",
                key: "Africa/Nairobi"
            },
            {
                name: "Tbilisi (GMT+03:00)",
                key: "Asia/Tbilisi"
            },
            {
                name: "Tehran (GMT+03:30)",
                key: "Asia/Tehran"
            },
            {
                name: "Abu Dhabi, Muscat (GMT+04:00)",
                key: "Asia/Muscat"
            },
            {
                name: "Baku (GMT+04:00)",
                key: "Asia/Baku"
            },
            {
                name: "Yerevan (GMT+04:00)",
                key: "Asia/Yerevan"
            },
            {
                name: "Kabul (GMT+04:30)",
                key: "Asia/Kabul"
            },
            {
                name: "Yekaterinburg (GMT+05:00)",
                key: "Asia/Yekaterinburg"
            },
            {
                name: "Islamabad, Karachi, Tashkent (GMT+05:00)",
                key: "Asia/Karachi"
            },
            {
                name: "Chennai, Kolkata, Mumbai, New Delhi (GMT+05:30)",
                key: "Asia/Calcutta"
            },
            {
                name: "Sri Jayawardenapura (GMT+05:30)",
                key: "Asia/Calcutta"
            },
            {
                name: "Kathmandu (GMT+05:45)",
                key: "Asia/Katmandu"
            },
            {
                name: "Almaty, Novosibirsk (GMT+06:00)",
                key: "Asia/Almaty"
            },
            {
                name: "Astana, Dhaka (GMT+06:00)",
                key: "Asia/Dhaka"
            },
            {
                name: "Yangon (Rangoon) (GMT+06:30)",
                key: "Asia/Rangoon"
            },
            {
                name: "Bangkok, Hanoi, Jakarta (GMT+07:00)",
                key: "Asia/Bangkok"
            },
            {
                name: "Krasnoyarsk (GMT+07:00)",
                key: "Asia/Krasnoyarsk"
            },
            {
                name: "Beijing, Chongqing, Hong Kong, Urumqi (GMT+08:00)",
                key: "Asia/Hong_Kong"
            },
            {
                name: "Kuala Lumpur, Singapore (GMT+08:00)",
                key: "Asia/Kuala_Lumpur"
            },
            {
                name: "Irkutsk, Ulaan Bataar (GMT+08:00)",
                key: "Asia/Irkutsk"
            },
            {
                name: "Perth (GMT+08:00)",
                key: "Australia/Perth"
            },
            {
                name: "Taipei (GMT+08:00)",
                key: "Asia/Taipei"
            },
            {
                name: "Osaka, Sapporo, Tokyo (GMT+09:00)",
                key: "Asia/Tokyo"
            },
            {
                name: "Seoul (GMT+09:00)",
                key: "Asia/Seoul"
            },
            {
                name: "Yakutsk (GMT+09:00)",
                key: "Asia/Yakutsk"
            },
            {
                name: "Adelaide (GMT+09:30)",
                key: "Australia/Adelaide"
            },
            {
                name: "Darwin (GMT+09:30)",
                key: "Australia/Darwin"
            },
            {
                name: "Brisbane (GMT+10:00)",
                key: "Australia/Brisbane"
            },
            {
                name: "Canberra, Melbourne, Sydney (GMT+10:00)",
                key: "Australia/Canberra"
            },
            {
                name: "Hobart (GMT+10:00)",
                key: "Australia/Hobart"
            },
            {
                name: "Guam, Port Moresby (GMT+10:00)",
                key: "Pacific/Guam"
            },
            {
                name: "Vladivostok (GMT+10:00)",
                key: "Asia/Vladivostok"
            },
            {
                name: "Magadan, Solomon Is., New Caledonia (GMT+11:00)",
                key: "Asia/Magadan"
            },
            {
                name: "Auckland, Wellington (GMT+12:00)",
                key: "Pacific/Auckland"
            },
            {
                name: "Fiji, Kamchatka, Marshall Is. (GMT+12:00)",
                key: "Pacific/Fiji"
            },
            {
                name: "Nuku'alofa (GMT+13:00)",
                key: "Pacific/Tongatapu"
            }
        ];

        this.sourceSelect = 'Etc/GMT+12';
        this.targetSelect = 'Pacific/Midway';
        this.countryZoneSelect();
    }

    dismiss(): void {
        this.modalCtrl.dismiss();
    }

    async zoneClocks(time_) {
        if (this.sourceSelect == this.targetSelect) {
            return await {
                equivalent: time_,
                dayDiff: 0,
            };
        }
        let date_ = momentTimeZone().format('YYYY-MM-DD');
        let d = `${date_} ${time_}`;
        let out = momentTimeZone
            .tz(d, 'YYYY-MM-DD h:mm:ss A', this.sourceSelect)
            .tz(this.targetSelect);
        const out_date = out.format('YYYY-MM-DD');
        const out_time = out.format('h:mm A');
        const targetDay = momentTimeZone(out_date);
        const sourceDay = momentTimeZone(date_);

        const duration = momentTimeZone.duration(targetDay.diff(sourceDay));
        return await {
            equivalent: out_time,
            dayDiff: duration.days(),
        };
    }

    countryZoneSelect = async () => {
        this.presentClock();
        this.clocks.map(async item => {
            const targetZone = await this.zoneClocks(item.clock);
            item.equivalent = targetZone.equivalent;
            item.dayDiff = targetZone.dayDiff;
        });
    };

    presentClock() {
        let now = momentTimeZone;
        this.zoneClock.source = now
            .tz(this.sourceSelect)
            .format('YYYY-MM-DD h:mm:ss A');
        this.zoneClock.target = now
            .tz(this.targetSelect)
            .format('YYYY-MM-DD h:mm:ss A');
    }
}
