import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { ToolService } from '@app/core/services/tool/tool.service';
import { Store } from '@ngrx/store';
import { State } from '@app/core/ngrx/reducers';
import { Apollo } from 'apollo-angular';
import { AuthService } from '@app/core/services/auth/auth.service';
import { NavController } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class TempUserGuard implements CanActivate {
    constructor(
        private cookie: CookieService,
        private toolService: ToolService,
        private store: Store<State>,
        private apollo: Apollo,
        private authService: AuthService,
        private navCtrl: NavController
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        // check if there if url is from management
        if (next.queryParams.mgmttype) {
            // if from management check if visitor
            let visitorToken;
            // if there is a visitors token attached to the url
            if (next.queryParams.visitors) {
                visitorToken = next.queryParams.visitors;
            }
            // if visitor token
            if (visitorToken) {
                this.toolService
                    .hostValidateVisitorToken(visitorToken)
                    .subscribe((res: any) => {
                        sessionStorage.setItem('visitors', JSON.stringify(res));
                    });
            }

            if (!this.cookie.check('amoactkn')) {
                this.cookie.set(
                    '_temp_',
                    '',
                    30,
                    '/',
                    '.amopportunities.org',
                    true,
                    'Lax'
                );
            }
            // let management visitor see the page
            return of(true);
        } // if not from management check if this user has no token and attach the _temp_ cookie
        // we use to display the temoporary program details page
        else if (!this.cookie.check('amoactkn')) {
            // set cookie
            this.cookie.set(
                '_temp_',
                '',
                30,
                '/',
                '.amopportunities.org',
                true,
                'Lax'
            );
            // allow to pass
            return of(true);
        } else {
            // this user has a valid auth cookie
            return of(true);
        }
    }
}
