import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ToolService } from '@app/core/services/tool/tool.service';
import { UserService } from '@app/core/services/user/user.service';
import { Router } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';
import { ModalController } from '@ionic/angular';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';

@Component({
    selector: 'app-university-department',
    templateUrl: './university-department.component.html',
    styleUrls: ['./university-department.component.scss'],
})
export class UniversityDepartmentComponent implements OnInit {
    faExclamationCircle = faExclamationCircle;

    constructor(private modalController: ModalController) {}

    ngOnInit() {
        console.log('university department yes or no!');
    }

    // user agrees to department attribution
    confirmAgreeToDepartment() {
        this.modalController.dismiss(true);
    }

    // user clicks opt out cancel
    cancelArgeeToDepartment() {
        this.modalController.dismiss(false);
    }
}
