import { Action, createReducer, on } from '@ngrx/store';

import { PaymentActions } from '../actions';

export const paymentFeatureKey = 'payment';

export interface PaymentState {
    paymentMethod: string; // payment is being made by wire
    payableApplication: any; // application thats most payable
    paymentType: string; // either deposit/final or full payment
    checkoutPrice: number; //  price user is about to pay for a application
    processingFee: number; // fee it costs for paying with a credit card
    viewPaymentInfo: boolean; // view the payment selection choices deposit/full during first-payment
    paymentUpdate: Array<string>; // application payment balance
    couponCode: string; // string value of coupon code
    couponDiscount: number; // number value of coupon discount
    pendingWire: boolean; // application has a pending wire transfer
    forceFullPayment: boolean; // used to force the full payment
    checkoutCoupons: Array<string>; // contains all the coupons codes attached to an application
    customerID: string; // stripe customer id
    paymentCoupons: Array<{}>; // coupons relevant to the current payment - not all the coupons applied
    redirect: boolean; // redirect the user to payment page
    paymentList: Array<{}>; //  serves as the primary payment list, immutable
    paymentCart: Array<{}>; // serves as the payment cart list, nonimmutable
    paymentSelectList: Array<{}>; // used for the disable list, nonimmutable,
    paymentIntentId: string; // user viewing current payment details
}

export const initialState: PaymentState = {
    paymentMethod: 'card',
    payableApplication: [],
    paymentType: 'no-payment',
    checkoutPrice: 0,
    processingFee: 5000,
    viewPaymentInfo: false,
    paymentUpdate: [],
    couponCode: 'coupon-code',
    couponDiscount: 0,
    pendingWire: false,
    forceFullPayment: false,
    checkoutCoupons: [],
    customerID: '',
    paymentCoupons: [],
    redirect: false,
    paymentList: [],
    paymentCart: [],
    paymentSelectList: [],
    paymentIntentId: 'none',
};

const PaymentReducer = createReducer(
    initialState,
    on(PaymentActions.setPaymentMethod, (state, args) => ({
        ...state,
        paymentMethod: args.paymentMethod,
    })),
    on(PaymentActions.setPaymentApp, (state, args) => ({
        ...state,
        payableApplication: args.payableApplication,
    })),
    on(PaymentActions.setPaymentType, (state, args) => ({
        ...state,
        paymentType: args.paymentType,
    })),
    on(PaymentActions.setCheckoutPrice, (state, args) => ({
        ...state,
        checkoutPrice: args.checkoutPrice,
    })),
    on(PaymentActions.setProcessingFee, (state, args) => ({
        ...state,
        processingFee: args.processingFee,
    })),
    on(PaymentActions.setViewPaymentInfo, (state, args) => ({
        ...state,
        viewPaymentInfo: args.viewPaymentInfo,
    })),
    on(PaymentActions.setPaymentListUpdate, (state, args) => ({
        ...state,
        paymentUpdate: args.paymentUpdate,
    })),
    on(PaymentActions.setCouponCode, (state, args) => ({
        ...state,
        couponCode: args.couponCode,
    })),
    on(PaymentActions.setCouponDiscount, (state, args) => ({
        ...state,
        couponDiscount: args.couponDiscount,
    })),
    on(PaymentActions.setPendingWire, (state, args) => ({
        ...state,
        pendingWire: args.pendingWire,
    })),
    on(PaymentActions.setForceFullPayment, (state, args) => ({
        ...state,
        forceFullPayment: args.forceFullPayment,
    })),
    on(PaymentActions.setCheckoutCoupons, (state, args) => ({
        ...state,
        checkoutCoupons: args.checkoutCoupons,
    })),
    on(PaymentActions.setStripeCustomerID, (state, args) => ({
        ...state,
        customerID: args.customerID,
    })),
    on(PaymentActions.setPaymentCoupons, (state, args) => ({
        ...state,
        paymentCoupons: args.paymentCoupons,
    })),
    on(PaymentActions.setPaymentRedirect, (state, args) => ({
        ...state,
        redirect: args.redirect,
    })),
    on(PaymentActions.setPaymentList, (state, args) => ({
        ...state,
        paymentList: args.paymentList,
    })),
    on(PaymentActions.setPaymentCart, (state, args) => ({
        ...state,
        paymentCart: args.paymentCart,
    })),
    on(PaymentActions.setPaymentSelectList, (state, args) => ({
        ...state,
        paymentSelectList: args.paymentSelectList,
    })),
    on(PaymentActions.setPaymentIntentId, (state, args) => ({
        ...state,
        paymentIntentId: args.paymentIntentId,
    }))
);
export function reducer(state: PaymentState | undefined, action: Action) {
    return PaymentReducer(state, action);
}
