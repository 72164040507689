<ion-list>
  <ion-item-divider>
    <ion-label color="danger">
      <ion-text color="white">
        Cancel Application
      </ion-text>
    </ion-label>
  </ion-item-divider>
  <ion-item>
    <ion-label>
      <ion-text>
        <h2>Cancel</h2>
      </ion-text>
    </ion-label>
    <ion-icon slot="end" name="alert" color="danger"></ion-icon>
  </ion-item>
</ion-list>