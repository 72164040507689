import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { QuicklinkModule } from 'ngx-quicklink';
import { MessageBannerComponent } from './message-banner/message-banner.component';

@NgModule({
    declarations: [MessageBannerComponent],
    imports: [CommonModule, IonicModule, RouterModule],
    exports: [MessageBannerComponent],
})
export class MessageComponentsModule {}
