<ion-card class="program-details-card">
    <!-- <div *ngIf="checkStructureContent"> -->
    <ion-list>
        <ion-item *ngIf="showPreceptor">
            <ion-label class="bold">Preceptor information</ion-label>
        </ion-item>
        <ion-item *ngIf="showPreceptor" [@slideInOut]="animationProgramPreceptor">
            <ion-label class="ion-text-wrap">
                <p>{{ preceptorInfo }}</p>
            </ion-label>
        </ion-item>
        <ion-item *ngIf="showSpecialtyInfo">
            <ion-label class="bold">Academic affiliations</ion-label>
        </ion-item>
        <ion-item *ngIf="showSpecialtyInfo" [@slideInOut]="animationProgramSpecialty">
            <ion-label class="ion-text-wrap child-text">
                <div [innerHTML]="specialtyInfo | safe : 'html'"></div>
            </ion-label>
        </ion-item>
        <ion-item *ngIf="showHospitalAffiliations">
            <ion-label class="bold">Hospital affiliations</ion-label>
        </ion-item>
        <ion-item *ngIf="showHospitalAffiliations" [@slideInOut]="animationHospitalAffiliations">
            <ion-label class="ion-text-wrap child-text">
                <div [innerHTML]="hospitalAffiliation | safe : 'html'"></div>
            </ion-label>
        </ion-item>
        <ion-item *ngIf="showExpectations">
            <ion-label class="bold">Expectations</ion-label>
        </ion-item>
        <ion-item *ngIf="showExpectations" [@slideInOut]="animationExpectations">
            <ion-label class="ion-text-wrap child-text">
                <div [innerHTML]="expectations | safe : 'html'"></div>
            </ion-label>
        </ion-item>
        <ion-item *ngIf="showExposureInfo">
            <ion-label class="bold">Exposure type</ion-label>
        </ion-item>
        <ion-item *ngIf="showExposureInfo" [@slideInOut]="animationProgramExposure">
            <ion-label class="ion-text-wrap child-text">
                <div [innerHTML]="exposuretype | safe : 'html'"></div>
            </ion-label>
        </ion-item>
        <ion-item *ngIf="showBestSuited">
            <ion-label class="bold ion-text-wrap">Best suited for</ion-label>
        </ion-item>
        <ion-item *ngIf="showBestSuited" [@slideInOut]="animationBestSuitedFor">
            <ion-label class="ion-text-wrap child-text">
                <div [innerHTML]="bestSuitedFor | safe : 'html'"></div>
            </ion-label>
        </ion-item>
        <ion-item *ngIf="showPatientLoad">
            <ion-label class="bold">Patient load</ion-label>
        </ion-item>
        <ion-item *ngIf="showPatientLoad" [@slideInOut]="animationProgramPatient">
            <ion-label class="ion-text-wrap child-text">
                <div [innerHTML]="patientload | safe : 'html'"></div>
            </ion-label>
        </ion-item>
        <ion-item *ngIf="showLanguages">
            <ion-label class="bold ion-text-wrap">Languages spoken other than English</ion-label>
        </ion-item>
        <ion-item *ngIf="showLanguages" [@slideInOut]="animationProgramLanguages">
            <ion-label class="ion-text-wrap child-text">
                <div [innerHTML]="languages | safe : 'html'"></div>
            </ion-label>
        </ion-item>
        <ion-item *ngIf="showEvaluation">
            <ion-label class="bold">How you are evaluated</ion-label>
        </ion-item>
        <ion-item *ngIf="showEvaluation" [@slideInOut]="animationEvaluation">
            <ion-label class="ion-text-wrap child-text">
                <div [innerHTML]="evaluation | safe : 'html'"></div>
            </ion-label>
        </ion-item>
        <ion-item *ngIf="showAdditionalRequirements">
            <ion-label class="bold">Additional requirements</ion-label>
        </ion-item>
        <ion-item *ngIf="showAdditionalRequirements" [@slideInOut]="animationProgramAdditionalReq" class="highlights-list">
            <div style="width: 100%">
                <div *ngIf="program?.requirementsDescriptions?.preApprovalDescriptions.length > 0">
                    <div style="width: 100%">
                        <ion-label class="section-title ion-text-wrap additionalHeader">
                            {{ (toolService.content | async)?.program?.components?.additionalRequirements?.preTitle }}
                        </ion-label>
                        <ol>
                            <li *ngFor="let document of program?.requirementsDescriptions?.preApprovalDescriptions">
                                <p style="font-weight: 600">
                                    <span class="bold-text highlight" *ngIf="!document.academicstatus.includes('Any')"
                                        >{{ document.academicstatus | uppercase }}S</span
                                    >
                                    <span class="bold-text highlight" *ngIf="document.programtype.includes('Virtual') && showVirtualTag"> VIRTUAL </span>
                                    {{ document.name }}
                                </p>
                                <div [innerHTML]="document.description | safe : 'html'" class="ion-text-wrap" style="padding-left: 1em"></div>
                            </li>
                            <hr />
                        </ol>
                    </div>
                </div>
                <div *ngIf="program?.requirementsDescriptions?.postReservationDescriptions.length > 0">
                    <div style="width: 100%">
                        <ion-label class="section-title ion-text-wrap additionalHeader">
                            {{ (toolService.content | async)?.program?.components?.additionalRequirements?.postTitle }}
                        </ion-label>
                        <ol>
                            <li *ngFor="let document of program?.requirementsDescriptions?.postReservationDescriptions">
                                <p style="font-weight: 600">
                                    <span class="bold-text highlight" *ngIf="!document.academicstatus.includes('Any')"
                                        >{{ document.academicstatus | uppercase }}S</span
                                    >
                                    <span class="bold-text highlight" *ngIf="document.programtype.includes('Virtual') && showVirtualTag"> VIRTUAL </span>
                                    {{ document.name }}
                                </p>
                                <div [innerHTML]="document.description | safe : 'html'" class="ion-text-wrap" style="padding-left: 1em"></div>
                            </li>
                            <hr />
                        </ol>
                    </div>
                </div>
            </div>
        </ion-item>
        <ion-item *ngIf="showAdditionalInfo" detail="false">
            <ion-label class="bold">Additional information</ion-label>
        </ion-item>
        <ion-item *ngIf="showAdditionalInfo" [@slideInOut]="animationProgramAdditional">
            <ion-label class="ion-text-wrap child-text">
                <div [innerHTML]="additionaltext | safe : 'html'"></div>
            </ion-label>
        </ion-item>
    </ion-list>
    <!-- </div> -->
    <!-- <div *ngIf="!checkStructureContent">
    <ion-item class="highlights-list">
      <div class="descr-wrap">
        <div [innerHTML]="program?.description | safe: 'html'" class="ion-text-wrap">
        </div>
      </div>
    </ion-item>
  </div> -->
</ion-card>
