<div class="ion-text-center">
  <ion-img class="modal-logo" src="assets/icons/logo_orange_full.png"></ion-img>
</div>
<ion-grid class="modal-content">
  <ion-row class="ion-justify-content-center">
    <ion-col size="10">
      <h3 class="ion-text-center">
        <strong>
          Welcome to our new site!
        </strong>
      </h3>
      <div class="ion-text-center ion-text-wrap">We have updated our Terms and Conditions, Rotations Policy, and Privacy Policy since your last login. Please review each of the following documents before clicking 'I Accept'.</div>
      <div class="ion-text-center terms-box">
        <ion-button class="terms-item" fill="clear" type="button" target="_blank"
          href="https://www.amopportunities.org/cookie-policy">
          Cookie Policy
        </ion-button>
        <ion-button class="terms-item" fill="clear" type="button" target="_blank"
          href="https://www.amopportunities.org/privacy">
          Privacy Policy
        </ion-button>
        <ion-button class="terms-item" fill="clear" type="button" target="_blank"
          href="https://www.amopportunities.org/experience-terms">
          Rotations Policy
        </ion-button>
        <ion-button class="terms-item" fill="clear" type="button" target="_blank"
          href="https://www.amopportunities.org/terms">
          Terms and Conditions
        </ion-button>
      </div>

    </ion-col>
  </ion-row>
</ion-grid>
<ion-footer>
  <ion-toolbar>
    <div style="padding: 5px;" class="ion-text-wrap ion-text-center">
        By clicking "I accept", you acknowledge that you accept our Cookie Policy, Privacy Policy, Rotations Policy, and Terms and Conditions.
    </div>
    <ion-button type="button" expand="block" (click)="dismiss()">I ACCEPT</ion-button>
      </ion-toolbar>
</ion-footer>