import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '@app/core/ngrx/reducers';
import { UserActions } from '@app/core/ngrx/actions';
@Component({
    selector: 'app-search-temp',
    templateUrl: './program-search-public-block.component.html',
    styleUrls: ['./program-search-public-block.component.scss'],
})
export class SearchTempComponent implements OnInit {
    constructor(
        private navCtrl: NavController,
        private modalCtrl: ModalController,
        private cookie: CookieService,
        private router: Router,
        private store: Store<State>
    ) {}

    @Input() header: string;
    @Input() title: string;
    @Input() topMessage: string;
    @Input() bottomMessage: string;
    @Input() icon: string;
    @Input() color: string;
    @Input() lines: number;
    @Input() task: string;

    currentUrl: any;

    ngOnInit() {
        const s = this.router.parseUrl(this.router.url);
        const encUrl = s.toString();
        const decUrl = encUrl;
        this.currentUrl = { next: decUrl };
    }

    toSignUp = (): void => {
        this.navCtrl.navigateRoot(['/signup']);
        this.modalCtrl.dismiss();
    };

    toLogin = (): void => {
        if (!this.cookie.check('amoactkn')) {
            // redirects user from auth page
            this.navCtrl.navigateRoot(['/login'], {
                queryParams: this.currentUrl,
            });
        } else if (this.cookie.check('_temp_')) {
            // redirect user from auth page
            this.navCtrl.navigateRoot(['/login'], {
                queryParams: this.currentUrl,
            });
        } else {
            this.navCtrl.navigateRoot(['/login']);
        }
        this.modalCtrl.dismiss();
    };
}
