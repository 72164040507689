import { Injectable } from '@angular/core';
import { ApplicationActions } from '@app/core/ngrx/actions';
import { ApplicationCancelComponent } from '@app/modules/application/components/application-cancel/application-cancel.component';
// eslint-disable-next-line max-len
import { ApplicationHousingSuggestionsComponent } from '@app/modules/application/components/application-housing-suggestions/application-housing-suggestions.component';
import { ApplicationHousingComponent } from '@app/modules/application/components/application-housing/application-housing.component';
import { ApplicationPassportComponent } from '@app/modules/application/components/application-passport/application-passport.component';
// eslint-disable-next-line max-len
import { ApplicationPreChecklistEnrollmentComponent } from '@app/modules/application/components/application-pre-enrolled-checklist/checklist-enrollment.component';
import { UploadComponent } from '@app/modules/document/components/document-upload/document-upload.component';
import { PaymentStripeModalComponent } from '@app/modules/payment/components/payment-stripe-modal/stripe-modal.component';
// eslint-disable-next-line max-len
import { PaymentSuccessfulChargeComponent } from '@app/modules/payment/components/payment-successful-charge/payment-successful-charge.component';
import { SearchProgramMobileComponent } from '@app/modules/program/components/program-search-filters-mobile/search-program.component';
import { SearchTempComponent } from '@app/modules/program/components/program-search-public-block/program-search-public-block.component';
// import { SidebarHelpContactComponent } from '@app/shared/components/sidebar-components/sidebar-help-contact/help-contact.component';
// import { SidebarHelpContactComponent } from '@app/shared/components/sidebar-components/sidebar-alert/sidebar-alert.component';
import { ModalController, PopoverController } from '@ionic/angular';
import { SuccessfulActionComponent } from '@shared/components/successful-action/successful-action.component';
import { UpdateAgreementComponent } from '@shared/components/update-agreement/update-agreement.component';
import { ActionConfirmationComponent } from '@shared/components/action-confirmation/action-confirmation.component';
import { ActionModalComponent } from '@shared/components/action-modal/action-modal.component';
import { OtherSchoolComponent } from '@shared/components/other-school/other-school.component';
import { SidebarAlertComponent } from '@shared/components/sidebar-components/sidebar-alert/sidebar-alert.component';
import { SidebarHelpContactComponent } from '@shared/components/sidebar-components/sidebar-help-contact/help-contact.component';
import { SidebarGeneralContentComponent } from '@shared/components/sidebar-components/sidebar-general-content/general-sidebar.component';
import { ItineraryListComponent } from '@shared/components/itinerary-list/itinerary-list.component';
import { ItiHelpComponent } from '@app/modules/program/components/iti-help/iti-help.component';
import { ProgramInfoModalComponent } from '@app/modules/application/components/program-info-modal/program-info-modal.component';
import { ProgramTimeZonesComponent } from '@app/modules/program/components/program-time-zones/program-time-zones.component';
import { UniversityDepartmentComponent } from '@app/shared/components/university-department/university-department.component';
import { UserAccountVerifyEmailModalComponent } from '@modules/user/components/user-account-verify-email-modal/user-account-verify-email-modal.component';
import { ApplicationApplySuccess } from '@shared/components/application-apply-success/application-apply-success.component';
import { DocumentViewerModalComponent } from '@shared/components/document-viewer-modal/document-viewer-modal.component';
import { ImmunizationStatusModalComponent } from '@shared/components/immunization-status-modal/immunization-status-modal.component';
import { ResetPasswordModalComponent } from '@shared/components/reset-password-modal/reset-password-modal.component';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    constructor(private modalController: ModalController, private popCtrl: PopoverController) {}

    /**
     * @description present user with successful action modal
     */
    successfulAction = async (data: any) => {
        const modal = await this.modalController.create({
            component: SuccessfulActionComponent,
            cssClass: data.class,
            componentProps: {
                message: `${data.message}`,
                submessage: `${data.submessage}`,
            },
            backdropDismiss: false,
        });

        await modal.present();

        try {
            setTimeout(() => {
                modal.dismiss();
            }, 2500);
        } catch (e) {
            // ignore err happened from user dismissing
        }

        return await modal.onDidDismiss();
    };

    applicationApplySuccess = async () => {
        const modal = await this.modalController.create({
            component: ApplicationApplySuccess,
            backdropDismiss: false,
            cssClass: 'application-apply-success',
        });

        await modal.present();

        // try {
        //     setTimeout(() => {
        //         modal.dismiss();
        //     }, 2500);
        // } catch (e) {
        //     // ignore err happened from user dismissing
        // }

        return await modal.onDidDismiss();
    };

    // confirmation modal
    async confirmation(obj: object): Promise<any> {
        const modal = await this.modalController.create({
            component: ActionConfirmationComponent,
            backdropDismiss: false,
            componentProps: {
                data: obj,
            },
        });
        // all application documents
        // present  modal
        await modal.present();

        // return onDidDismiss()
        return await modal.onDidDismiss();
    }

    async basicInfoAlert(data: any) {
        const modal = await this.modalController.create({
            component: SidebarAlertComponent,
            cssClass: data.class,
            backdropDismiss: false,
            componentProps: {
                title: `${data.title}`,
                header: `${data.header}`,
                topMessage: `${data.topMessage}`,
                bottomMessage: data.bottomMessage,
                color: `${data.color}`,
                lines: data.lines,
                task: data.task,
            },
        });
        return await modal.present();
    }

    async promotionModal(data: any) {
        const modal = await this.modalController.create({
            component: SidebarAlertComponent,
            cssClass: data.class,
            backdropDismiss: false,
            componentProps: {
                title: `${data.title}`,
                header: `${data.header}`,
                topMessage: `${data.topMessage}`,
                bottomMessage: data.bottomMessage,
                color: `${data.color}`,
                lines: data.lines,
                task: data.task,
            },
        });
        return await modal.present();
    }

    async tempUserSearch(data: any) {
        const modal = await this.modalController.create({
            component: SearchTempComponent,
            cssClass: data.class,
            backdropDismiss: false,
            componentProps: {
                title: `${data.title}`,
                header: `${data.header}`,
                topMessage: `${data.topMessage}`,
                bottomMessage: data.bottomMessage,
                color: `${data.color}`,
                lines: data.lines,
                task: data.task,
            },
        });
        return await modal.present();
    }

    async basicHelpAlert(data: any) {
        const modal = await this.modalController.create({
            component: SidebarHelpContactComponent,
            cssClass: data.class,
            componentProps: {
                title: `${data.title}`,
                header: `${data.header}`,
                item1: `${data.item1}`,
                item2: `${data.item2}`,
                item3: `${data.item3}`,
                item4: `${data.item4}`,
                color: `${data.color}`,
            },
        });
        return await modal.present();
    }

    /**
     * @description trigger the upload documents modal
     * @param item the document key you want to upload (always required, go to this definition to find list of keys)
     * @param profile just an object with the property of userid an name.  example: { id: user.id, name: user.name }
     * @param applicationId  applicationId (only required for additional documents)
     * @param customIdentity used for required document uploads, set to 'application'
     * @returns a promise from onDidDismiss(), check if "response".data.result is true before updating current view
     */
    async upload(
        item: string,
        profile: any,
        applicationId?: string,
        link?: string,
        filename?: string,
        submission?: boolean,
        applicationRequirementId?: string,
        applicationidList?: string[],
        levelParams?: any
    ): Promise<any> {
        const modal = await this.modalController.create({
            component: UploadComponent,
            componentProps: {
                uploadItem: item,
                profile,
                uploadIdentity: item,
                application_Id: applicationId,
                url: link,
                fileName: filename,
                applicationRequirement_Id: applicationRequirementId,
                application_IdList: applicationidList,
                levelParams: { ...levelParams },
                requiredSubmission: submission,
            },
            backdropDismiss: false,
        });

        // present da modal
        await modal.present();
        let response: any;
        // return the promise from onDidDismiss()
        await modal.onDidDismiss().then((data) => {
            response = data;
        });
        return response;
    }

    async success(role: any): Promise<any> {
        const modal = await this.modalController.create({
            component: ActionModalComponent,
            backdropDismiss: false,
            componentProps: {
                type: role,
            },
        });

        // present da modal
        await modal.present();

        return modal.onDidDismiss();
    }

    async successfulPayment(payment: string): Promise<any> {
        const modal = await this.modalController.create({
            component: PaymentSuccessfulChargeComponent,
            backdropDismiss: true,
            componentProps: {
                type: payment,
            },
        });

        // present da modal
        await modal.present();

        return modal.onDidDismiss();
    }

    async purchaseMalpractice(applicationId: string, applicationName?: string) {
        const modal = await this.modalController.create({
            component: PaymentStripeModalComponent,
            backdropDismiss: false,
            componentProps: {
                applicationId,
                isinsurance: true,
                applicationName: applicationName,
            },
        });

        // present  modal
        await modal.present();

        // return onDidDismiss()
        return modal.onDidDismiss();
    }

    async purchaseEnvoy(applicationId: string) {
        const modal = await this.modalController.create({
            component: PaymentStripeModalComponent,
            backdropDismiss: false,
            componentProps: {
                applicationId,
                isEnvoy: true,
            },
        });

        await modal.present();

        return modal.onDidDismiss();
    }

    // async documentUploadWarning(app: any) {
    //     const modal = await this.modalController.create({
    //         component: PrePaymentInfoComponent,
    //         cssClass: 'payment-options-modal',
    //         backdropDismiss: true,
    //         componentProps: {
    //             application: app
    //         }
    //     });
    //     modal.present();
    //     return modal.onDidDismiss();
    // }

    async error(): Promise<any> {
        const modal = await this.modalController.create({
            component: UploadComponent,
            backdropDismiss: false,
        });

        // present modal
        await modal.present();

        // return onDidDismiss()
        setTimeout(() => {
            return modal.onDidDismiss();
        }, 3000);
    }

    // Present housing modal
    async housing(applicationId: string, applicationName?: string): Promise<any> {
        const modal = await this.modalController.create({
            component: ApplicationHousingComponent,
            backdropDismiss: false,
            componentProps: {
                applicationId,
                applicationName,
            },
        });

        // present  modal
        await modal.present();

        // return onDidDismiss()
        return modal.onDidDismiss();
    }

    // Present housing suggestions modal
    async housingSuggestions(applicationId: string): Promise<any> {
        const modal = await this.modalController.create({
            component: ApplicationHousingSuggestionsComponent,
            backdropDismiss: false,
            componentProps: {
                applicationid: applicationId,
            },
        });

        // present  modal
        await modal.present();

        // return onDidDismiss()
        return modal.onDidDismiss();
    }

    // Present passport modal
    async passport(applicationId: string): Promise<any> {
        const modal = await this.modalController.create({
            component: ApplicationPassportComponent,
            backdropDismiss: false,
            componentProps: {
                applicationId,
            },
        });

        // present modal
        await modal.present();

        // return onDidDismiss()
        return modal.onDidDismiss();
    }

    async mobileSearchFilter(content: any, ordering: any): Promise<any> {
        const modal = await this.modalController.create({
            component: SearchProgramMobileComponent,
            backdropDismiss: false,
            componentProps: {
                content,
                ...ordering,
            },
        });
        await modal.present();
        return modal.onDidDismiss();
    }

    async otherSchool(signUpForm: any): Promise<any> {
        const modal = await this.modalController.create({
            component: OtherSchoolComponent,
            backdropDismiss: false,
            componentProps: {
                form: signUpForm,
            },
        });
        await modal.present();
        return modal.onDidDismiss();
    }

    cancelApplication = async (ev: Event, applicationId: string) => {
        const popover = await this.popCtrl.create({
            component: ApplicationCancelComponent,
            componentProps: {
                applicationid: applicationId,
            },
            event: ev,
            translucent: true,
        });
        return await popover.present();
    };

    itiHelp = async (ev: Event) => {
        const popover = await this.popCtrl.create({
            component: ItiHelpComponent,
            event: ev,
            translucent: true,
            cssClass: 'help-popover',
            showBackdrop: false,
        });
        return await popover.present();
    };

    enrollmentChecklist = async (missingRequirements: any[], completeRequirements: any[]) => {
        const modal = await this.modalController.create({
            component: ApplicationPreChecklistEnrollmentComponent,
            backdropDismiss: true,
            componentProps: {
                checklistMissing: missingRequirements,
                checklistComplete: completeRequirements,
            },
        });

        // present da modal
        await modal.present();

        return modal.onDidDismiss();
    };

    userAgreedUpdate = async (id: string, msg: string): Promise<any> => {
        const modal = await this.modalController.create({
            component: UpdateAgreementComponent,
            backdropDismiss: false,
            componentProps: {
                userId: id,
                message: msg,
            },
        });
        await modal.present();

        return await modal.onDidDismiss();
    };

    programSummary = async (program: any, user: any) => {
        const modal = await this.modalController.create({
            component: ProgramInfoModalComponent,
            backdropDismiss: true,
            componentProps: {
                program: program,
                user: user,
            },
        });

        await modal.present();

        return await modal.onDidDismiss();
    };

    advisorSidebarModal = async (applications: any[], application: any, profile: any, path: string) => {
        const modal = await this.modalController.create({
            component: SidebarGeneralContentComponent,
            componentProps: {
                applications,
                application,
                profile,
                path,
            },
        });

        await modal.present();

        return await modal.onDidDismiss();
    };

    itinerarySidebarModal = async () => {
        console.log('ran itinerarySidebarModal');
        const modal = await this.modalController.create({
            component: ItineraryListComponent,
        });

        await modal.present();

        return await modal.onDidDismiss();
    };
    async programTimeZones(): Promise<any> {
        const modal = await this.modalController.create({
            component: ProgramTimeZonesComponent,
            backdropDismiss: false,
        });
        await modal.present();
        return modal.onDidDismiss();
    }

    async universityDepartmentConfirm(): Promise<any> {
        const modal = await this.modalController.create({
            component: UniversityDepartmentComponent,
            backdropDismiss: false,
        });
        //
        await modal.present();
        return await modal.onDidDismiss();
    }

    documentViewer = async (providedURL: string | any[], userid?: any) => {
        const modal = await this.modalController.create({
            component: DocumentViewerModalComponent,
            componentProps: {
                url: providedURL,
                userid: userid,
                clear: false,
            },
            backdropDismiss: true,
            cssClass: Array.isArray(providedURL) == false ? 'document-viewer-modal' : 'multi-document-viewer-modal',
        });

        await modal.present();
        return modal.onDidDismiss();
    };

    immunizationStatusModal = async (statuses: any[]) => {
        const modal = await this.modalController.create({
            component: ImmunizationStatusModalComponent,
            componentProps: {
                statuses: statuses,
            },
            backdropDismiss: true,
            cssClass: 'immunization-modal',
        });

        await modal.present();
        return modal.onDidDismiss();
    };

    forceResetPasswordModal = async () => {
        const modal = await this.modalController.create({
            component: ResetPasswordModalComponent,
            backdropDismiss: false,
            cssClass: 'reset-password-modal',
        });

        await modal.present();
        return await modal.onDidDismiss();
    };
}
