import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Subscription, interval } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';

// eslint-disable-next-line @typescript-eslint/naming-convention
@Injectable({
    providedIn: 'root',
})
export class WorkerService {
    public showUpdateBanner = new BehaviorSubject(false);

    isNewVersionAvailable: boolean = false;
    intervalSource = interval(30000); // every 30 sec
    intervalSubscription: Subscription;
    constructor(public swUpdate: SwUpdate, private zone: NgZone) {
        if (swUpdate.isEnabled) {
            this.intervalSubscription?.unsubscribe();
            if (!this.swUpdate.isEnabled) {
                return;
            }

            this.zone.runOutsideAngular(() => {
                this.intervalSubscription = this.intervalSource.subscribe(async () => {
                    try {
                        this.isNewVersionAvailable = await this.swUpdate.checkForUpdate();
                        console.log(this.isNewVersionAvailable ? 'A new version is available.' : 'Already on the latest version.');
                    } catch (error) {
                        console.error('Failed to check for updates:', error);
                    }
                });
            });
        }
    }

    public async checkForUpdates() {
        try {
            const updateReady = await this.swUpdate.checkForUpdate();
            console.log('updateReady', updateReady);

            if (updateReady) {
                this.promptUser();
            }
        } catch (error) {
            console.log('error in checkForUpdates', error);
        }
    }

    async promptUser() {
        console.log('updating to new version');
        this.setShowBanner();
    }

    reload() {
        this.swUpdate.activateUpdate().then(() =>
            setTimeout(() => {
                document.location.reload();
            }, 1000)
        );
    }

    clearShowBanner() {
        this.showUpdateBanner.next(false);
    }
    setShowBanner() {
        this.showUpdateBanner.next(true);
    }
} // end of the tools service
