<!-- <ion-content> -->
<ion-header>
    <ion-item class="modal-header-color">
        <ion-label>
            <ion-text>
                <h2 class="ion-text-center ion-text-wrap modal-title">
                    <fa-icon [icon]="icons.home"></fa-icon>
                    Submit Housing Information
                </h2>
            </ion-text>
        </ion-label>
        <ion-button slot="end" class="close" type="button" fill="clear" (click)="dismiss()">
            <ion-icon slot="icon-only" color="danger" name="close" size="medium"></ion-icon>
        </ion-button>
    </ion-item>
</ion-header>
<ion-grid class="modal-content">
    <ion-col size="12">
        <ion-row>
            <ion-col>
                <ion-item class="ion-text-center" lines="none" *ngIf="applicationName">
                    <ion-text color="dark-teal">
                        <p>For: {{ applicationName }}</p>
                    </ion-text>
                </ion-item>
                <ion-item class="ion-text-center" lines="none">
                    <ion-text>
                        <p>Tell us where you'll be staying during your clinical experience.</p>
                    </ion-text>
                </ion-item>
            </ion-col>
        </ion-row>
        <form [formGroup]="housing_form" (ngSubmit)="submitForm()">
            <ion-row class="ion-justify-content-center">
                <ion-col>
                    <ion-list>
                        <ion-item class="form-elements" lines="none">
                            <ion-label position="stacked" class="form-title">* Address</ion-label>
                            <ion-input class="basic-inputs" type="text" formControlName="address"></ion-input>
                        </ion-item>
                        <ion-item class="form-elements" lines="none">
                            <ion-label position="stacked" class="form-title">* City</ion-label>
                            <ion-input class="basic-inputs" type="text" formControlName="city"></ion-input>
                        </ion-item>
                        <ion-item class="form-elements" lines="none">
                            <ion-label position="stacked" class="form-title">* State</ion-label>
                            <ion-select class="basic-select" formControlName="state">
                                <ion-select-option *ngFor="let state of usStateList" value="{{ state }}">{{ state }} </ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item class="form-elements" lines="none">
                            <ion-label position="stacked" class="form-title">* Postal Code</ion-label>
                            <ion-input class="basic-inputs" type="text" formControlName="postal"></ion-input>
                        </ion-item>
                        <ion-item class="ion-text-wrap form-elements" lines="none">
                            <ion-label position="stacked" class="form-title">* Who are you staying with?</ion-label>
                            <ion-select class="basic-inputs" formControlName="stayingwith">
                                <ion-select-option value="airbnb">Airbnb</ion-select-option>
                                <ion-select-option value="american homestay network">American Homestay Network</ion-select-option>
                                <ion-select-option value="rental">Rental</ion-select-option>
                                <ion-select-option value="hotel">Hotel</ion-select-option>
                                <ion-select-option value="family-friends">Family or Friends</ion-select-option>
                                <ion-select-option value="other">Other</ion-select-option>
                            </ion-select>
                        </ion-item>
                        <ion-item *ngIf="housing_form.get('stayingwith').value === 'airbnb'" class="ion-text-wrap form-elements" lines="none">
                            <ion-label position="stacked" class="form-title">* Link to Airbnb</ion-label>
                            <ion-input c lass="basic-inputs" type="text" formControlName="airbnblink"></ion-input>
                        </ion-item>
                    </ion-list>
                </ion-col>
            </ion-row>
        </form>
    </ion-col>
</ion-grid>
<ion-footer>
    <ion-toolbar>
        <ion-button expand="full" type="button" (click)="submitForm()" block>Submit Housing </ion-button>
    </ion-toolbar>
</ion-footer>
