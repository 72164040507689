import { Action, createAction, props } from '@ngrx/store';

export const setItineraryList = createAction(
    '[Itinerary] Set Itinerary List',
    props<{itineraryList: any}>()
);


// export const removeItineraryItem = createAction(
//     '[Itinerary] Remove Item',
//     props<{selections: any}>()
// );
