<ion-grid *ngIf="payment?.ready" fixed class="ion-no-padding">
    <ion-row class="modal-topbar">
        <ion-col
            sizeXl="12"
            sizeLg="12"
            sizeMd="12"
            sizeSm="12"
            sizeXs="12"
            style="padding: 0px;"
        >
            <ion-item color="--ion-color-white" lines="none">
                <ion-label
                    *ngIf="!isEnvoy && applicationName"
                    color="dark-teal"
                    class="ion-text-center small-label bold-text"
                >
                    Malpractice Insurance Payment for {{ applicationName }}
                </ion-label>
                <ion-label
                    class="section-title modal-title ion-text-center"
                    color="dark-teal"
                    *ngIf="!isEnvoy && !applicationName"
                >
                    Malpractice Insurance Payment
                </ion-label>
                <ion-label
                    class="section-title modal-title ion-text-center"
                    color="dark-teal"
                    *ngIf="isEnvoy"
                >
                    Envoy Immigration Payment
                </ion-label>
                <ion-icon
                    name="close"
                    class="close-button"
                    slot="end"
                    tappable
                    (click)="close()"
                ></ion-icon>
            </ion-item>
        </ion-col>
    </ion-row>
    <form [formGroup]="payment_form" (ngSubmit)="submit()" class="payment-form">
        <ion-col
            size="12"
            size-xl="12"
            size-lg="12"
            size-md="12"
            size-sm="12"
            size-xs="12"
        >
            <ion-card-header>
                <ion-card-title class="section-title" color="dark-teal"
                    >Billing Information</ion-card-title
                >
            </ion-card-header>
            <ion-list class="billing-list">
                <ion-item lines="none">
                    <ion-label position="stacked">Full Name</ion-label>
                    <ion-input
                        type="text"
                        class="basic-inputs"
                        formControlName="name"
                    ></ion-input>
                </ion-item>
                <ion-item lines="none">
                    <ion-label position="stacked">Address</ion-label>
                    <ion-input
                        type="text"
                        class="basic-inputs"
                        formControlName="address_line1"
                    ></ion-input>
                </ion-item>
                <ion-item lines="none">
                    <ion-label position="stacked">City</ion-label>
                    <ion-input
                        type="text"
                        class="basic-inputs"
                        formControlName="address_city"
                    ></ion-input>
                </ion-item>
                <ion-item lines="none">
                    <ion-label position="stacked">State / Province</ion-label>
                    <ion-input
                        type="text"
                        class="basic-inputs"
                        formControlName="address_state"
                    ></ion-input>
                </ion-item>
                <ion-item lines="none">
                    <ion-label position="stacked">Postal Code</ion-label>
                    <ion-input
                        type="text"
                        class="basic-inputs"
                        formControlName="address_zip"
                    ></ion-input>
                </ion-item>
                <ion-item lines="none">
                    <ion-label position="stacked">Country</ion-label>
                    <ion-select
                        formControlName="country"
                        class="basic-select country-input"
                    >
                        <ion-select-option
                            *ngFor="let country of (content | async).countries"
                            value="{{ country }}"
                        >
                            {{ country }}
                        </ion-select-option>
                    </ion-select>
                </ion-item>
                <ion-item lines="none">
                    <ion-label position="stacked">Email</ion-label>
                    <ion-input
                        type="email"
                        class="basic-inputs"
                        formControlName="email"
                    ></ion-input>
                </ion-item>
            </ion-list>
            <!-- <div>
        <p class="phone-label">Phone Number</p>
        <div class="phone-input">
          <ion-input type="tel" formControlName="phone"></ion-input>
        </div>
      </div> -->
            <ion-card-header>
                <ion-card-title
                    class="section-title payment-title"
                    color="dark-teal"
                    style="padding-left: 0px;"
                    >Card Information</ion-card-title
                >
                <div
                    class="card-errors ion-text-left"
                    id="card-errors"
                    role="alert"
                ></div>
            </ion-card-header>
            <div class="ion-padding-start">
                <p class="card-label">Card Number</p>
                <div class="basic-inputs" id="card-number"></div>
                <div>
                    <div class="card-input">
                        <div>
                            <p class="card-label">Expire Date</p>
                        </div>
                        <div class="stripe-input-short" id="card-expiry"></div>
                    </div>
                    <div class="card-input">
                        <div>
                            <p class="card-label">CVV</p>
                        </div>
                        <div class="stripe-input-short" id="card-cvc"></div>
                    </div>
                </div>
            </div>
            <div class="ion-text-center">
                <ion-progress-bar
                    *ngIf="payment?.paying"
                    type="indeterminate"
                ></ion-progress-bar>
                <ion-button
                    class="ion-text-wrap half-button"
                    *ngIf="!payment?.paying"
                    size="large"
                    type="submit"
                    [disabled]="!payment_form.valid"
                >
                    {{ payment?.buttonText }}
                </ion-button>
            </div>
        </ion-col>
    </form>
</ion-grid>
