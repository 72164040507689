import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ItineraryComponentsModule } from '@app/modules/itinerary/itinerary.module';
import { AngularMaterialModule } from '@app/shared/angular.material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { PipesComponentsModule } from '../pipes/pipes.modules';
import { ActionConfirmationComponent } from './action-confirmation/action-confirmation.component';
import { ActionModalComponent } from './action-modal/action-modal.component';
import { ApplicationCardComponent } from './application-card/application-card.component';
import { CalendlyComponent } from './calendly/calendly.component';
import { ContactAdvisorComponent } from './contact-advisor/contact-advisor.component';
import { ImageSlideComponent } from './image-slide/image-slide.component';
import { ItineraryCreateItemsMobileComponent } from './itinerary-create-items-mobile/itinerary-create-items-mobile.component';
import { ItineraryCreateItemsComponent } from './itinerary-create-items/itinerary-create-items.component';
import { ItineraryCreateListComponent } from './itinerary-create-list/itinerary-create-list.component';
import { ItineraryCustomerInfoComponent } from './itinerary-customer-info/itinerary-customer-info.component';
import { ProgramSelectionComponent } from './program-selection/program-selection.component';
import { PopoverComponent } from './popover/popover.component';
import { ItineraryItemComponent } from './itinerary-item/itinerary-item.component';
import { ItineraryListComponent } from './itinerary-list/itinerary-list.component';
import { OtherSchoolComponent } from './other-school/other-school.component';
import { SuccessfulActionComponent } from './successful-action/successful-action.component';
import { UpdateAgreementComponent } from './update-agreement/update-agreement.component';
import { ServiceWorkerComponent } from './service-worker/service-worker.component';
import { helpInfoComponent } from './help-info/help-info.component';
import { ApplicationApplySuccess } from './application-apply-success/application-apply-success.component';
import { SwiperModule } from 'swiper/angular';
import { SingleOptionSelectorModelComponent } from './single-option-selector-model/single-option-selector-model.component';
import { DocumentViewerModalComponent } from './document-viewer-modal/document-viewer-modal.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { QuicklinkModule } from 'ngx-quicklink';
import { ImmunizationStatusModalComponent } from './immunization-status-modal/immunization-status-modal.component';
import { ResetPasswordModalComponent } from './reset-password-modal/reset-password-modal.component';
@NgModule({
    declarations: [
        CalendlyComponent,
        ActionConfirmationComponent,
        ActionModalComponent,
        SuccessfulActionComponent,
        UpdateAgreementComponent,
        ImageSlideComponent,
        ApplicationCardComponent,
        ContactAdvisorComponent,
        OtherSchoolComponent,
        ItineraryItemComponent,
        ItineraryListComponent,
        ItineraryCreateItemsComponent,
        ItineraryCreateItemsMobileComponent,
        ItineraryCreateListComponent,
        ItineraryCustomerInfoComponent,
        ProgramSelectionComponent,
        PopoverComponent,
        ServiceWorkerComponent,
        helpInfoComponent,
        SingleOptionSelectorModelComponent,
        ApplicationApplySuccess,
        DocumentViewerModalComponent,
        ImmunizationStatusModalComponent,
    ],
    imports: [
        QuicklinkModule,
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        AngularMaterialModule,
        PipesComponentsModule,
        FontAwesomeModule,
        SwiperModule,
        NgxDocViewerModule,
        ResetPasswordModalComponent,
    ],
    exports: [
        CalendlyComponent,
        ActionConfirmationComponent,
        SuccessfulActionComponent,
        ImageSlideComponent,
        ApplicationCardComponent,
        ContactAdvisorComponent,
        ActionModalComponent,
        OtherSchoolComponent,
        ItineraryItemComponent,
        ItineraryListComponent,
        ItineraryCreateItemsComponent,
        ItineraryCreateItemsMobileComponent,
        ItineraryCreateListComponent,
        ItineraryCustomerInfoComponent,
        ProgramSelectionComponent,
        PopoverComponent,
        ServiceWorkerComponent,
        helpInfoComponent,
        SingleOptionSelectorModelComponent,
        ApplicationApplySuccess,
        DocumentViewerModalComponent,
        ImmunizationStatusModalComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedComponentsModule {}
