import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { v4 as uuid4 } from 'uuid';
import { environment } from '../../../../environments/environment';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { SendEventActionsService } from '@app/core/services/events/sendEventActions.service';
import { parseString } from 'xml2js';

@Injectable({
    providedIn: 'root',
})
export class DocumentService {
    constructor(private http: HttpClient, private apollo: Apollo, private sendEventActionService: SendEventActionsService) {}

    public progress = {
        percent: 0,
        status: '',
        multi: [],
    };

    applicationDocuments(): Observable<any> {
        return this.apollo.query({
            query: gql`
                query applicationDocuments {
                    activeApplications {
                        id
                        name
                        userid
                        fullname
                        applicationDocuments {
                            documents
                        }
                    }
                    completeApplications {
                        id
                        name
                        userid
                        fullname
                        applicationDocuments {
                            documents
                        }
                    }
                }
            `,
            fetchPolicy: 'network-only',
        });
    }

    applicationDocumentsDetails(): Observable<any> {
        return this.apollo.query({
            query: gql`
                query applicationDocuments {
                    activeApplications {
                        id
                        name
                        userid
                        fullname
                        city
                        state
                        startdate
                        enddate
                        type
                        states
                    }
                    completeApplications {
                        id
                        name
                        userid
                        fullname
                        city
                        state
                        startdate
                        enddate
                        type
                        states
                    }
                }
            `,
            fetchPolicy: 'network-only',
        });
    }

    userDocuments(): Observable<any> {
        return this.apollo.query({
            query: gql`
                query userDocuments {
                    user {
                        id
                        name
                        item1name
                        item1
                        item2name
                        item2
                        item3name
                        item3
                        item4name
                        item4
                        item5name
                        item5
                        item6name
                        item6
                        item7name
                        item7
                        item8name
                        item8
                        item9name
                        item9
                        item10name
                        item10
                    }
                }
            `,
            fetchPolicy: 'network-only',
        });
    }

    /**
     * @description send rum action for document upload
     * @param item
     * @param file
     * @param label
     * @param context
     */
    sendRumAction(item: string, file: File, label: string, context: any = {}): void {
        this.sendEventActionService.sendEventActions({
            eventAction: `document_upload_service_${label}`,
            eventCategory: 'ApplicationDetail',
            ddAction: `document_upload_service_${label}`,
            ddContext: {
                ctxEvent: label,
                ctxUploadName: file.name,
                ctxUploadItem: item,
                ctxUploadSize: file.size,
                ctxUploadType: file.type,
                ...context,
            },
            customDimensions: {},
        });
    }

    /**
     * @description set xhr listeners for document upload
     * @param xhr
     * @param type
     * @param file
     * @param callback
     */
    setXhrEvents(xhr: XMLHttpRequest, type: string, file: File, callback: Function) {
        const docTrackId = uuid4();
        let startTimestamp = 0;
        xhr.onloadstart = (e) => {
            startTimestamp = e.timeStamp;
            this.sendRumAction(type, file, 'onloadstart', {
                docTrackId,
                timestamp: startTimestamp,
            });
        };
        xhr.upload.onprogress = (e) => {
            const percent = Math.round((100 * e.loaded) / e.total) / 100;
            if (percent > this.progress.percent + 0.2 || percent === 1) {
                this.sendRumAction(type, file, 'onprogress', {
                    docTrackId,
                    percent,
                    timestamp: e.timeStamp,
                });
            }
            this.progress.percent = percent;
        };
        xhr.upload.onloadend = (e) => {
            this.progress.status = 'processing document...';
        };
        xhr.upload.onerror = (e) => {
            this.sendRumAction(type, file, 'upload.onerror', { docTrackId, status: xhr.status, response: xhr.responseText || 'Network request failed' });
        };
        xhr.onload = (e) => {
            this.sendRumAction(type, file, 'onload', {
                docTrackId,
                timestamp: e.timeStamp,
                uploadTime: e.timeStamp - startTimestamp,
                startTime: startTimestamp,
                response: xhr.response,
            });
            this.progress.status = 'onload';
            callback(null, xhr.response);
        };
        xhr.ontimeout = (e) => {
            this.sendRumAction(type, file, 'ontimeout', { docTrackId, status: xhr.status, response: xhr.responseText || 'Network request failed' });
            callback(e);
        };
        xhr.onerror = (e) => {
            this.sendRumAction(type, file, 'onerror', { docTrackId, status: xhr.status, response: xhr.responseText || 'Network request failed' });
            callback(e);
        };
    }

    // ---------------------------------------------------------------------------------------------------------------------------------//

    /**
     * @description Get AWS Bucket Permissions
     * @method GET
     * @param userid  current userid
     * @param filetype MIME file type (accepted are pdf and jpeg)
     */
    awsUploadPermissions(userid: string, filetype: string): Observable<any> {
        return this.http.get(`${environment.API}/users/upload?mimeType=${filetype}&userid=${userid}`);
    }

    downloadImmunizationForm(): Observable<any> {
        return this.http.get(`${environment.DOCUMENT_IMMUNIZATION}`);
    }

    /**
     * @description get additional documents the user needs to submit related to this application
     * @param applicationid - current applicationid
     * @method GET
     */
    listRequiredApplicationDocuments(applicationid: string): Observable<any> {
        return this.http.get(`${environment.API}/applications/${applicationid}/documentlist`);
    }

    /**
     * @description get all the application documents a user has submitted
     * @param applicationid current applicationid
     * @method GET
     *
     */
    listUserSubmittedApplicationDocuments(applicationid: string): Observable<any> {
        return this.http.get(`${environment.API}/applications/${applicationid}/additionaldocuments`);
    }

    /**
     *
     * @param applicationid current applicationid
     * @method GET
     * @description returns documents like deposit and payment reciepts, malpractice insruance reciepts, etc
     * NOT an endpoint for additional application documents, (listRequired...)
     *
     */
    listOfUserApplicationDocuments(applicationid: string): Observable<any> {
        return this.http.get(`${environment.API}/applications/${applicationid}/applicationdocuments`);
    }

    immunizationDocuments(userid: string) {
        return this.http.get(`${environment.API}/users/${userid}/immunizationdocuments`);
    }

    requirementDocuments(userid: string) {
        // submitted requirementDocuments
        return this.http.get(`${environment.API}/users/${userid}/requirementdocuments`);
    }

    pathRegex(originalFilename: string) {
        const folderNameString = String(originalFilename);
        const noSpacesFolder = folderNameString.replace(/ /g, '_');
        const fileName = noSpacesFolder.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');
        return fileName;
    }

    /**
     * @description method that handles application requirement map ids
     * @method POST
     * @param userid - current users id
     * @param applicationrequirementmapid - requirement map id that maps to application requirement id/programid/hostid/departmentid
     * @param originalFilename - original file name from the s3 bucket, overwrites whatever the user entered
     * @param files - FileList, the specific property type not an array of File objects
     */
    handleApplicationRequirementMapDocumentsUpload(
        files: FileList,
        originalFilename: string,
        type: string,
        userid: string,
        applicationid: string,
        applicationRequirementId: string,
        applicationidList: string[],
        levelParams: any,
        existingRequirementDocuments: any = []
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                Array.from(files).map(async (file) => {
                    const filenameString = String(file.name);
                    const filetype = String(file.type);
                    const noSpaces = filenameString.replace(/ /g, '_');
                    let fileName = noSpaces.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');

                    const folderName = this.pathRegex(originalFilename);

                    try {
                        // check if filename matches other files in the requirements folder
                        if (existingRequirementDocuments && existingRequirementDocuments.length > 0) {
                            let path = `userdocuments/${userid}/requirements/${applicationRequirementId}/${levelParams.levelType}/${levelParams.levelId}/${folderName}/`;
                            //"userdocuments/7ae4dfe7-ed93-45a6-9f79-ad4efbef3e0f/requirements/628df14b-c8d4-45fe-a485-6b8338c16b7d/program/100000134/COVID19_7_Seven_Day_Quarantine_for_International_Travelers_or_Negative_COVID_Test_24_Hours_Post_Travel/"
                            const foundProgramReqFilepaths = existingRequirementDocuments.filter((object) => {
                                //requirements/e0619c46-724a-46cc-a89f-b7890ee2812e/program/100000036/
                                if (object.url.includes(path)) {
                                    return object;
                                }
                            });

                            if (foundProgramReqFilepaths && foundProgramReqFilepaths.length > 0) {
                                const fileNameCompare = `filled_requirements_${fileName}`.split('.').slice(0, -1).join('.');
                                const numOfSameFileName: number = foundProgramReqFilepaths.reduce((acc, curr) => {
                                    const splitUrl = curr.url.split(path);
                                    const requirementFilePath = splitUrl[1];
                                    //console.log("::::::", requirementFilePath);
                                    if (requirementFilePath && requirementFilePath.includes(fileNameCompare)) acc++;
                                    return acc;
                                }, 0);
                                if (numOfSameFileName > 0) {
                                    let arr = fileName.split('.');
                                    arr[arr.length - 2] = `${arr[arr.length - 2]}(${numOfSameFileName})`;
                                    fileName = arr.join('.');
                                }
                            }
                        }
                    } catch (err) {
                        reject(`Additional Documents Upload Error: ${err}`);
                    }

                    // const newpathRegexed =`userdocuments/${userid}/requirements/${applicationRequirementId}/${levelParams.levelType}/${levelParams.levelId}/${folderName}/filled_requirements_${fileName}`
                    // console.log("newpathRegexed", newpathRegexed);

                    this.http
                        .get(`${environment.API}/users/upload?mimeType=${filetype}&userid=${userid}`)
                        .toPromise()
                        .then((resultPermissions) => {
                            try {
                                const s3Params = { ...resultPermissions };
                                const formData: FormData = new FormData();
                                const xhr: XMLHttpRequest = new XMLHttpRequest();

                                // eslint-disable-next-line max-len
                                formData.append(
                                    'key',
                                    `userdocuments/${userid}/requirements/${applicationRequirementId}/${levelParams.levelType}/${levelParams.levelId}/${folderName}/filled_requirements_${fileName}`
                                    //`userdocuments/${userid}/requirements/${applicationRequirementId}/${levelParams.levelType}/${levelParams.levelId}/${originalFilename}`  //old
                                );
                                formData.append('acl', 'private');
                                formData.append('Content-Type', file.type !== '' ? file.type : 'application/octet-stream');
                                formData.append('AWSAccessKeyId', s3Params['AWSAccessKeyId']);
                                formData.append('success_action_status', '201');
                                formData.append('Policy', s3Params['s3Policy']);
                                formData.append('Signature', s3Params['s3Signature']);
                                formData.append('file', file, fileName);

                                xhr.open('POST', environment.DOCUMENT_S3_UPLOAD, true);

                                this.setXhrEvents(xhr, type, file, (e, response) => {
                                    if (e) {
                                        reject(e);
                                    } else {
                                        const parser = new DOMParser();
                                        const xml = parser.parseFromString(response, 'text/xml');
                                        this.convertXmlToJson(xml).then((xmlObject: {}[]) => {
                                            console.log(xmlObject);
                                            const cleanETag = xmlObject['PostResponse'].ETag.replace(/[^\w\s]/gi, '');
                                            xmlObject['PostResponse'].ETag = cleanETag;
                                            resolve({
                                                success: true,
                                                parsedXML: {
                                                    ...xmlObject['PostResponse'],
                                                },
                                            });
                                        });
                                    }
                                });

                                xhr.send(formData);
                            } catch (err) {
                                reject(`Additional Documents Upload Error: ${err.message}`);
                            }
                        })
                        .catch((err) => {
                            reject(`Additional Documents Upload Error: ${err.message}`);
                        });
                });
            } catch (err) {
                reject(`Additional Documents Upload Error: ${err}`);
            }
        });
    }

    /**
     * @description method that handles application requirement map ids
     * @method POST
     * @param userid - current users id
     * @param applicationrequirementmapid - requirement map id that maps to application requirement id/programid/hostid/departmentid
     * @param originalFilename - original file name from the s3 bucket, overwrites whatever the user entered
     * @param files - FileList, the specific property type not an array of File objects
     */
    handleApplicationMalpracticeDocumentsUpload(files: FileList, originalFilename: string, type: string, userid: string, applicationId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                const tasks = Array.from(files).map(async (file) => {
                    const filenameString = String(file.name);
                    const filetype = String(file.type);
                    const noSpaces = filenameString.replace(/ /g, '_');
                    const fileName = noSpaces.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');

                    this.http
                        .get(`${environment.API}/users/upload?mimeType=${filetype}&userid=${userid}`)
                        .toPromise()
                        .then((resultPermissions) => {
                            try {
                                const s3Params = { ...resultPermissions };
                                const formData: FormData = new FormData();
                                const xhr: XMLHttpRequest = new XMLHttpRequest();

                                // eslint-disable-next-line max-len
                                formData.append('key', `userdocuments/${userid}/applications/${applicationId}/malpractice/${fileName}`);
                                formData.append('acl', 'private');
                                formData.append('Content-Type', file.type !== '' ? file.type : 'application/octet-stream');
                                formData.append('AWSAccessKeyId', s3Params['AWSAccessKeyId']);
                                formData.append('success_action_status', '201');
                                formData.append('Policy', s3Params['s3Policy']);
                                formData.append('Signature', s3Params['s3Signature']);
                                formData.append('file', file, fileName);

                                xhr.open('POST', environment.DOCUMENT_S3_UPLOAD, true);

                                this.setXhrEvents(xhr, type, file, (e, response) => {
                                    if (e) {
                                        reject(e);
                                    } else {
                                        const parser = new DOMParser();
                                        const xml = parser.parseFromString(response, 'text/xml');
                                        this.convertXmlToJson(xml).then((xmlObject) => {
                                            const cleanETag = xmlObject['PostResponse'].ETag.replace(/[^\w\s]/gi, '');
                                            xmlObject['PostResponse'].ETag = cleanETag;
                                            resolve({
                                                success: true,
                                                parsedXML: {
                                                    ...xmlObject['PostResponse'],
                                                },
                                            });
                                        });
                                    }
                                });

                                xhr.send(formData);
                            } catch (err) {
                                reject(`Additional Documents Upload malpractice Error: ${err.message}`);
                            }
                        })
                        .catch((err) => {
                            reject(`Additional Documents Upload malpractice Error: ${err.message}`);
                        });
                });
            } catch (err) {
                reject(`Additional Documents Upload malpractice Error: ${err.message}`);
            }
        });
    }

    // ---------------------------------------------------------------------------------------------------------------------------------//

    /**
     *
     * @param files FileList, not an array of files
     * @param userid current users id
     * @method POST
     * @description File upload method for immunization, requires s3 permissions
     *
     * MTR - Questions
     *    can we formData.append more than one file at a time???
     *    won't a call to subject.next() tell the modal to close???
     *      - we call this after each successful upload
     *      - (I guess) the loop will continue uploading the remaining files
     *      - but there will be no status - well each next() will run the subscribe and
     *      - keep checking for uploads
     *      - *** disable this first to watch the process ??? ***
     *    Were not checking each file for correct file type or size
     *    How about chenging the Array.from map to a Promise.all or Promise.allSettled???
     *    How about changing the single progress bar to a ngFor array of progress bars???
     *      - *** do this first to watch the process ***
     *    What to do if one of the files errors?
     */
    handleImmunizationUpload(files: FileList, type: string, userid: string, existingImmuneDocuments: any = []): Observable<any> {
        let subject = new Subject<any>();

        this.sendRumAction(type, files[0], 'handleImmunizationUpload', { userid, existingImmuneDocuments });

        try {
            Array.from(files).map((file) => {
                const filenameString = String(file.name);
                const filetype = String(file.type);
                const noSpaces = filenameString.replace(/ /g, '_');
                let fileName = noSpaces.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');

                // check if filename matches other files in the immue folder
                if (existingImmuneDocuments && existingImmuneDocuments.length > 0) {
                    const fileNameCompare = fileName.split('.').slice(0, -1).join('.');
                    const numOfSameFileName: number = existingImmuneDocuments.reduce((acc, curr) => {
                        if (curr.filename.includes(fileNameCompare)) acc++;
                        return acc;
                    }, 0);
                    if (numOfSameFileName > 0) {
                        let arr = fileName.split('.');
                        arr[arr.length - 2] = `${arr[arr.length - 2]}(${numOfSameFileName})`;
                        fileName = arr.join('.');
                    }
                }

                this.progress.status = 'uploading file...';
                this.progress.percent = 0;

                let localIndex = this.progress.multi.length - 1;

                try {
                    this.awsUploadPermissions(userid, filetype).subscribe(
                        (res) => {
                            try {
                                const xhr: XMLHttpRequest = new XMLHttpRequest();

                                const s3Params = { ...res };
                                const formData: FormData = new FormData();

                                formData.append('key', `userdocuments/${userid}/immunizations/filled_immunization_${fileName}`);
                                formData.append('acl', 'private');
                                formData.append('Content-Type', file.type || 'application/octet-stream');
                                formData.append('AWSAccessKeyId', s3Params.AWSAccessKeyId);
                                formData.append('success_action_status', '201');
                                formData.append('Policy', s3Params.s3Policy);
                                formData.append('Signature', s3Params.s3Signature);
                                formData.append('file', file, fileName);

                                xhr.open('POST', environment.DOCUMENT_S3_UPLOAD, true);

                                this.setXhrEvents(xhr, type, file, (e, response) => {
                                    if (e) {
                                        setTimeout(() => subject.next(e));
                                    } else {
                                        setTimeout(() => subject.next('success'));
                                    }
                                });

                                xhr.send(formData);
                                // console.log('Immunization Document Upload Send');
                            } catch (err) {
                                setTimeout(() => subject.next(err));
                                return throwError(() => new Error(`Immunization Upload Error: ${err.message}`));
                            }
                        },
                        (err) => {
                            setTimeout(() => subject.next(err));
                            return throwError(() => new Error(`Immunization Upload Permissions Error: ${err.message}`));
                        }
                    );
                } catch (err) {
                    setTimeout(() => subject.next(err));
                    return throwError(() => new Error(`Immunization Upload Error: ${err}`));
                }
            });
        } catch (err) {
            //any file related errors
            setTimeout(() => subject.next(err));
            return throwError(() => new Error(`Immunization Upload Error: ${err}`));
        }
        return subject.asObservable();
    }

    /**
     *
     * @param file
     * @param user current users id
     * @method POST
     * @description File upload method for Health Insurance Upload, requires s3 permissions
     */
    handleHealthInsuranceUpload(file: File, type: string, user: any, existingHealthInsuranceDocuments: any = []): Observable<any> {
        this.sendRumAction(type, file, 'start');

        const subject = new Subject<any>();
        const reader = new FileReader();

        try {
            reader.readAsDataURL(file);
            reader.addEventListener('load', (event: any) => {
                const filenameString = String(file.name);
                const filetype = String(file.type);
                const noSpaces = filenameString.replace(/ /g, '_');
                let fileName = noSpaces.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');

                this.progress.status = 'starting...';

                try {
                    // check if filename matches other files in the immue folder
                    if (existingHealthInsuranceDocuments && existingHealthInsuranceDocuments.length > 0) {
                        const fileNameCompare = fileName.split('.').slice(0, -1).join('.');
                        const numOfSameFileName: number = existingHealthInsuranceDocuments.reduce((acc, curr) => {
                            if (curr.filename.includes(fileNameCompare)) acc++;
                            return acc;
                        }, 0);
                        if (numOfSameFileName > 0) {
                            let arr = fileName.split('.');
                            arr[arr.length - 2] = `${arr[arr.length - 2]}(${numOfSameFileName})`;
                            fileName = arr.join('.');
                        }
                    }
                } catch (err) {
                    //any file name related errors
                    setTimeout(() => subject.next(err));
                    return throwError(new Error(`healthinsurance Upload Error: ${err}`));
                }

                this.awsUploadPermissions(user.id, filetype).subscribe(
                    (res) => {
                        try {
                            const xhr: XMLHttpRequest = new XMLHttpRequest();

                            const s3Params = { ...res };
                            const formData: FormData = new FormData();
                            let path = `userdocuments/${user.id}/applications/${user.applicationid}/healthinsurance/${fileName}`;
                            //console.log(path);
                            formData.append('key', path);
                            formData.append('acl', 'private');
                            formData.append('Content-Type', file.type !== '' ? file.type : 'application/octet-stream');
                            formData.append('AWSAccessKeyId', s3Params.AWSAccessKeyId);
                            formData.append('success_action_status', '201');
                            formData.append('Policy', s3Params.s3Policy);
                            formData.append('Signature', s3Params.s3Signature);
                            formData.append('file', file, fileName);

                            xhr.open('POST', environment.DOCUMENT_S3_UPLOAD, true);

                            this.setXhrEvents(xhr, type, file, (e, response) => {
                                if (e) {
                                    setTimeout(() => subject.next(e));
                                } else {
                                    const parser = new DOMParser();
                                    const xml = parser.parseFromString(response, 'text/xml');
                                    this.convertXmlToJson(xml).then((xmlObject) => {
                                        // const cleanETag = xmlObject['PostResponse'].ETag.replace(/[^\w\s]/gi, '');
                                        // xmlObject['PostResponse'].ETag = cleanETag;
                                        const responseObj = xmlObject['PostResponse'];
                                        subject.next(
                                            JSON.stringify({
                                                key: responseObj.Key,
                                                fileName: fileName, //[],
                                                from: 'handleHealthInsuranceUpload',
                                            })
                                        );
                                    });
                                }
                            });

                            xhr.send(formData);
                        } catch (err) {
                            setTimeout(() => subject.next(err));
                            return throwError(new Error(`healthinsurance Upload Error: ${err}`));
                        }
                    },
                    (err) => {
                        setTimeout(() => subject.next(err));
                        return throwError(new Error(`healthinsurance Upload Error: ${err}`));
                    }
                );
            });
            reader.addEventListener('error', (err) => {
                setTimeout(() => subject.next(err));
                return throwError(new Error(`healthinsurance Upload Error: ${err}`));
            });
        } catch (err) {
            //any file related errors
            setTimeout(() => subject.next(err));
            return throwError(new Error(`healthinsurance Upload Error: ${err}`));
        }

        return subject.asObservable();
    }

    //  --------------------------------------------------------------------------------------------------------------------//

    /**
     * @param file a single file object
     * @param type the type of file
     * @user profile
     * @description Uploading a single pdf file to aws without requiring permissions (example: passport, deans letter)
     * custom function that was originally stolen from stackoverflow for rxjs 5 but broken in 6, now AMOs own observable file upload
     * whata beauty! TYPE IS NOT FILE TYPE IT IS THE KEY OF THE FILE YOU ARE UPLOADING!!!
     */
    handleGeneralDocumentsUpload(file: File, type: string, user: any): Observable<any> {
        this.sendRumAction(type, file, 'start');

        const subject = new Subject<any>();
        const reader = new FileReader();

        this.progress.percent = 0;
        this.progress.status = 'uploading document...';
        try {
            reader.readAsDataURL(file);
            reader.addEventListener('load', (event: any) => {
                const filetype = String(file.type);
                const filenameString = String(file.name);
                const noSpaces = filenameString.replace(/ /g, '_');
                const fileName = noSpaces.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');
                const userCleanName = String(user.name.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, ''));
                const userSafeName = String(userCleanName.replace(/ /g, '_'));
                const fileExt = fileName.substr(fileName.lastIndexOf('.') + 1);

                try {
                    const xhr: XMLHttpRequest = new XMLHttpRequest();
                    //may get error for big file size in environment.DOCUMENT_UPLOAD_AWS, approximately more than 5mb or closely + or -
                    xhr.open('POST', `${environment.DOCUMENT_UPLOAD_AWS}/${user.id}/item_${type}_${userSafeName}.${fileExt}?ngsw-bypass=true`, true);
                    xhr.setRequestHeader('Content-Type', filetype);
                    xhr.withCredentials = false;

                    this.setXhrEvents(xhr, type, file, (e, response) => {
                        if (e) {
                            setTimeout(() => subject.next(e));
                        } else {
                            setTimeout(() => subject.next(response));
                        }
                    });

                    xhr.send(event.currentTarget.result);
                } catch (err) {
                    this.sendRumAction(type, file, 'error', { error: err });
                    setTimeout(() => subject.next(err));
                    return throwError(new Error(`handleGeneralDocumentsUpload Upload Error: ${err}`));
                }
            });
            reader.addEventListener('error', (err) => {
                setTimeout(() => subject.next(err));
                return throwError(new Error(`handleGeneralDocumentsUpload Upload Error: ${err}`));
            });
        } catch (err) {
            //any file related errors
            setTimeout(() => subject.next(err)); //any immediate error
            //subject.next(err);
            return throwError(new Error(`handleGeneralDocumentsUpload Upload Error: ${err}`));
        }
        return subject.asObservable();
    }

    /**
     * @description method that handles user profile image upload
     * @method POST
     * @param files - FileList, the specific property type not an array of File objects
     * @param userid - current users id
     */
    handleUserProfileImageUpload(
        // files: FileList,
        file: File,
        userid: string
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            //
            if (!userid) {
                reject('No user id found');
            }
            //
            //const tasks = Array.from(files).map(async file => {
            const filenameString = String(file.name);
            const filetype = String(file.type);
            const noSpaces = filenameString.replace(/ /g, '_');
            const fileName = noSpaces.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');
            // console.log(filenameString, filetype, noSpaces,fileName );

            this.http
                .get(`${environment.API}/users/upload?mimeType=${filetype}&userid=${userid}`)
                .toPromise()
                .then((resultPermissions) => {
                    try {
                        const s3Params = { ...resultPermissions };
                        const formData: FormData = new FormData();
                        const xhr: XMLHttpRequest = new XMLHttpRequest();

                        // eslint-disable-next-line max-len
                        formData.append('key', `userdocuments/${userid}/images/${fileName}`);
                        formData.append('acl', 'private');
                        formData.append('Content-Type', file.type !== '' ? file.type : 'application/octet-stream');
                        formData.append('AWSAccessKeyId', s3Params['AWSAccessKeyId']);
                        formData.append('success_action_status', '201');
                        formData.append('Policy', s3Params['s3Policy']);
                        formData.append('Signature', s3Params['s3Signature']);
                        formData.append('file', file, fileName);

                        xhr.open('POST', environment.DOCUMENT_S3_UPLOAD, true);

                        xhr.onreadystatechange = (e: any) => {
                            if (xhr.readyState === 4) {
                                this.convertXmlToJson(e.target.responseXML).then((xmlObject) => {
                                    const cleanETag = xmlObject['PostResponse'].ETag.replace(/[^\w\s]/gi, '');
                                    xmlObject['PostResponse'].ETag = cleanETag;
                                    resolve({
                                        success: true,
                                        parsedXML: {
                                            ...xmlObject['PostResponse'],
                                        },
                                    });
                                });
                            }
                        };

                        xhr.send(formData);
                    } catch (err) {
                        reject(`Additional Documents Upload Error: ${err.message}`);
                    }
                })
                .catch((err) => {
                    reject(`Additional Documents Upload Error: ${err.message}`);
                });
            //});
        });
    }

    // ---------------------------------------------------------------------------------------------------------------------------------//
    convertXmlToJson(xml: Document): Promise<any> {
        const xmlSerializer = new XMLSerializer();
        const xmlString = xmlSerializer.serializeToString(xml);
        return new Promise((resolve, reject) => {
            parseString(xmlString, (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    for (let key in result.PostResponse) {
                        result.PostResponse[key] = result.PostResponse[key][0];
                    }
                    resolve(result);
                }
            });
        });
    }
}
