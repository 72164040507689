import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Rotation from '@Interfaces/Rotation';
import { isNil } from '@app/shared/utils';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, flatMap, map, mergeMap, shareReplay, toArray } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ProgramService {
    setResetCatagoriesSubject: any = new BehaviorSubject<boolean>(false);

    rows: any[] = [];
    programAvailableDates: any = new BehaviorSubject<any>([]);

    private searchOffset: number = 0;
    private searchLimit: number = 25;

    getResetCatagoriesSubject = (): Observable<boolean> => this.setResetCatagoriesSubject.asObservable();

    setScrollProgramBundleSubject: any = new BehaviorSubject<boolean>(false);
    getScrollEventProgramBundleSubject = (): Observable<boolean> => this.setScrollProgramBundleSubject.asObservable();

    setScrollItinerarySubject: any = new BehaviorSubject<boolean>(false);
    getScrollEventItineraryBundleSubject = (): Observable<boolean> => this.setScrollItinerarySubject.asObservable();

    setSelectedProgramBundleIdSubject: any = new BehaviorSubject<string>('');
    getSelectedProgramBundleIdSubject = (): Observable<string> => this.setSelectedProgramBundleIdSubject.asObservable();

    setModalProgramBundleSubject: any = new BehaviorSubject<boolean>(false);
    getModalEventProgramBundleSubject = (): Observable<boolean> => this.setModalProgramBundleSubject.asObservable();

    setUserEmailSubject: any = new BehaviorSubject<string>('');
    getUserEmailSubject = (): Observable<string> => this.setUserEmailSubject.asObservable();

    constructor(private http: HttpClient) {}

    programShowDetail(program_id: string): Observable<any> {
        return this.http.get(`${environment.API}/programs/${program_id}/showprogramdetails`);
    }

    availableDates(program_id: string, fieldofstudy?: string): Observable<any> {
        return this.http.get(`${environment.API}/programs/${program_id}/currentavalability?ngsw-bypass=true`).pipe(
            flatMap((response: any) => {
                if (response && response.rows) {
                    this.rows = response.rows.map((date: any) => {
                        // formatting the price
                        const currencyHourlyPrice = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2,
                        }).format(date.hourlyprice);

                        const currencyRegPrice = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2,
                        }).format(date.price);

                        const pricingText =
                            fieldofstudy && fieldofstudy === 'Nurse Practitioner' ? `${currencyHourlyPrice} USD/hour` : `${currencyRegPrice} USD`;
                        //
                        const altSessionDisplay = fieldofstudy && fieldofstudy === 'Nurse Practitioner' ? date.session.split(' to')[0] : date.session;
                        if (date.available === 1) {
                            return {
                                display: altSessionDisplay.replace('-', ' ') + ' — 1 Seat available. — ' + pricingText,
                                value: date.selection,
                                price: date.price,
                                startdate: date.startdate,
                                session: altSessionDisplay,
                                enddate: date.enddate,
                                available: date.available,
                                hourlyprice: date.hourlyprice,
                            };
                        } else {
                            return {
                                display: altSessionDisplay.replace('-', ' ') + ' — ' + date.available + ' Seats available — ' + pricingText,
                                value: date.selection,
                                price: date.price,
                                session: altSessionDisplay,
                                startdate: date.startdate,
                                enddate: date.enddate,
                                available: date.available,
                                hourlyprice: date.hourlyprice,
                            };
                        }
                    });
                }
                this.programAvailableDates.next(this.rows);
                return of(this.rows);
            })
        );
    }

    onlyAvailableDates(): Observable<any> {
        return this.programAvailableDates;
    }

    shallowProgramDetails(program_id: string): Observable<any> {
        return this.http.get(`${environment.API}/programs/${program_id}/shallowprogramdetails`);
    }

    programShowDetailMgmt(program_id: string): Observable<any> {
        return this.http.get(`${environment.API}/programs/${program_id}/showprogramdetailsmgmt`);
    }

    showSuggestedPrograms(id: string): Observable<any> {
        return this.http.get(`${environment.API}/programs/${id}/suggested`);
    }

    showQuickSuggestedPrograms(id: string): Observable<any> {
        return this.http.get(`${environment.API}/programs/${id}/suggested`);
    }

    steveTypes = (programTypes: string[], types: string[]): boolean => (isNil(types) ? true : types.some((el: string) => programTypes.includes(el.trim())));

    steveSpecialty = (program: Rotation, specialties: string[]): boolean =>
        isNil(specialties) ? true : specialties.find((el: string) => program.specialty.toLowerCase() === el.toLowerCase()) !== undefined;

    steveSpecialties = (program: Rotation, specialties: string[]): boolean =>
        isNil(specialties) ? true : specialties.some((el: string) => program.specialties.includes(el));

    steveTags = (programtags: string[], tags: string[]): boolean => (isNil(tags) ? true : tags.some((tag: string) => programtags.includes(tag)));

    // steveDates = (program: Rotation, selectedDates: string[], yr?: string) => {
    //     if(isNil(selectedDates)) {
    //         return true;
    //     } else if (isNil(program.applicationCount)) {
    //         return true;
    //     } else {
    //         for (const date of selectedDates) {
    //             if(program.applicationCount[date]) {
    //                 return program.offered > program.applicationCount[date] ? true : false;
    //             }
    //         }
    //     }
    // }

    steveState = (city: string, state: string, locations: string[]): boolean => {
        return isNil(locations) ? true : locations.some((s: string) => s.toLowerCase().trim() === state.toLowerCase().trim());
    };

    // Program is the current rotation being analyzed
    // Ref is an array of city strings the user has selected
    // Geo is an array of objects with city strings and that cities geoloc
    distanceFilter = (program: any, ref: string[], geo: any[]): boolean => {
        try {
            const geoMap = geo.map((ge: string) => ge).filter((f: any) => ref.includes(f.location));

            const g: any = geoMap[0];

            if (g.geoloc === '' || g.geoloc === ' ' || g.geoloc === null || g.geoloc === undefined) {
                // console.log('admin set bad geo location value: ' , geoMap);
                g.geoloc = '41.881832, -87.623177';
                geoMap[0] = g;
            }

            // map over geoMap and return an array of booleans for each city distance compared to this program
            const geoMapBool = geoMap.map((gg: any) => {
                const geoLocs = gg.geoloc.split(',');
                // console.log(geoLocs);
                const km = this.getDistanceFromLatLonInKm(geoLocs[0], geoLocs[1], program.geoloc[0], program.geoloc[1]);
                // console.log(km);
                if (km < 64) {
                    // console.log('program location within distance: ', program.city);
                }
                return km < 64;
            });
            // console.log('GEOMAPBOOL: ', geoMapBool);
            return geoMapBool.includes(true);
        } catch (e) {
            // console.log(e);
            return true;
        }
    };

    collectReferences = (locations: any) => locations.filter((l: any) => l.indexOf(',') !== -1).map((c: string) => c.trim().replace(/ /g, '').toLowerCase());

    // haversine formula
    // https://en.wikipedia.org/wiki/Haversine_formula

    getDistanceFromLatLonInKm = (lat1: any, lon1: any, lat2: any, lon2: any) => {
        const R = 6371; // Radius of the earth in km
        const dLat = this.deg2rad(lat2 - lat1); // deg2rad below
        const dLon = this.deg2rad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c; // Distance in km
        return d;
    };

    deg2rad = (deg: any): number => deg * (Math.PI / 180);

    queryParamGenerator = (filters: object): object => {
        const x = {};
        Object.keys(filters).forEach((key: any) => {
            if (filters[key].length === 0) {
                delete x[key];
            } else {
                if (key === 'category') {
                    return;
                } else if (key === 'locations') {
                    x[key] = filters[key].map((item: any) => item.value).join('-');
                } else if (key === 'cities') {
                    x[key] = filters[key].map((item: any) => item.value).join('-');
                } else if (key === 'states') {
                    x[key] = filters[key].map((item: any) => item.value).join('-');
                } else if (key === 'dates') {
                    x[key] = filters[key].map((item: any) => item.value).join(',');
                } else {
                    x[key] = filters[key].map((item: any) => item.value).join();
                }
            }
        });
        return x;
    };

    toggleFavorite(userid: string, programid: string): Observable<any> {
        return this.http.get(`${environment.API}/favorites/togglefavorite?userid=` + userid + `&programid=` + programid);
    }

    favoritesList(): Observable<any> {
        return this.http.get(`${environment.API}/favorites/myfavoriteslist`);
    }

    programBundleShow(programid: string): Observable<any> {
        return this.http.get(`${environment.API}/programbundles/${programid}/traineeshow`);
    }

    /**
     * @description get the latest search offset value
     */
    get searchOffsetValue() {
        return this.searchOffset;
    }

    /**
     * @description increase the search offset value
     */
    set increaseSearchOffsetValue(offsetIncrease: number) {
        this.searchOffset += offsetIncrease;
    }

    /**
     * @description increase the search offset value
     */
    set resetSearchOffsetValue(offsetReset: number) {
        this.searchOffset = offsetReset;
    }
}
