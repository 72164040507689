<ion-grid fixed class="ion-no-padding time-zones">
    <ion-row class="modal-topbar">
        <ion-col>
            <ion-item color="--ion-color-white" lines="none">
                <ion-label
                    class="section-title modal-title ion-text-center"
                    color="dark-teal"
                >
                    Online Time Zone Converter
                </ion-label>
                <ion-icon
                    name="close"
                    slot="end"
                    tappable
                    (click)="dismiss()"
                ></ion-icon>
            </ion-item>
        </ion-col>
    </ion-row>

    <ion-row class="zone-header">
        <ion-col size="6">
            <div>
                <ion-label position="stacked"></ion-label>
                <ion-select
                    class="zones"
                    cancelText="Dismiss"
                    okText="Ok"
                    (ionChange)="countryZoneSelect()"
                    [(ngModel)]="sourceSelect"
                    [interfaceOptions]="customDropDownList"
                    interface="alert"
                >
                    <ion-select-option
                        *ngFor="let zone of countryZones"
                        value="{{ zone.key }}"
                    >
                        {{ zone.name }}
                    </ion-select-option>
                </ion-select>
                <p class="time-now">
                    {{ zoneClock?.source | date: 'h:mm a EEEE, MMMM d y' }}
                </p>
            </div>
        </ion-col>
        <ion-col size="6">
            <div>
                <ion-label position="stacked"></ion-label>
                <ion-select
                    class="zones"
                    cancelText="Dismiss"
                    okText="Ok"
                    (ionChange)="countryZoneSelect()"
                    [(ngModel)]="targetSelect"
                    [interfaceOptions]="customDropDownList"
                    interface="alert"
                >
                    <ion-select-option
                        *ngFor="let zone of countryZones"
                        value="{{ zone.key }}"
                    >
                        {{ zone.name }}
                    </ion-select-option>
                </ion-select>
                <p class="time-now">
                    {{ zoneClock?.target | date: 'h:mm a EEEE, MMMM d y' }}
                </p>
            </div>
        </ion-col>
    </ion-row>

    <ion-content class="time-zones">
        <div class="line"></div>
        <ion-grid fixed class="ion-no-padding">
            <ion-row *ngFor="let c of clocks">
                <ion-col size="6">
                    <p>{{ c.clock | dayZone }}</p>
                </ion-col>
                <ion-col size="6">
                    <p>
                        {{ c.equivalent | dayZone }}
                        <ion-badge color="primary" *ngIf="c.dayDiff != 0">
                            {{ c.dayDiff > 0 ? '+' : '' }}{{ c.dayDiff }} day
                        </ion-badge>
                    </p>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-content>
</ion-grid>
