import { isNil } from './tools';

// check that additional documents have been submitted
export const reqDocsCheck = (required: any[], submitted?: any[]): boolean => {
    if (
        required.length === submitted.length ||
        submitted.length > required.length
    ) {
        return true;
    } else {
        return false;
    }
};

// check if user has uploaded their visa information (request letter modal)
export const visaEnroll = (user: any): boolean => {
    return ['United States', 'Canada'].includes(user.passportissuecountry)
        ? true
        : // : user.hasvisa === true
        // ? true
        isNil(user.item7name) === false
        ? true
        : isNil(user.passportissuedate) === true
        ? false
        : isNil(user.passportexpiredate) === true
        ? false
        : isNil(user.passportnumber) === true
        ? false
        : isNil(user.passportissuecountry) === true
        ? false
        : true;
};

export const enrollable = (user: any, application: any): boolean => {
    return application.states !== 'Reserved'
        ? false
        : isNil(application.signedcontract)
        ? false
        : application.madedeposit === false &&
          application.madefullpayment === false
        ? false
        : application.madepayment === false &&
          application.madefullpayment === false
        ? false
        : isNil(user.item1name) === true
        ? false
        : user.item1name.indexOf('Temporary') !== -1
        ? false
        : isNil(user.item2name) === true
        ? false
        : isNil(user.item4name) === true
        ? false
        : isNil(user.item7name) === true
        ? false
        : isNil(user.item10name) === true
        ? false
        : isNil(application.housingtype) === true
        ? false
        : isNil(application.housingaddress) === true
        ? false
        : true;
};

export const validCv = (s: string): boolean => {
    return isNil(s) ? false : s.indexOf('_Temporary') !== -1 ? false : true;
};

export const notifications = async (application: any) => {
    // console.log('notifications generator');
    // console.log('generating notification for application: ', application);
};

export const hasHousing = (application: any): boolean => {
    return (
        isNil(application.housingtype) === false &&
        isNil(application.housingaddress) === false &&
        application.housingaddress.length !== 0 &&
        application.hashousing
    );
};
