import { datadogRum } from '@datadog/browser-rum';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { Store } from '@ngrx/store';
import { State } from '@app/core/ngrx/reducers';
import { userSelectors } from '@app/core/ngrx/selectors';

@Injectable({
    providedIn: 'root',
})
export class SendEventActionsService {
    USER_ID: string;
    USER_ID$ = this.store.select(userSelectors.getUserID);

    constructor(private angulartics2GoogleTagManager: Angulartics2GoogleTagManager, private userService: UserService, private store: Store<State>) {}

    /**
     *
     * @param object - { pageview: String, eventAction: String, eventCategory: String, eventValue: String, ddAction: String, customDimensions: Object }
     */
    async sendEventActions(object: any) {
        const slugify = (str) =>
            str
                .toLowerCase()
                .trim()
                .replace(/[^\w\s-]/g, '')
                .replace(/[\s_-]+/g, '-')
                .replace(/^-+|-+$/g, '');

        this.USER_ID$.subscribe((userid: string) => (this.USER_ID = userid));

        if (object.pageview) {
            if (object.customDimensions) {
                this.angulartics2GoogleTagManager.pushLayer(object.customDimensions);
            }

            setTimeout(() => {
                this.angulartics2GoogleTagManager.pageTrack(object.pageview);
            }, 1000);
        }

        // send Analytics (Angulartics) event
        // only action is required, but send category, label, and value if available
        if (object.eventAction) {
            if (object.customDimensions) {
                this.angulartics2GoogleTagManager.pushLayer(object.customDimensions);

                setTimeout(() => {
                    this.angulartics2GoogleTagManager.eventTrack(object.eventAction, {
                        category: object.eventCategory,
                        label: object.eventLabel,
                        value: object.eventValue,
                    });
                }, 1000);
            } else {
                setTimeout(() => {
                    this.angulartics2GoogleTagManager.eventTrack(object.eventAction, {
                        category: object.eventCategory,
                        label: object.eventLabel,
                        value: object.eventValue,
                    });
                }, 1000);
            }
        }

        // send DataDog RUM action
        if (object.ddAction) {
            object.ddContext = object.ddContext || {};
            object.ddContext.postInitTime = performance.now() - object.stopwatchStart;
            if (object.profile) {
                object.ddContext.id = object.profile.user ? object.profile.user.id : null;
                object.ddContext.name = object.profile.user ? object.profile.user.name : null;
                object.ddContext.user = object.profile.user;
            }

            setTimeout(() => {
                datadogRum.addUserAction(object.ddAction, object.ddContext);
            }, 1000);
        }
    }
}
