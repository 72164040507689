<ion-row *ngIf="showTeleMedicine === true" class="ion-justify-content-center">
    <ion-col size="12" class="ion-text-center">
        <ion-text>
            <ion-button id="teleMedicineCta" color="secondary" fill="clear" (click)="openTeleMedicineModal()">
                <ion-text class="ion-text-wrap underline">Telemedicine may be included in your experience.</ion-text>
                <ion-icon name="arrow-forward"></ion-icon>
            </ion-button>
        </ion-text>
    </ion-col>
</ion-row>
<ion-row *ngIf="showPreMed === true" class="ion-justify-content-center">
    <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="5" size-xl="5">
        <ion-text class="ion-text-center">
            <h2>What is Pre-Med Shadowing?</h2>
        </ion-text>
        <ion-card>
            <ion-text color="black" class="ion-text-left">
                <p class="text-content">
                    As a Pre-Med student, your rotation will consist of <span class="bold">shadowing with no direct patient contact.</span> The program
                    activities below are for medical students and graduate trainees only.
                </p>
                <p class="text-content">Letters of evaluation/recommendation are offered by all Pre-Med Shadowing preceptors.</p>
            </ion-text>
        </ion-card>
    </ion-col>
</ion-row>
<ion-row *ngIf="showFlexible === true" class="ion-justify-content-center">
    <ion-col size="6" size-xs="12" size-sm="12" size-md="6" size-lg="5" size-xl="5">
        <ion-text class="ion-text-center">
            <h2>Flexible Virtual Rotation</h2>
        </ion-text>
        <ion-card>
            <ion-text color="black" class="ion-text-left">
                <p class="text-content">
                    Based on your preference, this experience has a flexible format. The flexible format takes place during the preceptor's scheduled hours, and
                    you can choose when and how often you want to attend the experience.
                    <span class="bold">You can also choose to attend this experience for the full scheduled time.</span>
                </p>
                <p class="text-content">
                    To apply for this flexible experience, first submit your application and then send an email to
                    <a href="mailto:advisors@amopportunities.org?subject=I am interested in a flexible experience.">advisors&#64;amopportunities.org</a> with
                    the hours and days you would like to attend.
                </p>
            </ion-text>
        </ion-card>
    </ion-col>
</ion-row>
<ion-grid>
    <ion-row class="ion-justify-content-center">
        <ion-col size="12" size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="12">
            <ion-list class="program-details-list" color="light-teal">
                <ion-item class="program-details-tab" color="light-teal" lines="none">
                    <ion-label class="section-title" color="dark-teal">
                        <h3 style="font-size: 1.1em; text-align: center">Details</h3>
                    </ion-label>
                </ion-item>
                <div>
                    <app-program-details-card [program]="program"></app-program-details-card>
                </div>
            </ion-list>
        </ion-col>
    </ion-row>
</ion-grid>
<ion-grid class="summary-grid">
    <div class="details-row">
        <div class="schedule-tab">
            <ion-item class="program-schedule-tab" color="light-teal" lines="none" detail="false">
                <ion-label class="section-title" color="dark-teal">
                    <h3 style="text-align: center">Schedule</h3>
                </ion-label>
            </ion-item>
            <div>
                <app-program-schedule [unformattedSchedule]="program?.schedule"></app-program-schedule>
            </div>
        </div>
        <div class="map-tab">
            <ion-item class="program-schedule-tab" color="light-teal" lines="none">
                <ion-label class="section-title" color="dark-teal">
                    <h3 style="text-align: center">Location</h3>
                </ion-label>
            </ion-item>
            <ion-card class="mapContainer">
                <div [ngClass]="{ 'map-canvas': program?.affiliatedsites.length > 0, 'tall-map-canvas': program?.affiliatedsites.length === 0 }">
                    <google-map #locationMap="googleMap" height="100%" width="100%" [options]="mapOptions">
                        <map-advanced-marker
                            #advancedMarker="mapAdvancedMarker"
                            [position]="primaryCoordinate.position"
                            [content]="primaryCoordinate.icon"
                            [title]="primaryCoordinate.title"
                            (mapClick)="onMarkerClick(advancedMarker)"
                        ></map-advanced-marker>
                        <ng-container *ngFor="let affiliatedCoordinate of affiliatedCoordinates">
                            <map-advanced-marker
                                #advancedMarker="mapAdvancedMarker"
                                [position]="affiliatedCoordinate.position"
                                [content]="affiliatedCoordinate.icon"
                                [title]="affiliatedCoordinate.title"
                                (mapClick)="onMarkerClick(advancedMarker)"
                            >
                            </map-advanced-marker>
                        </ng-container>
                        <map-info-window></map-info-window>
                    </google-map>
                    <div class="pin-legend ion-text-wrap">
                        <p class="program-sites"><ion-icon name="pin" color="primary"></ion-icon>{{ program?.programlocation | truncate : [65, '...'] }}</p>
                        <div *ngIf="program?.affiliatedsites.length > 0">
                            <ng-container *ngFor="let location of program?.affiliatedsites">
                                <p class="program-sites" *ngIf="location.reporting == true">
                                    <ion-icon name="pin" color="secondary"></ion-icon>
                                    {{ location?.name | truncate : [65, '...'] }}
                                </p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ion-card>
        </div>
    </div>
</ion-grid>
