<div class="itinerary-menu-content">
    <ion-list>
        <ion-list-header class="ion-text-center">
            <ion-label class="ion-text-center" class="menu-title">
                Clinical Experiences Itinerary
            </ion-label>
        </ion-list-header>

        <!-- <ion-list-header class="ion-text-center">
            <ion-text class="ion-text-center">
                <a>What is my Itinerary?</a>
            </ion-text>
        </ion-list-header> -->

        <div class="ion-text-center">
            <ion-button
                (click)="whatIsItinerary()"
                class="iti-info-btn"
                fill="clear"
            >
                What is my Itinerary
                <fa-icon
                    style="margin-left: 0.2em; vertical-align: middle;"
                    [icon]="icons?.faQuestionCircle"
                ></fa-icon>
            </ion-button>

            <!-- <ion-list>
                <ion-item>
                    <ion-col class="ion-text-center">
                        <div class="button-row">
                            <ion-col
                                *ngIf="itineraryList$ | async as selections"
                            >
                                <ion-button
                                    class="ion-text-wrap"
                                    style="max-width: 100%;"
                                    color="primary"
                                    [disabled]="disableItinerarySubmit"
                                    size="large"
                                    (click)="submitItinerary()"
                                >
                                    <ion-text
                                        >Apply Now ({{
                                            selections?.length
                                        }})</ion-text
                                    >
                                </ion-button>
                            </ion-col>
                        </div>
                    </ion-col>
                </ion-item>
            </ion-list> -->
        </div>

        <div *ngIf="itineraryList$ | async as selections">
            <div
                *ngFor="
                    let selection of selections;
                    let i = index;
                    trackBy: trackByIndex
                "
                class="iti-container"
            >
                <app-itinerary-item
                    [selection]="selection"
                ></app-itinerary-item>
            </div>
            <div *ngIf="selections.length == 0" class="ion-text-center">
                <div class="no-iti-found">
                    <ion-icon class="not-found-icon" name="alert"></ion-icon>
                    <p>
                        <span>No programs saved for an itinerary.</span>
                    </p>
                </div>
            </div>
        </div>
    </ion-list>
</div>
