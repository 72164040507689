import { NgModule } from '@angular/core';
import { ImgPipe } from './img/img.pipe';
import { NumTimesPipe } from './num-times/num-times.pipe';
import { ReadablePipe } from './readable/readable.pipe';
import { SafePipe } from './safe/safe.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';
import { SplitPricePipe } from './split-price/split-price.pipe';
import { FutureStatusPipe } from './future-status/future-status.pipe';
import { DayZonePipe } from './day-zone/day-zone.pipe';
import { NumberToWordsPipe } from './numberToWords/number-to-words.pipe';
import { DecodeFileUrlPipe } from './decode-file-url/decode-file-url.pipe';
import { ChecklistPipe } from './checklist/checklist.pipe';


@NgModule({
    declarations: [
        NumTimesPipe,
        SafePipe,
        ImgPipe,
        TruncatePipe,
        ReadablePipe,
        SplitPricePipe,
        FutureStatusPipe,
        DayZonePipe,
        NumberToWordsPipe,
        DecodeFileUrlPipe,
        ChecklistPipe
    ],
    imports: [],
    exports: [
        NumTimesPipe,
        SafePipe,
        ImgPipe,
        TruncatePipe,
        ReadablePipe,
        SplitPricePipe,
        FutureStatusPipe,
        DayZonePipe,
        NumberToWordsPipe,
        DecodeFileUrlPipe,
        ChecklistPipe
    ],
})
export class PipesComponentsModule {}
