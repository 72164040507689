import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MutationService } from '@app/core/graphql/mutation/mutation.service';
import { QueryService } from '@app/core/graphql/query/query.service';
import { FormService } from '@app/core/services/form/form.service';
import { ToolService } from '@app/core/services/tool/tool.service';
import { WarningService } from '@app/core/services/warning/warning.service';
import { faHome } from '@fortawesome/pro-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-housing',
    templateUrl: './application-housing.component.html',
    styleUrls: ['./application-housing.component.scss'],
})
export class ApplicationHousingComponent implements OnInit {
    @Input() applicationId: string;
    @Input() applicationName?: string;
    updated = false;
    usStateList: string[];
    userid: string;
    subscription = this.formService
        .housingForm()
        .subscribe((form: FormGroup) => (this.housing_form = form));
    housing_form: FormGroup;

    icons: any = {
        home: faHome,
    };

    constructor(
        public toolService: ToolService,
        private modalController: ModalController,
        private queryService: QueryService,
        private mutationService: MutationService,
        private formService: FormService,
        private warning: WarningService
    ) {}

    ngOnInit() {
        this.usStateList = this.getStatesArray();

        this.queryService
            .housing(this.applicationId)
            .subscribe((response: any) => {
                if (response.data.user.application.hashousing) {
                    const values =
                        response.data.user.application.housingaddress[0];
                    this.housing_form.patchValue({
                        address: values.address,
                        state: values.state,
                        city: values.city,
                        postal: values.postal,
                        stayingwith: response.data.user.application.housingtype,
                        airbnblink:
                            response.data.user.application.airbnblink || '',
                    });
                }
            });
    }

    getStatesArray() {
        return Array.from(this.toolService.getUSAStatesArray());
    }

    // onst result = this.formService.invalidFields(this.profile_form);
    //         const missingFields = result.map((field: any) => field.control).join(', ');
    //         this.warning.create(missingFields, 'Profile is missing the following fields:');

    submitForm = (): void => {
        // if house form is invalid throw an error
        if (!this.housing_form.valid) {
            const result = this.formService.invalidFields(this.housing_form);
            const missingFields = result
                .map((field: any) => field.control)
                .join(', ');
            this.warning.create(
                missingFields,
                'Housing form is missing the following fields:'
            );
        } else {
            // create json object from just the address
            const addressJson = [
                {
                    address: this.housing_form.value.address,
                    city: this.housing_form.value.city,
                    state: this.housing_form.value.state,
                    postal: this.housing_form.value.postal,
                },
            ];

            // create update object
            const data = {
                housingaddress: JSON.stringify(addressJson),
                housingtype: this.housing_form.value.stayingwith,
                airbnblink: this.housing_form.value.airbnblink,
                hashousing: true,
            };

            // make mutation request to update application
            this.mutationService
                .updateHousing(data, this.applicationId)
                .subscribe((response: any) => {
                    if (response.data.updateApplication.success) {
                        this.updated = true;
                        this.dismiss(data);
                    }
                });
        }
    };

    dismiss(data?: any) {
        this.modalController.dismiss({
            update: this.updated,
            form: data,
        });
    }
}
