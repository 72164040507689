<div>
  <ion-toolbar color="--ion-color-light-teal-2" class="ion-text-center">
    <ion-buttons slot="start">
      <ion-button>
      </ion-button>
    </ion-buttons>
    <ion-title  class="ion-text-center">
      <div class="amoLogoOffsetLeft">
        <ion-img class="amoLogo" src="assets/icons/logo_orange.png"></ion-img>
      </div>
    </ion-title>
    <!-- <img class="amoLogo modal-logo" src="assets/icons/logo_orange.png" /> -->
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-card-content>
    <div class="ion-text-center title">
      <h2>
        {{title}}
      </h2>
    </div>
    <div class="top-message ion-text-left">
      {{topMessage}}
    </div>
    <br>
    <div class="ion-text-left bottom-message" [innerHTML]="bottomMessage | safe: 'html'">
      <!-- {{bottomMessage}} -->
    </div>
    <div class="modal-button-container ion-text-center">
      <buttons-primary-mobile text="Got it" (click)="close()" *ngIf="task !== 'tempUser'"></buttons-primary-mobile>

      <!-- <ion-button color="primary" class="half-button" (click)="close()" *ngIf="task !== 'tempUser'">
        Got it
      </ion-button> -->
      <div *ngIf="task === 'tempUser'">
        <ion-button color="secondary" class="small-button" (click)="toLogin()">
          Log in
        </ion-button>
        <ion-button color="primary" class="small-button" (click)="toSignUp()">
          Signup
        </ion-button>
      </div>
    </div>
  </ion-card-content>
</div>