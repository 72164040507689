<div class="parent">
    <ion-img class="amoLogo" src="assets/icons/logo_orange.png"></ion-img>
    <div class="title bold-text">
        Thank You
    </div>
    <div [ngSwitch]="type">
        <div *ngSwitchCase="'wire'" class="message">
            Wire Transfer Request Received.<br />Please check your email
        </div>
        <div *ngSwitchCase="'credit' || 'malpractice'" class="message">
            Your payment was successful
        </div>
        <div *ngSwitchCase="'booking'" class="message">
            Your card has been authorized
        </div>
        <div *ngSwitchDefault class="message">
            Your payment was successful
        </div>
    </div>
    <div>
        <fa-icon
            class="icon-size"
            slot="start"
            [icon]="faCheckCircle"
        ></fa-icon>
    </div>
    <div class="confirm-button">
        <ion-button color="primary" (click)="close()" class="half-button">
            Got it!
        </ion-button>
    </div>
</div>
