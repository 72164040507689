import { createSelector } from '@ngrx/store';
import { State } from '../reducers';

export const selectSearchState = (state: State) => state.search;

// is this user authenticated
export const isSearchFilters = createSelector(
    selectSearchState,
    search => search.filters
);

// is this user authenticated
export const getSearchContext = createSelector(
    selectSearchState,
    search => search.context
);

// is this user authenticated
export const getSearchLoading = createSelector(
    selectSearchState,
    search => search.loading
);

// is this user authenticated
export const getShowSuggested = createSelector(
    selectSearchState,
    search => search.suggested
);

// is this user authenticated
export const getSuggestedText = createSelector(
    selectSearchState,
    search => search.text
);

// is this user authenticated
export const getPriceDrop = createSelector(
    selectSearchState,
    search => search.pricedrop
);

// show program bundles on search page
export const getProgramBundle = createSelector(
    selectSearchState,
    search => search.showBundles
);
// get list of programs for the map
export const getProgramMapList = createSelector(
    selectSearchState,
    search => search.programMapList
);

// get list of programs for the map
export const getProgramBundleMapDisabled = createSelector(
    selectSearchState,
    search => search.programBundleMapDisabled
);
