<div>
  <ion-toolbar color="--ion-color-light-teal-2" class="ion-text-center">
    <ion-buttons slot="start">
      <ion-button>
      </ion-button>
    </ion-buttons>
    <ion-img class="amoLogo modal-logo logo-help" [src]="'assets/icons/logo_orange.png'"></ion-img>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-card-content class="ion-text-center ion-text-wrap">
    <ion-list>
      <ion-item lines="none">
        <ion-label *ngIf="!(hasReserved$ | async)" class="ion-text-wrap">
          <fa-icon [icon]="icons.envelope" class="fa-line-padding"></fa-icon><a
            href="mailto:advisors@amopportunities.org">{{item1}}
          </a>
        </ion-label>
        <ion-label *ngIf="(hasReserved$ | async)" class="ion-text-wrap">
          <fa-icon [icon]="icons.envelope" class="fa-line-padding"></fa-icon><a
            href="mailto:success@amopportunities.org">{{successEmail}}
          </a>
        </ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-label >
          <fa-icon [icon]="icons.phone" class="fa-line-padding"></fa-icon>{{item2}}
        </ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-label class="whatsapp">
          <fa-icon [icon]="icons.whatsApp" class="fa-line-padding"></fa-icon>WhatsApp: {{whatsAppNumber}}
        </ion-label>
      </ion-item>
      <ion-item lines="none" button (click)="toFAQ()">
        <ion-label>
          <fa-icon [icon]="icons.question" class="fa-line-padding"></fa-icon>{{item3}}
        </ion-label>
      </ion-item>
      <ion-item lines="none" button [routerLink]="['/chat']">
        <ion-label>
          <fa-icon [icon]="icons.comment" class="fa-line-padding"></fa-icon>{{item4}}
        </ion-label>
      </ion-item>
    </ion-list>
    <div class="modal-button-container ion-text-center ion-text-wrap">
      <ion-button color="primary" class="half-button" (click)="close()">
        Got it
      </ion-button>
    </div>
  </ion-card-content>
</div>