import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { RequirementItems } from '@app/core/interfaces/RequirementChecklist';

@Injectable({
    providedIn: 'root',
})
export class MutationService {
    constructor(private apollo: Apollo) {}

    enrollmentChecker = (applicationId: string): Observable<any> => {
        return this.apollo.mutate({
            mutation: gql`
                mutation enrollmentChecker($id: String) {
                    enrollmentChecker(id: $id)
                }
            `,
            variables: {
                id: applicationId,
            },
            fetchPolicy: 'no-cache',
        });
    };

    chainedEnrollmentChecker = (applicationId: string): Observable<any> => {
        return this.apollo.mutate({
            mutation: gql`
                mutation chainedEnrollmentChecker($id: String) {
                    chainedEnrollmentChecker(id: $id)
                }
            `,
            variables: {
                id: applicationId,
            },
            fetchPolicy: 'no-cache',
        });
    };

    activateAccount = (token: string): Observable<any> => {
        return this.apollo.mutate({
            mutation: gql`
                mutation activateUser($token: String!) {
                    activateUser(token: $token)
                }
            `,
            variables: {
                token,
            },
        });
    };

    sendWelcomeEmail = (email: string): Observable<any> => {
        return this.apollo.mutate({
            mutation: gql`
                mutation sendWelcomeEmail($email: String!) {
                    sendWelcomeEmail(email: $email)
                }
            `,
            variables: {
                email,
            },
        });
    };

    findSchool = (): any => {
        return gql`
            mutation findSchool($value: String!) {
                findSchool(value: $value)
            }
        `;
    };

    findSingleSchool = (): any => {
        return gql`
            mutation findSingleSchool($value: String!) {
                findSingleSchool(value: $value)
            }
        `;
    };

    findCountry = (): any => {
        return gql`
            mutation findCountry($value: String!) {
                findCountry(value: $value)
            }
        `;
    };

    updateProfile = (data: any): Observable<any> => {
        return this.apollo.mutate({
            mutation: gql`
                mutation updateProfile($profile: JSON!) {
                    updateProfile(profile: $profile)
                }
            `,
            variables: {
                profile: data,
            },
        });
    };

    updateProfilePersonalInfo = (data: any): Observable<any> => {
        return this.apollo.mutate({
            mutation: gql`
                mutation updateProfilePersonalInfo($profile: JSON!) {
                    updateProfilePersonalInfo(profile: $profile)
                }
            `,
            variables: {
                profile: data,
            },
        });
    };

    updateProfileEducation = (data: any): Observable<any> => {
        return this.apollo.mutate({
            mutation: gql`
                mutation updateProfileEducation($profile: JSON!) {
                    updateProfileEducation(profile: $profile)
                }
            `,
            variables: {
                profile: data,
            },
        });
    };

    updateProfileTravelReqs = (data: any): Observable<any> => {
        return this.apollo.mutate({
            mutation: gql`
                mutation updateProfileTravelReqs($profile: JSON!) {
                    updateProfileTravelReqs(profile: $profile)
                }
            `,
            variables: {
                profile: data,
            },
        });
    };

    updateProfilePreferences = (data: any): Observable<any> => {
        return this.apollo.mutate({
            mutation: gql`
                mutation updateProfilePreferences($profile: JSON!) {
                    updateProfilePreferences(profile: $profile)
                }
            `,
            variables: {
                profile: data,
            },
        });
    };

    easyUpdateProfile = (data: any, userId: string): Observable<any> => {
        return this.apollo.mutate({
            mutation: gql`
                mutation easyUpdateProfile($id: ID!, $profile: JSON!) {
                    easyUpdateProfile(profile: $profile, id: $id)
                }
            `,
            variables: {
                profile: data,
                id: userId,
            },
        });
    };

    toggleFavorite = (): any => {
        return gql`
            mutation toggleFavorite($programId: ID!) {
                toggleFavorite(programId: $programId)
            }
        `;
    };

    updateHousing = (housing: any, applicationId: string): Observable<any> => {
        return this.apollo.mutate({
            mutation: gql`
                mutation updateApplication($id: ID!, $data: JSON!) {
                    updateApplication(id: $id, data: $data)
                }
            `,
            variables: {
                data: housing,
                id: applicationId,
            },
        });
    };

    updatePassport = (): any => {
        return gql`
            mutation updateApplication($id: ID!, $data: JSON!) {
                updateApplication(id: $id, data: $data)
            }
        `;
    };

    updateMap = (data: any): Observable<any> => {
        return this.apollo.mutate({
            mutation: gql`
                mutation searchMapContent($data: JSON) {
                    searchMapContent(data: $data)
                }
            `,
            variables: {
                data,
            },
        });
    };

    preDepartureRegistration = (data: any): Observable<any> => {
        return this.apollo.mutate({
            mutation: gql`
                mutation preDepartureRegistration($data: JSON) {
                    preDepartureRegistration(data: $data)
                }
            `,
            variables: {
                data,
            },
        });
    };

    preEnrollmentRegistration = (data: any): Observable<any> => {
        return this.apollo.mutate({
            mutation: gql`
                mutation preEnrollmentRegistration($data: JSON) {
                    preEnrollmentRegistration(data: $data)
                }
            `,
            variables: {
                data,
            },
        });
    };

    identifyRegistrationOptions = (data: any): Observable<any> => {
        return this.apollo.mutate({
            mutation: gql`
                mutation identifyRegistrationOptions($data: JSON) {
                    identifyRegistrationOptions(data: $data)
                }
            `,
            variables: {
                data,
            },
        });
    };

    getOrientationSchedule = (data: any): Observable<any> => {
        return this.apollo.mutate({
            mutation: gql`
                mutation getOrientationSchedule($data: JSON) {
                    getOrientationSchedule(data: $data)
                }
            `,
            variables: {
                data,
            },
            fetchPolicy: 'no-cache',
        });
    };

    processAffiliateInvite = (): Observable<any> => {
        const data = {};

        return this.apollo.mutate({
            mutation: gql`
                mutation processConfirmedAffiliateInvite($data: JSON!) {
                    processConfirmedAffiliateInvite(data: $data)
                }
            `,
            variables: {
                data,
            },
        });
    };

    updateRequirementStatus = (applicationid: string, requirement: RequirementItems): Observable<any> => {
        const payload = { applicationid: applicationid, checklistItem: requirement };
        console.log(payload);

        return this.apollo.mutate({
            mutation: gql`
                mutation updateRequirementChecklist($payload: JSON!) {
                    updateRequirementChecklist(data: $payload)
                }
            `,
            variables: {
                payload,
            },
        });
    };
}
