import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, AbstractControl, Validators } from '@angular/forms';
import { isNil } from '@app/shared/utils';
import { from, Observable, of } from 'rxjs';
import moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class FormService {
    public static matchValues(
        matchTo: string // name of the control to match to
    ): (AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): ValidationErrors | null => {
            return !!control.parent && !!control.parent.value && control.value === control.parent.controls[matchTo].value ? null : { isMatching: false };
        };
    }

    public emailRegex =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;

    // match the form control name with a formatted response
    private formInputResponses = {
        name: 'Full Name',
        birthday: 'Birthday',
        gender: 'Gender',
        address: 'Address',
        city: 'City',
        state: 'State',
        postalcode: 'Postal Code',
        postal: 'Postal Code',
        status: 'Status',
        fieldofstudy: 'Field of Study',
        fieldofstudyid: 'Field of Study',
        yearsattended: 'Years Attended',
        usmle: 'USMLE Status',
        hasvisa: 'Visa Status',
        acquisition: 'How you found us',
        phone: 'Mobile Phone Number',
        altphone: 'Secondary Phone Number',
        preflocation: 'Preferred Locations',
        prefmonth: 'Preferred Months',
        prefspecialty: 'Preferred Specialties',
        passportnumber: 'Passport Number',
        passportissuecountry: 'Passport Issuing Country',
        passportissuedate: 'Passport Issue Date',
        passportexpiredate: 'Passport Expire Date',
        country: 'Country of Residence',
        countryofbirth: 'Country of Birth',
        universitycountry: 'University Location',
        university: 'University',
        statement: 'Personal Statement',
        stayingwith: 'Who are you staying with?',
        // payment form
        address_line1: 'Address',
        address_city: 'City',
        address_state: 'State/Province',
        email: 'Email',
    };

    fullNameValidator = (control: FormControl) => {
        const fullname = control.value;
        if (fullname && fullname.trim().indexOf(' ') === -1) {
            return {
                fullNameValidator: {
                    valid: false,
                },
            };
        } else {
            return null;
        }
    };

    isNotEnglish = (control: FormControl) => {
        const value = control.value;
        var uniCodeNonEnglish = /[^\u0000-\u007F]+/g;
        var isNotEnglish = uniCodeNonEnglish[Symbol.match](value);

        if (value && isNotEnglish) {
            return {
                isNotEnglish: {
                    valid: false,
                },
            };
        } else {
            return null;
        }
    };

    // isValidPhone = (control: FormControl) => {
    //     const value = control.value;
    //     var isValidPhoneRX = '^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$';
    //     var isValidPhone = isValidPhoneRX[Symbol.match](value);

    //     if(value && isValidPhone ){
    //         return null;
    //     } else {
    //         return {
    //             isValidPhone: {
    //                 valid: false
    //             }
    //         };
    //     }
    // }

    isValidDate = (control: FormControl) => {
        const date = control.value;
        if (date && moment(new Date(date)).isValid() === false) {
            return {
                isValidDate: {
                    valid: false,
                },
            };
        } else {
            return null;
        }
    };

    isValidBirthdayValue = (control: FormControl) => {
        const date = control.value;
        const year = moment().diff(date, 'years', true);
        if (year < 18) {
            return { isOldEnough: { valid: false } };
        } else {
            null;
        }
    };

    isFullName = (control: FormControl) => {
        const name = String(control.value);
        var nameSplit = name.split(' ');
        if (nameSplit.length === 1 || !nameSplit[1].length) {
            return {
                isFullName: {
                    valid: false,
                },
            };
        } else {
            return null;
        }
    };

    prefMaxFive = (control: FormControl) => {
        const array = control.value;
        if (array.length > 5) {
            return {
                prefMaxFive: {
                    valid: false,
                },
            };
        } else {
            return null;
        }
    };

    constructor(private formBuilder: FormBuilder) {}

    /**
     * @description
     * returns an array of objects with properties of the invalid fields <FormGroup> parameter
     *
     *
     */
    invalidFields = (myForm: FormGroup): any[] => {
        const result: any = [];
        Object.keys(myForm.controls).forEach((key) => {
            const controlErrors: ValidationErrors = myForm.get(key).errors;
            if (controlErrors) {
                Object.keys(controlErrors).forEach((keyError) => {
                    result.push({
                        control: this.formInputResponses[key],
                        error: keyError,
                        value: controlErrors[keyError],
                    });
                });
            }
        });
        return result.filter((r: any) => !isNil(r.control));
    };

    /** ---------------------------------------------------------------------------------------------------- */
    /** ---------------------------------------------------------------------------------------------------- */

    // return request for login form
    loginForm = (): Observable<FormGroup> => {
        return of(this.createLoginForm());
    };

    // create the login form
    private createLoginForm = (): FormGroup => {
        return this.formBuilder.group({
            email: new FormControl('', Validators.compose([Validators.email, Validators.required])),
            password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(5)])),
        });
    };

    // return request for migration form
    migrationForm = (): Observable<FormGroup> => {
        return of(this.createMigrationForm());
    };

    // create the migration form
    private createMigrationForm = (): FormGroup => {
        return this.formBuilder.group({
            password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(30)])),
            passwordConfirm: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(30)])),
        });
    };

    /** ---------------------------------------------------------------------------------------------------- */
    /** ---------------------------------------------------------------------------------------------------- */

    // public page password change form
    forgotPasswordForm = (): Observable<FormGroup> => {
        return of(
            this.formBuilder.group({
                email: new FormControl('', Validators.compose([Validators.email, Validators.required])),
            })
        );
    };

    /** ---------------------------------------------------------------------------------------------------- */
    /** ---------------------------------------------------------------------------------------------------- */

    // user settings password change form
    resetPasswordForm = (): Observable<FormGroup> => {
        return of(
            this.formBuilder.group({
                code: new FormControl(null, Validators.compose([Validators.required, Validators.minLength(6)])),
                password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
                passwordConfirm: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
            })
        );
    };

    // return review page form
    reviewForm = (): Observable<FormGroup> => {
        return of(this.createReviewForm());
    };
    // create review page form
    private createReviewForm = (): FormGroup => {
        return this.formBuilder.group({
            name: new FormControl('', Validators.compose([Validators.required])),
            programid: new FormControl('', Validators.compose([Validators.required])),
            userid: new FormControl('', Validators.compose([Validators.required])),
            programlocation: new FormControl('', Validators.compose([Validators.required])),
            date: new FormControl('', Validators.compose([Validators.required])),
            skills: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)])),
            activities: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)])),
            schedule: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)])),
            discontentment: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)])),
            predepartureinfo: new FormControl(''),
            advise: new FormControl(''),
            housing: new FormControl(''),
            additionalservices: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)])),
            content: new FormControl('', Validators.compose([Validators.required, Validators.minLength(60)])),
            recommend: new FormControl(Validators.compose([Validators.required])),
            usercontent: new FormControl(Validators.compose([Validators.required])),
            orientation: new FormControl(Validators.compose([Validators.required])),
            satisfaction: new FormControl(0, Validators.compose([Validators.required])),
            rating: new FormControl(0, Validators.compose([Validators.required])),
        });
    };

    /** ---------------------------------------------------------------------------------------------------- */
    /** ---------------------------------------------------------------------------------------------------- */

    paymentForm = (): Observable<FormGroup> => {
        return of(this.createPaymentForm());
    };

    createPaymentForm = (): FormGroup => {
        return this.formBuilder.group({
            name: new FormControl('', Validators.compose([Validators.required, this.fullNameValidator])),
            address_line1: new FormControl('', Validators.compose([Validators.required])),
            address_city: new FormControl('', Validators.compose([Validators.required])),
            address_state: new FormControl('', Validators.compose([Validators.required])),
            address_zip: new FormControl('', Validators.compose([Validators.required])),
            country: new FormControl('', Validators.compose([Validators.required])),
            email: new FormControl('', Validators.compose([Validators.email, Validators.required])),
            coupon: new FormControl(''),
        });
    };

    /** ---------------------------------------------------------------------------------------------------- */
    /** ---------------------------------------------------------------------------------------------------- */

    housingForm = (): Observable<FormGroup> => {
        return of(this.createHousingForm());
    };

    private createHousingForm = (): FormGroup => {
        return this.formBuilder.group({
            address: new FormControl('', Validators.compose([Validators.required])),
            city: new FormControl('', Validators.compose([Validators.required])),
            state: new FormControl('', Validators.compose([Validators.required])),
            postal: new FormControl('', Validators.compose([Validators.required])),
            stayingwith: new FormControl('', Validators.compose([Validators.required])),
            airbnblink: new FormControl('', Validators.compose([])),
        });
    };

    /** ---------------------------------------------------------------------------------------------------- */
    /** ---------------------------------------------------------------------------------------------------- */

    // passport form
    passportForm = (): Observable<FormGroup> => {
        return of(this.createPassportForm());
    };

    private createPassportForm = (): FormGroup => {
        return this.formBuilder.group({
            passportnumber: new FormControl('', Validators.compose([Validators.required])),
            passportissuecountry: new FormControl('', Validators.compose([Validators.required])),
            passportissuedate: new FormControl('', Validators.compose([Validators.required])),
            passportexpiredate: new FormControl('', Validators.compose([Validators.required])),
            visameetingdate: new FormControl('', Validators.compose([Validators.required])), // comment out visa meeting location until later date
            // visameetinglocation: new FormControl(
            //     '',
            //     Validators.compose([Validators.required])
            // ),
            passportitemname: new FormControl('', Validators.compose([Validators.required])),
        });
    };

    /** ---------------------------------------------------------------------------------------------------- */
    /** ---------------------------------------------------------------------------------------------------- */

    // application create form
    newApplicationForm = (): Observable<FormGroup> => {
        return of(this.createNewApplicationForm());
    };

    private createNewApplicationForm = (): FormGroup => {
        return this.formBuilder.group({
            type: new FormControl('', Validators.compose([Validators.required])),
            start: new FormControl('', Validators.compose([Validators.required])),
            userid: new FormControl(''),
            useremail: new FormControl(''),
            fullname: new FormControl(''),
            status: new FormControl(''),
            programid: new FormControl(''),
            month: new FormControl(''),
            startyear: new FormControl(''),
            startdate: new FormControl(''),
            applicationordertype: new FormControl('B2C'),
            nursing_hours: new FormControl(null),
        });
    };

    /** ---------------------------------------------------------------------------------------------------- */
    /** ---------------------------------------------------------------------------------------------------- */

    // setting page Password form
    settingForm = (): Observable<FormGroup> => {
        return of(this.createSettingForm());
    };

    private createSettingForm = (): FormGroup => {
        return this.formBuilder.group({
            email: new FormControl('', Validators.compose([Validators.required])),
            // passwordLastChanged: new FormControl(
            //     '',
            //     Validators.compose([
            //     ])
            // )
        });
    };

    // setting page Password form
    settingPasswordForm = (): Observable<FormGroup> => {
        return of(this.createSettingPasswordForm());
    };

    private createSettingPasswordForm = (): FormGroup => {
        return this.formBuilder.group({
            current_password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
            new_password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
            confirm_new_password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
        });
    };

    /** ---------------------------------------------------------------------------------------------------- */
    /** ---------------------------------------------------------------------------------------------------- */
    /** ---------------------------------------------------------------------------------------------------- */
    /** ---------------------------------------------------------------------------------------------------- */

    signUpForm = (): Observable<FormGroup> => {
        return of(this.createSignUpForm());
    };

    private createSignUpForm = (): FormGroup => {
        return this.formBuilder.group({
            email: new FormControl('', Validators.compose([Validators.email, this.isNotEnglish, Validators.required])),
            emailconfirm: new FormControl(
                '',

                Validators.compose([Validators.email, this.isNotEnglish, FormService.matchValues('email'), Validators.required])
            ),
            password: new FormControl('', Validators.compose([Validators.required, this.isNotEnglish, Validators.minLength(8)])),
            passwordconfirm: new FormControl(
                '',
                Validators.compose([Validators.required, this.isNotEnglish, Validators.minLength(8), FormService.matchValues('password')])
            ),
            name: new FormControl('', Validators.compose([Validators.required, this.fullNameValidator, this.isNotEnglish, Validators.minLength(1)])),
            origin: new FormControl(''),
            // fieldofstudy: new FormControl(
            //     '',
            //     Validators.compose([
            //         Validators.required,
            //         Validators.minLength(1),
            //     ])
            // ),
            // prefspecialty: new FormControl(
            //     '',
            //     Validators.compose([
            //         Validators.required,
            //         Validators.minLength(1),
            //     ])
            // ),
            // gender: new FormControl(
            //     '',
            //     Validators.compose([
            //         Validators.required,
            //         Validators.minLength(1),
            //     ])
            // ),
            // countryofbirth: new FormControl(
            //     '',
            //     Validators.compose([
            //         Validators.required,
            //         Validators.minLength(1),
            //     ])
            // ),
            // birthday: new FormControl(
            //     '',
            //     Validators.compose([Validators.required])
            // ),
            // phone: new FormControl(
            //     '',
            //     Validators.compose([
            //         Validators.required,
            //         Validators.minLength(7),
            //     ])
            // ),
            // address: new FormControl(
            //     '',
            //     Validators.compose([Validators.required])
            // ),
            // city: new FormControl(
            //     '',
            //     Validators.compose([Validators.required])
            // ),
            // state: new FormControl(
            //     '',
            //     Validators.compose([Validators.required])
            // ),
            // postalcode: new FormControl(
            //     '',
            //     Validators.compose([Validators.required])
            // ),
            // country: new FormControl(
            //     '',
            //     Validators.compose([
            //         Validators.required,
            //         Validators.minLength(1),
            //     ])
            // ),
            // passportissuecountry: new FormControl(
            //     '',
            //     Validators.compose([
            //         Validators.required,
            //         Validators.minLength(1),
            //     ])
            // ),
            // hasvisa: new FormControl(
            //     '',
            //     Validators.compose([Validators.required])
            // ),
            // status: new FormControl(
            //     '',
            //     Validators.compose([Validators.required])
            // ),
            // university: new FormControl(
            //     '',
            //     Validators.compose([Validators.required])
            // ),
            // yearsattended: new FormControl(
            //     '',
            //     Validators.compose([Validators.required])
            // ),
            // usmle: new FormControl(
            //     '',
            //     Validators.compose([Validators.required])
            // ),
            // requestcount: new FormControl(
            //     '',
            //     Validators.compose([Validators.required])
            // ),
            subscribed: new FormControl(false, Validators.compose([Validators.required])),
            smsSubscribed: new FormControl(false, Validators.compose([Validators.required])),
            // acquisition: new FormControl(
            //     '',
            //     Validators.compose([Validators.required])
            // ),
            invite: new FormControl('', Validators.compose([this.isNotEnglish])),
            utm_signup_source: new FormControl('', Validators.compose([])),
            // preflocation: new FormControl(
            //     '',
            //     Validators.compose([
            //         Validators.required,
            //         Validators.minLength(1),
            //         Validators.maxLength(5),
            //     ])
            // ),
            // prefmonth: new FormControl(
            //     '',
            //     Validators.compose([
            //         Validators.required,
            //         Validators.minLength(1),
            //         Validators.maxLength(5),
            //     ])
            // ),
            generalAgreement: new FormControl(false, Validators.compose([Validators.required, Validators.requiredTrue])),
        });
    };

    /** ---------------------------------------------------------------------------------------------------- */
    /** ---------------------------------------------------------------------------------------------------- */

    profileForm = (): Observable<FormGroup> => {
        return of(this.createProfileForm());
    };

    private createProfileForm = (): FormGroup => {
        return this.formBuilder.group({
            id: new FormControl('', Validators.compose([Validators.required])),
            name: new FormControl('', Validators.compose([Validators.required, this.isNotEnglish, this.fullNameValidator])),
            birthday: new FormControl('', Validators.compose([Validators.required, this.isValidDate])),
            gender: new FormControl('', Validators.compose([Validators.required])),
            address: new FormControl(
                ''
                // Validators.compose([Validators.required, this.isNotEnglish])
            ),
            city: new FormControl(
                ''
                // Validators.compose([Validators.required, this.isNotEnglish])
            ),
            state: new FormControl(''),
            postalcode: new FormControl(''),
            status: new FormControl('', Validators.compose([Validators.required])),
            fieldofstudyid: new FormControl('', Validators.compose([Validators.required])),
            fieldofstudy: new FormControl(null),
            yearsattended: new FormControl('', Validators.compose([Validators.required])),
            usmle: new FormControl('', Validators.compose([Validators.required])),
            hasvisa: new FormControl('', Validators.compose([Validators.required])),
            acquisition: new FormControl('', Validators.compose([Validators.required])),
            phone: new FormControl(
                '',
                Validators.compose([
                    Validators.minLength(7),
                    Validators.required,
                    Validators.pattern(new RegExp('^([+]?[0-9-()]{7,})$')), //11 including +   //[0-9 ]{7}
                ])
            ),
            altphone: new FormControl(''),
            preflocation: new FormControl([], Validators.compose([Validators.required, Validators.maxLength(5), this.prefMaxFive, Validators.minLength(1)])),
            prefmonth: new FormControl([], Validators.compose([Validators.required, this.prefMaxFive, Validators.maxLength(5), Validators.minLength(1)])),
            prefspecialty: new FormControl([], Validators.compose([Validators.required, Validators.minLength(1), this.prefMaxFive, Validators.maxLength(5)])),
            passportnumber: new FormControl(''),
            passportissuecountry: new FormControl('', Validators.compose([Validators.required])),
            passportissuedate: new FormControl(''),
            passportexpiredate: new FormControl(''),
            country: new FormControl('', Validators.compose([Validators.required])),
            countryofbirth: new FormControl('', Validators.compose([Validators.required])),
            university: new FormControl('', Validators.compose([Validators.required])),
            universitycountry: new FormControl(''),
            statement: new FormControl(''),
            departmentid: new FormControl(),
            isprofileblock: new FormControl(),
        });
    };

    /**
     * @returns FormGroup
     * @description clinical preferences of profile page
     */
    profilePrefForm = (): FormGroup => {
        return this.formBuilder.group({
            preflocation: new FormControl([], Validators.compose([Validators.required, Validators.maxLength(5), this.prefMaxFive, Validators.minLength(1)])),
            prefmonth: new FormControl([], Validators.compose([Validators.required, this.prefMaxFive, Validators.maxLength(5), Validators.minLength(1)])),
            prefspecialty: new FormControl([], Validators.compose([Validators.required, Validators.minLength(1), this.prefMaxFive, Validators.maxLength(5)])),
        });
    };

    //  Other School Form
    otherSchoolForm = (): Observable<FormGroup> => {
        return of(this.createOtherSchoolForm());
    };

    private createOtherSchoolForm = (): FormGroup => {
        return this.formBuilder.group({
            schoolName: new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
            schoolCountry: new FormControl('', Validators.compose([Validators.required])),
        });
    };

    /**
     * @returns FormGroup
     * @description part 1 personal info of profile block
     */
    profileBlockFormPt1 = (): FormGroup => {
        return this.formBuilder.group({
            name: new FormControl(null, Validators.compose([Validators.required, this.isNotEnglish, this.fullNameValidator])),
            birthday: new FormControl(null, Validators.compose([Validators.required, this.isValidDate, this.isValidBirthdayValue])),
            countryofbirth: new FormControl(null, Validators.compose([Validators.required])),
            gender: new FormControl(null, Validators.compose([Validators.required])),
            phone: new FormControl(
                null,
                Validators.compose([
                    Validators.minLength(7),
                    Validators.required,
                    Validators.pattern(new RegExp('^([+]?[0-9-()]{7,})$')), //11 including +   //[0-9 ]{7}
                ])
            ),
        });
    };

    /**
     * @returns FormGroup
     * @description part 2 medical education of profile block
     */
    profileBlockFormPt2 = (): FormGroup => {
        return this.formBuilder.group({
            status: new FormControl(null, Validators.compose([Validators.required])),
            university: new FormControl(null, Validators.compose([Validators.required])),
            universitycountry: new FormControl(null),
            yearsattended: new FormControl(null, Validators.compose([Validators.required])),
            usmle: new FormControl(null, Validators.compose([Validators.required])),
            fieldofstudyid: new FormControl(null, Validators.compose([Validators.required])),
            fieldofstudy: new FormControl(null),
            departmentid: new FormControl(null),
        });
    };

    /**
     * @returns FormGroup
     * @description part 3 travel requirements of profile block
     */
    profileBlockFormPt3 = (): FormGroup => {
        return this.formBuilder.group({
            country: new FormControl(null, Validators.compose([Validators.required])),
            passportissuecountry: new FormControl(null, Validators.compose([Validators.required])),
            hasvisa: new FormControl(null, Validators.compose([Validators.required])),
        });
    };

    /**
     * @returns FormGroup
     * @description part 4 clinical preferences of profile block
     */
    profileBlockFormPt4 = (): FormGroup => {
        return this.formBuilder.group({
            preflocation: new FormControl([], Validators.compose([Validators.required, Validators.maxLength(5), this.prefMaxFive, Validators.minLength(1)])),
            prefmonth: new FormControl([], Validators.compose([Validators.required, this.prefMaxFive, Validators.maxLength(5), Validators.minLength(1)])),
            prefspecialty: new FormControl([], Validators.compose([Validators.required, Validators.minLength(1), this.prefMaxFive, Validators.maxLength(5)])),
            acquisition: new FormControl(null, Validators.compose([Validators.required])),
        });
    };
}
