import { Component, Input, OnChanges, OnInit } from '@angular/core';
import SearchItem from '@Interfaces/SearchItem';
import { SearchActions } from '@app/core/ngrx/actions';
import { State } from '@app/core/ngrx/reducers';
import { ToolService } from '@app/core/services/tool/tool.service';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Angulartics2GoogleTagManager } from 'angulartics2';

@Component({
    selector: 'app-search-program',
    templateUrl: './search-program.component.html',
    styleUrls: ['./search-program.component.scss'],
})
export class SearchProgramMobileComponent implements OnInit {
    @Input() content: any;
    @Input() ordering: string;

    selected: any = {
        specialty: '',
        order: 'date-desc',
        tag: {
            value: '',
        },
        type: {
            value: '',
        },
        states: {
            value: '',
        },
        cities: {
            value: '',
        },
        date: {
            value: '',
        },
    };

    customAlertOptions: any = {
        cssClass: 'mobile-filter-selection',
    };

    selectedSpecialtyValue: SearchItem[] = [];
    selectedTypeValue: SearchItem[] = [];
    selectedTagValue: SearchItem[] = [];
    selectedStateValue: SearchItem[] = [];
    selectedCityValue: SearchItem[] = [];
    selectedDateValue: SearchItem[] = [];

    constructor(
        public toolService: ToolService,
        private modalCtrl: ModalController,
        private store: Store<State>,
        private angulartics2GoogleTagManager: Angulartics2GoogleTagManager
    ) {}

    ngOnInit() {
        if (this.content) {
            try {
                this.selected.specialty = [...this.content.content.specialty].filter((s: SearchItem) => s.checked === true) || '';
                this.selectedSpecialtyValue = [...this.selected.specialty];
                //
                this.selected.tags = [...this.content.content.tags].filter((s: SearchItem) => s.checked === true) || '';
                this.selectedTagValue = [...this.selected.tags];
                //
                this.selected.types = [...this.content.content.types].filter((s: SearchItem) => s.checked === true) || '';
                this.selectedTypeValue = [...this.selected.types];
                //
                this.selected.states = [...this.content.content.states].filter((s: SearchItem) => s.checked === true) || '';
                this.selectedStateValue = [...this.selected.states];
                //
                this.selected.cities = [...this.content.content.cities].filter((s: SearchItem) => s.checked === true) || '';
                this.selectedCityValue = [...this.selected.cities];
                //
                this.selected.dates = [...this.content.content.dates].filter((s: SearchItem) => s.checked === true) || '';
                this.selectedDateValue = [...this.selected.dates];
                //
                this.selected.order = this.ordering || 'date-desc';
            } catch (error) {
                console.log(error);
            }
        }
    }

    trackByIndex = (index: number): number => index;

    dismiss(): void {
        this.angulartics2GoogleTagManager.eventTrack('Filter Menu Closed', {
            category: 'Search',
        });

        this.modalCtrl.dismiss();
    }

    setDate(ev: any): void {
        this.angulartics2GoogleTagManager.eventTrack('Filter Dates', {
            category: 'Search',
        });

        if (ev.detail) {
            if (ev.detail.value.length === 0) {
                this.selected.dates = [...this.selected.dates].map((el: SearchItem) => {
                    return {
                        ...el,
                        checked: false,
                    };
                });
            } else {
                ev.detail.value.forEach((selected: any) => {
                    this.selected.dates = [...ev.detail.value].map((el: SearchItem) => ({ ...el, checked: true }));
                });
            }
        }
    }

    setTag(ev: any): void {
        this.angulartics2GoogleTagManager.eventTrack('Filter Tags', {
            category: 'Search',
        });

        if (ev.detail) {
            if (ev.detail.value.length === 0) {
                this.selected.tags = [...this.selected.tags].map((el: SearchItem) => {
                    return {
                        ...el,
                        checked: false,
                    };
                });
            } else {
                ev.detail.value.forEach((selected: any) => {
                    this.selected.tags = [...ev.detail.value].map((el: SearchItem) => ({ ...el, checked: true }));
                });
            }
        }
    }

    setType(ev: any): void {
        this.angulartics2GoogleTagManager.eventTrack('Filter Types', {
            category: 'Search',
        });
        if (ev.detail) {
            if (ev.detail.value.length === 0) {
                this.selected.types = [...this.selected.types].map((el: SearchItem) => {
                    return {
                        ...el,
                        checked: false,
                    };
                });
            } else {
                ev.detail.value.forEach((selected: any) => {
                    this.selected.types = [...ev.detail.value].map((el: SearchItem) => ({ ...el, checked: true }));
                });
            }
        }
    }

    setSpecialty(ev: any): void {
        this.angulartics2GoogleTagManager.eventTrack('Filter Specialty', {
            category: 'Search',
        });

        if (ev.detail) {
            if (ev.detail.value.length === 0) {
                this.selected.specialty = [...this.selected.specialty].map((el: SearchItem) => {
                    return {
                        ...el,
                        checked: false,
                    };
                });
            } else {
                ev.detail.value.forEach((selected: any) => {
                    this.selected.specialty = [...ev.detail.value].map((el: SearchItem) => ({ ...el, checked: true }));
                });
            }
        }
    }

    setState(ev: any): void {
        this.angulartics2GoogleTagManager.eventTrack('Filter States', {
            category: 'Search',
        });

        if (ev.detail) {
            if (ev.detail.value.length === 0) {
                this.selected.states = [...this.selected.states].map((el: SearchItem) => {
                    return {
                        ...el,
                        checked: false,
                    };
                });
            } else {
                ev.detail.value.forEach((selected: any) => {
                    this.selected.states = [...ev.detail.value].map((el: SearchItem) => ({ ...el, checked: true }));
                });
            }
        }
    }

    setCity(ev: any): void {
        this.angulartics2GoogleTagManager.eventTrack('Filter Cities', {
            category: 'Search',
        });

        if (ev.detail) {
            if (ev.detail.value.length === 0) {
                this.selected.cities = [...this.selected.cities].map((el: SearchItem) => {
                    return {
                        ...el,
                        checked: false,
                    };
                });
            } else {
                ev.detail.value.forEach((selected: any) => {
                    this.selected.cities = [...ev.detail.value].map((el: SearchItem) => ({ ...el, checked: true }));
                });
            }
        }
    }

    setOrder(ev: any): void {
        if (ev.detail) {
            this.angulartics2GoogleTagManager.eventTrack('Filter Menu Order By ' + ev.detail.value, { category: 'Search' });

            this.toolService.searchSortValue.next(ev.detail.value);
            // this.modalCtrl.dismiss();
        }
    }

    clearRow(type: string): void {
        switch (type) {
            case 'specialty':
                this.selected.specialty = [];
                this.selectedSpecialtyValue = [];

                break;
            case 'type':
                this.selected.types = [];
                this.selectedTypeValue = [];
                break;
            case 'dates':
                this.selected.dates = [];
                this.selectedDateValue = [];
                break;
            case 'tag':
                this.selected.tags = [];
                this.selectedTagValue = [];
                break;
            case 'states':
                this.selected.states = [];
                this.selectedStateValue = [];
                break;
            case 'cities':
                this.selected.cities = [];
                this.selectedCityValue = [];
                break;
            default:
                break;
        }

        for (const k in this.selected) {
            if (k == type) {
                this.selected[k] = '';
            }
        }
    }

    reset(): void {
        this.angulartics2GoogleTagManager.eventTrack('Filter Menu Reset Filters Clicked', { category: 'Search' });

        this.store.dispatch(SearchActions.setSearchLoading({ loading: true }));

        this.store.dispatch(
            SearchActions.setSearchContext({
                context: 'Search for your next clinical experience with us.',
            })
        );

        for (const k in this.selected) {
            if (this.selected[k]) {
                this.selected[k] = '';
            }
        }

        // hack to reset all filters
        const data = {
            specialty: [],
            types: [],
            tags: [],
            cities: [],
            states: [],
            locations: [],
            dates: [],
        };
        this.modalCtrl.dismiss(data);
    }

    runSearch() {
        // this.toolService.pageLoading();
        this.store.dispatch(SearchActions.setSearchLoading({ loading: true }));

        const specialties = [...this.selected.specialty].map((spec: SearchItem) => ({ ...spec, checked: true }));
        const types = [...this.selected.types].filter((val: any) => ({ ...val, checked: true }));
        const tags = [...this.selected.tags].filter((val: any) => ({ ...val, checked: true }));
        const cities = [...this.selected.cities].filter((val: any) => ({ ...val, checked: true }));
        const states = [...this.selected.states].filter((val: any) => ({ ...val, checked: true }));
        const dates = [...this.selected.dates].filter((val: any) => ({ ...val, checked: true }));

        const data = {
            specialty: specialties,
            types,
            tags,
            cities,
            states,
            dates,
        };

        this.modalCtrl.dismiss(data);
    }
}
