import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { State } from '@app/core/ngrx/reducers';
import { ApplicationService } from '@app/core/services/application/application.service';
import { ProgramService } from '@app/core/services/program/program.service';
import { WarningService } from '@app/core/services/warning/warning.service';
import { NavController, IonSelect } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { ModalController } from '@ionic/angular';
import { ItineraryActions } from '@core/ngrx/actions';
import { itinerarySelectors, userSelectors } from '@core/ngrx/selectors';
import { ModalService } from '@core/services/modal/modal.service';
import { SendEventActionsService } from '@core/services/events/sendEventActions.service';

import { faComment, faList, faStethoscope } from '@fortawesome/pro-regular-svg-icons';
import { faBookMedical, faBriefcaseMedical, faLaptopMedical, faMapMarkerAlt, faNotesMedical, faTooth, faUserNurse } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'app-itinerary-item',
    templateUrl: './itinerary-item.component.html',
    styleUrls: ['./itinerary-item.component.scss'],
})
export class ItineraryItemComponent implements OnInit {
    //
    @ViewChild(IonSelect) select: IonSelect;
    @Input() selection?: any;
    // user globals
    USER_ID: string; //
    USER_EMAIL: string; //
    USER_ID$ = this.store.select(userSelectors.getUserID);
    USER_EMAIL$ = this.store.select(userSelectors.getUserEmail);

    itinerarySelections: any[] = [];

    customAlertOptions: any = {
        cssClass: 'time-selection',
    };
    icons: any = {
        comment: faComment,
        faList: faList,
        faMapMarkerAlt: faMapMarkerAlt,
        faStethoscope: faStethoscope,
        faNotesMedical: faNotesMedical,
        faBookMedical: faBookMedical,
        faBriefcaseMedical: faBriefcaseMedical,
        faLaptopMedical: faLaptopMedical,
        faTooth: faTooth,
        faUserNurse: faUserNurse,
    };
    //
    //
    rows: any[] = [];
    savedItem: any = {
        display: '',
    };

    iconOfiti: any;

    constructor(
        private store: Store<State>,
        // private modalService: ModalService,
        private navCtrl: NavController,
        private applicationService: ApplicationService,
        private warning: WarningService,
        private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
        private programService: ProgramService,
        private sendEventActionsService: SendEventActionsService
    ) {}

    ngOnInit() {
        //
        //
        this.setIcons();
        //
        //
        this.USER_ID$.subscribe((userid: string) => (this.USER_ID = userid));
        this.USER_EMAIL$.subscribe((useremail: string) => (this.USER_EMAIL = useremail));
        //
    }

    // dismiss() {
    //   this.modalController.dismiss();
    // }

    removeFromItinerary(removeSelection) {
        this.warning
            .confirmationPrompt({
                header: 'Remove Program',
                cssClass: 'user-warning',
                message: 'Are you sure you want to remove this program from your Itinerary?',
                dismissText: 'Cancel',
                confirmationText: 'Remove Now.',
            })
            .then((confirm: any) => {
                if (confirm) {
                    // this.goToProfileBlock();
                } else {
                    //
                    // get current list from local storage
                    let itineraryListString = localStorage.getItem(this.USER_ID + '-itinerarySelections');

                    let currentItineraryList = JSON.parse(itineraryListString);
                    // filter out all itinerary items except this current selection
                    let updatedItineraryList = currentItineraryList.filter((item: any) => {
                        return item.int_id !== this.selection.int_id;
                    });
                    // update the list in local storage
                    //
                    localStorage.setItem(this.USER_ID + '-itinerarySelections', JSON.stringify(updatedItineraryList));

                    // set the new list as list next state
                    this.store.dispatch(
                        ItineraryActions.setItineraryList({
                            itineraryList: updatedItineraryList.map((itineraryItem: any) => {
                                return { ...itineraryItem };
                            }),
                        })
                    );

                    this.sendEventActionsService.sendEventActions({
                        pageview: '/remove-from-itinerary',
                        eventAction: 'removeFromItinerary',
                        eventCategory: 'Itinerary',
                        ddAction: 'removeFromItinerary',
                    });
                }
            });
    }

    setIcons(): void {
        switch (this.selection.type) {
            case 'Hands-On':
                this.iconOfiti = faStethoscope;
                break;
            case 'Observership':
                this.iconOfiti = faNotesMedical;
                break;
            case 'Nursing':
                this.iconOfiti = faUserNurse;
                break;
            case 'Dentistry':
                this.iconOfiti = faTooth;
                break;
            case 'Virtual':
                this.iconOfiti = faLaptopMedical;
                break;
            case 'Virtual Pre-med shadowing':
                this.iconOfiti = faLaptopMedical;
                break;
            case 'Research':
                this.iconOfiti = faBookMedical;
                break;
            case 'Pre-med shadowing':
                this.iconOfiti = faBriefcaseMedical;
                break;
            default:
                this.iconOfiti = faNotesMedical;
                break;
        }
    }

    updateDate() {
        this.programService.availableDates(this.selection.programid).subscribe(async (response: any) => {
            this.rows = response;
            await this.select.open();
        });
    }

    changeDates(event: any) {
        event.preventDefault();
        // get current list from local storage
        let itineraryListString = localStorage.getItem(this.USER_ID + '-itinerarySelections');
        let currentItineraryList = JSON.parse(itineraryListString);
        let selectedDate = event.detail.value;
        let startdate = selectedDate.startdate;
        let enddate = selectedDate.enddate;

        // filter out all itinerary items except this current selection
        let oldItineraryItem = currentItineraryList.find((item: any) => {
            return item.int_id === this.selection.int_id;
        });

        let newItineraryItem = {
            ...oldItineraryItem,
            startdate: startdate,
            enddate: enddate,
            saved_selected_date: { ...event.detail.value },
        };

        // validate this selection with the API before adding it to the itineraryList store.
        this.applicationService
            .userValidateItinerarySelection({
                existingitinerarylist: currentItineraryList,
                currentselection: newItineraryItem,
            })
            .subscribe(
                (response: any) => {
                    if (response.success === true) {
                        let index = currentItineraryList.findIndex((item: any) => {
                            return item.int_id === this.selection.int_id;
                        });
                        currentItineraryList.splice(index, 1, newItineraryItem);
                        let updatedItineraryList = currentItineraryList.map((itineraryItem: any) => {
                            return { ...itineraryItem };
                        });

                        // sort by start date descending order
                        updatedItineraryList = updatedItineraryList.sort((a: any, b: any) => new Date(a.startdate).getTime() - new Date(b.startdate).getTime());

                        localStorage.setItem(this.USER_ID + '-itinerarySelections', JSON.stringify(updatedItineraryList));
                        // set the new list as list next state
                        this.store.dispatch(
                            ItineraryActions.setItineraryList({
                                itineraryList: updatedItineraryList.map((itineraryItem: any) => {
                                    return { ...itineraryItem };
                                }),
                            })
                        );
                    } else {
                        this.warning.create(response.message);
                    }
                },
                (err: any) => {
                    this.warning.create(err.error.message);
                }
            );
    }
}
