import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { WarningService } from '@app/core/services/warning/warning.service';
import { IonicModule, ModalController } from '@ionic/angular';
import { isNil } from '@shared/utils';
import { regexTools } from '@shared/utils/regex';

@Component({
    selector: 'app-reset-password-modal',
    standalone: true,
    imports: [IonicModule, FormsModule, ReactiveFormsModule, CommonModule],
    styleUrl: './reset-password-modal.component.scss',
    template: `
        <ion-content class="ion-no-padding ion-no-margin background-white">
            <ion-grid class="ion-no-padding ion-no-margin">
                <ion-row class="ion-justify-content-center ion-no-padding ion-no-margin">
                    <ion-col size="12" class="ion-no-padding ion-no-margin">
                        <form [formGroup]="resetPassword_form" (ngSubmit)="submitReset()" (keyup.enter)="submitReset()">
                            <ion-grid>
                                <ion-row class="ion-justify-content-center">
                                    <ion-col size="12">
                                        <ion-toolbar style="height: 40px;">
                                            <ion-buttons slot="end">
                                                <ion-button (click)="closeModal()" size="small" style="--height: 40px;">
                                                    <ion-icon slot="icon-only" name="close-outline"></ion-icon>
                                                </ion-button>
                                            </ion-buttons>
                                        </ion-toolbar>
                                        <ion-list lines="none">
                                            <ion-list-header class="ion-text-center">
                                                <ion-label class="bold">Create a new password</ion-label>
                                            </ion-list-header>
                                            <ion-item>
                                                <ion-label position="stacked">New password</ion-label>
                                                <ion-input
                                                    class="basic-inputs form-inputs"
                                                    name="reset-password"
                                                    required
                                                    (ionBlur)="setBlur()"
                                                    type="password"
                                                    formControlName="password"
                                                >
                                                </ion-input>
                                            </ion-item>
                                            <ion-item>
                                                <ion-label position="stacked">New password confirm</ion-label>
                                                <ion-input
                                                    class="basic-inputs form-inputs"
                                                    name="reset-password-confirm"
                                                    required
                                                    (ionBlur)="setBlur()"
                                                    type="password"
                                                    formControlName="passwordConfirm"
                                                >
                                                </ion-input>
                                            </ion-item>
                                        </ion-list>
                                    </ion-col>
                                    <ion-col size="12">
                                        <ion-list lines="none" class="password-rules-list">
                                            <ion-item>
                                                <ion-icon color="success" *ngIf="passwordOptions?.oneNumber" slot="start" name="checkmark-circle"> </ion-icon>
                                                <ion-icon color="danger" *ngIf="!passwordOptions?.oneNumber" slot="start" name="alert"> </ion-icon>
                                                <ion-label class="ion-text-wrap">
                                                    <ion-note>At least 1 number</ion-note>
                                                </ion-label>
                                            </ion-item>
                                            <ion-item>
                                                <ion-icon color="success" *ngIf="passwordOptions?.oneSpecial" slot="start" name="checkmark-circle"> </ion-icon>
                                                <ion-icon color="danger" *ngIf="!passwordOptions?.oneSpecial" slot="start" name="alert"> </ion-icon>
                                                <ion-label class="ion-text-wrap">
                                                    <ion-note>At least 1 special character</ion-note>
                                                    <br />
                                                    <ion-note> Example: ^$*?"!&#64;#%&,:;|~</ion-note>
                                                </ion-label>
                                            </ion-item>
                                            <ion-item>
                                                <ion-icon color="success" *ngIf="passwordOptions?.oneUpper" slot="start" name="checkmark-circle"> </ion-icon>
                                                <ion-icon color="danger" *ngIf="!passwordOptions?.oneUpper" slot="start" name="alert"> </ion-icon>
                                                <ion-label class="ion-text-wrap">
                                                    <ion-note>At least 1 uppercase character</ion-note>
                                                </ion-label>
                                            </ion-item>
                                            <ion-item>
                                                <ion-icon color="success" *ngIf="passwordOptions?.eightChars" slot="start" name="checkmark-circle"> </ion-icon>
                                                <ion-icon color="danger" *ngIf="!passwordOptions?.eightChars" slot="start" name="alert"> </ion-icon>
                                                <ion-label class="ion-text-wrap">
                                                    <ion-note>At least 8 characters</ion-note>
                                                </ion-label>
                                            </ion-item>
                                        </ion-list>
                                    </ion-col>
                                </ion-row>
                            </ion-grid>
                            <div class="ion-text-center">
                                <ion-button [disabled]="submitted" type="submit">Submit</ion-button>
                            </div>
                        </form>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-content>
    `,
})
export class ResetPasswordModalComponent {
    constructor(private modalCtrl: ModalController, private warning: WarningService) {
        this.listeners();
        this.setBlur();
    }

    submitted: boolean = false;

    passwordOptions = {
        oneUpper: false,
        oneLower: false,
        oneNumber: false,
        oneSpecial: false,
        eightChars: false,
        nonEnglish: false,
        match: false,
    };
    updatedPasswordOptions = {
        oneUpper: false,
        oneLower: false,
        oneNumber: false,
        oneSpecial: false,
        eightChars: false,
        nonEnglish: false,
        match: false,
    };

    resetPassword_form: FormGroup = new FormGroup({
        password: new FormControl('', [Validators.required, Validators.minLength(8)]),
        passwordConfirm: new FormControl('', [Validators.required, Validators.minLength(8)]),
    });

    listeners = () => {
        this.resetPassword_form.get('password').valueChanges.subscribe((changes: string) => {
            if (changes) {
                // password is at least 8 characters
                this.updatedPasswordOptions.eightChars = changes.length > 7 ? true : false;
                // password has at least 1 uppercase character
                this.updatedPasswordOptions.oneUpper = isNil(changes.match(regexTools.upperCaseRegex))
                    ? false
                    : changes.match(regexTools.upperCaseRegex).length > 0
                    ? true
                    : false;
                // password has at least 1 lowercase character
                this.updatedPasswordOptions.oneLower = isNil(changes.match(regexTools.lowerCaseRegex))
                    ? false
                    : changes.match(regexTools.lowerCaseRegex).length > 0
                    ? true
                    : false;
                // password has at least 1 number
                this.updatedPasswordOptions.oneNumber = isNil(changes.match(regexTools.numberCaseRegex))
                    ? false
                    : changes.match(regexTools.numberCaseRegex).length > 0
                    ? true
                    : false;
                // password has at least 1 special character wadawdawdawd  wdawdawdawd qwdawdw

                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < changes.length; i++) {
                    const character = changes[i];
                    if (/([\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])$/.test(character)) {
                        this.updatedPasswordOptions.oneSpecial = true;
                    } else {
                        this.updatedPasswordOptions.oneSpecial = false;
                    }
                } // end of special check

                // does password have any non english characters
                this.updatedPasswordOptions.nonEnglish = isNil(changes.match(regexTools.nonEnglishRegex))
                    ? false
                    : changes.match(regexTools.specialCaseRegex).length > 0
                    ? true
                    : false;

                this.updatedPasswordOptions.oneSpecial = isNil(changes.match(/(?=.*[\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])/g))
                    ? false
                    : changes.match(regexTools.passwordSpecialCase).length > 0
                    ? true
                    : false;

                this.setBlur();
            }
        });

        this.resetPassword_form.get('passwordConfirm').valueChanges.subscribe((changes: string) => {
            this.updatedPasswordOptions.match = changes === this.resetPassword_form.value.password ? true : false;
            this.setBlur();
        });
    };

    setBlur() {
        this.passwordOptions = { ...this.updatedPasswordOptions };
    }

    submitReset() {
        this.submitted = true;
        if (this.resetPassword_form.value['password'] !== this.resetPassword_form.value['passwordConfirm']) {
            this.warning.create('Password Confirm: passwords must match.');

            this.submitted = false;
        } else if (this.updatedPasswordOptions.eightChars === false) {
            this.warning.create('Validation: password must have a minimum of 8 characters.');

            this.submitted = false;
        } else if (this.updatedPasswordOptions.oneUpper === false) {
            this.warning.create('Validation: password must have at least one capitalized upper case character. ie. [A-Z]');

            this.submitted = false;
        } else if (this.updatedPasswordOptions.oneLower === false) {
            this.warning.create('Validation: password must have at least one lower case character. ie. [a-z]');

            this.submitted = false;
        } else if (this.updatedPasswordOptions.oneNumber === false) {
            this.warning.create('Validation: password must have at least one number. ie. [0-9]');

            this.submitted = false;
        } else if (this.updatedPasswordOptions.oneSpecial === false) {
            this.warning.create('Validation: password must have at least one special character. Example: ^$*.[]{}()?"!@#%&,<:;|~');

            this.submitted = false;
        } else {
            this.submitted = false;
            this.modalCtrl.dismiss(this.resetPassword_form.value.passwordConfirm);
            this.resetPassword_form.reset();
        }
    }

    closeModal() {
        this.submitted = false;
        this.resetPassword_form.reset();
        this.modalCtrl.dismiss();
    }
}
