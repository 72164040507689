import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { State } from '@app/core/ngrx/reducers';
import { Store } from '@ngrx/store';
import { paymentSelectors } from '@core/ngrx/selectors';

import { environment } from '@environment/environment';
import { PaymentActions } from '@Ngrx/actions';
import PaymentApplication from '@Interfaces/PaymentApplication';

@Injectable({
    providedIn: 'root',
})
export class PaymentService {
    // displays or hides the payment component
    public showMeMoney = new BehaviorSubject<boolean>(false);
    public summaryMoney = new Subject<any>();
    public paymentTypeMoney = new Subject<any>();
    public paymentMethod = new Subject<any>();
    public paymentForm = new Subject<any>();

    // the current payment cart
    CURRENT_CART: any;
    // stripe sdk
    stripe: any;

    constructor(private http: HttpClient, private store: Store<State>) {
        // payment service subscription to current cart
        this.store.select(paymentSelectors.getPaymentCart).subscribe((currentCart) => {
            this.CURRENT_CART = currentCart;
        });
    }

    getPaymentForm = (): Observable<any> => {
        return this.paymentForm.asObservable();
    };

    getShowMeTheMoney = (): Observable<boolean> => {
        return this.showMeMoney.asObservable();
    };

    stripeEnv(): Observable<any> {
        return this.http.get(`${environment.API}/payments/stripepublickey`);
    }

    // --------------------------------------------------------------------------------------------------------//

    coupon(type: string, applicationid: string, code: string): Observable<any> {
        return this.http.get(`${environment.API}/coupons/append?paymenttype=${type}&applicationid=${applicationid}&code=${code}`);
    }

    // --------------------------------------------------------------------------------------------------------//
    // --------------------------------------------------------------------------------------------------------//

    // --------------------------------------------------------------------------------------------------------//
    /**
     *
     * @param applicationid application making the payment on
     * @method POST
     * @description return the amount for insurance
     */
    insuranceAmount(applicationid: string): Observable<any> {
        return this.http.post(`${environment.API}/payments/${applicationid}/insuranceamount`, {});
    }

    /**
     *
     * @param applicationid application this insurance charge is going for
     * @param srcid the stripe source id
     * @method POST
     * @description making a insurance payment
     */
    insuranceCharge(applicationid: string, srcid: string, email: string): Observable<any> {
        return this.http.post(`${environment.API}/payments/${applicationid}/insurancecharge`, { srcid, email });
    }

    // --------------------------------------------------------------------------------------------------------//
    /**
     *
     * @param applicationid application making the payment on
     * @method POST
     * @description return the amount for immigration
     */
    envoyAmount(applicationid: string): Observable<any> {
        return this.http.post(`${environment.API}/payments/${applicationid}/immigrationamount`, {});
    }

    /**
     *
     * @param applicationid application this envoy immigration charge is going for
     * @param srcid the stripe source id
     * @method POST
     * @description making a imigration payment
     */
    envoyCharge(applicationid: string, srcid: string): Observable<any> {
        return this.http.post(`${environment.API}/payments/${applicationid}/immigrationcharge`, { srcid });
    }

    // --------------------------------------------------------------------------------------------------------//
    /**
     *
     * @param data the classic payment object with billing information and stripe source id
     * @method POST
     * @description setter for the private authorize booking hold function
     */
    bookingPayment(data: any): Observable<any> {
        return this.authorizeBookingHold(data);
    }

    // --------------------------------------------------------------------------------------------------------------------------//

    private authorizeBookingHold(data: any): Observable<any> {
        return this.http.post(`${environment.API}/payments/${data.applicationid}/authorizebookingpayment`, data);
    }

    chargeBookingPayment = (applicationid: string) => {
        const body = { customerid: applicationid };
        // return this.http.post(`${environment.API}/applications/${applicationid}/acceptrequest`, body);
        return this.http.post(`${environment.API}/payments/${applicationid}/chargeBookingPayment`, body);
    };

    requestWireTransfer = (data: any): Observable<any> => {
        return this.submitWireTransfer(data);
    };

    private submitWireTransfer = (data: any) => {
        return (
            this.http
                // eslint-disable-next-line max-len
                .get(
                    `${environment.API}/payments/${data.applicationid}/wiretransfer?code=${data.code}&paymenttype=${data.paymenttype}&total=${data.amount}&amount=${data.amount}&userid=${data.userid}`.trim()
                )
        );
    };

    // =================================================================================================================== \\
    // ------------------------------------------------------------------------------------------------------------------- \\
    // =================================================================================================================== \\

    /**
     *
     * @param data
     */
    createCheckout = (data: any): Observable<any> => {
        return this.http.post(`${environment.API_GATEWAY_PAYMENTS}/payments/stripe-sessions/create?ngsw-bypass=true`, data);
    };

    /**
     *
     * @param type
     * @param applicationid
     * @param code
     */
    couponVerify(type: string, applicationid: string, code: string): Observable<any> {
        return this.http.get(`${environment.API}/coupons/verify?paymenttype=${type}&applicationid=${applicationid}&code=${code}`);
    }

    /**
     *
     * @param type
     * @param applicationid
     * @param code
     */
    userRemove(applicationid: string, code: string): Observable<any> {
        return this.http.get(`${environment.API}/coupons/userremove?applicationid=${applicationid}&code=${code}`);
    }

    //
    wiretransferCheckout = (applicationid: string, data: string) => {
        return this.http.get(`${environment.API}/payments/${applicationid}/wiretransfercheckout?d=${data}`);
    };

    //
    sendWireTransferEmail = (data: any) => {
        return this.http.post(`${environment.API}/payments/sendwiretransferemail`, data);
    };

    //
    paymentSuccessDetails = (applicationid: string, data: string) => {
        return this.http.get(`${environment.API}/payments/${applicationid}/paymentsuccessdetails?d=${data}`);
    };

    userRemoveWire = (applicationid: string) => {
        return this.http.get(`${environment.API}/payments/${applicationid}/wireuserremove`).toPromise();
    };

    /**
     * @description takes the current payment cart and creates itemized pending wire payment records
     */
    createPendingWireRecords(): Promise<boolean> {
        // get the current cart
        const currentCart = this.CURRENT_CART;

        return new Promise((resolve) => {
            // extract addons from current cart for itemized payment records
            let AddonCart = currentCart
                .map((item: any) =>
                    !item.addons
                        ? []
                        : item.addons.map((addon: any) => {
                              return {
                                  id: item.id,
                                  userid: item.userid,
                                  name: addon.name,
                                  amount: addon.price,
                                  paymentType: addon.name,
                                  program: item.program,
                              };
                          })
                )
                .flat();

            //
            let unformattedFinalCart = [...currentCart, ...AddonCart];

            //
            let formattedFinalCart = unformattedFinalCart.map((cartItem: any) => {
                //
                // set total amount for payment record
                let paymentRecordAmount;
                if (cartItem.paymentType === 'malpractice') {
                    paymentRecordAmount = cartItem.amount;
                } else if (cartItem.paymentType === 'switchprotection') {
                    paymentRecordAmount = cartItem.amount;
                } else if (cartItem.paymentType === 'first-payment') {
                    paymentRecordAmount = cartItem.firstSubtotalPrice;
                } else if (cartItem.paymentType === 'full-payment') {
                    // check for addon deductions of malpractice record
                    paymentRecordAmount = cartItem.fullSubtotalPrice;
                } else if (cartItem.paymentType === 'final-payment') {
                    // check for addon deductions of payment record
                    paymentRecordAmount = cartItem.finalSubtotalPrice;
                }

                return {
                    id: cartItem.id,
                    paymentType: cartItem.paymentType,
                    userid: cartItem.userid,
                    programid: cartItem.program.id,
                    amount: paymentRecordAmount,
                    couponCodes: cartItem.couponCodes,
                    addons: cartItem.addons,
                };
            });

            //
            forkJoin(
                formattedFinalCart.map((item) =>
                    this.wiretransferCheckout(
                        item.id,
                        JSON.stringify({
                            id: item.id,
                            paymentType: item.paymentType,
                            userid: item.userid,
                            programid: item.programid,
                            amount: item.amount,
                            coupons: item.couponCodes,
                            cart: {
                                applicationid: item.id,
                                paymentType: item.paymentType,
                                userid: item.userid,
                                coupons: item.couponCodes,
                                amount: item.amount,
                                addons: item.addons,
                            },
                        })
                    )
                )
            ).toPromise();

            resolve(true);
        });
    }

    /**
     *
     * @param selectedAddon addon found from the inside application ngmodel bind
     * @param application application from the ngmodel bind
     */
    updateCartWithAddons(selectedAddon: any, application: PaymentApplication): void {
        const currentCart = this.CURRENT_CART;
        console.log('THE CURRENT CART: ', currentCart);

        // make new temporary cart list
        let TempList = this.CURRENT_CART.map((i) => {
            return { ...i };
        });

        // get the cart index for this item
        let index = this.CURRENT_CART.findIndex((item: any) => {
            return item.id === application.id;
        });

        // card display payment type
        let displayPaymentType: string;
        //
        if (application.paymentType === 'first-payment') {
            displayPaymentType = 'Deposit Payment';
        } else if (application.paymentType === 'full-payment') {
            displayPaymentType = 'Full Payment';
        } else {
            displayPaymentType = 'Final Payment';
        }

        console.log('SWITCH PROTECTION APP: ', application);

        let switchProtection: boolean;
        let addons: any[] = [];
        let switchProtectionAddonSubtotal: number = 0;
        let malpracticeChecked: boolean;
        let malpracticeAddonSubtotal: number = 0;
        //
        // switch protection update
        if (selectedAddon.name === 'switchprotection') {
            // check for addons and calculate pricing for switch protection
            if (!application.switchProtectionChecked) {
                addons.push({
                    name: 'switchprotection',
                    price: application.switchProtectionAddonPrice,
                });
                switchProtection = true;
                switchProtectionAddonSubtotal = application.switchProtectionAddonPrice;
            } else {
                switchProtection = false;
            }
            // check for malpractice
            if (application.malpracticeChecked) {
                malpracticeChecked = true;
                malpracticeAddonSubtotal = application.malpracticeAddonPrice;
                addons.push({
                    name: 'malpractice',
                    price: application.malpracticeAddonPrice,
                });
            }
        } else {
            // check for addons and calculate pricing
            if (!application.malpracticeChecked) {
                addons.push({
                    name: 'malpractice',
                    price: application.malpracticeAddonPrice,
                });
                malpracticeChecked = true;
                malpracticeAddonSubtotal = application.malpracticeAddonPrice;
            } else {
                malpracticeChecked = false;
            }
            // check for switch protection when unchecking malpractice
            if (application.switchProtectionChecked) {
                addons.push({
                    name: 'switchprotection',
                    price: application.switchProtectionAddonPrice,
                });
                switchProtection = true;
                switchProtectionAddonSubtotal = application.switchProtectionAddonPrice;
            }
        }

        //
        TempList.splice(index, 1, {
            ...application,
            displayPaymentType: displayPaymentType,
            location: application.city + ', ' + application.state,
            addons: addons,
            image: application.program.image,
            malpracticeChecked: malpracticeChecked,
            switchProtectionChecked: switchProtection,
            switchProtectionAddonSubtotal: switchProtectionAddonSubtotal,
            malpracticeAddonSubtotal: malpracticeAddonSubtotal,
        });
        // update the current cart after addon selection
        // this.store.dispatch(
        //     PaymentActions.setPaymentCart({
        //         paymentCart: TempList.map((application: PaymentApplication) => {
        //             return { ...application };
        //         }),
        //     })
        // );
        this.updateCurrentCart(TempList);
    }
    // end of payment service file

    /**
     *
     * @param NextCartState takes an array of application cart items (interface PaymentApplication)
     */
    updateCurrentCart(NextCartState: Array<PaymentApplication>): void {
        this.store.dispatch(
            PaymentActions.setPaymentCart({
                paymentCart: NextCartState.map((application: PaymentApplication) => {
                    return { ...application };
                }),
            })
        );
    }

    /**
     *
     * @returns latest state of the current cart
     */
    get currentCartSubscription(): Observable<{}[]> {
        return this.store.select(paymentSelectors.getPaymentCart);
    }

    /**
     *
     * @returns latest state of the current cart total
     */
    get currentCartTotalSubscription(): Observable<number> {
        return this.store.select(paymentSelectors.getCheckoutPrice);
    }

    /**
     *
     * @returns current list of applications in payment page
     */
    get currentPaymentList(): Observable<{}[]> {
        return this.store.select(paymentSelectors.getPaymentList);
    }

    /**
     * @returns string 'wire' or 'card'
     */
    get currentPaymentMethod(): Observable<string> {
        return this.store.select(paymentSelectors.getPaymentMethod);
    }

    /**
     *
     * @@returns returns true if user has an application marked pending wire
     */
    get pendingWireTransfer(): Observable<boolean> {
        return this.store.select(paymentSelectors.getPendingWire);
    }

    /**
     *
     * @param paymentMethod string 'wire' or 'card'
     */
    set updateCurrentPaymentMethod(paymentMethod: string) {
        this.store.dispatch(
            PaymentActions.setPaymentMethod({
                paymentMethod: paymentMethod,
            })
        );
    }

    /**
     *
     * @param nextTotal next total amount of the current cart
     */
    set updateCartTotal(nextTotal: number) {
        this.store.dispatch(
            PaymentActions.setCheckoutPrice({
                checkoutPrice: nextTotal,
            })
        );
    }

    /**
     * @description updates the ngmodel list bound to the UI
     */
    set updateApplicationPaymentList(NextList: PaymentApplication[]) {
        //
        this.store.dispatch(
            PaymentActions.setPaymentList({
                paymentList: NextList,
            })
        );
    }

    // createCheckoutSession() {
    //     // create stripe
    //     this.stripe = Stripe(environment.STRIPE_KEY);

    //     this.store
    //     .select(paymentSelectors.getCustomerID)
    //     .pipe(
    //         flatMap((customerid: string) => {
    //             customer_id = customerid;
    //             return this.store.select(
    //                 paymentSelectors.getCouponDiscount
    //             );
    //         }),
    //         flatMap(discount => {
    //             coupon_discount = discount;
    //             return this.store.select(paymentSelectors.getCheckoutPrice);
    //         }),
    //         flatMap(total => {
    //             checkout_price = total;
    //             return this.store.select(
    //                 paymentSelectors.getCouponDiscount
    //             );
    //         }),
    //         flatMap(discount => {
    //             coupon_discount = discount;
    //             return this.store.select(paymentSelectors.getPaymentCart);
    //         }),
    //         flatMap((cart: any) => {
    //             //
    //             //
    //             let multi_APD = [];
    //             // console.log('WHOLE CART: ', cart);
    //             //
    //             let success_data = '';
    //             //
    //             let custom_Webhook_Metadata = {};
    //             // create custom line items from cart
    //             //
    //             let customLineItems = cart.map(
    //                 (item: PaymentApplication) => {
    //                     //
    //                     console.log('CART ITEM: %o ', item);
    //                     // what payment type was selected for this cart item
    //                     let cartItemPaymentType = item.paymentType;
    //                     // what the application id is for this cart item
    //                     let cartItemId = item.id;
    //                     // what coupons were effecting this payment
    //                     let cartItemCouponCodes = item.couponCodes;
    //                     // what the amount was after payment type - coupons
    //                     let cartItemAmount;
    //                     // what program id this application was for
    //                     let cartItemProgramId = item.program.id;
    //                     //  application cart item addons
    //                     let cartItemAddons = item.addons.map(addon => addon);
    //                     //
    //                     let userId = item.userid;

    //                     //
    //                     let imageURL;
    //                     if (environment.production) {
    //                         imageURL = 'https://app.amopportunities.org' + this.checkoutImageGen(
    //                             item.image,
    //                             item.state,
    //                             'md'
    //                         )
    //                     } else {
    //                         imageURL = 'https://d6fj0ztu4j10m.cloudfront.net' +
    //                             this.checkoutImageGen(
    //                                 item.image,
    //                                 item.state,
    //                                 'md'
    //                             );
    //                     }

    //                     // console.log('ITEM IMAGE URL: ', imageURL);
    //                     if (imageURL.indexOf('orgprogram') !== -1) {
    //                         // check for bad image urls like program #6 soho
    //                         const safeImageURL = imageURL.replace(
    //                             'orgprograms',
    //                             'org/programs'
    //                         );
    //                         imageURL = safeImageURL;
    //                     }
    //                     // in development and staging check
    //                     if (imageURL.indexOf('netprogram') !== -1) {
    //                         // check for bad image urls like program #6 soho
    //                         const safeImageURL = imageURL.replace(
    //                             'netprograms',
    //                             'net/programs'
    //                         );
    //                         imageURL = safeImageURL;
    //                     }
    //                     //
    //                     //
    //                     let appStartDate = new Date(item.startdate);
    //                     //
    //                     let appEndDate = new Date(item.enddate);
    //                     //
    //                     //
    //                     let startDateString = format(
    //                         addMinutes(
    //                             appStartDate,
    //                             appStartDate.getTimezoneOffset()
    //                         ),
    //                         'MMMM-DD-YYYY'
    //                     );
    //                     //
    //                     let endDateString = format(
    //                         addMinutes(
    //                             appEndDate,
    //                             appEndDate.getTimezoneOffset()
    //                         ),
    //                         'MMMM-DD-YYYY'
    //                     );
    //                     //

    //                     //

    //                     // filters active coupons by selected payment type
    //                     let filteredCoupons = [];
    //                     if (item.couponCodes.length > 0) {
    //                         if (item.paymentType === 'first-payment') {
    //                             filteredCoupons = cartItemCouponCodes.filter(coupon => coupon.type === 'deposit' ||
    //                                 coupon.type === 'voucher')
    //                         } else if (item.paymentType === 'full-payment') {
    //                             filteredCoupons = cartItemCouponCodes.filter(coupon => coupon.type === 'full-payment' ||
    //                                 coupon.type === 'final-payment' ||
    //                                 coupon.type === 'voucher')
    //                         } else {
    //                             filteredCoupons = cartItemCouponCodes.filter(coupon => coupon.type === 'final-payment' ||
    //                                 coupon.type === 'voucher' ||
    //                                 coupon.type ===
    //                                 'customer-satisfaction')
    //                         }
    //                     }

    //                     // pass all coupons regardless of payment type for success page
    //                     if (filteredCoupons.length > 0) {
    //                         filteredCoupons.forEach(e =>
    //                             all_coupons.push(e)
    //                         );
    //                     }

    //                     // set the subtotal by payment type
    //                     // set any mapd abbreviations
    //                     let smallSafePaymentType;
    //                     if (cartItemPaymentType === 'first-payment') {
    //                         cartItemAmount = item.firstSubtotalPrice;
    //                         smallSafePaymentType = 'first';
    //                     } else if (cartItemPaymentType === 'full-payment') {
    //                         cartItemAmount = item.fullSubtotalPrice;
    //                         smallSafePaymentType = 'full';
    //                     } else {
    //                         cartItemAmount = item.finalSubtotalPrice;
    //                         smallSafePaymentType = 'final';
    //                     }

    //                     // abbreviate mapd data to save metadata space
    //                     // this string is used to
    //                     // create itemized payment records and process multi application records
    //                     multi_APD.push({
    //                         id: item.id, // applicationid
    //                         pt: smallSafePaymentType, // application payment type
    //                         pi: item.program.id, // application program id
    //                         at: cartItemAmount,
    //                         ad: item.addons.map(a => a.name).join(','), // application addon names
    //                         cp: filteredCoupons.map(c => c.code).join(','), // application coupon codes
    //                     });

    //                     // end mapd data

    //                     // custom metadata
    //                     custom_Webhook_Metadata[cartItemId] = {
    //                         id: cartItemId,
    //                         payment_type: cartItemPaymentType,
    //                         program_id: cartItemProgramId,
    //                         amount: cartItemAmount,
    //                         addons: cartItemAddons,
    //                         coupons: filteredCoupons,
    //                         user_id: userId
    //                     }

    //                     //
    //                     //                //1                     //2
    //                     success_data +=
    //                         item.id +
    //                         ',,' + cartItemAmount +
    //                         ',,' +
    //                         cartItemPaymentType +
    //                         ',,' +
    //                         JSON.stringify(item.previousPaymentList) +
    //                         ',,,';

    //                     return {
    //                         price_data: {
    //                             product_data: {
    //                                 name:
    //                                     'Start date: ' +
    //                                     startDateString +
    //                                     '    ' +
    //                                     '       End date: ' +
    //                                     endDateString,
    //                                 description: item.name,
    //                                 images: [imageURL],
    //                                 metadata: {
    //                                     applicationid: item.id,
    //                                     programid: item.programid,
    //                                     paymenttype: item.paymentType,
    //                                     userid: item.userid,
    //                                 },
    //                             },
    //                             currency: 'usd',
    //                             unit_amount: cartItemAmount * 100,
    //                         },
    //                         quantity: 1,
    //                     };
    //                 }
    //             );
    //             //
    //             // return of(customLineItems);

    //             // console.log('customLineItems', customLineItems);

    //             // if (customLineItems.length <= 0) {
    //             //   console.log('no cart items');
    //             //   this.noCartItems();
    //             // }

    //             let addonItemized = cart.map(
    //                 (item: PaymentApplication) => {
    //                     return item.addons.map((addon: any) => {
    //                         let unit_amount = 0;
    //                         let name;
    //                         if (addon.name === 'malpractice') {
    //                             unit_amount = addon.price * 100;
    //                             name = 'Malpractice Insurance';
    //                         } else {
    //                             unit_amount = addon.price * 100;
    //                             name = 'Switch Protection';
    //                         }

    //                         //
    //                         let imageURL;
    //                         if (environment.production) {
    //                             imageURL = 'https://app.amopportunities.org' + this.checkoutImageGen(
    //                                 item.image,
    //                                 item.state,
    //                                 'md'
    //                             )
    //                         } else {
    //                             imageURL = 'https://d6fj0ztu4j10m.cloudfront.net' +
    //                                 this.checkoutImageGen(
    //                                     item.image,
    //                                     item.state,
    //                                     'md'
    //                                 );
    //                         }

    //                         // console.log('ITEM IMAGE URL: ', imageURL);
    //                         if (imageURL.indexOf('orgprogram') !== -1) {
    //                             // check for bad image urls like program #6 soho
    //                             const safeImageURL = imageURL.replace(
    //                                 'orgprograms',
    //                                 'org/programs'
    //                             );
    //                             imageURL = safeImageURL;
    //                         }
    //                         // in development and staging check
    //                         if (imageURL.indexOf('netprogram') !== -1) {
    //                             // check for bad image urls like program #6 soho
    //                             const safeImageURL = imageURL.replace(
    //                                 'netprograms',
    //                                 'net/programs'
    //                             );
    //                             imageURL = safeImageURL;
    //                         }

    //                         return {
    //                             price_data: {
    //                                 product_data: {
    //                                     name: name,
    //                                     description: item.name,
    //                                     images: [imageURL],
    //                                     metadata: {
    //                                         applicationid: item.id,
    //                                         programid: item.program.id,
    //                                         paymenttype: addon.name,
    //                                         userid: item.userid,
    //                                     },
    //                                 },
    //                                 currency: 'usd',
    //                                 unit_amount: unit_amount,
    //                             },
    //                             quantity: 1,
    //                         };
    //                     })
    //                 }).flat();
    //             // first line item in checkout items
    //             let firstLineItem = customLineItems[0];
    //             //
    //             console.log([...customLineItems, ...addonItemized]);
    //             // // data being sent to success page after payment
    //             let multi_APD_string = JSON.stringify(multi_APD);
    //             //
    //             console.log('CUSTOM METADATA!');
    //             console.log(custom_Webhook_Metadata)

    //             //
    //             let stripeCheckoutData = {
    //                 success_url:
    //                     window.location.origin +
    //                     '/payment-success?d=' +
    //                     btoa(success_data),
    //                 cancel_url: window.location.href,
    //                 payment_method_types: ['card', 'alipay'],
    //                 mode: 'payment',
    //                 customer: customer_id,
    //                 line_items: [...customLineItems, ...addonItemized],  //webhook metadata
    //                 user_id:
    //                     firstLineItem.price_data.product_data.metadata
    //                         .userid,
    //                 application_id:
    //                     firstLineItem.price_data.product_data.metadata
    //                         .applicationid,
    //                 program_id:
    //                     firstLineItem.price_data.product_data.metadata
    //                         .programid,
    //                 payment_type:
    //                     firstLineItem.price_data.product_data.metadata
    //                         .paymenttype,
    //                 discount: coupon_discount,
    //                 coupons: JSON.stringify(all_coupons),
    //                 origin: window.location.origin,
    //                 voucher: false,
    //                 checkout: true,
    //                 hook_stage: environment.MODE,
    //                 multi_APD: multi_APD_string,
    //                 addons: JSON.stringify(all_addons),
    //                 custom_Webhook_Metadata: custom_Webhook_Metadata
    //             };

    //             // console.log('STRIPE CHECKOUT DATA: ', stripeCheckoutData);
    //             //
    //             // console.log('ALL COUPONS?!');
    //             // console.log(all_coupons);
    //             //
    //             return this.paymentService.createCheckout(
    //                 stripeCheckoutData
    //             );
    //         })
    //     )
    //     .subscribe((response: any) => {
    //         console.log('MY CUSTOM LINE ITEMS');
    //         console.log(response);
    //         this.stripe.redirectToCheckout({ sessionId: response.id });
    //     });
    // }
}
