<div class="sidebar-title-container">
    <ion-label class="sidebar-title">{{
        textcontent?.shared?.generalSidebar?.navigator?.sectiontitle
    }}</ion-label>
</div>
<div class="no-color-container">
    <div *ngIf="application === undefined || null">
        <ion-item button [routerLink]="'/search'">
            <ion-text color="text">
                <p class="bold-text link-anchor">
                    {{
                        textcontent?.shared?.generalSidebar?.navigator
                            ?.beginsearch
                    }}
                </p>
            </ion-text>
        </ion-item>
        <ion-label class="sidebar-title">{{
            textcontent?.shared?.generalSidebar?.navigator?.book
        }}</ion-label>
        <ion-item>
            <ion-text color="text">
                <p>
                    {{
                        textcontent?.shared?.generalSidebar?.navigator
                            ?.getaheadpt1
                    }}
                    <span
                        class="link-anchor"
                        tappable
                        (click)="openBookingInfo()"
                        >{{
                            textcontent?.shared?.generalSidebar?.navigator
                                ?.getaheadtp2
                        }}</span
                    >
                </p>
            </ion-text>
        </ion-item>
    </div>
    <div *ngIf="application !== undefined || null">
        <ion-label class="sidebar-title"
            >{{ textcontent?.shared?.generalSidebar?.navigator?.currently }}
        </ion-label>
        <ion-item>
            <ion-text color="text">
                <p class="dark-teal-1">{{ application?.name }}</p>
                <p>
                    <span class="form-text">{{
                        textcontent?.shared?.generalSidebar?.navigator
                            ?.anotherlook
                    }}</span>
                    <br />
                    <a
                        class="link-anchor"
                        button
                        [routerLink]="['/dashboard/']"
                        [routerDirection]="'root'"
                    >
                        {{
                            textcontent?.shared?.generalSidebar?.navigator
                                ?.goback
                        }}
                    </a>
                </p>
            </ion-text>
        </ion-item>
    </div>
</div>
