import { Component, Input, OnChanges } from '@angular/core';
import { ToolService } from '@app/core/services/tool/tool.service';
import { ModalService } from '@core/services/modal/modal.service';
import { faClock } from '@fortawesome/pro-regular-svg-icons';

@Component({
    selector: 'app-program-schedule',
    templateUrl: './program-schedule.component.html',
    styleUrls: ['./program-schedule.component.scss'],
})
export class ProgramScheduleComponent implements OnChanges {
    @Input() unformattedSchedule: any;
    formattedSchedule: any;
    faClock = faClock;
    constructor(
        public toolService: ToolService,
        private modalService: ModalService
    ) {}

    ngOnChanges() {
        this.formattedSchedule = this.toolService.formatSchedule(
            this.unformattedSchedule
        );
    }

    openTimeZines = () => {
        this.modalService.programTimeZones().then((data: any) => {
            // console.log(data);
        });
    };
}
