<ion-content>
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-icon slot="icon-only" name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
    <ion-row>
        <ion-col size="2" class="p-0" *ngIf="isMultiDoc">
            <ion-list class="p-0 doc-list">
                <ion-item lines="full" class="side-menu-item" *ngFor="let item of url; let i = index" [class.active-doc]="selectedIndex === i">
                    <ion-label>
                        <a class="anchor-link teal-color" (click)="viewImmunization(item.Key || item.key); selectedIndex = i">
                            {{ item.Key?.split('/').pop() || item.key?.split('/').pop() }}
                            <br />
                            {{ item.LastModified | date : 'MM/dd/yyyy h:mm a' : 'UTC' }}
                        </a>
                        <br />
                        <a *ngIf="item.Key && clear == true" class="clear-button anchor-link teal-color" (click)="clearImmunizationDocuments(item.Key, i)">
                            Clear File
                        </a>
                    </ion-label>
                </ion-item>
            </ion-list>
        </ion-col>

        <ion-col [size]="isMultiDoc ? 10 : 12" class="ion-no-padding">
            <div *ngIf="showDocument" class="dashboard-document-container">
                <ngx-doc-viewer [viewerUrl]="currentDocument" [url]="currentDocument" [viewer]="documentFormat" style="width: 100%; height: 95%">
                </ngx-doc-viewer>
            </div>
        </ion-col>
    </ion-row>
</ion-content>
