<div class="sidebar-title-container">
  <ion-label class="sidebar-title">AMO Partners</ion-label>
</div>
<div class="no-color-container">
  <div class="text-container">
    <ion-text color="text">
      <p class="sidebar-text">AMO partners with a diverse range of
      companies to give our students exclusive
      benefits from housing to educational services.
      We want to provide you with useful resources
      that will ultimately guide you in your medical
      journey.</p>
    </ion-text>
    <div>
      <div color="text">
          <fa-icon [icon]="faCircle"></fa-icon>&nbsp;<a tappable [routerLink]="['/services']" class="link-anchor" (click)="dismiss()">Take a look at our complete list of partner resources.</a><br />
      </div>
    </div>
  </div>
</div>