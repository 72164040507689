import { Component, Input, OnChanges } from '@angular/core';
import { applicationSelectors } from '@app/core/ngrx/selectors';
import { select, Store } from '@ngrx/store';
import { isNil } from '@shared/utils/index';
import { Observable } from 'rxjs';
import { State } from '../../../../core/ngrx/reducers';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-sidebar-general-content',
    templateUrl: './general-sidebar.component.html',
    styleUrls: ['./general-sidebar.component.scss'],
})
export class SidebarGeneralContentComponent implements OnChanges {
    @Input() applications?: any;
    @Input() application?: any;
    @Input() chainApplications?: any;
    @Input() profile?: any;
    @Input() path?: string;

    sidebar: any = {
        adviser: true,
        coach: false,
    };

    constructor(private modalController: ModalController) {}

    ngOnChanges() {
        // make boolean logic methods outside of template to avoid crazy ng if statements
        // if user has no applications - Sam
        // if user has only Pending applications - Sam
        // if user has only Accepted Applications - Sam
        // if user has Reserved, Enrolled applications - Coach
        // if user has Reserved, Enrolled and no Coach - Sam?

        if (
            isNil(this.application) &&
            isNil(this.applications) &&
            isNil(this.chainApplications)
        ) {
            this.sidebar.adviser = true;
            this.sidebar.coach = false;
        } else if (
            !isNil(this.applications) ||
            (!isNil(this.chainApplications) && isNil(this.application))
        ) {
            if (
                this.applications.some(
                    (a: any) =>
                        a.states === 'Reserved' || a.states === 'Enrolled'
                )
            ) {
                this.sidebar.adviser = false;
                this.sidebar.coach = true;
                this.application = this.applications
                    .sort(
                        (a, b) =>
                            new Date(a.startdate).getTime() -
                            new Date(b.startdate).getTime()
                    )
                    .find(
                        (a: any) =>
                            a.states === 'Reserved' || a.states === 'Enrolled'
                    );
            } else if (
                this.chainApplications &&
                this.chainApplications.length &&
                this.chainApplications[0].applications.some(
                    (a: any) =>
                        a.states === 'Reserved' || a.states === 'Enrolled'
                )
            ) {
                this.sidebar.adviser = false;
                this.sidebar.coach = true;
                this.application = this.chainApplications[0].applications
                    .sort(
                        (a, b) =>
                            new Date(a.startdate).getTime() -
                            new Date(b.startdate).getTime()
                    )
                    .find(
                        (a: any) =>
                            a.states === 'Reserved' || a.states === 'Enrolled'
                    );
            } else {
                this.sidebar.adviser = true;
                this.sidebar.coach = false;
            }
        } else if (isNil(this.applications) && !isNil(this.application)) {
            if (
                this.application.states === 'Reserved' ||
                this.application.states === 'Enrolled'
            ) {
                this.sidebar.adviser = false;
                this.sidebar.coach = true;
            } else {
                this.sidebar.adviser = true;
                this.sidebar.coach = false;
            }
        } else if (!isNil(this.application) && !isNil(this.applications)) {
            if (
                this.applications.some(
                    (a: any) =>
                        a.states === 'Reserved' || a.states === 'Enrolled'
                )
            ) {
                this.sidebar.adviser = false;
                this.sidebar.coach = true;
                this.application = this.applications
                    .sort(
                        (a, b) =>
                            new Date(a.startdate).getTime() -
                            new Date(b.startdate).getTime()
                    )
                    .find(
                        (a: any) =>
                            a.states === 'Reserved' || a.states === 'Enrolled'
                    );
            } else {
                this.sidebar.adviser = true;
                this.sidebar.coach = false;
            }
        } else {
            this.sidebar.adviser = true;
            this.sidebar.coach = false;
        }
    }

    dismiss() {
        this.modalController.dismiss();
    }
}
