import { Action, createReducer, on } from '@ngrx/store';

import {
    ItineraryActions
} from '../actions';

export const itineraryFeatureKey = 'itinerary';


export interface ItineraryState {
    itineraryList: []
}

export const initialState: ItineraryState = {
    itineraryList: []
};

const ItineraryReducer = createReducer(
    initialState,
    on(ItineraryActions.setItineraryList, (state, args) => ({
        ...state,
        itineraryList: args.itineraryList
    })),
    // on(ItineraryActions.removeItineraryItem, (state, args) => ({
    //     ...state,
    //     selections: args.selections
    // })),
);
export function reducer(state: ItineraryState | undefined, action: Action) {
    return ItineraryReducer(state, action);
  }

