<ion-toolbar class="immune-toolbar">
    <ion-title class="immune-modal-title">Immunization Records Status</ion-title>
    <ion-buttons slot="end">
        <ion-button class="close-btn" (click)="close()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
    </ion-buttons>
</ion-toolbar>
<ion-content class="background-white">
    <div class="modal-content-padding">
        <ion-row>
            <ion-col size="7">
                <p class="immune-row-header">Immunization:</p>
            </ion-col>
            <ion-col size="5">
                <p class="immune-row-header">Status:</p>
            </ion-col>
        </ion-row>
        <hr class="hr-line" />
        <ng-container *ngFor="let record of statuses">
            <ion-row>
                <ion-col size="7">
                    <div class="name-containers">
                        <div class="child-container-name">
                            <p class="immune-name">
                                {{ record.name === 'PPD Report' ? 'Tuberculosis (TP) Screening' : record.name === 'Flu (October-May)' ? 'Flu' : record.name }}
                            </p>
                            <small class="label-description" *ngIf="record.name === 'AMO Form'">(Filled, signed and stamped)</small>
                            <small class="label-description" *ngIf="record.name === 'Covid-19 Full Vaccination + Booster'">(Card or certificate)</small>
                            <small class="label-description" *ngIf="record.name === 'PPD Report'"
                                >(PPD, IGRA, or chest x-ray within 6 months before start date. If BCG-vaccinated, a chest x-ray/qualitative report)</small
                            >
                            <small
                                class="label-description"
                                *ngIf="record.name === 'Hep B Surface Antibody Lab' || record.name === 'IgG for MMR' || record.name === 'IgG for Varicella'"
                                >(Lab report confirming immunity)</small
                            >
                            <small class="label-description" *ngIf="record.name === 'Tdap'">(Within 10 years from start date) </small>
                            <small class="label-description" *ngIf="record.name === 'Flu (October-May)'">(flu season is October through May) </small>
                        </div>
                    </div>
                </ion-col>
                <ion-col size="5">
                    <div class="status-containers">
                        <div class="child-container-status">
                            <p
                                class="status-label"
                                [ngClass]="
                                    record.status === 'Partial Completion' ? 'partial-label' : record.status === 'Complete' ? 'accepted-label' : 'missing-label'
                                "
                            >
                                <fa-icon [icon]="faExclamationCircle" *ngIf="record.status === 'Partial Completion'"></fa-icon
                                ><fa-icon [icon]="faCheckCircle" *ngIf="record.status === 'Complete'"></fa-icon>
                                <fa-icon [icon]="faExclamationCircle" *ngIf="record.status === 'Not Submitted'"></fa-icon
                                >{{
                                    record.status === 'Partial Completion'
                                        ? 'Partially Complete'
                                        : record.status === 'Not Submitted'
                                        ? 'Pending Review or Submission'
                                        : record.status === 'Complete'
                                        ? 'Complete'
                                        : ''
                                }}
                            </p>
                        </div>
                    </div>
                </ion-col>
            </ion-row>
            <hr class="hr-line" />
        </ng-container>
    </div>
</ion-content>
