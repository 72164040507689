import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesComponentsModule } from '@app/shared/pipes/pipes.modules';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { QuicklinkModule } from 'ngx-quicklink';
// Payment Components
import { PaymentApplicationDetailsComponent } from './components/payment-application-list/payment-application-list.component';
import { PaymentCouponCodeComponent } from './components/payment-coupon/payment-coupon.component';
import { PaymentDetailsSummaryComponent } from './components/payment-cart-items/payment-cart-items.component';
import { PaymentRequestWiretransferComponent } from './components/payment-request-wiretransfer/payment-request-wiretransfer.component';
import { PaymentStripeModalComponent } from './components/payment-stripe-modal/stripe-modal.component';
import { PaymentSuccessfulChargeComponent } from './components/payment-successful-charge/payment-successful-charge.component';
import { SharedComponentsModule } from '@app/shared/components/shared.module';
import { FooterComponentsModule } from '@app/shared/components/footer.module';
import { PaymentNotifyPopupComponent } from './components/payment-notify-popup/payment-notify-popup.component';
import { AngularMaterialModule } from '@app/shared/angular.material.module';
import { PaymentStripeSummaryComponent } from './components/payment-stripe-summary/payment-stripe-summary.component';
import { PaymentStripeInputsComponent } from './components/payment-stripe-inputs/payment-stripe-inputs.component';
import { PaymentBillingInputsComponent } from './components/payment-billing-inputs/payment-billing-inputs.component';

@NgModule({
    declarations: [
        PaymentApplicationDetailsComponent,
        PaymentCouponCodeComponent,
        PaymentDetailsSummaryComponent,
        PaymentRequestWiretransferComponent,
        PaymentStripeModalComponent,
        PaymentDetailsSummaryComponent,
        PaymentSuccessfulChargeComponent,
        PaymentNotifyPopupComponent,
        PaymentStripeSummaryComponent,
        PaymentStripeInputsComponent,
        PaymentBillingInputsComponent,
    ],
    imports: [
        QuicklinkModule,
        IonicModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        SharedComponentsModule,
        PipesComponentsModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        FooterComponentsModule,
        AngularMaterialModule,
    ],
    exports: [
        QuicklinkModule,
        PaymentApplicationDetailsComponent,
        PaymentCouponCodeComponent,
        PaymentDetailsSummaryComponent,
        PaymentRequestWiretransferComponent,
        PaymentStripeModalComponent,
        PaymentSuccessfulChargeComponent,
        PaymentNotifyPopupComponent,
        PaymentStripeSummaryComponent,
        PaymentStripeInputsComponent,
        PaymentBillingInputsComponent,
    ],
})
export class PaymentComponentsModule {}
