<ion-toolbar color="--ion-color-light-teal-2" class="ion-text-center">
    <ion-buttons slot="end">
        <ion-button id="goToVerifyEligibilityDismissBtn" (click)="dismiss()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
    </ion-buttons>
</ion-toolbar>

<ion-content class="white">
    <ion-row class="white">
        <ion-col size="12">
            <ion-row>
                <ion-col class="text-center" size="12">
                    <fa-icon class="info-icon" [icon]="faInfoCircle"></fa-icon>
                </ion-col>
                <ion-col size="12" class="ion-text-center">
                    <ion-text>
                        <p class="bold-text content-text">
                            The Clinical Experience Itinerary feature allows you
                            to plan out one or more clincal experiences to be
                            booked together as one trip, like going on vacation
                            and stopping at one or more different locations.
                        </p>
                        <ul class="iti-list">
                            <li>
                                Once you've confirmed your itinerary, you can
                                submit requirements for all of your clinical
                                experiences from one single page.
                            </li>
                            <li>
                                When you're ready to create your applications
                                for the Clinical Experiences and dates selected,
                                press <span class="bold-text">"Submit"</span>.
                            </li>
                            <li>
                                You always add more programs to your itinerary
                                by going to our Search page and finding a
                                program that fits you.
                            </li>
                            <li>
                                If you want to cancel and start over, select
                                <span class="bold-text">"Clear Itinerary"</span
                                >.
                            </li>
                        </ul>
                    </ion-text>
                </ion-col>
            </ion-row>
        </ion-col>
    </ion-row>
</ion-content>

<ion-footer class="ion-no-border ion-text-center">
    <ion-button
        class="btncta ion-margin-bottom add-width"
        id="itineraryInfoDismissBtn"
        mode="md"
        fill="solid"
        color="secondary"
        type="button"
        (click)="ctaClick()"
    >
        Got it!
    </ion-button>
</ion-footer>
