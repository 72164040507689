<ion-content>
    <ion-list lines="none" class="sidebar-list">
        <ion-list-header class="hidden-md hidden-lg">
            <ion-title>Help</ion-title>
            <ion-button type="button" fill="clear" (click)="dismiss()">
                <ion-icon name="close-circle" slot="icon-only" size="small"></ion-icon>
            </ion-button>
        </ion-list-header>
        <app-application-navigator [application]="application" *ngIf="path === 'statusPage' && !application?.ApplicationChainMap"></app-application-navigator>
        <app-advisor-sidebar [application]="application" [advisor]="sidebar?.adviser" [coach]="sidebar?.coach"> </app-advisor-sidebar>
        <div class="sidebar-title-container">
            <ion-label class="sidebar-title">Visa Appointment Information </ion-label>
        </div>
        <div class="no-color-container">
            <ion-item>
                <ion-text color="text">
                    <div class="sidebar-text">
                        <a target="_blank" href="https://travel.state.gov/content/travel/en/us-visas/visa-information-resources/wait-times.html"
                            >Check Visa Appointment Wait Times</a
                        >
                        <br /><br />Covid-19 has impacted many U.S. Consulates. We expect this to change soon, but make sure you check your consulate's wait
                        times. If your consulate is closed, apply for an AMO rotation far in advance (at least 90 days) so you have a better chance of the
                        consulate reopening before your rotation.
                    </div>
                </ion-text>
            </ion-item>
        </div>
        <app-virtual-sidebar *ngIf="path === 'statusPage' && application?.states === 'Reserved'"> </app-virtual-sidebar>
        <app-upcoming-deadline
            *ngIf="
                path === 'statusPage' &&
                application?.states === 'Reserved' &&
                (application?.madefullpayment === false || application?.madepayment === false) &&
                application?.madepayment === false
            "
            [name]="application?.name"
        >
        </app-upcoming-deadline>
        <app-documents-sidebar *ngIf="application !== undefined"></app-documents-sidebar>
        <!-- <app-visitor-services></app-visitor-services> -->
        <div class="sidebar-title-container">
            <ion-label class="sidebar-title">Monthly Photo and Blog Contest</ion-label>
        </div>
        <div class="no-color-container">
            <ion-item>
                <ion-text color="text">
                    <div class="sidebar-text">
                        Share your experience with us by entering our
                        <a href="https://share.hsforms.com/1lUYGXrmdRKekLEJFv4iplg3q0zu" target="_blank">Photo and Blog Contest.</a>
                        Winners are selected each month and receive a prize!
                    </div>
                </ion-text>
            </ion-item>
        </div>
        <app-partner-sidebar></app-partner-sidebar>
        <app-help-sidebar></app-help-sidebar>
    </ion-list>
</ion-content>
