export const randomizedQueryUrl = (len: number): string => {
    let result = '';
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < len; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
};

// slightly obfuscate a developer for coding
export const queryEncrypt = (i: string): string => btoa(i);

// understand a slightly obfuscate code from a developer
export const queryDecrypt = (i: string): string => atob(i);
