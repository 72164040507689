import { Action, createReducer, on } from '@ngrx/store';

import { SearchActions } from '../actions';

export const searchFeatureKey = 'search';

export interface SearchState {
    filters: any; // selected search filters
    context: string; // context bar search filters
    loading: boolean; // is search loading some results
    suggested: boolean; // is the user looking at suggested programs
    pricedrop: boolean; // is the user looking at price drop programs
    text: string; // quick actions suggested button text
    showBundles: boolean;
    programMapList: Array<any>; // list of programs used to create map markers
    programBundleMapDisabled: boolean; // disable program map on search
}

export const initialState: SearchState = {
    filters: {},
    context: 'Search for your next clinical experience with us.',
    loading: false,
    suggested: false,
    pricedrop: false,
    text: 'View suggested programs for you.',
    showBundles: false,
    programMapList: [],
    programBundleMapDisabled: false,
};

const AuthReducer = createReducer(
    initialState,
    on(SearchActions.setSearchFilters, (state, args) => ({
        ...state,
        filters: args.filters,
    })),
    on(SearchActions.setSearchContext, (state, args) => ({
        ...state,
        context: args.context,
    })),
    on(SearchActions.setSearchLoading, (state, args) => ({
        ...state,
        loading: args.loading,
    })),
    on(SearchActions.setShowSuggested, (state, args) => ({
        ...state,
        suggested: args.suggested,
    })),
    on(SearchActions.setSuggestedText, (state, args) => ({
        ...state,
        text: args.text,
    })),
    on(SearchActions.setShowPriceDrop, (state, args) => ({
        ...state,
        pricedrop: args.pricedrop,
    })),
    on(SearchActions.setShowProgramBundle, (state, args) => ({
        ...state,
        showBundles: args.showBundles,
    })),
    on(SearchActions.setProgramMapList, (state, args) => ({
        ...state,
        programMapList: args.programMapList,
    })),
    on(SearchActions.setProgramBundleMapDisabled, (state, args) => ({
        ...state,
        programBundleMapDisabled: args.programBundleMapDisabled,
    }))
);
export function reducer(state: SearchState | undefined, action: Action) {
    return AuthReducer(state, action);
}
