import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    constructor(private loadingController: LoadingController) {}

    loading: any;

    public isLoading = new BehaviorSubject<boolean>(false);
    public isPaymentLoading = new BehaviorSubject<boolean>(false);

    /**
     * @param message string message you want to display
     * @param duration how long you want to show this loader
     * @description used as a simple loader, estimate the load time
     */
    async presentLazyLoadingCtrl(message: string, time: number) {
        const loading = await this.loadingController.create({
            message: `${message}`,
            duration: time,
        });

        await loading.present();

        return loading.onDidDismiss();
    }
}
