import { Injectable } from '@angular/core';

import {
    HttpClientXsrfModule,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpXsrfTokenExtractor,
} from '@angular/common/http';

import { LoadingService } from '@core/services/loading/loading.service';
import { environment } from '@environment/environment';
import { NavController } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { from, Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { env } from 'process';

/** Loading and Error Interceptor */

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    private requests: Array<HttpRequest<any>> = [];

    constructor(private loaderService: LoadingService) {}

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        this.requests.splice(i, 1);
        this.loaderService.isLoading.next(this.requests.length > 0);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requests.push(req);
        this.loaderService.isLoading.next(true);
        return Observable.create((observer) => {
            const subscription = next.handle(req).subscribe(
                (event) => {
                    if (event instanceof HttpResponse) {
                        this.removeRequest(req);
                        observer.next(event);
                    }
                },
                (err) => {
                    this.removeRequest(req);
                    observer.error(err);
                },
                () => {
                    this.removeRequest(req);
                    observer.complete();
                }
            );
            // teardown logic in case of cancelled requests
            return () => {
                this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    }
}

/** Auth Interceptor */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private cookie: CookieService, private auth: AuthService, private navCtrl: NavController) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const promise = new Promise((resolve) => resolve(this.cookie.get('amoactkn')));
        return from(promise).pipe(
            mergeMap((token) => {
                const clonedReq = this.formatReq(req, token);
                return next.handle(clonedReq).pipe(
                    catchError((error: any) => {
                        console.log('catch error: ', error);
                        if (error.status === 403) {
                            if (error.error) {
                                if (error.error.error) {
                                    location.reload();
                                    return throwError(error);
                                    //} else if (error.error.code === 'EHARDRFSH') {
                                    //   console.log('ehARDRFSH');
                                    //    location.reload(true);
                                    //    return throwError(error);
                                } else if (error.error.code) {
                                    location.reload();
                                    return throwError(error);
                                } else {
                                    return throwError(error);
                                }
                            } else {
                                return throwError(error);
                            }
                        } else if (error.status === 401) {
                            this.auth.logout();
                            // this.navCtrl.navigateRoot(['/login']);
                            return throwError(error);
                        } else {
                            return throwError(error);
                        }
                    })
                );
            })
        );
    }
    // https://54mkffx395.execute-api.us-east-1.amazonaws.com/v0/download/immunization
    formatReq(request: HttpRequest<any>, token: any) {
        if (request.url.indexOf(`${environment.DOCUMENT_IMMUNIZATION}`) !== -1) {
            let clone: HttpRequest<any>;
            var newHeaders = request.headers.delete('XSRF-TOKEN');
            var newHeaders2 = newHeaders.delete('customerappserviceversion');
            clone = request.clone({
                headers: newHeaders2,
                withCredentials: false,
            });

            return clone;
        } else if (request.url.indexOf(`${environment.API_GATEWAY_COGNITO_AUTH}`) !== -1) {
            let clone: HttpRequest<any>;

            clone = request.clone({
                headers: request.headers,
                withCredentials: true,
            });
            return clone;
        } else if (request.url.indexOf('54mkffx395.execute-api.us-east-1.amazonaws.com') !== -1) {
            let clone: HttpRequest<any>;
            clone = request.clone({
                headers: new HttpHeaders(),
                withCredentials: false,
            });
            return clone;
        } else if (request.url.indexOf(`${environment.COUNTRY_CODE_LIST}`) !== -1) {
            let clone: HttpRequest<any>;
            clone = request.clone({
                headers: new HttpHeaders(),
                withCredentials: false,
            });
            return clone;
        } else if (request.url.indexOf(environment.DOCUMENT_S3_UPLOAD) !== -1) {
            let clone: HttpRequest<any>;
            clone = request.clone({
                headers: new HttpHeaders(),
                withCredentials: false,
            });
            return clone;
        } else if (request.url === environment.COUNTRY_LIST) {
            let clone: HttpRequest<any>;
            clone = request.clone({
                headers: new HttpHeaders(),
                withCredentials: false,
            });
            return clone;
        } else if (request.url.indexOf('payments/stripe-sessions/create') !== -1) {
            let clone: HttpRequest<any>;
            clone = request.clone({
                headers: new HttpHeaders(),
                withCredentials: false,
            });
            return clone;
        } else if (request.url.indexOf('kwusau0m49.execute-api') !== -1) {
            let clone: HttpRequest<any>;
            clone = request.clone({
                headers: new HttpHeaders(),
                withCredentials: false,
            });
            return clone;
        } else if (request.url.indexOf('https://ipinfo.io') !== -1) {
            let clone: HttpRequest<any>;
            clone = request.clone({
                headers: new HttpHeaders(),
                withCredentials: false,
            });
            return clone;
        } else if (request.url.indexOf('https://amo-static-enc.s3.amazonaws.com/text.json') !== -1) {
            let clone: HttpRequest<any>;
            var newHeaders = request.headers.delete('XSRF-TOKEN');
            var newHeaders2 = newHeaders.delete('customerappserviceversion');
            clone = request.clone({
                headers: newHeaders2,
                withCredentials: false,
            });
            return clone;
        } else if (request.url.indexOf('/no-search-result') !== -1) {
            let clone: HttpRequest<any>;
            clone = request.clone({
                headers: new HttpHeaders(),
                withCredentials: false,
            });
            return clone;
        } else if (request.url.indexOf(`${environment.CONTENT_URL_JSON}`) !== -1) {
            let clone: HttpRequest<any>;
            var newHeaders = request.headers.delete('XSRF-TOKEN');
            var newHeaders2 = newHeaders.delete('customerappserviceversion');

            clone = request.clone({
                headers: newHeaders2,
                withCredentials: true,
            });
            return clone;
        } else if (token) {
            let clone: HttpRequest<any>;
            clone = request.clone({
                setHeaders: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                withCredentials: true,
            });
            return clone;
        } else {
            let clone: HttpRequest<any>;
            clone = request.clone({
                setHeaders: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            return clone;
        }
    }
}

/** gCaptcha Interceptor */
@Injectable()
export class CaptchaInterceptor implements HttpInterceptor {
    constructor(private cookie: CookieService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.cookie.get('_gcap_') as string;
        if (token !== null) {
            const cloneReq = this.addCapchaToken(req, token);
            return next.handle(cloneReq);
        } else {
            return next.handle(req);
        }
    }

    private addCapchaToken(req: HttpRequest<any>, token: string) {
        const clone = req.clone({
            headers: req.headers.set('GCAP-TOKEN', token),
        });
        return clone;
    }
}

/** XSRF Interceptor */
@Injectable()
export class XsrfInterceptor implements HttpInterceptor {
    constructor(private tokenExtractor: HttpXsrfTokenExtractor) {}

    private excludeXsrfUrls: string[] = ['https://u1pn0i2tke.execute-api.us-west-2.amazonaws.com'];

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headerName = 'XSRF-TOKEN';
        const token = this.tokenExtractor.getToken() as string;
        HttpClientXsrfModule.withOptions({
            headerName: 'XSRF-TOKEN',
            cookieName: 'XSRF-TOKEN',
        });
        if (
            token !== null &&
            !req.headers.has(headerName) &&
            req.url !== environment.CONTENT_URL_JSON &&
            this.excludeXsrfUrls.some((url: string) => req.url.indexOf(url) === -1)
        ) {
            const cloneReq = this.addXsrf(req, headerName, token);
            return next.handle(cloneReq);
        } else {
            return next.handle(req);
        }
    }

    private addXsrf(req: HttpRequest<any>, headerName: string, token: string) {
        const clone = req.clone({
            headers: req.headers.set(headerName, token),
        });
        return clone;
    }
}

@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // const cloneReq = this.addAppVersionNumber(req, 'CustomerAppServiceVersion', environment.IONIC_VERSION)
        // console.log('modifiedReq', cloneReq);
        // return next.handle(cloneReq);

        req = req.clone({
            setHeaders: {
                CustomerAppServiceVersion: environment.IONIC_VERSION,
            },
        });
        return next.handle(req);
    }
}
