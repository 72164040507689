import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '@app/shared/angular.material.module';
import { SharedComponentsModule } from '@app/shared/components/shared.module';
import { PipesComponentsModule } from '@app/shared/pipes/pipes.modules';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { QuicklinkModule } from 'ngx-quicklink';
// @ts-ignore
import { UserPassportInformationComponent } from '@modules/user/components/user-passport-information/user-passport-information.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [UserPassportInformationComponent],
    imports: [IonicModule, CommonModule, FontAwesomeModule, FormsModule, ReactiveFormsModule, AngularMaterialModule],
    exports: [UserPassportInformationComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserPassportModule {}
