<ion-grid>
    <ion-row>
        <ion-col size="12">
            <ion-text class="text-center bold-text">
                <h4>
                    Thank You!
                </h4>
            </ion-text>
        </ion-col>
        <ion-col class="text-center" size="12">
            <div>
                <fa-icon
                    class="icon-size"
                    slot="start"
                    [icon]="faCheckCircle"
                ></fa-icon>
            </div>
        </ion-col>
        <ion-col size="12">
            <ion-text class="text-center bold-text">
                <h4 class="text-color">
                    {{ message }}
                </h4>
            </ion-text>
        </ion-col>
    </ion-row>
</ion-grid>
<ion-footer>
    <ion-row>
        <ion-col class="text-center">
            <ion-button
                id="RequestWireTransferCancelBtn"
                mode="md"
                size="large"
                fill="solid"
                color="primary"
                type="button"
                (click)="close()"
                >Close
            </ion-button>
        </ion-col>
    </ion-row>
</ion-footer>
