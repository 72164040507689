import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { NavController } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private cookie: CookieService, private navCtrl: NavController) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.cookie.check('amoactkn') || this.cookie.check('mgmtlogin') || this.cookie.check('amomgmt') || this.cookie.check('mgmt_token')) {
            return true;
        } else {
            let decUrl = '';

            if (next.params.applicationid) {
                decUrl = `/application/${next.params.applicationid}`;
                //
            } else if (next.params.programid) {
                decUrl = `/program/${next.params.programid}`;
                //
            } else if (next.url.toString() === 'payment') {
                if (next.queryParams.applicationid) {
                    decUrl = `/payment?applicationid=${next.queryParams.applicationid}`;
                    //
                } else if (next.queryParams.chainid) {
                    decUrl = `/payment?chainid=${next.queryParams.chainid}`;
                    //
                } else {
                    decUrl = '/payment';
                    //
                }
            } else if (next.params.applicationchainid) {
                decUrl = `/application-chain/${next.params.applicationchainid}`;
                //
            } else if (next.url.toString() === 'itinerary-create') {
                decUrl = '/itinerary-create';
            }

            const nextUrl = { next: decUrl };

            if (decUrl.length > 0) {
                this.navCtrl.navigateRoot(['/login'], {
                    queryParams: nextUrl,
                });
            } else {
                this.navCtrl.navigateRoot(['/login']);
            }

            return false;
        }
    }
}
