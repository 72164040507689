<div class="iti-container">
    <div class="iti-lead">
        <fa-icon [icon]="iconOfiti"></fa-icon>
    </div>
    <div class="iti-item">
        <p class="bold-text iti-text">
            {{ selection?.programname }}
        </p>
        <div class="iti-inline">
            <ion-note class="iti-program-type">{{ selection?.type }}</ion-note>
            <ion-note class="iti-program-location">
                <fa-icon [icon]="icons.faMapMarkerAlt"></fa-icon>
                {{ selection?.location }}
            </ion-note>
        </div>
        <div>
            <p class="iti-label">Dates:</p>
            <p class="iti-dates">
                {{ selection?.saved_selected_date?.session }}
            </p>
        </div>
        <div>
            <p class="iti-price">${{ selection?.price }} USD</p>
        </div>
        <ion-toolbar class="iti-toolbar">
            <ion-buttons slot="end">
                <ion-button
                    fill="outline"
                    size="small"
                    color="secondary"
                    class="iti-btn-left"
                    (click)="updateDate()"
                >
                    Change Dates
                </ion-button>

                <ion-select
                    placeholder="Change Dates"
                    interface="alert"
                    [interfaceOptions]="customAlertOptions"
                    [(ngModel)]="savedItem.display"
                    class="sneaky-change-dates"
                    (ionChange)="changeDates($event)"
                >
                    <ion-select-option *ngFor="let time of rows" [value]="time">
                        {{ time?.display }}
                    </ion-select-option>
                </ion-select>

                <ion-button
                    fill="clear"
                    size="small"
                    color="black"
                    (click)="removeFromItinerary(selection)"
                    class="iti-btn-right"
                >
                    Remove
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </div>
</div>
