import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    NavigationExtras,
} from '@angular/router';
import { State } from '@app/core/ngrx/reducers';
import { userSelectors, authSelectors } from '@app/core/ngrx/selectors';
import { NavController, ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { catchError, map, flatMap, mergeMap } from 'rxjs/operators';
import { AuthService } from '../../services/auth/auth.service';
import { randomizedQueryUrl } from '@app/shared/utils/random';

@Injectable({
    providedIn: 'root',
})
export class UserProfileGuard implements CanActivate {
    constructor(
        private navCtrl: NavController,
        private router: Router,
        private location: Location,
        private cookie: CookieService,
        private store: Store<State>
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.store.select(userSelectors.isValidProfile).pipe(
            map((response: boolean) => {
                if (!response || this.cookie.check('_bp_')) {
                    const navigationExtras: NavigationExtras = {
                        queryParams: {
                            t: randomizedQueryUrl(160),
                        },
                    };

                    const newUrl = '/my-profile-block';
                    const url = this.router
                        .createUrlTree([`${newUrl}`])
                        .toString();
                    this.location.replaceState(url);
                    this.navCtrl.navigateRoot(
                        ['/my-profile-block'],
                        navigationExtras
                    );
                    return false;
                } else {
                    return true;
                }
            })
        );
    }
}
