import { Injectable } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
// import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    constructor(private toastController: ToastController, private navCtrl: NavController) {}

    /**
     * @description
     *  header: 'Toast header',
     *  message: 'Click to Close',
     *  position: 'top',
     *  duration: 5,
     *   buttons: [
     *     {
     *       side: 'start',
     *       icon: 'star',
     *       text: 'Favorite',
     *       handler: () => {
     *         console.log('Favorite clicked');
     *       }
     *     }, {
     *       text: 'Done',
     *       role: 'cancel',
     *       handler: () => {
     *         console.log('Cancel clicked');
     *       }
     *     }
     *  ]
     *
     *
     *
     */
    create = async (object: any) => {
        object.position = 'top';
        const toast = await this.toastController.create(object);
        await toast.present();
    };

    /**
     * @description use this one if you need control over parameters
     * @param header string
     * @param message string
     * @param buttons array
     * @param position string
     * @param color string
     * @param duration number
     * @returns void
     * @example display('Toast header', 'Click to Close', [], 'top', 'primary', 5);
     *
     */
    display = async (header: string, message: string, buttons?: [], position?: string, color?: string, duration?: number) => {
        const data = {
            header,
            message,
        };
        const toast = await this.toastController.create(data);
        await toast.present();
    };

    async successToast() {
        const toast = await this.toastController.create({
            message: 'Profile Updated',
            duration: 2000,
            color: 'success',
            position: 'top',
            buttons: [
                {
                    text: 'Close',
                    role: 'cancel',
                    handler: () => {
                        console.log('Cancel clicked');
                    },
                },
            ],
        });

        await toast.present();
    }

    async presentActivationToast() {
        // this.angulartics2GoogleTagManager.pageTrack('/presentActivationToast');
        // this.angulartics2GoogleTagManager.eventTrack('presentActivationToast', { category: 'Activation' });

        const toast = await this.toastController.create({
            header: 'Activation successful! Please login.',
            // message: 'Click to Close',
            position: 'top',
            buttons: [
                {
                    text: 'Close',
                    role: 'cancel',
                    handler: () => {
                        console.log('Cancel clicked');
                    },
                },
            ], // buttons: [
            //   {
            //     side: 'start',
            //     icon: 'star',
            //     text: 'Favorite',
            //     handler: () => {
            //       console.log('Favorite clicked');
            //     }
            //   }, {
            //     text: 'Done',
            //     role: 'cancel',
            //     handler: () => {
            //       console.log('Cancel clicked');
            //     }
            //   }
            // ]
        });

        toast.present();
    }

    async presentFinalizeItineraryToast() {
        const toast = await this.toastController.create({
            header: 'Remember to finalize your itinerary and apply to your programs.',
            position: 'top',
            duration: 3000,
            color: 'primary',
            buttons: [
                {
                    text: 'Finalize Now',
                    handler: () => {
                        this.navCtrl.navigateRoot(['/itinerary-create']);
                    },
                },
                {
                    text: 'Close',
                    role: 'cancel',
                    handler: () => {
                        console.log('Cancel clicked');
                    },
                },
            ],
        });

        toast.present();
    }
}
