import { Component, Input, OnInit } from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';
import { ItineraryActions } from '@core/ngrx/actions';
import { itinerarySelectors, userSelectors } from '@core/ngrx/selectors';
import { State } from '@core/ngrx/reducers';
import { Store } from '@ngrx/store';
import { ApplicationService } from '@app/core/services/application/application.service';
import { WarningService } from '@app/core/services/warning/warning.service';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { NavController } from '@ionic/angular';
import { SuccessfulActionComponent } from '@shared/components/successful-action/successful-action.component';

import { faComment, faList, faMapMarkerAlt, faQuestionCircle, faStethoscope } from '@fortawesome/pro-regular-svg-icons';
import { ProgramService } from '@app/core/services/program/program.service';
import { SendEventActionsService } from '@app/core/services/events/sendEventActions.service';
import { ItineraryCustomerInfoComponent } from '../itinerary-customer-info/itinerary-customer-info.component';

@Component({
    selector: 'app-itinerary-list',
    templateUrl: './itinerary-list.component.html',
    styleUrls: ['./itinerary-list.component.scss'],
})
export class ItineraryListComponent implements OnInit {
    constructor(
        private store: Store<State>,
        private navCtrl: NavController,
        private applicationService: ApplicationService,
        private warning: WarningService,
        private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
        private menuCtrl: MenuController,
        private modalCtrl: ModalController,
        private sendEventActionService: SendEventActionsService
    ) {}

    icons: any = {
        comment: faComment,
        faList: faList,
        faMapMarkerAlt: faMapMarkerAlt,
        faStethoscope: faStethoscope,
        faQuestionCircle: faQuestionCircle,
    };
    //
    // list of local storage itinerary items
    itineraryList$ = this.store.select(itinerarySelectors.getItineraryList);
    trackByIndex = (index: number): number => index;

    // from details page
    @Input() profile: any;

    disableItinerarySubmit = false;

    itinerarySelections: any[] = [];

    // user store
    USER_ID: string; //
    USER_EMAIL: string; //
    USER_ID$ = this.store.select(userSelectors.getUserID);
    USER_EMAIL$ = this.store.select(userSelectors.getUserEmail);

    ngOnInit() {
        //
        this.itineraryList$.subscribe((itineraryList: any) => {
            this.itinerarySelections = itineraryList.map((itineraryItem: any) => {
                return { ...itineraryItem };
            });
        });

        this.USER_ID$.subscribe((userid: string) => (this.USER_ID = userid));
        this.USER_EMAIL$.subscribe((useremail: string) => (this.USER_EMAIL = useremail));
    }

    async whatIsItinerary() {
        const noCartItemsModal = await this.modalCtrl.create({
            component: ItineraryCustomerInfoComponent,
            backdropDismiss: true,
            cssClass: 'itinerary-notify-popup',
        });

        noCartItemsModal.onDidDismiss().then((modalData: any) => {
            if (modalData !== null) {
                if (modalData.data.success === true) {
                    return modalData.data.success;
                } else {
                    return modalData.data.success;
                }
            }
        });

        //
        //
        await noCartItemsModal.present();
        return { success: false };
        //
    }

    closeItineraryMenu() {
        this.menuCtrl.close('customsearch');
        this.menuCtrl.close('custom');
    }

    finalizeItinerary() {
        this.navCtrl.navigateRoot(['/itinerary-create']);
    }

    successfulAction = async (data: any) => {
        const modal = await this.modalCtrl.create({
            component: SuccessfulActionComponent,
            cssClass: data.class,
            componentProps: {
                message: `${data.message}`,
                submessage: `${data.submessage}`,
            },
            backdropDismiss: false,
        });

        await modal.present();

        try {
            setTimeout(() => {
                modal.dismiss();
            }, 2500);
        } catch (e) {
            // ignore err happened from user dismissing
        }

        return await modal.onDidDismiss();
    };

    submitItinerary() {
        if (this.USER_ID && this.itinerarySelections) {
            this.disableItinerarySubmit = true;
            this.applicationService
                .newItinerary({
                    userid: this.USER_ID,
                    selections: this.itinerarySelections,
                })
                .subscribe(
                    (response: any) => {
                        this.closeItineraryMenu();

                        if (response.success === true) {
                            this.removeItinerary();
                            // created a single application send to application details page
                            if (response.applicationChain.id) {
                                this.angulartics2GoogleTagManager.pageTrack('/createdapplication');
                                this.angulartics2GoogleTagManager.eventTrack('New Application', { category: 'Application' });

                                // this.modalService
                                //     .successfulAction({
                                //         message: 'Application Created',
                                //         submessage:
                                //             'Follow Next Step to continue your application process.',
                                //     })
                                //     .then((res: any) => {
                                //         setTimeout(() => {
                                //             this.navCtrl.navigateForward([
                                //                 `/application/${response.applicationChain.id}`,
                                //             ]);
                                //         }, 300);
                                //     })
                                //     .catch((error: Error) => {
                                //         setTimeout(() => {
                                //             this.navCtrl.navigateForward([
                                //                 `/application/${response.applicationChain.id}`,
                                //             ]);
                                //         }, 300);
                                //     });

                                this.successfulAction({
                                    message: 'Application Created',
                                    submessage: 'Follow Next Step to continue your application process.',
                                })
                                    .then((res: any) => {
                                        setTimeout(() => {
                                            this.navCtrl.navigateRoot([`/application/${response.applicationChain.id}`]);
                                        }, 300);
                                    })
                                    .catch((error: Error) => {
                                        setTimeout(() => {
                                            this.navCtrl.navigateRoot([`/application/${response.applicationChain.id}`]);
                                        }, 300);
                                    });
                            } else {
                                this.sendEventActionService.sendEventActions({
                                    pageview: '/created-application-chain',
                                    eventAction: 'createdApplicationChain',
                                    eventCategory: 'Itinerary',
                                    ddAction: 'createdApplicationChain',
                                    customDimensions: {
                                        cdEmail: this.USER_EMAIL,
                                        cdChainLength: this.itinerarySelections.length,
                                    },
                                });

                                let applicationChainId = response.applicationChain.applicationChain.id;

                                this.successfulAction({
                                    message: 'Applications Created',
                                    submessage: 'Follow Next Step to continue your application process.',
                                })
                                    .then((res: any) => {
                                        setTimeout(() => {
                                            this.navCtrl.navigateRoot(['/application-chain/' + applicationChainId]);
                                        }, 300);
                                    })
                                    .catch((error: Error) => {
                                        setTimeout(() => {
                                            this.navCtrl.navigateRoot(['/application-chain/' + applicationChainId]);
                                        }, 300);
                                    });
                            }
                        }
                    },
                    (err: any) => {
                        if (err.error) {
                            this.disableItinerarySubmit = false;
                            err = err.error;
                            if (err.message) {
                                this.warning.create(err.message);
                            } else if (err.errorLog) {
                                this.warning.create(err.errorLog[0].message);
                            } else {
                                this.warning.create(err.error.errors[0].message);
                            }
                        }
                    }
                );
        } else {
            this.disableItinerarySubmit = false;
        }
    }

    removeItinerary(): void {
        // clear the users itinerary list from local storage.
        localStorage.removeItem(String(this.USER_ID + '-itinerarySelections'));

        // set itinerary list state to empty
        this.store.dispatch(
            ItineraryActions.setItineraryList({
                itineraryList: [],
            })
        );
    }
}
