<div *ngIf="(remain$ | async) !== 999" class="sidebar-title-container">
    <ion-label class="sidebar-title">Upcoming deadlines</ion-label>
</div>
<div *ngIf="(remain$ | async) !== 999" class="no-color-container">
    <div class="text-container circle-box">
        <div>
            <div *ngIf="(remain$ | async) !== 999">
                <div class="circle">{{ remain$ | async }} days left</div>
            </div>
        </div>
        <ion-text color="text circle-text">
            <p class="bold-text">
                {{ date$ | async | date: 'longDate':'UTC' }}
            </p>
            <p class="sidebar-text">{{ message$ | async }}</p>
            <p>{{ name }}</p>
        </ion-text>
    </div>
</div>
