import { HTTP_INTERCEPTORS } from '@angular/common/http';

import {
    AppVersionInterceptor,
    AuthInterceptor,
    LoadingInterceptor,
    XsrfInterceptor,
} from './interceptor';

export const httpInterceptors = [
    { provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AppVersionInterceptor,
        multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
];
