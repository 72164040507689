import { Action, createReducer, on } from '@ngrx/store';
import {
    ApplicationChainActions
} from '../actions';

export const applicationChainFeatureKey = 'applicationChain';

export interface ApplicationChainState {
    applications: any; // applications within a application chain
}

export const initialState: ApplicationChainState = {
    applications: []
};

const ApplicationChainReducer = createReducer(
    initialState,
    on(ApplicationChainActions.setApplicationsInChain, (state, args) => ({
        ...state,
        applications: args.applications
    }))
);

export function reducer(state: ApplicationChainState | undefined, action: Action) {
    return ApplicationChainReducer(state, action)
}
