import { ErrorHandler, Injectable, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';

import { Angulartics2Module } from 'angulartics2';

import { IonicModule } from '@ionic/angular';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { InMemoryCache } from '@apollo/client';
import { HttpLink } from 'apollo-angular/http';

// amoApp services
import { MutationService } from '@core/graphql/mutation/mutation.service';
import { QueryService } from '@core/graphql/query/query.service';
import { ToastService } from '@core/services/toast/toast.service';
import { ApplicationService } from './core/services/application/application.service';
import { AuthService } from './core/services/auth/auth.service';
import { FormService } from './core/services/form/form.service';
import { PaymentService } from './core/services/payment/payment.service';
import { ProgramService } from './core/services/program/program.service';
import { SEOService } from './core/services/seo/seo.service';
import { UserService } from './core/services/user/user.service';
import { SendEventActionsService } from './core/services/events/sendEventActions.service';

// custom injectable classes
import { ModalService } from '@core/services/modal/modal.service';
import { WarningService } from '@core/services/warning/warning.service';

// http interceptors
import { httpInterceptors } from './core/interceptors/index';

// some random font awesome stuff
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
// ngx-cookie-service for set, get, check, delete cookies
import { CookieService } from 'ngx-cookie-service';

// quicklinks
// all ng module files
// angular material module
// used for mat calendar time formatting
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { AngularMaterialModule } from '@app/shared/angular.material.module';
import { ApplicationComponentsModule } from './modules/application/application.module';
import { AuthComponentsModule } from './modules/auth/auth.module';
import { DocumentComponentsModule } from './modules/document/document.module';
import { PaymentComponentsModule } from './modules/payment/payment.module';
import { ProgramComponentsModule } from './modules/program/program.module';
import { UserComponentsModule } from './modules/user/user.module';
import { SharedComponentsModule } from './shared/components/shared.module';
// shared pipes module
import { PipesComponentsModule } from './shared/pipes/pipes.modules';

library.add(fas, far);

// ngrx dev tools
import { ReactiveFormsModule } from '@angular/forms';
import { metaReducers, ROOT_REDUCERS } from '@core/ngrx/reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UserEffects } from './core/ngrx/effects/user.effects';
import { ApplicationProgramComponentsModule } from './modules/application/application.program.module';
import { UserProfileComponentsModule } from './modules/user/user.profile.module';
import { MessageComponentsModule } from './shared/components/message.module';
import { SharedSidebarComponentsModule } from './shared/components/sidebar.module';
import { UserPassportModule } from './modules/user/components/user-passport-information/user-passport.module';
import { FooterComponentsModule } from './shared/components/footer.module';
import { datadogRum } from '@datadog/browser-rum';
import { ToolService } from './core/services/tool/tool.service';
import { UniversityPickerComponentModule } from './shared/components/university-picker.module';
import { UniversityDepartmentComponentModule } from './shared/components/university-department.module';
import { WorkerService } from './core/services/worker/worker.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    handleError(error: any): void {
        let chunkFailedMessage = /Loading chunk [\d]+ failed/;

        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
        }

        if (environment.ENV === 'development') {
            console.log(error);
        }
    }
}
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
        HttpClientModule,
        ApolloModule,
        AuthComponentsModule,
        SharedComponentsModule,
        ApplicationComponentsModule,
        DocumentComponentsModule,
        PaymentComponentsModule,
        ProgramComponentsModule,
        UserComponentsModule,
        PipesComponentsModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        AngularMaterialModule,
        MatMomentDateModule,
        Angulartics2Module.forRoot(),
        /**
         * StoreModule.forRoot is imported once in the root module, accepting a reducer
         * function or object map of reducer functions. If passed an object of
         * reducers, combineReducers will be run creating your application
         * meta-reducer. This returns all providers for an @ngrx/store
         * based application.
        */
       StoreModule.forRoot(ROOT_REDUCERS, {
           metaReducers,
           runtimeChecks: {
               strictStateImmutability: true,
               strictActionImmutability: true,
               strictStateSerializability: true,
               strictActionSerializability: true,
            },
        }),
        EffectsModule.forRoot([
            // ApplicationEffects,
            UserEffects,
        ]),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode,
        }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        ReactiveFormsModule,
        SharedSidebarComponentsModule,
        ApplicationProgramComponentsModule,
        UserProfileComponentsModule,
        MessageComponentsModule,
        UserPassportModule,
        FooterComponentsModule,
        UniversityPickerComponentModule,
        UniversityDepartmentComponentModule,
    ],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: (httpLink: HttpLink) => {
                return {
                    link: httpLink.create({
                        uri: `${environment.GRAPHQL_URL}?ngsw-bypass=true`,
                        withCredentials: true,
                    }),
                    cache: new InMemoryCache({
                        dataIdFromObject: (object: { id: string }) => object.id,
                    }),
                };
            },
            deps: [HttpLink],
        },
        CookieService,
        AuthService,
        ApplicationService,
        PaymentService,
        ProgramService,
        SEOService,
        UserService,
        httpInterceptors,
        ToastService,
        MutationService,
        QueryService,
        FormService,
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: true },
        },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },

        WarningService,
        ModalService,
        WorkerService,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
    constructor(private toolService: ToolService) {
        datadogRum.init({
            applicationId: '77885dc5-c9b6-4b50-b208-ac21d36d96aa',
            clientToken: 'pube08e70a31906beff241541f2a3eae954',
            site: 'datadoghq.com',
            service: 'app.amopportunities.org',
            env: environment.ENV,
            version: environment.RELEASE_VERSION,
            sampleRate: 100,
            allowedTracingOrigins: ['https://api.amopportunities.org', /https:\/\/.*\-api.amopportunities\.org/],
            trackInteractions: true,
        });
    }
}
