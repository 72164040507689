import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { flatMap, shareReplay } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import Auth from '@aws-amplify/auth';
import awsconfigmanagement from '../../../../aws-exports-management';

/** Cache Size */
const CACHE_SIZE = 1;

/** Interface for getMe user details */
export interface Me {
    departmentid: string;
    email: string;
    firstname: string;
    id: string;
    invitecode: string;
    name: string;
    permissions: any;
    role: string;
}

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private http: HttpClient) {}

    // ** --------------- User Me --------------------------------------- **//
    /**
     * @description returns a summary of the user, is cached in a observable if already called
     * @method GET
     */
    get Me() {
        if (!this.me$) {
            this.me$ = this.requestMe().pipe(shareReplay(CACHE_SIZE));
        }
        return this.me$;
    }

    // ** --------------- User Profile --------------------------------------- **//

    /**
     * @description returns a users profile
     * @method GET
     */
    get myProfile() {
        return this.requestMyProfile;
    }

    // observable cache
    private me$: Observable<Me[]>;

    /**
     * @description Update profile without backend validation
     * @param data items to update
     * @param userid current users id
     *
     */
    easyUpdateProfile(data: object, userid: string) {
        return this.http.put(`${environment.API}/users/${userid}/updateprofile`, data);
    }

    // ----------------------------------------------------------------------------------------------------------------------//

    /**
     * @description Public forgot password email link sends to this set a users password, Public api call
     * @param data that contains new passport
     * @param hashid  users hashid
     *
     */
    setPasswordPublic(data: object, userid: string): Observable<any> {
        return this.http.post(`${environment.API}/users/${userid}/setpassword`, data);
    }

    // ----------------------------------------------------------------------------------------------------------------------//

    /**
     * Settings Page change a users password
     * @param data that contains new passport
     * @param userid user id
     *
     */
    changePassword(data: object, userid: string): Observable<any> {
        return this.http.put(`${environment.API}/users/${userid}/password`, data);
    }
    // ----------------------------------------------------------------------------------------------------------------------//

    private requestMe(): Observable<any> {
        return this.http.get(`${environment.API}/users/me`);
    }

    identifyUser(): Observable<any> {
        return this.http.get(`${environment.API}/users/me`);
    }

    identifyAuthenticatedUser(): Observable<any> {
        return this.http.get(`${environment.API}/users/isauthenticated`);
    }

    private requestMyProfile(): Observable<any> {
        return this.http.get(`${environment.API}/users/myprofile`);
    }

    updateProfile(data: any, userid: string): Observable<any> {
        return this.http.put(`${environment.API}/users/${userid}/updateprofileform`, data);
    }

    // ----------------------------------------------------------------------------------------------------------------------//

    /**
     * @param data - must contain valid user email
     * @description sends the user a email with a link to reset their password
     * @method GET
     */
    resetRequest(email: string, token?: string) {
        return this.http.get(`${environment.API}/users/${email}/resetrequest?captcha_token=${token}`);
    }
    // ----------------------------------------------------------------------------------------------------------------------//

    /**
     * @param data contains new user data
     * @description creates a new user
     * @method POST
     */
    newUser(data: any): Observable<any> {
        return this.http.post(`${environment.API_GATEWAY_COGNITO_AUTH}/auth/signup?ngsw-bypass=true`, data);
        // return this.http.post(`${environment.API}/users/signup`, data);
    }

    generalAgreement(data: any) {
        return this.http.post(`${environment.API}/users/generalAgreement`, data);
    }

    requestNewActivationEmail(data: any) {
        return this.http.put(`${environment.API}/users/activationEmail`, {});
    }

    requestGuestEmailChange(data: any) {
        return this.http.put(`${environment.API}/users/changeGuestEmail`, data);
    }

    invitedBy(invite: any): any {
        if (invite) {
            return this.http.get(`${environment.API}/users/invitedby?invite=${invite}`);
        } else {
            return of({ data: null });
        }
    }

    cognitoForgotPassword(email: string) {
        const clientId = environment.COGNTIO_APP_CLIENT_ID;
        return this.http.post(`${environment.API_GATEWAY_COGNITO_AUTH}/auth/forgot-password`, { email, clientId });
    }

    cognitoSendResetEmail(email: string) {
        const clientId = environment.COGNTIO_APP_CLIENT_ID;
        return this.http.post(`${environment.API_GATEWAY_COGNITO_AUTH}/auth/send-reset-email`, { email, clientId });
    }

    cognitoResetPassword(email: string, code: string, password: string, origin: string) {
        const clientId = environment.COGNTIO_APP_CLIENT_ID;
        return this.http.post(`${environment.API_GATEWAY_COGNITO_AUTH}/auth/change-password`, { email, code, password, clientId, origin });
    }

    getUniversityCountryList() {
        return this.http.get(`${environment.API}/users/universitycountrylist?ngsw-bypass=true`);
    }

    getUniversityList(country: any) {
        return this.http.get(`${environment.API}/users/universitylist?country=${country}&ngsw-bypass=true`);
    }

    getCustomUniversityList(country: any) {
        return this.http.get(`${environment.API}/users/getcustomuniversitylists?country=${country}`);
    }

    /**
     * @description lets the user view their uploaded documents
     * @param requestPayload current application id
     * @method POST
     */
    getUploadedDocuments = (requestPayload: any): Observable<any> => {
        return this.http.post(`${environment.API}/users/${requestPayload.userid}/downloadrequirementdocument`, requestPayload);
    };

    getUploadedPersonalDocuments = (requestPayload: any): Observable<any> => {
        return this.http.post(`${environment.API}/users/${requestPayload.userid}/downloadpersonaldocument`, requestPayload);
    };

    userupdateprofileimage(data: any): Observable<any> {
        return this.http.post(`${environment.API}/users/userupdateprofileimage`, { profileimageurl: data });
    }

    /**
     * @description archives and removes an uploaded user document
     * @param requestPayload userid, itemno, originalfilename (this includes the subpath from userdocuments/id/.../filename)
     * @method POST
     */
    archiveUploadedDocument = (requestPayload: any): Observable<any> => {
        return this.http.post(`${environment.API}/users/archivedocument`, requestPayload);
    };

    /**
     * @description update the users email for auth
     * @method POST
     */
    updateUserEmailAuth = (body): Observable<any> => {
        return this.http.post(`${environment.API}/users/updateuseremail?ngsw-bypass=true`, body);
    };

    /**
     * @description check what table the user is from
     * @method GET
     */
    precheck = (email: string): Observable<any> => {
        return this.http.get(`${environment.API}/users/precheck?email=${email}&ngsw-bypass=true`);
    };

    /**
     * @returns type array that a user is able to select from
     * @method GET
     */
    getUserTypesForProgram = (): Observable<any> => {
        return this.http.get(`${environment.API}/users/getuserapplytypes?ngsw-bypass=true`);
    };

    /**
     * @description sends an eventbridge event to trigger hubspotconnector trainee-contact-sms_update lambda
     * @method PUT
     */
    updateHubspotSMS = (body: any): Observable<any> => {
        return this.http.post(`${environment.API}/users/smsupdate`, body);
    };
}
