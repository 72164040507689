import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { QuicklinkModule } from 'ngx-quicklink';
import { FooterComponent } from './nav/footer/footer.component';

@NgModule({
    declarations: [FooterComponent],
    imports: [CommonModule, IonicModule, RouterModule, QuicklinkModule],
    exports: [QuicklinkModule, FooterComponent],
})
export class FooterComponentsModule {}
