import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { QuicklinkModule } from 'ngx-quicklink';
import { PipesComponentsModule } from '../pipes/pipes.modules';
import { SidebarAdvisorComponent } from './sidebar-components/sidebar-advisor/advisor-sidebar.component';
import { SidebarAlertComponent } from './sidebar-components/sidebar-alert/sidebar-alert.component';
import { SidebarApplicationNavigatorComponent } from './sidebar-components/sidebar-application-navigator/application-navigator.component';
import { SidebarDocumentsComponent } from './sidebar-components/sidebar-documents/documents-sidebar.component';
import { SidebarGeneralContentComponent } from './sidebar-components/sidebar-general-content/general-sidebar.component';
import { SidebarHelpContactComponent } from './sidebar-components/sidebar-help-contact/help-contact.component';
import { SidebarHelpComponent } from './sidebar-components/sidebar-help/help-sidebar.component';
import { SidebarPartnershipsComponent } from './sidebar-components/sidebar-partnerships/partner-sidebar.component';
import { SidebarUpcomingDeadlineComponent } from './sidebar-components/sidebar-upcoming-deadline/upcoming-deadline.component';
import { SidebarVirtualComponent } from './sidebar-components/sidebar-virtual-sidebar/virtual-sidebar.component';
import { SidebarVisitorServicesComponent } from './sidebar-components/sidebar-visitor-services/visitor-services.component';

@NgModule({
    declarations: [
        SidebarAdvisorComponent,
        SidebarAlertComponent,
        SidebarApplicationNavigatorComponent,
        SidebarDocumentsComponent,
        SidebarGeneralContentComponent,
        SidebarHelpComponent,
        SidebarHelpContactComponent,
        SidebarPartnershipsComponent,
        SidebarUpcomingDeadlineComponent,
        SidebarVirtualComponent,
        SidebarVisitorServicesComponent,
    ],
    imports: [QuicklinkModule, PipesComponentsModule, FontAwesomeModule, CommonModule, IonicModule, RouterModule],
    exports: [
        QuicklinkModule,
        SidebarAdvisorComponent,
        SidebarAlertComponent,
        SidebarApplicationNavigatorComponent,
        SidebarDocumentsComponent,
        SidebarGeneralContentComponent,
        SidebarHelpContactComponent,
        SidebarHelpComponent,
        SidebarPartnershipsComponent,
        SidebarUpcomingDeadlineComponent,
        SidebarVirtualComponent,
        SidebarVisitorServicesComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedSidebarComponentsModule {}
