<ion-header>
    <ion-item class="modal-header-color">
        <ion-label>
            <ion-text>
                <h2 class="ion-text-center ion-text-wrap modal-title">Enrollment Checklist</h2>
            </ion-text>
        </ion-label>
        <ion-button slot="end" class="close" type="button" fill="clear" (click)="dismiss()">
            <ion-icon slot="icon-only" color="danger" name="close" size="medium"></ion-icon>
        </ion-button>
    </ion-item>
</ion-header>
<ion-grid class="modal-content">
    <ion-list>
        <br />
        <div class="center-block">
            <fa-icon [icon]="faExclamationCircle" class="orange"></fa-icon>
            <ion-text class="center bold-text">
                You have incomplete tasks.
                <br /><br />
            </ion-text>
        </div>
        <ion-item-divider color="danger">
            <ion-label>Incomplete</ion-label>
        </ion-item-divider>
        <ion-item *ngFor="let item of checklistMissing; let i = index; trackBy: trackByIndex">
            <ion-label>
                <ion-icon *ngIf="item.type !== 'danger'" name="checkmark-circle-outline" slot="start" color="success"> </ion-icon>
                <ion-icon *ngIf="item.type === 'danger'" name="alert" slot="start" color="danger"></ion-icon>
                {{ item.message }}
            </ion-label>
        </ion-item>
        <ion-item-divider color="success">
            <ion-label>Complete</ion-label>
        </ion-item-divider>
        <ion-item *ngFor="let item of checklistComplete; let i = index; trackBy: trackByIndex">
            <ion-label>
                <ion-icon *ngIf="item.type !== 'danger'" name="checkmark-circle-outline" slot="start" color="success"> </ion-icon>
                <ion-icon *ngIf="item.type === 'danger'" name="alert" slot="start" color="danger"></ion-icon>
                {{ item.message }}
            </ion-label>
        </ion-item>
    </ion-list>
</ion-grid>
