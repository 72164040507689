<ion-card class="scheduleCard">
    <ion-card-content>
        <p class="schedule-disclaimer">
            {{
                (toolService.content | async)?.program?.components
                    ?.programSchedule?.disclaimer
            }}
        </p>
        <ion-list
            *ngFor="let date of formattedSchedule; let ind = index"
            class="ion-text-center"
        >
            <div [ngClass]="ind % 2 === 0 ? 'even' : 'odd'">
                <p>{{ date.day }} {{ date.time }}</p>
            </div>
        </ion-list>
        <div class="view-zone">
            <a (click)="openTimeZines()">
                <fa-icon [icon]="faClock"></fa-icon>
                View Time Zone Converter Chart
            </a>
        </div>
    </ion-card-content>
</ion-card>
