import { environment } from '@environment/environment';
import {
    Action,
    ActionReducer,
    ActionReducerMap,
    ActionType,
    MetaReducer,
} from '@ngrx/store';

import { InjectionToken } from '@angular/core';

import * as fromApplication from './application.reducer';
import * as fromAuth from './auth.reducers';
import * as fromPayment from './payment.reducer';
import * as fromSearch from './search.reducers';
import * as fromUser from './user.reducers';
import * as fromItinerary from './itinerary.reducers';
import * as fromApplicationChain from './application-chain.reducer';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
    [fromApplication.applicationFeatureKey]: fromApplication.ApplicationState;
    [fromUser.userFeatureKey]: fromUser.UserState;
    [fromPayment.paymentFeatureKey]: fromPayment.PaymentState;
    [fromAuth.authFeatureKey]: fromAuth.AuthState;
    [fromSearch.searchFeatureKey]: fromSearch.SearchState;
    [fromItinerary.itineraryFeatureKey]: fromItinerary.ItineraryState;
    [fromApplicationChain.applicationChainFeatureKey]: fromApplicationChain.ApplicationChainState;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const ROOT_REDUCERS = new InjectionToken<
    ActionReducerMap<State, Action>
>('Root reducers token', {
    factory: () => ({
        [fromApplication.applicationFeatureKey]: fromApplication.reducer,
        [fromUser.userFeatureKey]: fromUser.reducer,
        [fromPayment.paymentFeatureKey]: fromPayment.reducer,
        [fromAuth.authFeatureKey]: fromAuth.reducer,
        [fromSearch.searchFeatureKey]: fromSearch.reducer,
        [fromItinerary.itineraryFeatureKey]: fromItinerary.reducer,
        [fromApplicationChain.applicationChainFeatureKey]:
            fromApplicationChain.reducer,
    }),
});

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
    return (state, action) => {
        const result = reducer(state, action);
        // console.groupCollapsed(action.type);
        // console.log('prev state', state);
        // console.log('action', action);
        // console.log('next state', result);
        // console.groupEnd();
        return result;
    };
}

export function clearState(reducer: ActionReducer<State>) {
    return (state: State, action: ActionType<any>) => {
        if (action.type === '[Auth] Set Is Not Authenticated') {
            state = undefined;
        }
        return reducer(state, action);
    };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: Array<MetaReducer<State>> = !environment.production
    ? [logger, clearState]
    : [clearState];
