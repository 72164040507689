import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { UniversityDepartmentComponent } from './university-department/university-department.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [UniversityDepartmentComponent],
    imports: [CommonModule, IonicModule, RouterModule, FormsModule, ReactiveFormsModule, MatTooltipModule, FontAwesomeModule],
    exports: [UniversityDepartmentComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UniversityDepartmentComponentModule {}
