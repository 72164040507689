import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from '@environment/environment';
import { FormGroup, ValidationErrors } from '@angular/forms';
import Rotation from '@Interfaces/Rotation';
import { LoadingController } from '@ionic/angular';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface content {
    content: any;
    countries: string[];
    locations: string[];
    schoolList: any[];
    specialties: string[];
    tags: string[];
    types: string[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface contentResponse {
    value: content[];
}

@Injectable({
    providedIn: 'root',
})
export class ToolService {
    private text$: any;
    private countries$: any;
    shareFilters = new BehaviorSubject<any>({});
    universityCheck = new BehaviorSubject<any>({});
    showMap = new BehaviorSubject<boolean>(false);
    searchSort = new BehaviorSubject<boolean>(true);
    searchSortLabel = new BehaviorSubject<string>('Created Date');
    searchSortValue = new Subject<string>();
    searchProgramCount = new Subject<number>();
    clearFilters = new Subject<boolean>();
    showFeatured = new Subject<boolean>();
    showSuggested = new Subject<Rotation[]>();
    showDynamicEligibility = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient, private loadingController: LoadingController) {}

    // map toggle
    getShowMap = (): Observable<boolean> => this.showMap.asObservable();
    // search order
    getSearchSort = (): Observable<boolean> => this.searchSort.asObservable();
    // search filters
    getSearchFilters = (): Observable<any> => this.shareFilters.asObservable();
    // search sort label
    getSearchSortLabel = (): Observable<string> => this.searchSortLabel.asObservable();
    getSearchSortValue = (): Observable<string> => this.searchSortValue.asObservable();
    // get total programs found
    getSearchProgramCount = (): Observable<number> => this.searchProgramCount.asObservable();
    // clear all filters
    getSearchClearFilters = (): Observable<boolean> => this.clearFilters.asObservable();
    getShowFeatured = (): Observable<boolean> => this.showFeatured.asObservable();
    getShowSuggested = (): Observable<Rotation[]> => this.showSuggested.asObservable();
    getDynamicEligibility = (): Observable<boolean> => this.showDynamicEligibility.asObservable();
    getUniversityCheck = (): Observable<any> => this.universityCheck.asObservable();

    async searchLoading() {
        const loading = await this.loadingController.create({
            message: 'Searching..',
            duration: 2000,
        });
        await loading.present();

        const { role, data } = await loading.onDidDismiss();
    }

    async pageLoading() {
        const loading = await this.loadingController.create({
            message: 'Loading..',
            duration: 2500,
        });
        await loading.present();

        const { role, data } = await loading.onDidDismiss();
    }

    // private function that will fetch countries
    private countryList(): Observable<any> {
        return this.http.get(`${environment.COUNTRY_LIST}`);
    }
    // public function to reference the countries cache or make request
    get countries(): Observable<any> {
        if (!this.countries$) {
            this.countries$ = this.countryList().pipe(shareReplay(1));
        }
        return this.countries$;
    }

    // private requestTextContent(): Observable<any> {
    //     return this.http.get('./assets/content/text.json');
    // return this.http.get('https://amo-static-enc.s3.amazonaws.com/text.json').pipe(
    //    catchError(err => this.http.get('./assets/content/text.json'))
    // );
    // https://d6fj0ztu4j10m.cloudfront.net/text.json
    // this.http.get('https://amo-static-enc.s3.amazonaws.com/text.json')
    // this.http.get('./assets/content/text.json')
    // }

    countryCodeList = (): Observable<any> => {
        return this.http.get(`https://app.amopportunities.org/img/flags/country.json`);
    };

    get userIP(): Observable<any> {
        return this.userInfoIP();
    }
    private userInfoIP(): Observable<any> {
        return this.http.get('https://ipinfo.io?token=f5265c480312fb');
    }

    /**
     *
     * @author Malek Yala
     * @method requestContent
     * @returns Observable
     * @description returns cached content or calls the private requestContent Function
     * access this function by importing the provider this.profile.content.pipe()
     * or this.provider.content.subscribe
     *
     */
    get content(): Observable<any> {
        if (!this.text$) {
            this.text$ = this.requestAllContent().pipe(shareReplay(1));
        }
        return this.text$;
    }

    /**
     *
     * @method HTTP(GET)
     * @returns Observable
     * @author Malek
     * @description returns application program content
     * (content, countries, locations, schoolList, specialties, tags, types )
     */
    private requestAllContent(): Observable<any> {
        const contents = this.http.get(`${environment.API}/programs/content?ngsw-bypass=true`);
        return contents.pipe(
            map((res: any) => {
                const data = { ...res };
                return data;
            })
        );
    }

    // log no search results
    noSearchResultsLog = (data: any) => {
        return this.http.post(`https://gpe8hqxl6k.execute-api.us-east-1.amazonaws.com/dev/no-search-result`, data);
    };

    logProgramViews = (programId: string): void => {
        const xhr: XMLHttpRequest = new XMLHttpRequest();
        const jsonString = JSON.stringify({ programId });
        xhr.open('POST', 'https://x29y8g6jk1.execute-api.us-east-1.amazonaws.com/v0/logsearch', true);
        xhr.send(jsonString);
    };

    // Management endpoints*********************************************

    validateVisitorToken(token: any): Observable<any> {
        return this.http.post(`${environment.API}/managements/validatevisitortoken`, { token: token });
    }

    hostValidateVisitorToken(token: any): Observable<any> {
        return this.http.post(`${environment.API}/managements/hostingvalidatevisitortoken`, { token: token });
    }

    // end of Management endpoints*********************************************

    /**
     *
     * @param schedule schedule json object
     * @description cannot be sure of the order they are being formatted on backend yet, add them in M-Sun order
     */
    formatSchedule = (schedule: any): Array<{}> => {
        // blank array to hold the current schedule
        const templateSchedule: any[] = [];
        // check if the schedule is nilish, or empty
        if (!schedule || (Array.isArray(schedule) && schedule.length === 0)) {
            return [];
        } else {
            if (schedule.Monday) {
                if (schedule.Monday.time && schedule.Monday.time !== '') {
                    templateSchedule.push({
                        day: 'Monday',
                        time: schedule.Monday.time,
                    });
                }
            }
            if (schedule.Tuesday) {
                if (schedule.Tuesday.time && schedule.Tuesday.time !== '') {
                    templateSchedule.push({
                        day: 'Tuesday',
                        time: schedule.Tuesday.time,
                    });
                }
            }
            if (schedule.Wednesday) {
                if (schedule.Wednesday.time && schedule.Wednesday.time !== '') {
                    templateSchedule.push({
                        day: 'Wednesday',
                        time: schedule.Wednesday.time,
                    });
                }
            }
            if (schedule.Thursday) {
                if (schedule.Thursday.time && schedule.Thursday.time !== '') {
                    templateSchedule.push({
                        day: 'Thursday',
                        time: schedule.Thursday.time,
                    });
                }
            }
            if (schedule.Friday) {
                if (schedule.Friday.time && schedule.Friday.time !== '') {
                    templateSchedule.push({
                        day: 'Friday',
                        time: schedule.Friday.time,
                    });
                }
            }
            if (schedule.Saturday) {
                if (schedule.Saturday.time && schedule.Saturday.time !== '') {
                    templateSchedule.push({
                        day: 'Saturday',
                        time: schedule.Saturday.time,
                    });
                }
            }
            if (schedule.Sunday) {
                if (schedule.Sunday.time && schedule.Sunday.time !== '') {
                    templateSchedule.push({
                        day: 'Sunday',
                        time: schedule.Sunday.time,
                    });
                }
            }
        }

        return templateSchedule;
    };

    // copy folders in S3
    transferfilesforapplicationrequirementid = (applicationid: string, list: any) => {
        const data = {
            applicationId: applicationid,
            list: list.transferRequirements,
        };

        console.log('getting data', data);

        return this.http.post(`${environment.API}/applications/${applicationid}/transferfilesforapplicationrequirements`, data);
    };

    // list of field of studies
    listOfFieldOfStudies = (hasDepartments: boolean, allowProfileBlock?: boolean, userid?: string): Observable<any> => {
        if (userid) {
            return this.http.get(
                `${environment.API}/fieldofstudies/fieldofstudieslist?hasdepartments=${hasDepartments}&allowprofileblock=${allowProfileBlock}&userid=${userid}`
            );
        } else {
            return this.http.get(
                `${environment.API}/fieldofstudies/fieldofstudieslist?hasdepartments=${hasDepartments}&allowprofileblock=${allowProfileBlock}`
            );
        }
    };

    mapMarkers = (programs: any): Array<{}> => {
        let location = {};
        const geoLocations = [];

        programs.forEach((program: any) => {
            if (program.geoloc !== null) {
                const latLng = program.geoloc.split(',');
                location = {
                    programid: program.id,
                    center: {
                        latitude: latLng[0],
                        longitude: latLng[1],
                    },
                    name: program.name,
                };
                geoLocations.push(location);
            }
        });
        return geoLocations;
    };

    invalidFields = (myForm: FormGroup) => {
        const result = [];
        Object.keys(myForm.controls).forEach((key) => {
            const controlErrors: ValidationErrors = myForm.get(key).errors;
            if (controlErrors) {
                Object.keys(controlErrors).forEach((keyError) => {
                    result.push({
                        control: key,
                        error: keyError,
                        value: controlErrors[keyError],
                    });
                });
            }
        });
        return result;
    };

    getMonthsArray = (): string[] => {
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    };

    getSpecialtiesArray = (): Observable<any> => {
        return from(
            new Promise((resolve) =>
                resolve([
                    'Allergy & Immunology',
                    'Anesthesiology',
                    'Bariatric Surgery',
                    'Breast Surgery',
                    'Cardiac Surgery',
                    'Cardiology',
                    'Cardiovascular Disease',
                    'Cardiovascular Surgery',
                    'Child Psychiatry',
                    'Clinical Psychology',
                    'Colorectal Surgery',
                    'Cosmetic Dermatology',
                    'Cosmetic Surgery',
                    'Critical Care',
                    'Critical Care Surgery',
                    'Dentistry',
                    'Dermatologic Surgery',
                    'Dermatology',
                    'Dermatopathology',
                    'Diagnostic Radiology',
                    'Emergency Medicine',
                    'Endocrinology',
                    'Family Medicine',
                    'Foot Surgery',
                    'Gastroenterology',
                    'General Surgery',
                    'Geriatrics',
                    'Gynecology',
                    'Hematology',
                    'Infectious Disease',
                    'Internal Medicine',
                    'Musculoskeletal Medicine',
                    'Neonatology',
                    'Nephrology',
                    'Neuro-Oncology',
                    'Neurology',
                    'Neuropsychology',
                    'Neuroradiology',
                    'Neuroscience',
                    'Neurosurgery',
                    'Obstetrics & Gynecology',
                    'Oncology',
                    'Ophthalmic Pathology',
                    'Ophthalmology',
                    'Oral & Maxillofacial Surgery',
                    'Orthodontics',
                    'Orthopedic Surgery',
                    'Otolaryngology',
                    'PA',
                    'Pain Management',
                    'Pain Medicine',
                    'Pathology',
                    'Pediatric Cardiology',
                    'Pediatric Dentistry',
                    'Pediatric Dermatology',
                    'Pediatric Pulmonology',
                    'Pediatrics',
                    'Physical Medicine',
                    'Physical Therapy',
                    'Physicians Assistant',
                    'Plastic Surgery',
                    'Podiatry',
                    'Primary Care',
                    'Prosthodontics',
                    'Psychiatry',
                    'Pulmonary Disease',
                    'Pulmonology',
                    'Radiation Oncology',
                    'Radiology',
                    'Reconstructive Surgery',
                    'Reproductive Endocrinology',
                    'Rheumatology',
                    'Sleep Medicine',
                    'Spine Surgery',
                    'Sports Medicine',
                    'Surgery',
                    'Surgical Oncology',
                    'Teleradiology',
                    'Trauma Surgery',
                    'Urgent Care',
                    'Urology',
                    'Vascular Surgery',
                    "Women's Health",
                ])
            )
        );
    };
    /**
     * @description returns an array of all the US States spelled correctly
     */
    getUSAStatesArray = (): string[] => {
        return [
            'Alaska',
            'Alabama',
            'Arkansas',
            'American Samoa',
            'Arizona',
            'California',
            'Colorado',
            'Connecticut',
            'District of Columbia',
            'Delaware',
            'Florida',
            'Georgia',
            'Guam',
            'Hawaii',
            'Iowa',
            'Idaho',
            'Illinois',
            'Indiana',
            'Kansas',
            'Kentucky',
            'Louisiana',
            'Massachusetts',
            'Maryland',
            'Maine',
            'Michigan',
            'Minnesota',
            'Missouri',
            'Mississippi',
            'Montana',
            'North Carolina',
            ' North Dakota',
            'Nebraska',
            'New Hampshire',
            'New Jersey',
            'New Mexico',
            'Nevada',
            'New York',
            'Ohio',
            'Oklahoma',
            'Oregon',
            'Pennsylvania',
            'Puerto Rico',
            'Rhode Island',
            'South Carolina',
            'South Dakota',
            'Tennessee',
            'Texas',
            'Utah',
            'Virginia',
            'Virgin Islands',
            'Vermont',
            'Washington',
            'Wisconsin',
            'West Virginia',
            'Wyoming',
        ];
    };

    // Program is the current rotation being analyzed
    // Ref is an array of city strings the user has selected
    // Geo is an array of objects with city strings and that cities geoloc
    distanceFilter = (geoOne: string[], geoTwo: string[]): number => {
        const km = this.getDistanceFromLatLonInKm(parseFloat(geoOne[0]), parseFloat(geoOne[1]), parseFloat(geoTwo[0]), parseFloat(geoTwo[1]));
        return km;
    };

    // haversine formula
    // https://en.wikipedia.org/wiki/Haversine_formula

    getDistanceFromLatLonInKm = (lat1: any, lon1: any, lat2: any, lon2: any): number => {
        const R = 6371; // Radius of the earth in km
        const dLat = this.deg2rad(lat2 - lat1); // deg2rad below
        const dLon = this.deg2rad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c; // Distance in km
        return d;
    };

    deg2rad = (deg: any) => deg * (Math.PI / 180);
} // end of the tools service
