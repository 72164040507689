import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '@app/shared/angular.material.module';
import { SharedComponentsModule } from '@app/shared/components/shared.module';
import { PipesComponentsModule } from '@app/shared/pipes/pipes.modules';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { QuicklinkModule } from 'ngx-quicklink';
import { UserContactInformationComponent } from './components/user-contact-information/user-contact-information.component';
import { UserPersonalInformationComponent } from './components/user-personal-information/user-personal-information.component';
import { UniversityPickerComponentModule } from '@app/shared/components/university-picker.module';

@NgModule({
    declarations: [UserPersonalInformationComponent, UserContactInformationComponent],
    imports: [
        QuicklinkModule,
        IonicModule,
        CommonModule,
        PipesComponentsModule,
        SharedComponentsModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        UniversityPickerComponentModule,
        UniversityPickerComponentModule,
    ],
    exports: [UserPersonalInformationComponent, UserContactInformationComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserProfileComponentsModule {}
