import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { SearchGuard } from './core/guards/search/search.guard';
import { UserProfileGuard } from './core/guards/user-profile/user-profile.guard';
import { PageNotFoundComponent } from './modules/auth/components/page-not-found/page-not-found.component';
import { TempUserGuard } from './core/guards/temp-user/temp-user.guard';
const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full',
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./modules/auth/pages/login/login.module').then(
                m => m.LoginPageModule
            ),
    },
    {
        path: 'activation-warning',
        loadChildren: () =>
            import(
                './modules/auth/pages/activation-warning/activation-warning.page.module'
            ).then(m => m.ActivationWarningPageModule),
    },
    {
        path: 'signup',
        loadChildren: () =>
            import('./modules/auth/pages/signup/signup.module').then(
                m => m.SignupPageModule
            ),
    },
    {
        path: 'complete',
        loadChildren: () =>
            import('./modules/auth/pages/complete/complete.module').then(
                m => m.CompletePageModule
            ),
    },
    {
        path: 'activate',
        loadChildren: () =>
            import('./modules/auth/pages/activate/activate.module').then(
                m => m.ActivatePageModule
            ),
    },
    {
        path: 'forgot',
        loadChildren: () =>
            import('./modules/auth/pages/forgot/forgot.module').then(
                m => m.ForgotPageModule
            ),
    },
    {
        path: 'reset-password',
        loadChildren: () =>
            import(
                './modules/auth/pages/rest-password/rest-password.module'
            ).then(m => m.RestPasswordPageModule),
    },
    {
        path: 'sent-password',
        loadChildren: () =>
            import(
                './modules/auth/pages/sent-password-change/sent.password.change.page.module'
            ).then(m => m.SentPasswordChangeModule),
    },
    {
        path: 'search',
        loadChildren: () =>
            import(
                './modules/program/pages/program-search/program-search.module'
            ).then(m => m.SearchPageModule),
        canActivate: [SearchGuard],
    },
    {
        path: 'program',
        children: [
            {
                path: '',
                children: [
                    {
                        path: ':programid',
                        loadChildren: () =>
                            import(
                                './modules/program/pages/program-details/program-details.module'
                            ).then(m => m.DetailsPageModule),
                        canActivate: [TempUserGuard],
                    },
                    {
                        path: 'bundle/:programid',
                        loadChildren: () =>
                            import(
                                './modules/program/pages/program-bundle/program-bundle.module'
                            ).then(m => m.ProgramBundlePageModule),
                        canActivate: [TempUserGuard],
                    },
                ],
            },
        ],
    },
    {
        path: 'dashboard',
        loadChildren: () =>
            import(
                './modules/user/pages/user-dashboard/user-dashboard.module'
            ).then(m => m.DashboardPageModule),
        canActivate: [AuthGuard, UserProfileGuard],
    },
    {
        path: 'profile',
        children: [
            {
                path: '',
                loadChildren: () =>
                    import(
                        './modules/user/pages/user-profile/user-profile.module'
                    ).then(m => m.ProfilePageModule),
                canActivate: [AuthGuard, UserProfileGuard],
            },
            {
                path: 'travel-requirements',
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import(
                                '@modules/user/pages/travel-requirements/travel-requirements.module'
                            ).then(m => m.TravelRequirementsPageModule),
                        canActivate: [AuthGuard, UserProfileGuard],
                    },
                ],
            },
            {
                path: 'clinical-preferences',
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import(
                                './modules/user/pages/user-clinical-preferences/user-clinical-preferences.module'
                            ).then(m => m.UserClinicalPreferencesPageModule),
                        canActivate: [AuthGuard, UserProfileGuard],
                    },
                ],
            },
            {
                path: 'medical-education',
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import(
                                './modules/user/pages/user-medical-education/user-medical-education.module'
                            ).then(m => m.UserMedicalEducationPageModule),
                        canActivate: [AuthGuard, UserProfileGuard],
                    },
                ],
            },
            {
                path: 'personal-information',
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import(
                                './modules/user/pages/user-personal-information/user-personal-information.module'
                            ).then(m => m.UserPersonalInformationPageModule),
                        canActivate: [AuthGuard, UserProfileGuard],
                    },
                ],
            },
            {
                path: 'account-settings',
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import(
                                './modules/user/pages/user-settings/user-settings.module'
                            ).then(m => m.SettingsPageModule),
                        canActivate: [AuthGuard, UserProfileGuard],
                    },
                ],
            },
            {
                path: 'account-subscriptions',
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import(
                                './modules/user/pages/user-subscriptions/user-subscriptions.module'
                            ).then(m => m.UserSubscriptionsPageModule),
                        canActivate: [AuthGuard, UserProfileGuard],
                    },
                ],
            },
            {
                path: 'account-terms',
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import(
                                './modules/user/pages/user-account-terms/user-account-terms.module'
                            ).then(m => m.UserAccountTermsPageModule),
                        canActivate: [AuthGuard, UserProfileGuard],
                    },
                ],
            },
        ],
    },
    {
        path: 'services',
        loadChildren: () =>
            import(
                './modules/user/pages/user-services/user-services.module'
            ).then(m => m.UserServicesPageModule),
        canActivate: [AuthGuard, UserProfileGuard],
    },
    {
        path: 'contact',
        loadChildren: () =>
            import(
                './modules/application/pages/application-help-chat/application-help-chat.module'
            ).then(m => m.HelpChatPageModule),
        canActivate: [AuthGuard, UserProfileGuard],
    },
    {
        path: 'chat',
        loadChildren: () =>
            import(
                './modules/application/pages/application-help-chat/application-help-chat.module'
            ).then(m => m.HelpChatPageModule),
        canActivate: [AuthGuard, UserProfileGuard],
    },
    {
        path: 'payment',
        loadChildren: () =>
            import(
                './modules/payment/pages/payment-details/payment-details.module'
            ).then(m => m.PaymentPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'twofactor',
        loadChildren: () =>
            import('./modules/auth/pages/two-factor/two-factor.module').then(
                m => m.TwoFactorPageModule
            ),
    },
    {
        path: 'application',

        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/dashboard',
            },
            {
                path: ':applicationid',
                pathMatch: 'full',
                loadChildren: () =>
                    import(
                        './modules/application/pages/application-details/application-details.module'
                    ).then(m => m.StatusPageModule),
                canActivate: [AuthGuard, UserProfileGuard],
            },
            {
                path: ':applicationid/clinical-agreement',
                loadChildren: () =>
                    import(
                        './modules/application/pages/application-clinical-agreement/application-clinical-agreement.module'
                    ).then(m => m.HelloSignPageModule),
                canActivate: [AuthGuard, UserProfileGuard],
            },
        ],
    },
    {
        path: 'documents',
        children: [
            {
                path: '',
                loadChildren: () =>
                    import(
                        './modules/document/pages/document-details/documents.module'
                    ).then(m => m.DocumentsPageModule),
                canActivate: [AuthGuard, UserProfileGuard],
            },
            {
                path: 'receipts',
                loadChildren: () =>
                    import(
                        './modules/document/pages/document-receipts/receipts.module'
                    ).then(m => m.ReceiptsPageModule),
                canActivate: [AuthGuard, UserProfileGuard],
            },
            {
                path: 'resources',
                loadChildren: () =>
                    import(
                        './modules/document/pages/document-resources/document-resources.module'
                    ).then(m => m.ResourcesPageModule),
                canActivate: [AuthGuard, UserProfileGuard],
            },
            {
                path: 'personal',
                loadChildren: () =>
                    import(
                        './modules/document/pages/document-personal/personal.module'
                    ).then(m => m.PersonalPageModule),
                canActivate: [AuthGuard, UserProfileGuard],
            },
            {
                path: 'application',
                loadChildren: () =>
                    import(
                        './modules/document/pages/application/document-application.module'
                    ).then(m => m.DocumentsApplicationPageModule),
                canActivate: [AuthGuard, UserProfileGuard],
            },
        ],
    },
    {
        path: '__auth',
        loadChildren: () =>
            import(
                './modules/auth/pages/central-landing/central-landing.module'
            ).then(m => m.CentralLandingPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'my-profile-block',
        loadChildren: () =>
            import(
                './modules/user/pages/user-profile-block/user-profile-block.module'
            ).then(m => m.UserProfileBlockPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'rest-password',
        loadChildren: () =>
            import(
                './modules/auth/pages/rest-password/rest-password.module'
            ).then(m => m.RestPasswordPageModule),
    },
    {
        path: 'payment-success',
        loadChildren: () =>
            import(
                './modules/payment/pages/payment-success/payment-success.module'
            ).then(m => m.PaymentSuccessPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'wire-transfer-details',
        loadChildren: () =>
            import(
                './modules/payment/pages/wire-transfer-details/wire-transfer-details.module'
            ).then(m => m.WireTransferDetailsPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'payment-booking',
        loadChildren: () =>
            import(
                './modules/payment/pages/payment-booking/payment-booking.module'
            ).then(m => m.PaymentBookingPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'favorites',
        loadChildren: () =>
            import(
                './modules/favorite/pages/favorites-list/favorites-list.module'
            ).then(m => m.FavoritesListPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'application-chain',
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/itinerary-create',
            },
            {
                path: ':applicationchainid',
                pathMatch: 'full',
                loadChildren: () =>
                    import(
                        './modules/application-chain/pages/application-chain/application-chain.module'
                    ).then(m => m.ApplicationChainPageModule),
                canActivate: [AuthGuard, UserProfileGuard],
            },
        ],
    },
    {
        path: 'itinerary-create',
        loadChildren: () =>
            import(
                './modules/itinerary/pages/itinerary-create/itinerary-create.module'
            ).then(m => m.ItineraryCreatePageModule),
        canActivate: [AuthGuard, UserProfileGuard],
    },
    {
        // ** ALWAYS KEEP LAST OR ELSE YOURE GONNA HAVE A BAD TIME MMMM'KAY */
        path: '**',
        component: PageNotFoundComponent,
    },
];

@NgModule({
    imports: [
        QuicklinkModule,
        RouterModule.forRoot(routes, {
            preloadingStrategy: QuicklinkStrategy,
            // onSameUrlNavigation: 'reload',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
