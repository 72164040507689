import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedSidebarComponentsModule } from '@app/shared/components/sidebar.module';
import { PipesComponentsModule } from '@app/shared/pipes/pipes.modules';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { ProgramDetailsCardComponent } from '../program/components/program-details-card/program-details-card.component';
// eslint-disable-next-line max-len
import { ProgramDetailsDescriptionComponent } from '../program/components/program-details-description/program-details-description.component';
import { ProgramScheduleComponent } from '../program/components/program-schedule/program-schedule.component';
import { ProgramComponentsModule } from '../program/program.module';
import { ApplicationSummaryComponent } from './components/application-program-summary/application-program-summary.component';
import { ProgramInfoModalComponent } from './components/program-info-modal/program-info-modal.component';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
    declarations: [
        ProgramScheduleComponent,
        ProgramDetailsCardComponent,
        ProgramDetailsDescriptionComponent,
        ProgramScheduleComponent,
        ApplicationSummaryComponent,
        ProgramInfoModalComponent,
    ],
    imports: [IonicModule, CommonModule, RouterModule, FontAwesomeModule, PipesComponentsModule, SharedSidebarComponentsModule, GoogleMapsModule],
    exports: [
        ProgramScheduleComponent,
        ProgramDetailsCardComponent,
        ProgramDetailsDescriptionComponent,
        ProgramScheduleComponent,
        ApplicationSummaryComponent,
        ProgramInfoModalComponent,
    ],
})
export class ApplicationProgramComponentsModule {}
