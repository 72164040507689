import { Component, Input, OnInit } from '@angular/core';
import { userSelectors } from '@app/core/ngrx/selectors';
import { ToolService } from '@app/core/services/tool/tool.service';
import { faAngleLeft, faCheckCircle, faTimes, faUpload, faPlane, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { State } from '@app/core/ngrx/reducers';

@Component({
    selector: 'app-application-apply-success',
    templateUrl: './application-apply-success.component.html',
    styleUrls: ['./application-apply-success.component.scss'],
})
export class ApplicationApplySuccess implements OnInit {
    @Input() message: any;
    @Input() submessage: any;

    faCheckCircle = faCheckCircle;
    faAngleLeft = faAngleLeft;
    faUpload = faUpload;
    faTimes = faTimes;
    faPlane = faPlane;
    faCalendarCheck = faCalendarCheck;
    modalTab: number = 1;

    USER_EMAIL$ = this.store.select(userSelectors.getUserEmail);
    USER_EMAIL: string;

    constructor(private modalCtrl: ModalController, public toolService: ToolService, private store: Store<State>) {}

    ngOnInit() {
        this.USER_EMAIL$.subscribe(
            (useremail: string) => (this.USER_EMAIL = useremail)
        );
    }

    // manual close
    close() {
        try {
            this.modalCtrl.dismiss();
        } catch (e) {
            // failed to dismiss probably already happened
        }
    }

    modalNextHandler() {
        this.modalTab += 1;
        if (this.modalTab > 3) {
            // setTimeout(() => {
            //     this.close();
            // }, 2500);
        }
    }

    modalPrevHandler() {
        this.modalTab -= 1;
    }
}
