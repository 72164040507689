// import { environment } from 'src/environments/environment';
// import {environment}
import { environment } from '../src/environments/environment';
// resource: https://docs.amplify.aws/lib/auth/getting-started/q/platform/js/#set-up-backend-resources
// Amplify.configure({
//     Auth: {
//         region: environment.COGNITO_REGION,
//         userPoolId: environment.COGNITO_USER_POOL,
//         userPoolWebClientId: environment.COGNTIO_APP_CLIENT_ID,
//         authenticationFlowType: environment.COGNITO_FLOW_TYPE
//     },
// });
const awsconfig = {
    region: environment.COGNITO_REGION,
    userPoolId: environment.COGNITO_USER_POOL,
    userPoolWebClientId: environment.COGNTIO_APP_CLIENT_ID,
    authenticationFlowType: environment.COGNITO_FLOW_TYPE,
};
export default awsconfig;
